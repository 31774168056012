import { FC, ReactElement, useEffect, useState } from "react";
import { Header } from "antd/lib/layout/layout";
import { Button, Dropdown, Menu, Popover, Tooltip } from "antd";
import { UserLogout } from "../../Utils/UserLogout";
import { FiBell, FiUser } from "react-icons/fi";
import "./HeaderComponent.styles.scss";
import { AiOutlinePlus } from "react-icons/ai";
import { ReactSVG } from "react-svg";
import Modal from "antd/lib/modal/Modal";
import { useSelector } from "react-redux";
import {
	HiOutlineChevronDoubleLeft,
	HiOutlineChevronDoubleRight,
} from "react-icons/hi";
import { BsLightning } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { CreateDocument } from "../../Apis/Firebase/CreateDocument";
import SignoutIcon from "../../Images/SignoutIcon.svg";
import InviteIcon from "../../Images/InviteIcon.svg";
import HelpIcon from "../../Images/HelpIcon.svg";
import InboxIcon from "../../Images/Inbox.svg";
import { isGoogleIntegrationEnabled } from "../../Apis/Integration/google";
import { togglePipelineCreateModal } from "../../Store/ActionReducerPairs/Pipeline";
import { useAppDispatch } from "../../hooks";
import { BiCommand } from "react-icons/bi";
import { CreateFormComponent } from "../Forms/ObjectCreationForms/CreateFormComponent";
import { CreateLogActivityFormComponent } from "../Forms/ObjectCreationForms/CreateLogActivityFormComponent";
import { TopInsightsPipelineDataRenderComponent } from "./TopInsightsPipelineDataRenderComponent";
import clsx from "clsx";
import store from "../../Store/Store";

export const HeaderComponent: FC<{
	collapsed: boolean;
	onCollapse: any;
	onInvite: any;
	pageTitle?: string | ReactElement;
}> = ({ collapsed, onCollapse, onInvite, pageTitle }) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [isGoogleAuthed, setIsGoogleAuthed] = useState<boolean>(false);
	const [popupVisible, setPopupVisible] = useState(false);
	const [newEventData] = useState<Record<string, string>>({});
	const [selectedMenu, setSelectedMenu] = useState<any>();
	const storePipeline = useSelector((state: any) => state.pipeline);

	useEffect(() => {
		isGoogleIntegrationEnabled().then((res: boolean) => setIsGoogleAuthed(res));
		const addCallbackHandlerToCommandBar = (
			callbackId: string,
			categoryName: string,
		) => {
			window.CommandBar.addCallback(callbackId, () => {
				const modalData = categories.find((item) => item.name === categoryName);
				setSelectedMenu({ name: modalData?.name, iconUrl: modalData?.iconUrl });
				togglePipelineCreateModal({
					modalVisible: true,
					selectedModal: { name: modalData?.name, iconUrl: modalData?.iconUrl },
				});
			});
		};

		addCallbackHandlerToCommandBar("create_new_account", "Account");
		addCallbackHandlerToCommandBar("create_new_contact", "Contact");
		addCallbackHandlerToCommandBar("create_new_opportunity", "Opportunity");
		addCallbackHandlerToCommandBar("create_new_lead", "Lead");
		addCallbackHandlerToCommandBar("create_new_activity", "Log New Activity");
	}, []);

	const openNewEventDrawer = (data: any) => {
		dispatch({
			type: "OPEN_DRAWER",
			payload: {
				drawerType: "new-event",
				opportunityId: data.opportunityId,
				opportunityName: data.opportunityName,
			},
		});
	};

	interface CategoryType {
		fieldText: string;
		name: string;
		iconUrl: string;
	}

	const categories: CategoryType[] = [
		{
			fieldText: "Opportunity",
			name: "Opportunity",
			iconUrl: "/images/icons/SFDC-objects/opportunities.svg",
		},
		{
			fieldText: "Account",
			name: "Account",
			iconUrl: "/images/icons/SFDC-objects/accounts.svg",
		},
		{
			fieldText: "Contact",
			name: "Contact",
			iconUrl: "/images/icons/SFDC-objects/contacts.svg",
		},
		{
			fieldText: "Lead",
			name: "Lead",
			iconUrl: "/images/icons/SFDC-objects/leads.svg",
		},
		...(isGoogleAuthed
			? [
					{
						fieldText: "Invite",
						name: "New Meeting",
						iconUrl: "/images/icons/CalenderIcon.svg",
					},
			  ]
			: []),
		{
			fieldText: "Log New Activity",
			name: "Log New Activity",
			iconUrl: "/images/icons/activity.svg",
		},
		{
			fieldText: "New Document",
			name: "New Document",
			iconUrl: "/images/icons/NewDocument.svg",
		},
	];

	const createIconClick = (name: any, iconUrl: any) => {
		if (name == "New Document") {
			CreateDocument().then((res) => {
				navigate("/document-hub/notes-page/" + res);
			});
		} else {
			setSelectedMenu({ name: name, iconUrl: iconUrl });
			togglePipelineCreateModal({
				modalVisible: true,
				selectedModal: { name: name, iconUrl: iconUrl },
			});
		}
	};

	const userDropdownItems = [
		{
			name: "Invite",
			icon: InviteIcon,
			clickHandler: onInvite,
		},
		{
			name: "Help",
			icon: HelpIcon,
			clickHandler: () => {
				// @ts-ignore
				Intercom("show");
			},
		},
		{
			name: "Add to Chrome",
			icon: InboxIcon,
			clickHandler: () => {
				window.open(
					"https://chrome.google.com/webstore/detail/pod-sales-productivity-wo/gccbjkelbedcjkomaakkdllmppboeoca",
					"_blank",
				);
			},
		},
		{
			name: "Sign out",
			icon: SignoutIcon,
			clickHandler: UserLogout,
		},
	];
	const userDropdown = (
		<div className="user-dropdown-parent">
			{userDropdownItems.map((item, i) => (
				<div
					key={i}
					className="user-dropdown-child"
					onClick={item.clickHandler}
				>
					<img src={item.icon} alt="" />
					<p style={{ display: "inline-block" }}>{item.name}</p>
				</div>
			))}
		</div>
	);

	const createOptionLinks = (
		<Menu className="common_header_create">
			{categories.map(({ name, iconUrl }: any, index: number) => (
				<Menu.Item key={index} onClick={() => createIconClick(name, iconUrl)}>
					<div className="px-2 py-1">
						<ReactSVG wrapper="span" src={iconUrl} /> {name}
					</div>
				</Menu.Item>
			))}
		</Menu>
	);

	useEffect(() => {
		if (
			selectedMenu &&
			selectedMenu.name &&
			selectedMenu.name === "New Meeting"
		) {
			openNewEventDrawer(newEventData);
		}
	}, [selectedMenu]);

	return (
		<Header className={`common_header px-3 ${collapsed ? "closed" : ""}`}>
			<div className="d-flex align-items-center common_header_wrp">
				<div className="col-md-7">
					<div
						className="common_aside_toggler trigger"
						onClick={() => onCollapse(collapsed)}
					>
						{collapsed ? (
							<HiOutlineChevronDoubleRight />
						) : (
							<HiOutlineChevronDoubleLeft />
						)}
					</div>

					<div className="common_header_wrp_title">{pageTitle}</div>
				</div>

				<div className="col-md-5 text-end common_header_wrp_right">
					{window.CommandBar && (
						<div
							className="commandbar_launcher"
							onClick={() => {
								window.CommandBar?.open?.();
							}}
						>
							Search...
							<div className="shortcuts">
								<BiCommand />
								<span>K</span>
							</div>
						</div>
					)}
					<Dropdown
						trigger={["click"]}
						overlay={createOptionLinks}
						destroyPopupOnHide
					>
						<Button type="primary" className="custom_btn">
							<AiOutlinePlus
								size={14}
								className="me-2"
								style={{ verticalAlign: "-2px" }}
							/>{" "}
							Create
						</Button>
					</Dropdown>
					{window.location.href.includes("pipeline-view") && (
						<Tooltip
							title={
								storePipeline.category !== "opportunities"
									? "Coming soon..."
									: undefined
							}
						>
							<Popover
								overlayClassName="pip_actions_insights_wrapper"
								content={<TopInsightsPipelineDataRenderComponent />}
								trigger={"click"}
								destroyTooltipOnHide
								placement="bottomRight"
								visible={
									popupVisible && storePipeline.category === "opportunities"
								}
								onVisibleChange={setPopupVisible}
							>
								<span
									className={
										"common_header_wrp_right--icon" +
										(storePipeline.category !== "opportunities"
											? "--disabled"
											: "")
									}
								>
									<BsLightning size={18} color="#777" />
								</span>
							</Popover>
						</Tooltip>
					)}
					{!window.location.href.includes("activity-hub") &&
						!window.location.href.includes("pipeline-view") &&
						!window.location.href.includes("document-hub") && (
							<span className="common_header_wrp_right--icon">
								<BsLightning size={18} color="#777" />
							</span>
						)}
					<span className="common_header_wrp_right--icon">
						<FiBell size={20} color="#777" />
					</span>
					<Dropdown
						placement="topRight"
						arrow
						overlay={userDropdown}
						trigger={["click"]}
					>
						<span className="me-1 common_header_wrp_right--icon">
							<FiUser size={20} color="#777" />
						</span>
					</Dropdown>
				</div>
			</div>
			{storePipeline.modalVisible &&
				storePipeline.selectedModal &&
				storePipeline.selectedModal.name !== "New Meeting" && (
					<Modal
						centered
						className="common_header_create_modal"
						visible={storePipeline.modalVisible}
						destroyOnClose
						onCancel={() =>
							togglePipelineCreateModal({
								modalVisible: false,
								selectedModal: {},
							})
						}
						zIndex={1020}
						title={
							<h5 className="py-2 my-0">
								<ReactSVG
									wrapper="span"
									src={storePipeline.selectedModal.iconUrl || ""}
								/>{" "}
								<strong>
									{" "}
									{storePipeline.selectedModal &&
									storePipeline.selectedModal.name !== "Log New Activity"
										? "Create " + storePipeline.selectedModal.name
										: storePipeline.selectedModal.name}{" "}
								</strong>
							</h5>
						}
						footer={null}
					>
						<div className="common_header_create_form">
							{["Opportunity", "Account", "Contact", "Lead", "Event"].includes(
								storePipeline.selectedModal.name,
							) ? (
								<CreateFormComponent
									objectType={storePipeline.selectedModal.name}
									initialState={storePipeline.selectedModal.initialState || {}}
								/>
							) : null}
							{storePipeline.selectedModal.name == "Log New Activity" ? (
								<CreateLogActivityFormComponent />
							) : null}
						</div>
					</Modal>
				)}
		</Header>
	);
};
