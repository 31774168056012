import api from "./api";

export const SyncActivity = async (
	subject: string,
	start_date_time: string,
	end_date_time: string,
	description: string,
	sfdc_opportunity_id: string,
	participants: Array<string>,
	google_reference_id: string,
	google_source: string,
) => {
	return api
		.post("/sync_meeting_to_sfdc", {
			pod_user_id: Number(localStorage.getItem("podUserId")),
			subject,
			start_date_time,
			end_date_time,
			description,
			sfdc_opportunity_id,
			participant_list: participants,
			google_reference_id,
			google_source,
		})
		.then((res) => res.data);
};
