import { AnyAction } from "redux";
import { makeAction } from "./Action";

interface Modal {
	visible: boolean;
}

interface UpdateRecordErrorModal extends Modal {
	errorMessage: string;
}

const defaultState: Partial<{
	updateRecordError: UpdateRecordErrorModal;
}> = {
	updateRecordError: undefined,
};

export const setUpdateRecordErrorModal = makeAction<
	UpdateRecordErrorModal | undefined
>("SET_UPDATE_RECORD_ERROR_MODAL");

export const modalReducer = (
	state = defaultState,
	action: AnyAction,
): typeof defaultState => {
	if (setUpdateRecordErrorModal.guard(action)) {
		return {
			...state,
			updateRecordError: action.payload,
		};
	}

	return state;
};
