import { Button, Form, InputNumber } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { BsCurrencyDollar } from "react-icons/bs";
import "./PipelineTextEditorComponent.styles.scss";

interface Props {
	value?: string;
	big?: boolean;
	type?: any;
	UpdateTextorNumberType: (value?: string, data?: any, name?: string) => void;
	updateData?: any;
	length?: number;
	nullable?: boolean;
	textForm: FormInstance;
	setShouldRequestUpdate: (a: boolean) => void;
}

const PipelineNumberUpdaterComponent = ({
	value,
	big,
	type,
	UpdateTextorNumberType,
	updateData,
	length,
	nullable,
	textForm,
	setShouldRequestUpdate,
}: Props) => {
	const onFinishUpdate = ({ numberField }: { numberField?: string }) => {
		setShouldRequestUpdate(false);
		UpdateTextorNumberType(numberField, updateData);
	};

	const doManualSubmit = () => {
		const updateData = {
			numberField: textForm.getFieldValue("numberField"),
		};
		onFinishUpdate(updateData);
	};

	const renderNumberFormSection = () => (
		<Form.Item
			name="numberField"
			className="mb-1"
			rules={[{ required: true, message: "Please input a number!" }]}
		>
			<InputNumber
				size="large"
				prefix={
					["currency", "money"].includes(type) ? (
						<BsCurrencyDollar />
					) : ["percent"].includes(type) ? (
						"%"
					) : (
						""
					)
				}
				onPressEnter={doManualSubmit}
				onClick={(e) => e.stopPropagation()}
				placeholder="Enter your new value"
				className="w-100"
				formatter={
					["currency", "money"].includes(type)
						? (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
						: (value) => `${value || ""}`
				}
				style={{ fontSize: 14 }}
			/>
		</Form.Item>
	);

	const renderFormFooter = () => (
		<div className="text_editor_bottom d-flex justify-content-between align-items-center w-100">
			<div>
				<strong>click outside</strong> to close
			</div>
			<Button
				type="text"
				className="text_editor_bottom--submit"
				htmlType="submit"
			>
				Update
			</Button>
		</div>
	);

	return (
		<Form
			className={`pip_col_editor text_editor ${big ? "big" : ""}`}
			onFinish={onFinishUpdate}
			form={textForm}
			initialValues={{ numberField: value, textField: value }}
			onChange={() => setShouldRequestUpdate(true)}
		>
			{renderNumberFormSection()}
			{renderFormFooter()}
		</Form>
	);
};

export default PipelineNumberUpdaterComponent;
