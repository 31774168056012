import { firebaseApp } from "./Config";
import {
	collection,
	query,
	where,
	getDocs,
	getFirestore,
} from "firebase/firestore";

export const GetUserDocuments = async (
	opportunityId?: string,
	accountId?: string,
) => {
	const db = getFirestore(firebaseApp);
	const pod_user_id = localStorage.getItem("podUserId");
	const sfdc_instance_url = localStorage.getItem("sfdcInstanceUrl");
	const searchText = localStorage.getItem("documentHubSearch");
	const {
		opportunity,
		account,
		stakeholders,
		createdOn,
		myDocs,
		privateDocs,
		documentType,
	} = JSON.parse(localStorage.getItem("documentHubFilters") || "{}");

	const queries = [
		where("sfdc_instance_url", "==", sfdc_instance_url),
		...(myDocs ? [where("pod_user_id", "==", pod_user_id)] : []),
		...(privateDocs ? [where("is_private", "==", true)] : []),
		...(opportunity?.id || opportunityId
			? [where("opportunity_id", "==", opportunity.id || opportunityId)]
			: []),
		...(account?.id || accountId
			? [where("account_id", "==", account?.id || accountId)]
			: []),
		...(stakeholders?.length
			? [where("stakeholders", "array-contains-any", stakeholders || [])]
			: []),
		...(createdOn ? [where("event_date", "==", createdOn)] : []),
		...(documentType ? [where("document_type", "==", documentType)] : []),
	];

	const q = query(collection(db, "documents"), ...queries);
	const querySnapshot = await getDocs(q);

	return querySnapshot.docs.filter((item) => {
		const data = item.data();
		const bySearch = searchText
			? data.title.toLowerCase().includes(searchText.toLocaleLowerCase())
			: true;
		const byAccess = !data.is_private || data.pod_user_id === pod_user_id;
		return bySearch && byAccess;
	});
};
