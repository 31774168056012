import { Button, Dropdown, Input, Radio, Space, Spin, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import {
	AiFillCheckCircle,
	AiOutlineArrowUp,
	AiOutlineQuestionCircle,
	AiOutlineSearch,
} from "react-icons/ai";
import { BsPerson } from "react-icons/bs";
import { MdClose, MdRefresh } from "react-icons/md";
import { ReactSVG } from "react-svg";

const Placeholder = ({ onAsk }: { onAsk: (query: string) => void }) => {
	const templateQuestions = [
		"What is the main product of the company?",
		"What was the summary of the last meeting?",
		"What was discussed in the previous meeting?",
		"Who is the main stakeholder on this deal?",
	];

	return (
		<div className="ask_contents_placeholder">
			<div className="ask_contents_placeholder--title">
				<ReactSVG src="/images/icons/NewCampaign.svg" />
				<div>Ask Anything - Coming Soon!</div>
			</div>
			<div className="ask_contents_placeholder--queries">
				{templateQuestions.map((item) => (
					<div
						className="ask_contents_placeholder--query"
						key={item}
						onClick={() => onAsk(item)}
					>
						{item}
					</div>
				))}
			</div>
		</div>
	);
};

const AskQuestion = ({
	data,
	onReset,
}: { data: any; onReset: VoidFunction }) => {
	return (
		<div className="ask_contents_item">
			<div className="ask_contents_item--avatar">
				<BsPerson size={20} color="white" />
			</div>
			<div className="ask_contents_item--content">{data.content}</div>
			<div className="ask_contents_item--action">
				<Tooltip placement="bottomRight" title="Reset Chat">
					<MdRefresh size={20} color="#7D828C" onClick={onReset} />
				</Tooltip>
			</div>
		</div>
	);
};

const AskFeedback = ({ onClose }: { onClose: VoidFunction }) => {
	const [saving, setSaving] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState(false);

	const handleFeedbackSubmit = async () => {
		setSaving(true);
		await new Promise((resolve) => setTimeout(() => resolve(true), 1000));
		setSaving(false);
		setIsSubmitted(true);
		setTimeout(() => {
			onClose();
		}, 2000);
	};

	return (
		<div className="ask_contents_feedback" onClick={(e) => e.stopPropagation()}>
			<div className="ask_contents_feedback--close" onClick={onClose}>
				<MdClose />
			</div>
			<div className="ask_contents_feedback--title">Give us feedback</div>
			{saving ? (
				<div className="ask_contents_feedback--submit">
					<Spin />
					<div className="ask_contents_feedback--submitted">Saving...</div>
				</div>
			) : isSubmitted ? (
				<div className="ask_contents_feedback--submit">
					<AiFillCheckCircle />
					<div className="ask_contents_feedback--submitted">
						Feedback Submitted
					</div>
				</div>
			) : (
				<Radio.Group onChange={handleFeedbackSubmit}>
					<Space direction="vertical" className="ask_contents_feedback--radios">
						<Radio value="accurate">Accurate</Radio>
						<Radio value="false">False data</Radio>
						<Radio value="relevant">Relevant</Radio>
						<Radio value="irrelevant">Irrelevant</Radio>
					</Space>
				</Radio.Group>
			)}
		</div>
	);
};

const AskAnswer = ({ data }: { data: any }) => {
	const [len, setLen] = useState<number>(0);
	const [showFeedback, setShowFeedback] = useState<boolean>(false);

	useEffect(() => {
		if (data.content && len < data.content.length) {
			setTimeout(() => {
				setLen((l) => l + 1);
			}, 10);
		}
	}, [len]);

	return (
		<div className="ask_contents_item answer">
			<ReactSVG src="/images/icons/NewCampaign.svg" />
			<div className="ask_contents_item--content">
				{data.content.slice(0, len)}
				<span className="caret" />
			</div>
			<div className="ask_contents_item--action">
				<Dropdown
					overlay={<AskFeedback onClose={() => setShowFeedback(false)} />}
					placement="bottomRight"
					trigger={["click"]}
					visible={showFeedback}
					onVisibleChange={(visible) => setShowFeedback(visible)}
				>
					<AiOutlineQuestionCircle size={20} color="#7D828C" />
				</Dropdown>
			</div>
		</div>
	);
};

export const AskAnythingTab = ({ data }: { data: any }) => {
	const [query, setQuery] = useState("");
	const [loading, setLoading] = useState(false);
	const [contents, setContents] = useState<any[]>([]);

	const handleAsk = async (query: string) => {
		if (query) {
			setQuery("");
			setLoading(true);

			try {
				await new Promise((resolve) =>
					setTimeout(() => {
						setContents([
							{
								type: "question",
								name: "JP",
								content: query,
							},
							{
								type: "answer",
								content:
									"Pod Chat will be launched soon. Stay posted for more details!",
							},
						]);
						resolve({});
					}, 3000),
				);
			} catch (e) {
				console.log(e);
			} finally {
				setLoading(false);
			}
		}
	};

	const handleReset = () => {
		setContents([]);
	};

	return (
		<div className="pod_drawer_tab ask_anything">
			<div className="ask_contents">
				{loading ? (
					<div className="ask_contents_loading">Finding you an answer…</div>
				) : contents?.length ? (
					contents.map((item, index) => (
						<React.Fragment key={index}>
							{item.type === "question" ? (
								<AskQuestion data={item} onReset={handleReset} />
							) : (
								<AskAnswer data={item} />
							)}
						</React.Fragment>
					))
				) : (
					<Placeholder onAsk={handleAsk} />
				)}
			</div>
			<div className="ask_input">
				<Input.Group compact className="ask_input--group">
					<AiOutlineSearch size={24} color="#7D828C" />
					<Input.TextArea
						autoSize={{ minRows: 1, maxRows: 5 }}
						value={query}
						onChange={(e) => setQuery(e.target.value)}
						placeholder="Tell me something about this opportunity"
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								e.preventDefault();
								void handleAsk(query);
							}
						}}
					/>
					<Button
						type="primary"
						shape="circle"
						icon={<AiOutlineArrowUp />}
						onClick={() => handleAsk(query)}
						disabled={!query}
					/>
				</Input.Group>
			</div>
		</div>
	);
};
