import { firebaseApp } from "./Config";
import {
	doc,
	setDoc,
	getFirestore,
	collection,
	query,
	where,
	getDocs,
} from "firebase/firestore";
import moment from "moment";

const GetUserFilters = async (pod_user_id: string, db: any) => {
	const q = query(
		collection(db, "master_filters"),
		where("pod_user_id", "==", pod_user_id),
	);
	const querySnapshot = await getDocs(q);

	return querySnapshot.docs[0];
};

export const UpdateMasterFilters = async (filters: any) => {
	const db = getFirestore(firebaseApp);
	const pod_user_id = localStorage.getItem("podUserId");

	const userFilter = await GetUserFilters(pod_user_id!, db);

	const reference = userFilter ? userFilter.id : `${pod_user_id}${Date.now()}`;
	const document = doc(db, "master_filters", reference);

	await setDoc(document, {
		pod_user_id,
		filters,
		last_modified_at: moment().format().split("T")[0],
	});
};

export const SyncMasterFilters = async (
	filters: Record<string, string>[] = [],
) => {
	const db = getFirestore(firebaseApp);
	const pod_user_id = localStorage.getItem("podUserId");

	const userFilter = await GetUserFilters(pod_user_id!, db);

	const reference = userFilter ? userFilter.id : `${pod_user_id}${Date.now()}`;
	const document = doc(db, "master_filters", reference);

	const parsedFilters = filters.map((filter) => ({
		Favorite: Boolean(
			userFilter?.data().filters.find((i: any) => i.Id === filter.Id)?.Favorite,
		),
		...filter,
	}));

	await setDoc(document, {
		pod_user_id,
		filters: parsedFilters,
		last_modified_at: moment().format().split("T")[0],
	});

	return parsedFilters;
};
