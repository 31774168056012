import { useEffect, useState } from "react";
import { Collapse, Skeleton, Tooltip } from "antd";
import { ReactSVG } from "react-svg";
import { AiFillCaretDown } from "react-icons/ai";
import { getNotesFieldUpdates } from "../../../../../Apis/NotesAssistant/fieldUpdates";
import { Note } from "../NotesAssistantDrawer";
import { togglePipelineCreateModal } from "../../../../../Store/ActionReducerPairs/Pipeline";
import { getNotesNextSteps } from "../../../../../Apis/NotesAssistant/nextSteps";
import { getNotesSummary } from "../../../../../Apis/NotesAssistant/summary";

const iconUrls: { [key: string]: string } = {
	Opportunity: "/images/icons/SFDC-objects/opportunities.svg",
	Account: "/images/icons/SFDC-objects/accounts.svg",
	Contact: "/images/icons/SFDC-objects/contacts.svg",
	Lead: "/images/icons/SFDC-objects/leads.svg",
	Custom: "/images/icons/Shared.svg",
};

export const FieldUpdatesTab = ({ note }: { note: Note }) => {
	const [fieldUpdates, setFieldUpdates] = useState<string[] | undefined>(
		undefined,
	);

	useEffect(() => {
		(async () => {
			for await (const value of getNotesFieldUpdates(
				note.type,
				note.content,
				note.stakeholders,
			)) {
				setFieldUpdates((prevState) => (prevState ?? []).concat(value));
			}
			setFieldUpdates((prevState) => prevState ?? []);
		})();
	}, []);

	const handleSync = (
		createModalName: string,
		iconUrl: string,
		contactName: string,
	) => {
		const lastSpaceIndex = contactName.lastIndexOf(" ");
		const initialState =
			lastSpaceIndex === -1
				? {
						FirstName: contactName,
				  }
				: {
						FirstName: contactName.substring(0, lastSpaceIndex),
						LastName: contactName.substring(lastSpaceIndex + 1),
				  };
		togglePipelineCreateModal({
			modalVisible: true,
			selectedModal: {
				name: createModalName,
				iconUrl: iconUrl,
				initialState: initialState,
			},
		});
	};

	const renderFieldUpdates = () => {
		switch (fieldUpdates) {
			case undefined:
				return <Skeleton active paragraph={{ rows: 10 }}></Skeleton>;
			case null:
				return <p>No field updates detected</p>;
			default:
				return (
					<Collapse
						bordered={false}
						expandIcon={() => <AiFillCaretDown size={16} />}
						expandIconPosition="right"
						className="notes_assistant_tab updated_fields"
					>
						{
							// fieldUpdates.map((record, i) => (
							<Collapse.Panel
								className="updated_fields_record"
								key=""
								header={
									<>
										<ReactSVG src={iconUrls.Contact} size={18} />
										<div className="updated_fields_record--name">
											Suggested Contacts
										</div>
									</>
								}
							>
								<div className="updated_fields_record--items">
									{fieldUpdates.map((item, i) => (
										<div className="updated_fields_field" key={i}>
											<div className="updated_fields_field--block">
												<div className="updated_fields_field--title">
													Suggested contact
												</div>
												<div className="updated_fields_field--value">
													{item}
												</div>
											</div>
											{/*<div className="updated_fields_field--block">*/}
											{/*  <div className="updated_fields_field--title">Updated value</div>*/}
											{/*  <div className="updated_fields_field--value">{item}</div>*/}
											{/*</div>*/}
											<div className="updated_fields_field--action">
												<Tooltip
													title="Sync to salesforce"
													placement="bottomRight"
												>
													<ReactSVG
														src="/images/icons/CloudFill.svg"
														onClick={() =>
															handleSync("Contact", iconUrls.Contact, item)
														}
													/>
												</Tooltip>
											</div>
										</div>
									))}
								</div>
							</Collapse.Panel>
							// ))
						}
					</Collapse>
				);
		}
	};

	return renderFieldUpdates();
};
