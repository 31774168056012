import { Popover } from "antd";
import { FunctionComponent, useState } from "react";
import PipelineColAutoCompleteComponent from "./PipelineColAutoCompleteComponent";

interface Props {
	currentId?: string;
	list: Record<string, string>[];
	onClick: (item: any) => void;
	keepOpenOnClick?: boolean;
}

const PipelineRenderCustomColumnComponent: FunctionComponent<Props> = ({
	currentId,
	list,
	children,
	onClick,
	keepOpenOnClick,
}) => {
	const [visible, setVisible] = useState(false);

	const selectOptions = list.map(({ label, value }) => ({
		key: value,
		value: label,
	}));
	const activeName = selectOptions.find((item) => item.key === currentId);

	const onMenuClick = ({ key }: { key: string }) => {
		onClick(list.find((item) => key === item.value) || key);
		!keepOpenOnClick && setVisible(false);
	};

	const handleVisibleChange = (visible: boolean) => {
		setVisible(visible);
	};

	return (
		<Popover
			visible={visible}
			onVisibleChange={handleVisibleChange}
			placement="bottomLeft"
			trigger="click"
			destroyTooltipOnHide
			content={
				<PipelineColAutoCompleteComponent
					onMenuClick={onMenuClick}
					selectOptions={selectOptions}
				/>
			}
		>
			{children ? (
				children
			) : (
				<div>
					{/* {activeName ? activeName.name : 'n/a'} */}
					{(activeName && activeName.value) || "n/a"}
					{/* value */}
				</div>
			)}
		</Popover>
	);
};

export default PipelineRenderCustomColumnComponent;
