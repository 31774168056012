import api from "./api";

export const GetSFDCObjectDetailsV2 = async (
	object_type: any,
	object_id: string,
	use_case: string = "sfdc_side_drawer",
) => {
	return api
		.post("/get_sfdc_object_details_v2", {
			object_type,
			object_id,
			pod_user_id: Number(localStorage.getItem("podUserId")),
			opts: { use_case },
		})
		.then((res) => res.data);
};
