import { firebaseApp } from "./Config";
import {
	collection,
	query,
	where,
	getDocs,
	getFirestore,
} from "firebase/firestore";

export const GetUserTasks = async () => {
	const db = getFirestore(firebaseApp);
	const pod_user_id = localStorage.getItem("podUserId");

	const q = query(
		collection(db, "tasks"),
		where("pod_user_id", "==", pod_user_id),
	);
	const querySnapshot = await getDocs(q);

	return querySnapshot.docs;
};

export const GetOpportunityTasks = async (opportunityId: string) => {
	const db = getFirestore(firebaseApp);
	const pod_user_id = localStorage.getItem("podUserId");

	const q = query(
		collection(db, "tasks"),
		where("pod_user_id", "==", pod_user_id),
		where("opportunity_id", "==", opportunityId),
	);
	const querySnapshot = await getDocs(q);

	return querySnapshot.docs;
};
