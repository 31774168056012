import { Button, Menu, Tooltip } from "antd";
import Sider from "antd/lib/layout/Sider";
import { FC } from "react";
import { FiFileText, FiUserPlus } from "react-icons/fi";
import { BiBriefcaseAlt, BiTask } from "react-icons/bi";
import { Link, NavLink, useLocation } from "react-router-dom";
import PodLogoSmall from "../../Images/PodLogoSmall.svg";
import { HiOutlineCalendar } from "react-icons/hi";
import { ReactSVG } from "react-svg";

interface AsideComponentProps {
	collapsed: boolean;
	onCollapse?: any;
	onInvite: any;
	taskManagerVisibility: string;
	setTaskManagerVisibility?: any;
	pendingTasks: number;
}

const { Item } = Menu;
const AsideComponent: FC<AsideComponentProps> = ({
	collapsed,
	onCollapse,
	onInvite,
	taskManagerVisibility,
	setTaskManagerVisibility,
	pendingTasks,
}) => {
	const history = useLocation();

	return (
		<Sider
			theme="light"
			trigger={null}
			collapsible
			collapsed={collapsed}
			collapsedWidth={86}
			// onCollapse={onCollapse}
			className="common_aside"
		>
			<div className="common_brand_logo px-3 py-2 mb-3">
				<Link to="/dashboard/pipeline-view">
					<img src={PodLogoSmall} className="img-fluid" alt="POD" />
				</Link>
			</div>
			<Menu
				inlineCollapsed={collapsed}
				mode="inline"
				defaultSelectedKeys={["/dashboard"]}
				selectedKeys={[
					history.pathname,
					taskManagerVisibility === "visible" ? "task-view" : "",
				]}
			>
				{/*<Item key="/dashboard" icon={<FiHome className="me-1" size={17} />}>
          <NavLink to="/dashboard"> Home</NavLink>
        </Item>*/}
				<Item
					key="/dashboard/pipeline-view"
					icon={<BiBriefcaseAlt className="me-1" size={17} />}
				>
					<NavLink to="/dashboard/pipeline-view"> Pipeline</NavLink>
				</Item>
				<Item
					key="/dashboard/calendar-view"
					icon={<HiOutlineCalendar className="me-1" size={17} />}
				>
					<NavLink to="/dashboard/calendar-view"> Calendar</NavLink>
				</Item>
				<Item
					key="/document-hub/summary-page"
					icon={<FiFileText className="me-1" size={17} />}
				>
					<NavLink to="/document-hub/summary-page"> Notes</NavLink>
				</Item>
				<Item key="task-view" icon={<BiTask className="me-1" size={17} />}>
					<NavLink
						to="#"
						onClick={(e) => {
							e.preventDefault();
							setTaskManagerVisibility(
								taskManagerVisibility === "visible" ? "hide" : "visible",
							);
						}}
					>
						{" "}
						Tasks
						<span
							style={{
								position: "absolute",
								right: 16,
								borderRadius: 2,
							}}
						>
							<ReactSVG src="/images/icons/expandTM.svg" />
						</span>
					</NavLink>
				</Item>
			</Menu>
			<div
				style={{
					position: "absolute",
					bottom: 0,
					left: 0,
					right: 0,
					padding: !collapsed ? "8px 20px 8px 16px" : 8,
					borderTop: "1px solid #E9EBF0",
					display: "flex",
					flexDirection: !collapsed ? "row" : "column",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				<Tooltip placement="right" title={collapsed ? "Invite" : ""}>
					<Button
						className="pod_btn_secondary"
						onClick={onInvite}
						style={{
							margin: !collapsed ? 0 : 13,
							padding: !collapsed ? "8px 6px" : "0 4px",
							width: !collapsed ? "unset" : 24,
							height: !collapsed ? 32 : 24,
						}}
					>
						{!collapsed && <span style={{ marginRight: 10 }}>Invite</span>}
						<FiUserPlus size={16} />
					</Button>
				</Tooltip>
				<Tooltip placement="right" title={collapsed ? "Help" : ""}>
					<NavLink
						to="#"
						onClick={(e) => {
							e.preventDefault();
							// @ts-ignore
							Intercom("show");
						}}
						style={{ margin: !collapsed ? 0 : 13 }}
					>
						<ReactSVG src="/images/icons/HelpIcon.svg" />
					</NavLink>
				</Tooltip>
			</div>
		</Sider>
	);
};
export default AsideComponent;
