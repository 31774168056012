import api from "./api";

export type OpportunityAndAccountName = {
	opportunity_name: string;
	account_name: string;
};

export const GetOpportunityAndAccountName = async (
	opportunity_id: string,
): Promise<OpportunityAndAccountName> => {
	return api
		.post("/get_opportunity_and_account_name", {
			pod_user_id: Number(localStorage.getItem("podUserId")),
			opportunity_id,
		})
		.then((res) => res.data as OpportunityAndAccountName);
};
