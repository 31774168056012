import { Tabs, Tooltip } from "antd";
import { ReactSVG } from "react-svg";
import { ActivityHubDrawerLayout } from "../../ActivityHub/Components/ActivityHubDrawerLayout";
import { SummaryTab } from "./NotesAssistantDrawerTabs/SummaryTab";
import { NextStepsTab } from "./NotesAssistantDrawerTabs/NextStepsTab";
import { FieldUpdatesTab } from "./NotesAssistantDrawerTabs/FieldUpdatesTab";
import { FollowupTab } from "./NotesAssistantDrawerTabs/FollowupTab";

import "./NotesAssistantDrawer.styles.scss";

const { TabPane } = Tabs;

export type Note = {
	type: string;
	content: string;
	stakeholders: string[];
};

type Tab = {
	title: string;
	component: (note: Note) => JSX.Element;
	icon: JSX.Element;
};

const tabs: Tab[] = [
	{
		title: "Summary",
		component: (note) => <SummaryTab note={note} />,
		icon: <ReactSVG src="/images/icons/Summary.svg" />,
	},
	{
		title: "Next steps",
		component: (note) => <NextStepsTab note={note} />,
		icon: <ReactSVG src="/images/icons/NotesAdd.svg" />,
	},
	{
		title: "Follow-up email",
		component: (note) => <FollowupTab note={note} />,
		icon: <ReactSVG src="/images/icons/Followup.svg" />,
	},
	{
		title: "Updated fields",
		component: (note) => <FieldUpdatesTab note={note} />,
		icon: <ReactSVG src="/images/icons/CloudData.svg" />,
	},
];

export const NotesAssistantDrawer = ({
	onClose,
	note,
}: {
	onClose: VoidFunction;
	note: Note;
}) => {
	return (
		<ActivityHubDrawerLayout
			icon={<ReactSVG src="/images/icons/NotesAssistant.svg" />}
			title="Notes Assistant"
			description="AI is a tool to supplement, enhance, and make suggestions. Please fact-check before acting on any AI-generated information."
			onClose={onClose}
		>
			<div className="notes_assistant">
				<Tabs defaultActiveKey="0">
					{tabs.map((tab, i) => (
						<TabPane key={i} tab={tab.icon}>
							<Tooltip title={tab.title} placement="bottom">
								{tab.component(note)}
							</Tooltip>
						</TabPane>
					))}
				</Tabs>
			</div>
		</ActivityHubDrawerLayout>
	);
};
