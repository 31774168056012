import api from "./api";

export const CreateOrUpdateSFDCNoteObject = async (
	title: string,
	body: string,
	opportunityId: string,
	accountId: string,
	sfdc_note_id?: string,
) => {
	return api
		.post("/create_or_update_sfdc_note_object", {
			pod_user_id: Number(localStorage.getItem("podUserId")),
			sfdc_note_id,
			data: {
				Title: title,
				Body: body,
				ParentId: opportunityId || accountId,
			},
		})
		.then((res) => res.data);
};
