import { Button, DatePicker, Form, Input, notification } from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import CloudIcon from "../../../Images/Cloud.svg";
import { CreateLogEvent } from "../../../Apis/CreateLogEvent";
import { DropdownSearch } from "../../../Components/DropdownSearchV2";
import {
	refreshPipelineTable,
	togglePipelineCreateModal,
} from "../../../Store/ActionReducerPairs/Pipeline";

const { Item, useForm } = Form;
export const CreateLogActivityFormComponent = () => {
	const opportunityId = useSelector(
		(state: any) => state.pipeline?.selectedModal.opportunityId || "",
	);
	const opportunityName = useSelector(
		(state: any) => state.pipeline?.selectedModal.opportunityName || "",
	);
	const [selectedOp, setSelectedOp] = useState("");
	const [selectedOpName, setSelectedOpName] = useState("");
	const [selectedContact, setSelectedContact] = useState("");
	const [selectedContactName, setSelectedContactName] = useState("");
	const [form] = useForm();

	const submitForm: any = (values: any) => {
		let _preparedData: any = {
			...values,
			opportunity_id: selectedOp || opportunityId,
		};
		_preparedData["user_tz"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
		CreateLogEvent("Log", _preparedData).then((res: any) => {
			if (res.isError) {
				return;
			}
			form.resetFields();

			window.analytics.track("PipelineView_Log_Activity", {
				podUserId: localStorage.getItem("podUserId"),
			});

			notification.success({
				message: "Successfully Created New Event",
				placement: "bottomRight",
				duration: 1,
			});
			togglePipelineCreateModal({
				modalVisible: false,
				selectedModal: {},
			});
			refreshPipelineTable(new Date());
		});
	};

	return (
		<Form
			initialValues={{ opportunity_id: opportunityId }}
			layout="vertical"
			onFinish={submitForm}
			form={form}
			scrollToFirstError
		>
			<Item
				name="opportunity_id"
				label={`Opportunity Name`}
				rules={[
					{ required: true, message: "Please Provide a opportunity name" },
				]}
			>
				<DropdownSearch
					currentName={selectedOpName || opportunityName}
					currentId={selectedOp || opportunityId}
					objectType={"Opportunity"}
					UpdateTextOrNumberType={(item: any, _: any, value: string) => {
						setSelectedOp(item);
						setSelectedOpName(value);
						form.setFieldsValue({
							opportunity_id: item,
						});
					}}
				/>
			</Item>
			<Item
				name="contact_id"
				label={`Contact Name`}
				rules={[{ message: "Please select contact" }]}
			>
				<DropdownSearch
					currentName={selectedContactName || selectedContact}
					currentId={selectedContact}
					objectType={"Contact"}
					UpdateTextOrNumberType={(item: string, _: unknown, value: string) => {
						setSelectedContact(item);
						setSelectedContactName(value);
						form.setFieldsValue({
							contact_id: item,
						});
					}}
				/>
			</Item>

			<Item
				name="subject"
				label={`Subject`}
				rules={[{ required: true, message: "Please provide a subject" }]}
			>
				<Input size="large" />
			</Item>

			<Item
				name="start_time"
				label="Start Date/Time"
				rules={[{ required: true, message: "Please select a start date/time" }]}
			>
				<DatePicker
					size="large"
					className="w-100"
					format="YYYY-MM-DD HH:mm"
					showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }}
				/>
			</Item>

			<Item
				name="end_time"
				label="End Date/Time"
				rules={[{ required: true, message: "Please select a end date/time" }]}
			>
				<DatePicker
					size="large"
					className="w-100"
					format="YYYY-MM-DD HH:mm"
					showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }}
				/>
			</Item>
			<Item
				name="description"
				label={`Description`}
				rules={[{ message: "Please select receiver name" }]}
			>
				<TextArea />
			</Item>

			<Item className="my-2 pt-2">
				<div className="d-flex justify-content-between align-items-center">
					<span className="text-danger">Fill in required Fields</span>
					<Button
						htmlType="submit"
						type="primary"
						className="syn_btn"
						icon={<img src={CloudIcon} className="img-fluid" />}
					>
						Sync
					</Button>
				</div>
			</Item>
		</Form>
	);
};
