import api from "./api";
import { Field } from "./RecordTypesAndStages";
import { Filter } from "../Components/Pipeline/MasterFilter";

export type SfdcCategory = "opportunities" | "accounts" | "contacts" | "leads"; // TODO change to string enum

export type CustomView = {
	objectType: SfdcCategory;
	label: string;
	fields: Field[];
	filters: Filter[];
	isFavorite: boolean;
	id?: number;
};

export const getCustomViews = async (
	objectType: SfdcCategory,
): Promise<CustomView[]> => {
	const res = await api.get("custom_view", {
		params: {
			pod_user_id: Number(localStorage.getItem("podUserId")),
			object_type: objectType,
		},
	});

	return res.data;
};

export const saveCustomView = async (customView: CustomView) => {
	await api.post("custom_view", {
		user_id: Number(localStorage.getItem("podUserId")),
		object_type: customView.objectType,
		label: customView.label,
		fields_list: customView.fields,
		filters: customView.filters,
		is_favorite: customView.isFavorite,
		custom_view_id: customView.id,
	});
};

// TODO use POST /custom_view and change to accept arbitrary attributes
export const updateCustomViewFilters = async (
	customViewId: number,
	filters: Filter[],
) => {
	await api.post("custom_view/filters", {
		custom_view_id: customViewId,
		filters: filters,
	});
};

export const duplicateCustomView = async (customViewId: string) => {
	await api.post("custom_view/duplicate", {
		custom_view_id: customViewId,
	});
};

export const deleteCustomView = async (customViewId: string) => {
	await api.delete("custom_view", {
		params: {
			custom_view_id: customViewId,
		},
	});
};
