import api from "./api";

export const UpdatePipelineViewTableConfig = async (payload: any) => {
	window.analytics.track("PipelineView_Update_Config", {
		podUserId: localStorage.getItem("podUserId"),
	});

	return api
		.post(`update_pipeline_view_table_config`, {
			pod_user_id: Number(localStorage.getItem("podUserId")),
			...payload,
		})
		.then((res) => res.data);
};
