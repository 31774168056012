import {
	Drawer,
	Button,
	Row,
	Col,
	Tabs,
	Skeleton,
	Spin,
	Tooltip,
	notification,
} from "antd";
import { useState, useEffect, useMemo } from "react";
import { IoMdOpen } from "react-icons/io";
import { AiOutlineUser, AiOutlineUsergroupAdd } from "react-icons/ai";
import { ReactSVG } from "react-svg";
import CloudIcon from "../../../Images/Cloud.svg";
import { FiUsers } from "react-icons/fi";
import moment from "moment";
import { shortTxt } from "../../../Utils/Index";
import { BsClock } from "react-icons/bs";
import { GetActivityDrawerDetails } from "../../../Apis/GetActivityDrawerDetails";
import { DropdownSearch } from "../../../Components/DropdownSearchV2";
import { SyncActivity } from "../../../Apis/SyncActivity";
import { DrawerProps } from "../DrawerList";

export const CustomerMeetingDrawer = ({
	visible,
	onClose,
	initData,
}: DrawerProps) => {
	const { data, sfdcOpportunityName, sfdcOpportunityId, internal } = initData;
	const [opportunityName, setOpportunityName] = useState<string>("");
	const [opportunityId, setOpportunityId] = useState<string>("");
	const [details, setDetails] = useState<any>();
	const [loader, setLoader] = useState(false);

	const { TabPane } = Tabs;

	const handleEventLink = (link: any) => {
		window.open(link, "_blank");
	};

	useEffect(() => {
		setDetails(null);
		setOpportunityName("");
		setOpportunityId("");
		if (data && data.source && data.category && data.reference_id) {
			GetActivityDrawerDetails(
				data.reference_id,
				data.source,
				data.category,
			).then((res: any) => {
				setDetails(res);
				setOpportunityId(sfdcOpportunityId);
				setOpportunityName(sfdcOpportunityName);
			});
		}
	}, [data]);

	const handleSubmit = () => {
		setLoader(true);
		SyncActivity(
			details.summary,
			details.start.dateTime,
			details.end.dateTime,
			details.description,
			opportunityId,
			attendees?.map((attendee: any) => attendee.email),
			data.reference_id,
			"gcal",
		).then((resp) => {
			window.analytics.track("ActivityView_Sync_Activity", {
				podUserId: localStorage.getItem("podUserId"),
			});

			if (resp.is_success) {
				notification.success({
					message: "Sync to Salesforce successful",
					placement: "bottomRight",
					duration: 1,
				});
			} else {
				notification.error({
					message: "Sync to Salesforce failed",
					placement: "bottomRight",
					duration: 1,
				});
			}
			setLoader(false);
		});
	};

	const attendees = useMemo(() => {
		return (
			details?.attendees.filter(
				({ email }: any) => email && email !== "Email not stored in SFDC",
			) || []
		);
	}, [details]);

	return (
		<Drawer
			title=""
			placement="right"
			width={400}
			onClose={onClose}
			visible={visible}
			style={{
				marginTop: "64px",
			}}
			className="customer_drawer_wrapper"
		>
			{!details ? (
				<div className="my-5 px-3">
					<Skeleton active paragraph={{ rows: 10 }}></Skeleton>
				</div>
			) : (
				<div className="pod_drawer customer_drawer">
					{/*<span className="pod_drawer_meeting_title">
              This meeting is from {data?.source === "gcal" ? "Google Calendar" : "Salesforce"}
            </span>*/}
					<div className="pod_drawer_head">
						<div className="d-flex justify-content-between">
							<div className="pod_drawer_head--title">
								<span
									className={
										internal
											? "customer-icon internal-meetings-icon"
											: "customer-icon"
									}
								>
									{internal ? (
										<AiOutlineUsergroupAdd color="#fff" />
									) : (
										<AiOutlineUser color="#fff" />
									)}
								</span>
								<span
									className="pod_drawer_meeting_title"
									title={details?.summary}
								>
									{details?.summary && shortTxt(details?.summary)}
								</span>
							</div>
						</div>

						<div
							className="pod_drawer_head--subtitle"
							style={{ paddingLeft: 0 }}
						>
							<Spin spinning={loader}>
								<Row style={{ alignItems: "center" }}>
									<Col span={2}>
										<ReactSVG
											className="crown-icon"
											src="/images/icons/SFDC-objects/opportunities.svg"
										/>
									</Col>
									<Col
										span={16}
										style={{ paddingLeft: 10 }}
										className="hide-disable"
									>
										<DropdownSearch
											currentName={opportunityName}
											currentId={opportunityId}
											objectType="Opportunity"
											UpdateTextOrNumberType={(
												value: string,
												_: unknown,
												name: string,
											) => {
												setOpportunityId(value);
												setOpportunityName(name);
											}}
											size="middle"
											disabled
										/>
									</Col>
									<Col span={6}>
										<div className="pod_stage_syn--btn">
											{data?.source !== "gcal" ? (
												<Tooltip
													placement="topLeft"
													title="Activity synced to Salesforce"
													style={{ background: "none" }}
												>
													<Button
														type="primary"
														onClick={handleSubmit}
														style={{
															height: "32px",
															marginLeft: "10px",
															borderRadius: 4,
															backgroundColor: "#d9d9d9",
														}}
														icon={
															<img
																src={CloudIcon}
																className="img-fluid"
																style={{ marginRight: 4 }}
															></img>
														}
														disabled={data?.source !== "gcal"}
													>
														{" "}
														Sync
													</Button>
												</Tooltip>
											) : (
												<Button
													type="primary"
													onClick={handleSubmit}
													className="syn_btn"
													style={{ height: "32px", marginLeft: "10px" }}
													icon={
														<img src={CloudIcon} className="img-fluid"></img>
													}
												>
													Sync
												</Button>
											)}
										</div>
									</Col>
								</Row>
							</Spin>
						</div>
					</div>
					<div className="pod_drawer_meeting_details_area">
						<div className="row meeting_date_time_container">
							<div className="col-md-2">
								<BsClock
									style={{
										alignSelf: "center",
										width: "23px",
										height: "23px",
										marginLeft: "-5px",
									}}
								/>
							</div>
							<div className="col-md-4">
								{details?.start &&
									moment(details.start.dateTime?.replaceAll("/", "-")).format(
										"ddd, MMM DD",
									)}
							</div>
							<div className="col-md-6 p-0 d-flex justify-content-end">
								<p className="mb-0">
									<span>
										{details?.start &&
											moment(
												details.start.dateTime?.replaceAll("/", "-"),
											).format("hh:mm a")}
									</span>
									{"  -  "}
									<span>
										{details?.end &&
											moment(details.end.dateTime?.replaceAll("/", "-")).format(
												"hh:mm a",
											)}{" "}
									</span>
								</p>
							</div>
						</div>

						<div>
							<Tabs defaultActiveKey="1">
								<TabPane tab="Details" key="1">
									<div className="row row-items">
										<div className="col-md-2">
											<ReactSVG
												className="google-meet-icon"
												src="/images/icons/google-meet.svg"
												style={{
													alignSelf: "center",
												}}
											/>
										</div>
										{!details.hangoutLink ? (
											<div className="col-md-10">
												<div
													className="google-meet-link-title"
													style={{ color: "#333333" }}
												>
													No Google Meet Link
												</div>
											</div>
										) : (
											<div className="col-md-8">
												<div
													className="google-meet-link-title"
													style={{ cursor: "pointer" }}
													onClick={() =>
														window.open(details.hangoutLink, "_blank")
													}
												>
													Join Google Meet
													<div
														className="google-meet-link"
														style={{ cursor: "pointer" }}
														onClick={() =>
															window.open(details.hangoutLink, "_blank")
														}
													>
														{" "}
														{details.hangoutLink}{" "}
													</div>
												</div>
											</div>
										)}
									</div>
									<div className="row pt-3 row-items">
										<div className="col-md-2">
											<ReactSVG
												className="phone-svg-icon"
												src="/images/icons/phone.svg"
												style={{
													alignSelf: "center",
												}}
											/>
										</div>
										{details.phoneNumber ? (
											<div className="col-md-8">
												<div className="google-phone-title">Join by Phone</div>
												<div className="google-phone">
													{details.phoneNumber}
												</div>
											</div>
										) : (
											<div className="col-md-10">
												<div
													className="google-meet-link-title"
													style={{ color: "#333333" }}
												>
													No Phone Number
												</div>
											</div>
										)}
									</div>
									<div className="description-area border-bottom-silver">
										<div className="border-separator"></div>
										<div className="pt-3">
											<span
												style={{
													fontWeight: "600",
													color: "#1B1B1C",
													fontSize: "15px",
												}}
											>
												Description
											</span>
										</div>
										<div
											style={{
												fontSize: "14px",
												marginTop: "10px",
											}}
										>
											<div className="pb-3">
												<div
													dangerouslySetInnerHTML={{
														__html: details?.description,
													}}
												/>
											</div>
										</div>
										<div className="border-separator"></div>
									</div>

									<div style={{ height: "150px" }}></div>

									<Row>
										<div
											className="open-with-calendar-box"
											style={{
												position: "fixed",
												bottom: 0,
												padding: "20px 0",
												borderTop: "1px solid #ddd",
												width: "100%",
												backgroundColor: "white",
											}}
										>
											{details && details.htmlLink ? (
												<Button
													className="calendar-open-btn"
													style={{
														border: "1px solid #5000A2",
														borderRadius: "4px",
														color: "#5000A2",
														fontSize: "15px",
														fontWeight: "400",
														height: "40px",
													}}
													icon={
														<IoMdOpen
															fontSize="20"
															style={{ marginRight: "10px" }}
														/>
													}
													onClick={() =>
														handleEventLink(details && details.htmlLink)
													}
												>
													{" "}
													Open in Google Calendar
												</Button>
											) : (
												""
											)}
										</div>
									</Row>
								</TabPane>
								<TabPane tab="Participants" key="2" style={{ margin: "0" }}>
									<div>
										<div className="recipient-list">
											<div className="gutter-row recipient-list-heading">
												<FiUsers className="participants-icon" />{" "}
												{attendees?.length} Participants
											</div>
											<div>
												<ul className="recipient-list-ul">
													{attendees?.map((attendee: any) => {
														return (
															<li>
																{attendee.email}{" "}
																<span>
																	{attendee.organizer
																		? "Organizer"
																		: attendee.responseStatus == "needsAction"
																		? "Pending"
																		: attendee.responseStatus}
																</span>{" "}
															</li>
														);
													})}
												</ul>
											</div>
										</div>
									</div>
									<div style={{ height: "150px" }}></div>
									<Row>
										<div
											className="open-with-calendar-box"
											style={{
												position: "fixed",
												bottom: 0,
												padding: "20px 0",
												borderTop: "1px solid #ddd",
												width: "100%",
												backgroundColor: "white",
											}}
										>
											{details && details.htmlLink ? (
												<Button
													className="calendar-open-btn"
													style={{
														border: "1px solid #5000A2",
														borderRadius: "4px",
														color: "#5000A2",
														fontSize: "15px",
														fontWeight: "400",
														height: "40px",
													}}
													icon={
														<IoMdOpen
															fontSize="20"
															style={{ marginRight: "10px" }}
														/>
													}
													onClick={() =>
														handleEventLink(details && details.htmlLink)
													}
												>
													{" "}
													Open in Google Calendar
												</Button>
											) : (
												""
											)}
										</div>
									</Row>
								</TabPane>
								<div
									style={{
										position: "relative",
										right: "45px",
										bottom: "52.5px",
									}}
								>
									{data?.source === "gcal" ? (
										<img
											style={{ width: 23 }}
											src="/images/icons/google-login-icon.png"
											alt="google-icon"
											className="google-icon"
										/>
									) : (
										<img
											style={{ width: 27 }}
											src="/images/icons/cloud.png"
											alt="sfdc-icon"
										/>
									)}
								</div>
							</Tabs>
						</div>
					</div>
				</div>
			)}
		</Drawer>
	);
};
