import { fetchStream } from "../../Utils/Index";

export const getNotesFieldUpdates = async function* (
	noteType: string,
	noteContent: string,
	noteStakeholders: string[],
): AsyncGenerator<string> {
	yield* fetchStream("/notes_field_updates", {
		pod_user_id: Number(localStorage.getItem("podUserId")),
		type: noteType,
		content: noteContent,
		stakeholders: noteStakeholders,
	});
};
