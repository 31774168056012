import { useState } from "react";
import { isValidEmailFormat } from "../../../Utils/Index";
import { createInvite } from "../../../Apis/WelcomeFlow/invite";
import "./Invite.styles.scss";

export const InviteModal = ({
	inviteSentCB,
}: {
	inviteSentCB: (email: string) => void;
}) => {
	const [nameInput, setNameInput] = useState<string>("");
	const [emailInput, setEmailInput] = useState<string>("");

	const handleSubmit = () => {
		if (!nameInput || !isValidEmailFormat(emailInput)) {
			return;
		}
		createInvite(emailInput, nameInput).then(() => inviteSentCB(nameInput));
	};

	return (
		<div className="invite-modal-column">
			<p>Name</p>
			<input
				className="invite-modal-input"
				type="text"
				placeholder="Name"
				value={nameInput}
				onChange={(e) => setNameInput(e.target.value)}
				onKeyDown={(e) => e.key === "Enter" && handleSubmit()}
				autoFocus
			/>
			<p>Email</p>
			<input
				className="invite-modal-input"
				type="email"
				placeholder="Email"
				value={emailInput}
				onChange={(e) => setEmailInput(e.target.value)}
				onKeyDown={(e) => e.key === "Enter" && handleSubmit()}
			/>
			<button
				className="invite-modal-btn"
				onClick={handleSubmit}
				style={{
					alignSelf: "end",
				}}
			>
				Send Invite
			</button>
		</div>
	);
};
