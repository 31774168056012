import "./Skeleton.styles.scss";
import "./OnboardPage.styles.scss";
import { Skeleton } from "./Skeleton";
import PodLogoSmall from "../../Images/PodLogoSmall.svg";
import { Button } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import Align from "./Components/Align";
import { Invite } from "./Components/Invite";
import GettingStarted from "./Components/GettingStarted";

export const OnboardPage = () => {
	const state = useLocation().state as {
		firstName: string;
		title: string;
		companyName: string;
	};
	const step = +(useParams().step ?? "");
	const navigate = useNavigate();

	// TODO fix. Navigate must be called in hooks, which are run post-render. state is referenced during render
	useEffect(() => {
		// No state means they manually changed the url or we accidentally didn't pass it
		if (state === null) {
			navigate("/");
		}
	}, []);

	const steps = [
		{ name: "Align", component: <Align /> },
		{ name: "Invite", component: <Invite /> },
		{ name: "Get Started", component: <GettingStarted /> },
	];

	const onBackClick = () => {
		navigate(`/onboard/${step - 1}`, { state: state });
	};

	const onNextClick = () => {
		if (step < steps.length) {
			navigate(`/onboard/${step + 1}`, { state: state });
		} else {
			window.analytics.track("SignupFlow_End", {
				podUserId: localStorage.getItem("podUserId"),
			});
			navigate("/dashboard/pipeline-view");
		}
	};

	const getStepStatus = (i: number) => {
		if (i < step - 1) {
			return <div className="circle-checked" />;
		} else if (i === step - 1) {
			return (
				<div className="circle">
					<div className="circle-inner" />
				</div>
			);
		}
		return <div className="circle" />;
	};

	return (
		<Skeleton
			LeftContent={
				<div className="welcome-column" style={{ margin: "12vh 0 0 8vw" }}>
					<div className="welcome-column">
						<img
							src={PodLogoSmall}
							alt=""
							style={{ width: "50px", marginBottom: "5.5vh" }}
						/>
						<div className="welcome-column">
							<p
								style={{
									fontWeight: "700",
									fontSize: "34px",
									letterSpacing: "0.0025em",
									height: "35px",
									marginLeft: "-1px",
								}}
							>
								Hi {state.firstName}!
							</p>
							<p
								style={{
									fontWeight: "400",
									letterSpacing: "0.0015em",
									height: "1rem",
									color: "#1B1B1C",
								}}
							>
								{[state.title, state.companyName].filter(Boolean).join(", ")}
							</p>
						</div>
						<div className="welcome-column" style={{ marginTop: "5vh" }}>
							{steps.map((item, i) => (
								<div key={i}>
									<div className="welcome-row" style={{ alignItems: "center" }}>
										{getStepStatus(i)}
										<p
											className="step"
											style={i > step - 1 ? { color: "#666666" } : {}}
										>
											{item.name}
										</p>
									</div>
									{i < steps.length - 1 ? (
										i < step - 1 ? (
											<div className="line-traversed" />
										) : (
											<div className="line" />
										)
									) : (
										<></>
									)}
								</div>
							))}
						</div>
					</div>
				</div>
			}
			RightContent={
				<div
					className="welcome-column"
					style={{
						height: "100%",
						alignItems: "center",
						justifyContent: "space-between",
					}}
				>
					{steps.map((item, i) => (
						// Pre-render to prevent re-renders, to persist state
						<div
							key={i}
							style={i === step - 1 ? { height: "100%" } : { display: "none" }}
						>
							{item.component}
						</div>
					))}
					<div
						className="welcome-row"
						style={{
							justifyContent: "space-between",
							width: "474px",
							margin: "3.2vh 0 12vh 5px",
						}}
					>
						{step > 1 ? (
							<Button
								className="nav-btn nav-btn-onboard"
								onClick={onBackClick}
								style={{
									color: "#5000A2",
								}}
							>
								<p style={{ fontSize: "16px", color: "#5000A2" }}>Back</p>
							</Button>
						) : (
							<div style={{ width: "217px" }}></div>
						)}
						<Button
							className="nav-btn nav-btn-onboard"
							onClick={onNextClick}
							style={{
								color: "white",
								backgroundColor: "#5000A2",
							}}
						>
							<p style={{ fontSize: "16px", color: "white" }}>
								{step < steps.length ? "Next" : "Get Started"}
							</p>
						</Button>
					</div>
				</div>
			}
		/>
	);
};
