import api from "../api";
import axios from "axios";

export const isGoogleIntegrationEnabled = async (): Promise<boolean> => {
	let hasToken = null;
	try {
		const response = await api.head("integration", {
			params: {
				type: "google",
				pod_user_id: Number(localStorage.getItem("podUserId")),
			},
		});
		hasToken = response.status;
	} catch (error: unknown) {
		if (!axios.isAxiosError(error) || error.response?.status !== 404) {
			throw error;
		}
	}

	return hasToken === 204;
};

export const enableGoogleIntegration = async (authorizationCode: string) => {
	await api
		.post("integration", {
			type: "google",
			pod_user_id: Number(localStorage.getItem("podUserId")),
			redirect_uri: window.location.origin,
			scopes: process.env.REACT_APP_GOOGLE_SCOPES,
			authorization_code: authorizationCode,
		})
		.then((res) => res.data);

	window.analytics.track("Google_Login", {
		podUserId: localStorage.getItem("podUserId"),
	});
};

// TODO hook this up to future integrations page in settings
export const disableGoogleIntegration = async () => {
	await api.delete("integration", {
		params: {
			type: "google",
			pod_user_id: Number(localStorage.getItem("podUserId")),
		},
	});
};
