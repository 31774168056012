import api from "./api";
import { Event } from "../Pages/ActivityHub/Components/ActivityAreaV2";

export const GetPhoneCallsForActivityHub = async (
	opportunity_id: string,
	start_date: string,
	end_date: string,
): Promise<Event[]> => {
	return api
		.post("/get_phone_calls_for_activity_hub", {
			pod_user_id: Number(localStorage.getItem("podUserId")),
			opportunity_id,
			start_date,
			end_date,
		})
		.then(
			(res) =>
				res.data.map((i: any) => ({
					...i,
					date: i.date.replaceAll("/", "-"),
				})) as Event[],
		);
};
