import api from "./api";

export const GetDealStagesForAcivityHub = async (
	opportunity_id: string,
	start_date: string,
	end_date: string,
): Promise<Event[]> => {
	return api
		.post("/deal_stages_for_activity_hub", {
			pod_user_id: Number(localStorage.getItem("podUserId")),
			opportunity_id,
			start_date,
			end_date,
		})
		.then(
			(res) =>
				res.data.deal_stage_history_for_activity_hub.map((item: any) => ({
					name: item.stage_name,
					date: item.stage_date_time,
				})) as Event[],
		);
};
