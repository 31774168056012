import { Form } from "antd";
import { useState } from "react";
import { FieldSelector } from "../../../FieldSelector";
import { effectiveLabelValue } from "../../../../Utils/Index";
import { BsCheck2Circle } from "react-icons/bs";
import { ImSpinner3 } from "react-icons/im";

interface Props {
	fieldInfo?: any;
	refObjectName: string;
	currentValue: any;
	onUpdate: (value: unknown, name?: string) => any;
	onSave: (newValues?: any) => Promise<void>;
	recordTypeId: any;
}

const PipelineDrawerFormItemComponent = ({
	fieldInfo,
	refObjectName,
	currentValue,
	onUpdate,
	onSave,
	recordTypeId,
}: Props) => {
	const [isSaving, setIsSaving] = useState<boolean | null>(null);

	return (
		<Form.Item
			label={
				<div className="pod_details_form_item">
					{effectiveLabelValue(fieldInfo.label, fieldInfo.type)}
					{isSaving === true ? (
						<ImSpinner3 color="#C4C4C4" className="loading" />
					) : isSaving === false ? (
						<BsCheck2Circle color="#0E7537" />
					) : null}
				</div>
			}
			className="w-100"
		>
			<FieldSelector
				objectType={fieldInfo.referenceTo[0]}
				type={fieldInfo.type}
				refObjectName={refObjectName}
				currentValue={currentValue}
				updateValue={async (
					value: unknown,
					name?: string,
					isSave?: boolean,
				) => {
					const newFormValues = onUpdate(value, name);
					if (isSave) {
						setIsSaving(true);
						await onSave(newFormValues);
						setIsSaving(false);
						setTimeout(() => {
							setIsSaving(null);
						}, 2000);
					}
				}}
				saveValue={async () => {
					setIsSaving(true);
					await onSave();
					setIsSaving(false);
					setTimeout(() => {
						setIsSaving(null);
					}, 2000);
				}}
				picklist={fieldInfo.picklistValues}
				updateable={fieldInfo.updateable}
				size="large"
				recordTypeId={recordTypeId}
				fieldInfo={fieldInfo}
			/>
		</Form.Item>
	);
};

export default PipelineDrawerFormItemComponent;
