import "./Align.styles.scss";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { updateObjectives } from "../../../Apis/WelcomeFlow/align";

const Align = () => {
	const [objectives, setObjectives] = useState<
		{ isChecked: boolean; text: string }[]
	>([
		{ isChecked: false, text: "Update Salesforce 10x faster" },
		{
			isChecked: false,
			text: "Access information from various tools in a single place",
		},
		{
			isChecked: false,
			text: "Take better meeting notes and collaborate with colleagues",
		},
		{
			isChecked: false,
			text: "Make better, faster, and smarter decisions about your pipeline",
		},
	]);

	const isFirstRender = useRef<boolean>(true);
	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;
			return;
		}

		void updateObjectives(
			objectives.reduce<number[]>((acc, objective, i) => {
				if (objective.isChecked) {
					acc.push(i);
				}
				return acc;
			}, []),
		);
	}, [objectives]);

	const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		setObjectives(
			objectives.map((objective, i) =>
				String(i) === e.currentTarget.name
					? { ...objective, isChecked: e.currentTarget.checked }
					: objective,
			),
		);
	};

	return (
		<div
			className="welcome-column"
			style={{ alignItems: "center", marginTop: "14vh" }}
		>
			<p style={{ fontWeight: "600", fontSize: "24px" }}>
				What are your objectives?
			</p>
			{objectives.map((objective, i) => (
				<div key={i}>
					<input
						className="input-checkbox"
						type="checkbox"
						id={String(i)}
						name={String(i)}
						checked={objectives[i].isChecked}
						onChange={handleInputChange}
					/>
					<label className="checkbox-container" htmlFor={String(i)}>
						<span
							className="welcome-row"
							style={{
								width: "400px",
								height: "max-content",
								margin: "11px 0 11px 15px",
								alignItems: "center",
							}}
						>
							<p
								style={{
									fontSize: "14px",
									letterSpacing: "0.1px",
									color: "#1B1B1C",
									width: "400px",
									height: "max-content",
									marginTop: "16px",
								}}
							>
								{objective.text}
							</p>
							<div className="checkbox"></div>
						</span>
					</label>
				</div>
			))}
		</div>
	);
};

export default Align;
