import "./Invite.styles.scss";
import { SetStateAction, useState } from "react";
import { isValidEmailFormat } from "../../../Utils/Index";
import InviteSentTick from "../../../Images/InviteSentTick.svg";
import { createInvite } from "../../../Apis/WelcomeFlow/invite";

export const Invite = () => {
	const [invites, setInvites] = useState<string[]>([]);
	const [emailInput, setEmailInput] = useState<string>("");

	const handleChange = (event: {
		target: { value: SetStateAction<string> };
	}) => {
		setEmailInput(event.target.value);
	};

	const handleSubmit = () => {
		if (!isValidEmailFormat(emailInput)) {
			return;
		}
		createInvite(emailInput).then(() => {
			setInvites([...invites, emailInput]);
			setEmailInput("");
		});
	};

	return (
		<div
			className="welcome-column"
			style={{ alignItems: "center", marginTop: "17vh" }}
		>
			<p style={{ fontWeight: "600", fontSize: "24px", marginBottom: "6px" }}>
				Invite Your Team
			</p>
			<p
				style={{
					fontWeight: "400",
					width: "400px",
					textAlign: "center",
					marginBottom: "32px",
				}}
			>
				You can now collaborate with the rest of your sales team in your new Pod
				workspace
			</p>
			<div style={{ position: "relative" }}>
				<input
					className="email-container invite-component-input"
					type="email"
					placeholder="Type email here..."
					value={emailInput}
					onChange={handleChange}
					onKeyDown={(e) => e.key === "Enter" && handleSubmit()}
					autoFocus
				/>
				<button
					onClick={handleSubmit}
					style={{
						whiteSpace: "nowrap",
						display: "inline-flex",
						alignItems: "center",

						background: "#F5F5F5",
						border: "1px solid #5000A2",
						borderRadius: "8px",

						fontWeight: "600",
						fontSize: "12px",
						letterSpacing: "0.004em",
						color: "#5000A2",

						width: "113px",
						height: "40px",
						padding: "12px 24px",

						position: "absolute",
						right: "16px",
						top: "16px",
					}}
				>
					Send Invite
				</button>
			</div>
			<div style={{ marginTop: "16px", height: "235px", overflow: "scroll" }}>
				{invites.map((email, i) => (
					<div
						key={i}
						className="email-container welcome-row"
						style={{
							marginBottom: "16px",
							paddingTop: "19px",
							paddingBottom: "18px",
							alignItems: "center",
						}}
					>
						<p
							style={{ marginBottom: "0", justifySelf: "left", width: "326px" }}
						>
							{email}
						</p>
						<span style={{ alignItems: "center" }}>
							<p
								style={{
									fontWeight: "600",
									fontSize: "12px",
									letterSpacing: "0.004em",
									color: "#0E7537",
									marginBottom: "0",
								}}
							>
								Invite Sent
							</p>
							<img
								src={InviteSentTick}
								alt=""
								style={{ width: "14px", marginLeft: "9px" }}
							/>
						</span>
					</div>
				))}
			</div>
		</div>
	);
};
