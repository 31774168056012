import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CreateDocument } from "../../Apis/Firebase/CreateDocument";
import { GetUserDocuments } from "../../Apis/Firebase/GetUserDocuments";
import { UserLogout } from "../../Utils/UserLogout";
import { useAppDispatch } from "../../hooks";
import { GetSFDCObjectV2 } from "../../Apis/GetSFDCObjectV2";

interface DocumentCallbackDataType {
	"Document name": {
		label: string;
		url: string;
	};
}

export const CommandBar = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	useEffect(() => {
		const initializeCommandBar = async () => {
			const loggedInUserId = "12345"; // example
			await window.CommandBar.boot(loggedInUserId);
			window.CommandBar.addCallback("create_new_document", async () => {
				const res = await CreateDocument();
				navigate("/document-hub/notes-page/" + res);
			});

			window.CommandBar.addCallback("logout", UserLogout);
			window.CommandBar.addCallback("create_new_task", () => {
				dispatch({
					type: "UPDATE_TASK_MANAGER_STATE",
					payload: {
						showNewTaskArea: "block",
						visible: "visible",
					},
				});
			});

			// add document commands
			const userDocs = await GetUserDocuments();

			const filteredUserDocs = userDocs.map((document) => {
				const dat = document.data();
				return { id: document.id, title: dat.title };
			});

			window.CommandBar.addArgumentChoices(
				"open_document_params",
				filteredUserDocs.map((document) => ({
					label: document.title,
					url: document.id,
				})),
			);

			window.CommandBar.addCallback(
				"open_document",
				async (params: DocumentCallbackDataType) => {
					navigate(`/document-hub/notes-page/${params["Document name"].url}`);
				},
			);

			// add records search
			const searchRecords = async (query: string, pagination?: any) => {
				return (
					await Promise.all(
						["opportunities", "accounts", "contacts", "leads"].map(
							async (changeURLKey) => {
								try {
									const res = await GetSFDCObjectV2({
										changeURLKey,
										pageSize: 100000,
										pageNum: 1,
										searchText: query,
										ignoreFilters: true,
									});

									return res.data;
								} catch {
									return [];
								}
							},
						),
					)
				).reduce((prev, curr) => [...prev, ...curr], []);
			};

			window.CommandBar.addRecords("records", [], {
				onInputChange: searchRecords,
				labelKey: "Name",
			});

			window.CommandBar.addCallback("search_records", (params: any) => {
				const objectType = params.record.attributes.type;
				const objectId = params.record.Id;
				dispatch({
					type: "OPEN_DRAWER",
					payload: { drawerType: "pipeline", objectType, objectId },
				});
			});
		};

		void initializeCommandBar();

		return () => {
			window.CommandBar.shutdown();
		};
	}, []);

	return null;
};
