import { useEffect } from "react";
import { API_FETCH } from "./Index";
import { clearAllLocalState } from "./UserLogout";
import { SfdcCategory } from "../Apis/CustomViews";

export const SFDCCallbackHandler = () => {
	useEffect(() => {
		const queryParams = new URLSearchParams(window.location.search);

		void signin(queryParams.get("code"));
	}, []);

	return null;
};

export const signin = async (
	code: string | null,
	podUserId?: string,
	password?: string,
) => {
	clearAllLocalState();
	const response = await fetch(
		API_FETCH(
			code
				? `/signin?code=${code}`
				: `/impersonate?pod_user_id=${podUserId}&password=${password}`,
		),
	);
	const data = await response.json();
	const reduxState = JSON.parse(data.redux_state);
	const currentCategory: SfdcCategory =
		reduxState?.currentCategory || "opportunities";
	const selectedViews: any = reduxState?.selectedViews || {};

	const listData = data.sfdc_list_view_data;
	localStorage.setItem(
		"pipelineViewMasterFilter",
		JSON.stringify({
			opportunities:
				currentCategory === "opportunities" && selectedViews?.opportunities
					? selectedViews.opportunities
					: listData.find(
							(obj: any) =>
								obj.SobjectType == "Opportunity" && obj.Name.includes("All"),
					  )?.Id ||
					  listData.find((obj: any) => obj.SobjectType == "Opportunity")?.Id,
			accounts:
				currentCategory === "accounts" && selectedViews?.accounts
					? selectedViews.accounts
					: listData.find(
							(obj: any) =>
								obj.SobjectType == "Account" && obj.Name.includes("All"),
					  )?.Id ||
					  listData.find((obj: any) => obj.SobjectType == "Account")?.Id,
			contacts:
				currentCategory === "contacts" && selectedViews?.contacts
					? selectedViews.contacts
					: listData.find(
							(obj: any) =>
								obj.SobjectType == "Contact" && obj.Name.includes("All"),
					  )?.Id ||
					  listData.find((obj: any) => obj.SobjectType == "Contact")?.Id,
			leads:
				currentCategory === "leads" && selectedViews?.leads
					? selectedViews.leads
					: listData.find(
							(obj: any) =>
								obj.SobjectType == "Lead" && obj.Name.includes("All"),
					  )?.Id || listData.find((obj: any) => obj.SobjectType == "Lead")?.Id,
		}),
	);

	localStorage.setItem(
		"sortingOrder",
		JSON.stringify({
			opportunities: {},
			accounts: {},
			contacts: {},
			leads: {},
		}),
	);

	localStorage.setItem(
		"documentHubFilters",
		JSON.stringify({
			opportunity: {},
			privateDocs: false,
			myDocs: false,
			createdOn: "",
			documentType: "",
		}),
	);

	localStorage.setItem(
		"sortingOrder",
		JSON.stringify({
			opportunities: {},
			accounts: {},
			contacts: {},
			leads: {},
		}),
	);

	localStorage.setItem("sfdcInstanceUrl", data.instance_url);
	localStorage.setItem("sfdcUserDisplayName", data.sfdc_user_display_name);
	localStorage.setItem("sfdcEmail", data.sfdc_email);
	localStorage.setItem("pageNum", "0");
	localStorage.setItem("searchText", "");
	localStorage.setItem("podUserId", data.pod_user_id);
	localStorage.setItem("organizationId", data.organization_id);

	if (code) {
		window.opener.postMessage(data, window.location.origin);

		// Send message to chrome extension as well
		window.opener.postMessage(data, "*");
		window.close();
	}

	return data;
};
