import { DefaultRootState } from "react-redux";
import { AnyAction } from "redux";

const defaultState: DefaultRootState = {
	visible: "hidden",
	newTaskContent: "",
	newTaskOpportunityId: "",
	newTaskOpportunityName: "",
	showNewTaskArea: "",
};

export const taskManagerReducer = (
	state: any = defaultState,
	action: AnyAction,
) => {
	switch (action.type) {
		case "OPEN_TASK_MANAGER":
			return {
				...state,
				visible: "visible",
			};
		case "CLOSE_TASK_MANAGER":
			return {
				...state,
				visible: "hidden",
			};
		case "UPDATE_TASK_MANAGER_STATE":
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
};
