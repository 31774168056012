import { Button, Dropdown, Input, List, Menu } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import clsx from "clsx";
import "./PipelineCustomViewComponent.styles.scss";
import { AiFillStar, AiOutlineSearch, AiOutlineStar } from "react-icons/ai";
import { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import { FiMoreHorizontal } from "react-icons/fi";
import {
	CustomView,
	deleteCustomView,
	getCustomViews,
	SfdcCategory,
	saveCustomView,
	duplicateCustomView,
} from "../../Apis/CustomViews";
import { isValidSfdcId, truthyGuard } from "../../Utils/Index";
import { useSelector } from "react-redux";
import { updatePipelineFilter } from "../../Store/ActionReducerPairs/Pipeline";
import { defaultFilter } from "./MasterFilter";
import { defaultField } from "../../Apis/RecordTypesAndStages";
import { persistReduxState } from "../../Apis/ReduxState/ReduxState";

const viewModes = [
	{
		key: "favorite_views",
		icon: <AiOutlineStar />,
		label: "Favorite Views",
	},
	{
		key: "my_views",
		icon: <ReactSVG src="/images/icons/MyViews.svg" />,
		label: "My Views",
	},
	{
		key: "salesforce_views",
		icon: <ReactSVG src="/images/icons/Cloud.svg" />,
		label: "Salesforce Views",
	},
	// TODO: use shared views further in next phase
	// {
	//   key: "shared_views",
	//   icon: <ReactSVG src="/images/icons/Shared.svg" />,
	//   label: "Shared Views",
	// },
];

type View = {
	id: string;
	label: string;
	isFavorite: boolean;
	isCustom: boolean;
};

export const ViewDropdown = ({
	objectType,
	sfdcViews,
	onUpdateMasterFilter,
	onUpdateSfdcFavorite,
	fields,
}: {
	objectType: SfdcCategory;
	sfdcViews: View[];
	onUpdateMasterFilter: (value: string) => void;
	onUpdateSfdcFavorite: (favorite: boolean, id: string) => void;
	fields: any;
}) => {
	fields = fields.filter(truthyGuard); // TODO Haven't looked for its source, but there's a trailing undefined

	const [views, setViews] = useState<View[]>(sfdcViews);
	const [currentView, setCurrentView] = useState<View | null>(null);
	const [viewMode, setViewMode] = useState("my_views");
	const [search, setSearch] = useState("");
	const [visible, setVisible] = useState(false);
	const [isRenaming, setIsRenaming] = useState<string | null>(null);
	const [renameValue, setRenameValue] = useState("");
	const filters = useSelector(
		({ pipeline }: any) => pipeline.filters[objectType],
	);

	// We need this instead of just initialState because on 1st render it's empty,
	// meaning on 1st render it'll set to [] and subsequently won't have any trigger to mutate
	useEffect(() => {
		sfdcViews = sfdcViews.filter(truthyGuard); // TODO find the undefined that's passed in before its populated
		sfdcViews = convertSfdcViewToView(sfdcViews);
		(async () => {
			const customViews = convertCustomViewToView(
				await getCustomViews(objectType),
			);
			const newViews = sfdcViews.concat(customViews);
			setViews(newViews);
			const foundCurrentView = newViews.find(
				(view) =>
					view.id ===
					JSON.parse(localStorage.getItem("pipelineViewMasterFilter") || "{}")[
						objectType
					],
			);
			setCurrentView(foundCurrentView || null);
		})();
	}, [sfdcViews]);

	const convertSfdcViewToView = (sfdcViews: any[]): View[] => {
		return sfdcViews.map((sfdcView: any) => ({
			id: sfdcView.Id,
			label: sfdcView.Name,
			isFavorite: sfdcView.Favorite,
			isCustom: false,
		}));
	};

	const convertCustomViewToView = (customViews: CustomView[]): View[] => {
		return customViews.map((customView) => ({
			id: String(customView.id),
			label: customView.label,
			isFavorite: customView.isFavorite,
			isCustom: true,
		}));
	};

	const updateCurrentView = (view: View) => {
		setCurrentView(view);
		onUpdateMasterFilter(view.id);
		if (isValidSfdcId(view.id)) {
			updatePipelineFilter({
				[objectType]: [defaultFilter],
			});
		}
		void persistReduxState({
			currentCategory: objectType,
			selectedViews: JSON.parse(
				localStorage.getItem("pipelineViewMasterFilter") || "{}",
			),
		}); // TODO persist automatically with every redux mutation (requires those mutations to begin with :D)
	};

	// TODO new api route for duplicating a route and passing a flag
	const renameNewestView = async () => {
		const newCustomViews = await getCustomViews(objectType);
		const customViews = convertCustomViewToView(newCustomViews);
		sfdcViews = sfdcViews.filter(truthyGuard); // TODO find the undefined that's passed in before its populated
		sfdcViews = convertSfdcViewToView(sfdcViews);
		setViews(sfdcViews.concat(customViews));
		updateCurrentView(customViews[0]); // The newest view is in front, since we order DESC on the BE
		setRenameValue(customViews[0].label);
		setIsRenaming(customViews[0].id);
	};

	const submitRename = (view: View) => {
		if (view.label !== renameValue) {
			void saveCustomView({
				objectType: objectType,
				label: renameValue,
				fields: fields,
				filters: filters,
				isFavorite: view.isFavorite,
				id: Number(view.id),
			});
			view.label = renameValue;
			setViews([...views]);
		}
		setIsRenaming(null);
	};

	const renderDropdown = () => (
		<Menu>
			<div className="views_select_dropdown">
				<div className="views_select_search">
					<Input
						className="custom_input_1"
						size="large"
						prefix={<AiOutlineSearch size={20} color="#999" />}
						placeholder="Search views"
						value={search}
						onChange={(e) => setSearch(e.target.value)}
					/>
					<Button
						className="pod_btn_secondary"
						onClick={async (e) => {
							setViewMode("my_views");
							await saveCustomView({
								objectType: objectType,
								label: "New View",
								fields: [
									{
										...defaultField,
										label: "Name",
										query_string: "Name",
									},
								],
								filters: [],
								isFavorite: false,
							});
							// TODO return id from insert to save a round trip
							renameNewestView();
							updatePipelineFilter({
								[objectType]: [defaultFilter],
							});
						}}
					>
						+
					</Button>
				</div>
				<div className="views_select_content">
					<Menu
						selectedKeys={[viewMode]}
						onClick={(e) => setViewMode(e.key)}
						mode="inline"
						items={viewModes}
						className="views_select_content_menu"
					/>
					<List
						dataSource={views.filter((item) => {
							if (
								search &&
								!item.label.toLowerCase().includes(search.toLowerCase())
							) {
								return false;
							}

							switch (viewMode) {
								case "favorite_views":
									return item.isFavorite;
								case "salesforce_views":
									return !item.isCustom;
								case "my_views":
									return item.isCustom;
								default:
									console.log(`${viewMode} is an invalid viewMode`);
									return false;
							}
						})}
						renderItem={(view) => (
							<List.Item
								key={view.id}
								onClick={() => {
									updateCurrentView(view);
								}}
								className={clsx(
									view.id === (currentView ? currentView.id : null) &&
										"selected",
								)}
							>
								{view.id === isRenaming ? (
									<Input
										size="large"
										value={renameValue}
										onChange={(e) => setRenameValue(e.target.value)}
										autoFocus
										onFocus={(e) => e.target.select()}
										onBlur={() => submitRename(view)}
										onPressEnter={() => submitRename(view)}
										className={clsx("views_select_input", "edit")}
									/>
								) : (
									<>
										<div className="name">{view.label}</div>
										<div className="actions">
											{(() => {
												const onClick = async () => {
													view.isFavorite = !view.isFavorite;
													setViews([...views]);
													if (view.isCustom) {
														await saveCustomView({
															objectType: objectType,
															label: view.label,
															fields: fields,
															filters: filters,
															isFavorite: view.isFavorite,
															id: Number(view.id),
														});
													} else {
														onUpdateSfdcFavorite(view.isFavorite, view.id);
													}
												};
												return view.isFavorite ? (
													<AiFillStar onClick={onClick} color="#fcb95b" />
												) : (
													<AiOutlineStar onClick={onClick} />
												);
											})()}
											{viewMode === "my_views" && (
												<Dropdown
													placement="bottomRight"
													trigger={["click"]}
													overlay={
														<Menu className="views_select_kebab_menu">
															<Menu.Item
																onClick={() => {
																	setRenameValue(view.label);
																	setIsRenaming(view.id);
																}}
															>
																Rename
															</Menu.Item>
															<Menu.Item
																onClick={async () => {
																	await duplicateCustomView(view.id);
																	// TODO return id from insert to save a round trip
																	await renameNewestView();
																}}
															>
																Duplicate
															</Menu.Item>
															<Menu.Item
																onClick={async (e) => {
																	e.domEvent.stopPropagation();
																	updateCurrentView(
																		convertSfdcViewToView(sfdcViews)[0],
																	); // sfdc instances must have > 0 listviews
																	await deleteCustomView(view.id);
																	setViews(
																		views.filter((v) => v.id !== view.id),
																	);
																}}
															>
																Delete
															</Menu.Item>
														</Menu>
													}
												>
													<FiMoreHorizontal
														onClick={(e) => e.stopPropagation()}
													/>
												</Dropdown>
											)}
										</div>
									</>
								)}
							</List.Item>
						)}
						className="views_select_content_list"
					/>
				</div>
			</div>
		</Menu>
	);

	return (
		<div className="views_select">
			<Dropdown
				visible={visible}
				onVisibleChange={(flag) => setVisible(flag)}
				trigger={["click"]}
				overlay={renderDropdown}
				placement="bottomLeft"
			>
				<div style={{ cursor: "pointer" }} onClick={() => setVisible(!visible)}>
					<Input
						size="large"
						prefix={
							<ReactSVG
								src={
									currentView && currentView.isCustom
										? "/images/icons/MyViews.svg"
										: "/images/icons/Cloud.svg"
								}
							/>
						}
						suffix={<CaretDownOutlined />}
						value={currentView ? currentView.label : undefined} // TODO why can't we check for !null?
						readOnly
						type="button"
						style={{ width: 230 }}
						className="views_select_input"
					/>
				</div>
			</Dropdown>
		</div>
	);
};
