import { Tooltip } from "antd";
import { ReactNode } from "react";

import "./ConditionalTooltip.style.scss";

export const ConditionalTooltip = ({
	title,
	limit = 20,
	children,
}: {
	title?: string;
	limit?: number;
	children?: ReactNode;
}) => {
	return title && title.length > limit ? (
		<Tooltip title={title}>
			<div className="nowrap-text">{children || title}</div>
		</Tooltip>
	) : (
		<div className="nowrap-text">{children || title}</div>
	);
};
