import api from "../api";

export type OrgData = {
	// TODO omit key from BE when data is missing - should be X | undefined.
	name: string | null;
	description: string | null;
	foundingTimestamp: number | null;
	numEmployees: number | null;
	annualRevenue: {
		revenue: number | null;
		currency: string | null;
		year: number | null;
	} | null;
	relatedUrls: {
		homepage: string | null;
		twitter: string | null;
		linkedin: string | null;
		facebook: string | null;
		crunchbase: string | null;
	};
	investments: {
		timestamp: number | null;
		amount: number | null;
		currency: string | null;
		series: string | null;
	}[];
	industriesBroad: string[];
	industriesNiche: string[];
	competitors: {
		name: string;
		summary: string | null;
		image: string | null;
		targetDiffbotId: string;
	}[];
	address: string | null;
	phone: string | null;
	logo: string | null;
} | null;

export const getOrgDataFromOpportunityId = async (
	opportunityId: string,
): Promise<OrgData | null> => {
	return await api
		.get("/org_summary_opp", {
			params: {
				pod_user_id: Number(localStorage.getItem("podUserId")),
				opportunity_id: opportunityId,
			},
		})
		.then((res) => res.data);
};

export const getOrgDataFromDiffbotId = async (
	diffbotId: string,
): Promise<OrgData | null> => {
	return await api
		.get("/org_summary_diffbot", {
			params: {
				diffbot_id: diffbotId,
			},
		})
		.then((res) => res.data);
};
