import { useEffect, useState } from "react";
import { Modal, Input, Form, Col, Button, Row, notification } from "antd";
import Editor from "rich-markdown-editor";
import "./style.scss";

import { CreateTemplate } from "../../../Apis/Firebase/CreateTemplate";
import { UpdateTemplate } from "../../../Apis/Firebase/UpdateTemplate";

const { Item } = Form;

export interface Props {
	visible: boolean;
	setVisible: (visible: boolean) => void;
	reload: () => void;
	data?: any;
	id?: string;
}

const base = `### Title…
\\
\\

**Description**

Your text here…

\\
\\
**Requirements**

Your text here…

\\
\\
**Next catchup**

Your text here…`;

const CreateDocumentTemplate = ({
	visible,
	setVisible,
	reload,
	id,
	data,
}: Props) => {
	const [title, setTitle] = useState<string>(data?.title || "");
	const [template, setTemplate] = useState<string>(data?.body || base);

	useEffect(() => {
		setTitle(data?.title || "");
		setTemplate(data?.body || base);
	}, [id]);

	const save = () => {
		if (!title) {
			notification.success({
				message: "Please fill template title",
				placement: "bottomRight",
				duration: 1,
			});
			return;
		}
		if (id) {
			UpdateTemplate(id, {
				...data,
				title,
				body: template,
			}).then(() => {
				setVisible(false);
				notification.success({
					message: "Document template updated successfully",
					placement: "bottomRight",
					duration: 1,
				});
				reload();
			});
		} else {
			CreateTemplate(title, template).then(() => {
				setVisible(false);
				notification.success({
					message: "Document template created successfully",
					placement: "bottomRight",
					duration: 1,
				});

				window.analytics.track("DocumentHub_Create_Template", {
					podUserId: localStorage.getItem("podUserId"),
				});

				reload();
			});
		}
	};

	const onCancel = () => {
		setVisible(false);
	};

	return (
		<Modal
			visible={visible}
			className="create-modal"
			bodyStyle={{ padding: 0, borderRadius: 8 }}
			destroyOnClose
			onCancel={onCancel}
			footer={null}
			style={{ top: 40 }}
		>
			<div style={{ padding: 24 }}>
				<Input
					className="title_input_box"
					type="text"
					value={title}
					onChange={(e) => {
						setTitle(e.target.value);
					}}
					style={{
						border: "none",
						borderBottom: "solid 1px #3333",
						borderRadius: 0,
						fontSize: 16,
						paddingLeft: 0,
					}}
					placeholder="Enter Template Name..."
				/>
				<Editor
					defaultValue={data?.body || base}
					onChange={(value) => setTemplate(value())}
					style={{
						marginTop: 10,
						// border: "solid 1px #3333",
						padding: "30px",
						paddingLeft: 30,
						borderRadius: 4,
						height: 500,
						overflowY: "scroll",
						justifyContent: "flex-start",
					}}
					className="editor-template editor-content"
				/>
			</div>
			<Row
				style={{
					height: 60,
					marginTop: 0,
					paddingRight: 24,
					paddingTop: 14,
					borderTop: "solid 1px #3333",
				}}
			>
				<Col span={24} className="text-end">
					<Item className="d-inline-block">
						<Button
							style={{
								color: "#5000A2",
								borderColor: "#5000A2",
								borderRadius: 4,
								marginRight: 10,
							}}
							onClick={onCancel}
						>
							Discard
						</Button>
					</Item>
					<Item className="d-inline-block ms-2">
						<Button
							className="custom_btn"
							type="primary"
							htmlType="submit"
							onClick={save}
						>
							Save
						</Button>
					</Item>
				</Col>
			</Row>
		</Modal>
	);
};

export default CreateDocumentTemplate;
