import { firebaseApp } from "./Config";
import { doc, getDoc, getFirestore } from "firebase/firestore";

export const GetSingleDocument = async (documentId: string) => {
	const db = getFirestore(firebaseApp);
	const document = await getDoc(doc(db, "documents", documentId));
	if (document.exists()) {
		return document.data();
	} else {
		return null;
	}
};
