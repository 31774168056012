import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

export interface Props {
	style?: any;
}

const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const OutlinedLoader = ({ style }: Props) => (
	<Spin indicator={antIcon} style={style} />
);

export default OutlinedLoader;
