import { API_FETCH } from "../../Utils/Index";

export const RemoveSFDCObject = (objectType: any, objectId: any) => {
	const myHeaders = new Headers();
	myHeaders.append("Content-Type", "application/json");

	const requestOptions: any = {
		method: "POST",
		headers: myHeaders,
		body: JSON.stringify({
			objectType,
			objectId,
			pod_user_id: Number(localStorage.getItem("podUserId")),
		}),
		redirect: "follow",
	};
	return new Promise((resolve, reject) => {
		fetch(API_FETCH(`/remove_sfdc_object`), requestOptions)
			.then((response) => response.json())
			.then((result) => resolve(result))
			.catch((error) => {
				console.warn("error while getting Opportunites =>", error);
				reject(error);
			});
	});
};
