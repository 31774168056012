import { Tabs, Button, Input, Skeleton, Tooltip, notification } from "antd";
import { useEffect, useState } from "react";
import ContactIcon from "../../../Images/SFDC-objects/contacts.svg";
import AccountIcon from "../../../Images/SFDC-objects/accounts.svg";
import ProfileIcon from "../../../Images/Profile.svg";
import BackIcon from "../../../Images/Arrow.svg";
import EmailIcon from "../../../Images/Email.svg";
import CallIcon from "../../../Images/Call.svg";
import { AiOutlineSearch } from "react-icons/ai";
import "./PipelineDrawerComponent.styles.scss";
import { GetSFDCObjectDetailsV2 } from "../../../Apis/GetSFDCObjectDetailsV2";
import { PipelineObjectHelpers } from "../../../Apis/Pipeline/PipelineObjectHelpers";
import { UpdateSFDCObject } from "../../../Apis/UpdateSFDCObject";
import { UpdateSFDCSideDrawerConfig } from "../../../Apis/UpdateSFDCSideDrawerConfig";
import PipelineDrawerFormComponent from "../../Forms/Pipeline/Drawer/PipelineDrawerFormComponent";
import { PipelineBulkStageRenderComponent } from "./PipelineBulkStageRenderComponent";
import { setUpdateRecordErrorModal } from "../../../Store/ActionReducerPairs/Modals";
import {
	refreshPipelineTable,
	togglePipelineCreateModal,
} from "../../../Store/ActionReducerPairs/Pipeline";
import { useAppDispatch, useAppSelector } from "../../../hooks";

const { TabPane } = Tabs;

export const PipelineContactDrawerComponent = ({
	initData,
}: {
	initData: { objectType: string; objectId: string };
}) => {
	const dispatch = useAppDispatch();
	const [objectData, setObjectData] = useState<any>(null);
	const [fieldInfo, setFieldInfo] = useState<any>(null);
	const [formHelpers, setFormHelpers] = useState<any>(null);
	const [fieldsToDisplay, setFieldsToDisplay] = useState<Array<string>>([]);
	const [loading, setLoading] = useState(false);
	const drawers = useAppSelector((state: any) => state.drawer.drawers);

	const closeDrawer = () => {
		dispatch({
			type: "CLOSE_DRAWER",
		});
	};

	const retrieveObjectBaseData = (data: any) => {
		setLoading(true);
		GetSFDCObjectDetailsV2(data.objectType, data.objectId).then((res: any) => {
			setObjectData({ ...res.data[0] });
			setFieldInfo([...res.field_info]);
			setFieldsToDisplay(res.sfdc_drawer_fields_for_display);
			setLoading(false);
		});
	};

	useEffect(() => {
		PipelineObjectHelpers().then((res: any) => {
			setFormHelpers(res);
		});
		retrieveObjectBaseData(initData);
		return () => {
			setObjectData(null);
		};
	}, [initData]);

	const UpdateTextOrNumberType = (value: any, o: any, multipleUpdate: any) => {
		UpdateSFDCObject(
			o.id,
			o.objectType,
			multipleUpdate ? value : { [o.objectDataKey]: value },
		).then((res: any) => {
			refreshPipelineTable(new Date());
			retrieveObjectBaseData(initData);
			if (o.objectType === "account") {
				retrieveObjectBaseData({ objectType: "account", objectId: o.id });
			}
			res?.data.is_success
				? notification.success({
						message: "Contact updated successfully",
						placement: "bottomRight",
						duration: 1,
				  })
				: setUpdateRecordErrorModal({
						visible: true,
						errorMessage: res.data.message.message,
				  });
		});
	};

	const [relatedSearchWord, setRelatedSearchWord] = useState<string>("");

	const updateSideDrawerConfig = (data: Record<string, unknown>) => {
		setLoading(true);
		UpdateSFDCSideDrawerConfig(
			data.field_name as string,
			data.field_type as string,
			"Contact",
			data.toggle_type as string,
			data.reference_to as Array<string>,
		).then(() => {
			retrieveObjectBaseData(initData);
		});
	};

	const openInSalesforce = () => {
		const instanceUrl = localStorage.getItem("sfdcInstanceUrl") || "";
		window.open(`${instanceUrl}/${objectData.Id}`, "_blank");
	};

	return (
		<>
			{objectData && fieldInfo ? (
				<div className="pod_drawer pod_pipeline_drawer">
					<div className="pod_drawer_head">
						{drawers.length > 1 && (
							<div className="close-icon">
								<img src={BackIcon} alt="" onClick={closeDrawer} />
							</div>
						)}
						<div className="pod_drawer_head--title">
							<span>Contact</span>
							<img src={ContactIcon} alt="" />
						</div>
						<Tooltip
							placement="topLeft"
							title="Open in Salesforce"
							style={{ background: "none" }}
						>
							<span className="external-icon-wrapper">
								<img
									src="/images/icons/cloud.png"
									alt="external-icon"
									className="external-icon"
									onClick={openInSalesforce}
								/>
							</span>
						</Tooltip>
					</div>
					<div className="pod_contact_card">
						<div className="pod_contact_card_info_wrap">
							<span className="bank" style={{ cursor: "inherit" }}>
								<img src={AccountIcon} alt="" className="img-fluid" />{" "}
								{objectData.Account?.Name || "n/a"}
							</span>
							<div className="pod_contact_card_info">
								<div className="pod_contact_card_info--profile">
									<img src={ProfileIcon} className="img-fluid" alt="" />
								</div>
								<div className="pod_contact_card_info--name">
									{objectData.Name || "n/a"}
								</div>
								<div className="pod_contact_card_info--desn">
									{objectData.Title || "n/a"}
								</div>
							</div>
							<div className="d-flex align-items-center justify-content-between">
								<div className="pod_contact_card_info--email">
									{" "}
									<img src={EmailIcon} className="img-fluid" alt="" />{" "}
									{objectData.Email || "n/a"}
								</div>
								<div className="pod_contact_card_info--phn">
									<img src={CallIcon} className="img-fluid" alt="" />{" "}
									{objectData.Phone || "n/a"}
								</div>
							</div>
						</div>
					</div>
					<div className="pod_contact_content">
						<Tabs defaultActiveKey="4">
							<TabPane tab="Details" key="4">
								<PipelineDrawerFormComponent
									initData={{ ...initData, fieldInfo: fieldInfo }}
									formData={objectData}
									objectType="Contact"
									onUpdate={() => retrieveObjectBaseData(initData)}
									onSave={(newValues) => setObjectData(newValues)}
									loading={loading}
									fieldsToDisplay={fieldsToDisplay}
									onUpdateSideDrawerConfig={updateSideDrawerConfig}
								/>
							</TabPane>
							<TabPane tab="Opportunities" key="5">
								<div className="pod_assign_contact">
									<Input
										className="custom_input_1"
										onChange={(e: any) => setRelatedSearchWord(e.target.value)}
										size="large"
										prefix={<AiOutlineSearch size={20} color="#999" />}
										placeholder="Search by Name"
									/>
									<Button
										className="pod_btn_secondary"
										onClick={() =>
											togglePipelineCreateModal({
												modalVisible: true,
												selectedModal: {
													fieldText: "Opportunity",
													name: "Opportunity",
													iconUrl:
														"/images/icons/SFDC-objects/opportunities.svg",
												},
											})
										}
									>
										+ &nbsp;Opportunity
									</Button>
								</div>
								{formHelpers ? (
									<PipelineBulkStageRenderComponent
										SearchInRelatedOpp={relatedSearchWord || ""}
										stages={formHelpers.StageName}
										initData={{
											objectType: "contact",
											objectId: objectData.Id,
										}}
										UpdateTextOrNumberType={UpdateTextOrNumberType}
									/>
								) : (
									<Skeleton active paragraph={{ rows: 5 }} />
								)}
							</TabPane>
						</Tabs>
					</div>
				</div>
			) : (
				<div className="my-5 p-2">
					<Skeleton active paragraph={{ rows: 10 }}></Skeleton>
				</div>
			)}
		</>
	);
};
