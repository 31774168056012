import { useState } from "react";
import { BiPencil } from "react-icons/bi";
import { ActivityHubDrawerLayout } from "../Components/ActivityHubDrawerLayout";

import "./AutoGeneratedEmailDrawer.styles.scss";
import { Select } from "antd";
import {
	KnowledgeCenterEmailType,
	emailTypeLabels,
	knowledgeCenterEmailTypes,
} from "../../../../Apis/EmailGen/emailGen";
import { EmailPreview } from "../../../Shared/EmailGen/EmailPreview";
import { GenerateEmailForm } from "../../../Shared/EmailGen/GenerateEmailForm";

export const AutoGeneratedEmailDrawer = ({
	onClose,
}: { onClose: VoidFunction }) => {
	const [values, setValues] = useState<{ [key: string]: unknown }>({});
	const [isFormSubmitted, showIsFormSubmitted] = useState<boolean>(false);
	const [emailType, setEmailType] =
		useState<KnowledgeCenterEmailType>("Outreach");

	const handleSubmitForm = (values: { [key: string]: unknown }) => {
		window.analytics.track("ActivityHub_EmailGeneration_Submit", {
			podUserId: localStorage.getItem("podUserId"),
		});
		setValues(values);
		showIsFormSubmitted(true);
	};

	return (
		<ActivityHubDrawerLayout
			icon={<BiPencil size={18} />}
			title="Auto-Generated Email"
			description="AI is a tool to supplement, enhance, and make suggestions. Please fact-check before acting on any AI-generated information."
			onClose={onClose}
		>
			<div className="auto_generated_email">
				{!isFormSubmitted ? (
					<>
						<p
							style={{
								marginBottom: "0",
								fontSize: "12px",
								fontWeight: "400",
								lineHeight: "16px",
								paddingBottom: "5px",
								color: "#000000D9",
							}}
						>
							Type
							<span
								style={{
									display: "inline-block",
									marginLeft: "4px",
									color: "#ff4d4f",
									fontSize: "14px",
									fontFamily: "SimSun, sans-serif",
									lineHeight: "1",
									content: "*",
								}}
							>
								*
							</span>
						</p>
						<Select
							style={{
								border: "1px solid #e0e0e0",
								borderRadius: "4px",
								marginBottom: "15px",
							}}
							value={emailType}
							onChange={(v) => setEmailType(v)}
						>
							{knowledgeCenterEmailTypes.map(
								(emailType: KnowledgeCenterEmailType) => (
									<Select.Option key={emailType} value={emailType}>
										{emailTypeLabels[emailType]}
									</Select.Option>
								),
							)}
						</Select>
						<GenerateEmailForm
							emailType={emailType}
							onSubmit={handleSubmitForm}
						/>
					</>
				) : (
					<EmailPreview
						emailType={emailType}
						context={values}
						onBack={() => showIsFormSubmitted(false)}
					/>
				)}
			</div>
		</ActivityHubDrawerLayout>
	);
};
