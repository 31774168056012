import { Tabs } from "antd";
import { AiOutlineBulb } from "react-icons/ai";
import { AskAnythingTab } from "./KnowledgeCenterTabs/AskAnythingTab";
import { NewsTab } from "./KnowledgeCenterTabs/NewsTab";
import { ActivityHubDrawerLayout } from "../Components/ActivityHubDrawerLayout";

import "./KnowledgeCenterDrawer.styles.scss";
import { SummaryTab } from "./KnowledgeCenterTabs/SummaryTab/OrgSummary";

const { TabPane } = Tabs;

export const ENTITY_404_MESSAGE =
	"No organization found with this account name";

export const KnowledgeCenterDrawer = ({
	onClose,
	opportunityId,
}: {
	onClose: VoidFunction;
	opportunityId: string;
}) => {
	return (
		<ActivityHubDrawerLayout
			icon={<AiOutlineBulb size={18} />}
			title="Knowledge Center"
			description="AI is a tool to supplement, enhance, and make suggestions. Please fact-check before acting on any AI-generated information."
			onClose={onClose}
		>
			<div className="knowledge_center">
				<Tabs defaultActiveKey="1">
					<TabPane tab="Summary" key="1">
						<SummaryTab initialId={opportunityId} />
					</TabPane>
					<TabPane tab="News" key="2">
						<NewsTab opportunityId={opportunityId} />
					</TabPane>
					{/* <TabPane tab="History" key="3"></TabPane>
            <TabPane tab="Deal Progress" key="4"></TabPane>
            <TabPane tab="Stakeholder" key="5"></TabPane> */}
					<TabPane tab="Ask Anything" key="6">
						<AskAnythingTab data={opportunityId} />
					</TabPane>
				</Tabs>
			</div>
		</ActivityHubDrawerLayout>
	);
};
