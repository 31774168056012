import { getApp } from "firebase/app";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

export const UploadImage = async (file: any) => {
	const pod_user_id = localStorage.getItem("podUserId");
	const firebaseApp = getApp();
	const storage = getStorage(firebaseApp);
	const storageRef = ref(
		storage,
		`images/${Date.now()}${pod_user_id}-${file.name}`,
	);

	return uploadBytes(storageRef, file).then(() => {
		return getDownloadURL(storageRef);
	});
};
