import { Dropdown, Form, Statistic } from "antd";
import { useState } from "react";
// import { Gauge } from "@ant-design/plots";
import PipelineNumberUpdaterComponent from "../PipelineNumberEditorComponent";
import PipelineTextEditorComponent from "../PipelineTextEditorComponent";

// const config: any = {
//     range: {
//         color: "#31D0AA",
//     },
//     innerRadius: 0.8,
//     width: 40,
//     height: 40,
//     startAngle: Math.PI,
//     endAngle: 2 * Math.PI,
//     indicator: null,
//     autoFit: false,
// };

interface PipelineEditorDropdownProps {
	currentValue: string;
	updateValue: (value?: string, data?: any, name?: string) => void;
	updateData?: any;
	inputLength?: number;
	nullable?: boolean;
	name: string;
	numberEditorProps?: {
		type: string;
		isEngagementScore?: boolean;
	};
}

const PipelineEditorDropdown = ({
	currentValue,
	updateValue,
	updateData,
	inputLength,
	nullable,
	name,
	numberEditorProps,
}: PipelineEditorDropdownProps) => {
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [shouldRequestUpdate, setShouldRequestUpdate] = useState(false);
	const [textForm] = Form.useForm();

	const handleVisibleChange = (visible: boolean) => {
		const userRequestedModalClose = isDropdownOpen && !visible;
		setIsDropdownOpen(visible);
		if (userRequestedModalClose && shouldRequestUpdate) {
			textForm.submit();
		}
	};

	const renderTextFieldContent = () => (
		<div
			style={name === "Name" || name === "title" ? { fontWeight: "bold" } : {}}
		>
			{currentValue}
		</div>
	);

	const renderNumberFieldContent = () => (
		<div className="ps-1">
			<Statistic
				className="custom_statistic"
				prefix={
					["currency", "money"].includes(numberEditorProps!.type) ? "$" : ""
				}
				suffix={["percent"].includes(numberEditorProps!.type) ? "%" : ""}
				value={currentValue}
			/>
		</div>
	);

	const renderEngagementSection = () => (
		<div className="pip_col_egscore">
			<span className="pip_col_egscore--chart">
				{/* <Gauge
                    percent={Number(currentValue || 0) / 100}
                    {...config}
                    statistic={{
                        ...config.statistic,
                    }}
                /> */}
			</span>
			<span className="pip_col_egscore--value">{currentValue || 0}%</span>
		</div>
	);

	const textFieldEditorComponent = (
		<PipelineTextEditorComponent
			big
			value={currentValue}
			UpdateTextorNumberType={updateValue} // TODO: test updation
			updateData={updateData}
			length={inputLength}
			nullable={nullable}
			textForm={textForm}
			setShouldRequestUpdate={setShouldRequestUpdate}
		/>
	);

	const numberFieldEditorComponent = (
		<PipelineNumberUpdaterComponent
			type={numberEditorProps?.type}
			value={currentValue}
			UpdateTextorNumberType={updateValue}
			updateData={updateData}
			setShouldRequestUpdate={setShouldRequestUpdate}
			textForm={textForm}
		/>
	);

	const renderValueToUser = () => {
		if (numberEditorProps?.isEngagementScore) {
			// TODO: Couldn't manage to test this, but setting it to a placeholder value doesn't seem to break anything... yet.

			// renderEngagementSection()

			return <p>ENGAGEMENT DATA</p>;
		}
		if (numberEditorProps) {
			return renderNumberFieldContent();
		}
		return renderTextFieldContent();
	};

	return (
		<div className="pip_col--item">
			<Dropdown
				visible={isDropdownOpen}
				onVisibleChange={handleVisibleChange}
				overlay={
					numberEditorProps
						? numberFieldEditorComponent
						: textFieldEditorComponent
				}
				trigger={["click"]}
			>
				{renderValueToUser()}
				{/* {renderTextFieldContent()} */}
			</Dropdown>
		</div>
	);
};

export default PipelineEditorDropdown;
