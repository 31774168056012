import { firebaseApp } from "./Config";
import { doc, setDoc, getFirestore } from "firebase/firestore";
import moment from "moment";

export const UpdateTask = async (
	reference: string,
	params: Record<string, unknown>,
) => {
	const db = getFirestore(firebaseApp);
	const document = doc(db, "tasks", reference);
	return await setDoc(document, {
		...params,
		last_modified_at: moment().format().split("T")[0],
	});
};
