import {
	Tabs,
	Button,
	AutoComplete,
	Input,
	Skeleton,
	Tooltip,
	notification,
} from "antd";
import { useEffect, useState } from "react";
import AccountIcon from "../../../Images/SFDC-objects/accounts.svg";
import BackIcon from "../../../Images/Arrow.svg";
import UserIcon from "../../../Images/User.svg";
import CallIcon from "../../../Images/Call.svg";
import GlobeIcon from "../../../Images/Globe.svg";
import { AiOutlineSearch } from "react-icons/ai";
import "./PipelineDrawerComponent.styles.scss";
import { GetSFDCObjectDetailsV2 } from "../../../Apis/GetSFDCObjectDetailsV2";
import { PipelineObjectHelpers } from "../../../Apis/Pipeline/PipelineObjectHelpers";
import { UpdateSFDCSideDrawerConfig } from "../../../Apis/UpdateSFDCSideDrawerConfig";
import { UpdateSFDCObject } from "../../../Apis/UpdateSFDCObject";
import PipelineDrawerFormComponent from "../../Forms/Pipeline/Drawer/PipelineDrawerFormComponent";
import { PipelineBulkStageRenderComponent } from "./PipelineBulkStageRenderComponent";
import PipelineContactListsComponent from "./PipelineContactListsComponent";
import { setUpdateRecordErrorModal } from "../../../Store/ActionReducerPairs/Modals";
import {
	refreshPipelineTable,
	togglePipelineCreateModal,
} from "../../../Store/ActionReducerPairs/Pipeline";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import NoteList from "../../Pipeline/Notes/List";
import { ArrayObjectStringFilter } from "../../../Utils/Index";
import { GetUserDocuments } from "../../../Apis/Firebase/GetUserDocuments";

export const PipelineAccountDrawerComponent = ({
	initData,
}: {
	initData: { objectType: string; objectId: string };
}) => {
	const dispatch = useAppDispatch();
	const [objectData, setObjectData] = useState<any>(null);
	const [fieldInfo, setFieldInfo] = useState<any>(null);
	const [loading, setLoading] = useState(false);
	const [formHelpers, setFormHelpers] = useState<any>(null);
	const [fieldsToDisplay, setFieldsToDisplay] = useState<Array<string>>([]);
	const [notes, setNotes] = useState<any>([]);
	const [allNotes, setAllNotes] = useState<any>([]);
	const drawers = useAppSelector((state: any) => state.drawer.drawers);

	const closeDrawer = () => {
		dispatch({
			type: "CLOSE_DRAWER",
		});
	};

	const getNotes = async () => {
		try {
			const res = await GetUserDocuments(undefined, initData.objectId);
			const notes = res
				.map((item: any) => ({
					id: item.id,
					...item.data(),
				}))
				.sort(
					(a, b) =>
						new Date(b.last_modified_at).getTime() -
						new Date(a.last_modified_at).getTime(),
				);
			setAllNotes(notes);
			setNotes(notes);
		} catch (e) {
			console.log(e);
		}
	};

	const searchInNotes = (e: any) => {
		let value = ArrayObjectStringFilter(allNotes, e.target.value);
		setNotes([...value]);
	};

	const retrieveObjectBaseData = (data: any) => {
		setLoading(true);
		GetSFDCObjectDetailsV2(data.objectType, data.objectId).then((res: any) => {
			setObjectData({ ...res.data[0] });
			setFieldInfo([...res.field_info]);
			setFieldsToDisplay(res.sfdc_drawer_fields_for_display);
			setLoading(false);
		});
	};

	useEffect(() => {
		void getNotes();
		PipelineObjectHelpers().then((res: any) => {
			setFormHelpers(res);
		});
		retrieveObjectBaseData(initData);
		return () => {
			setObjectData(null);
		};
	}, [initData]);

	const { TabPane } = Tabs;
	const UpdateTextOrNumberType = (value: any, o: any, multipleUpdate: any) => {
		UpdateSFDCObject(
			o.id,
			o.objectType,
			multipleUpdate ? value : { [o.objectDataKey]: value },
		).then((res: any) => {
			refreshPipelineTable(new Date());
			retrieveObjectBaseData(initData);
			res?.data.is_success
				? notification.success({
						message: "Account updated successfully",
						placement: "bottomRight",
						duration: 1,
				  })
				: setUpdateRecordErrorModal({
						visible: true,
						errorMessage: res.data.message.message,
				  });
		});
	};

	// *** Contact Related Opportunity ***
	const [relatedSearchWord, setRelatedSearchWord] = useState<string>("");

	const updateSideDrawerConfig = (data: Record<string, unknown>) => {
		setLoading(true);
		UpdateSFDCSideDrawerConfig(
			data.field_name as string,
			data.field_type as string,
			"Account",
			data.toggle_type as string,
			data.reference_to as Array<string>,
		).then(() => {
			retrieveObjectBaseData(initData);
		});
	};

	const openInSalesforce = () => {
		const instanceUrl = localStorage.getItem("sfdcInstanceUrl") || "";
		window.open(`${instanceUrl}/${objectData.Id}`, "_blank");
	};

	return (
		<>
			{objectData && fieldInfo ? (
				<div className="pod_drawer pod_pipeline_drawer">
					<div className="pod_drawer_head">
						{drawers.length > 1 && (
							<div className="close-icon">
								<img src={BackIcon} alt="" onClick={closeDrawer} />
							</div>
						)}
						<div className="pod_drawer_head--title">
							<span>Account</span>
							<img src={AccountIcon} alt="" />
						</div>
						<Tooltip
							placement="topLeft"
							title="Open in Salesforce"
							style={{ background: "none" }}
						>
							<span className="external-icon-wrapper">
								<img
									src="/images/icons/cloud.png"
									alt="external-icon"
									className="external-icon"
									onClick={openInSalesforce}
								/>
							</span>
						</Tooltip>
					</div>

					<div className="pod_contact_card">
						<div className="pod_contact_card_info_wrap">
							<div className="pod_contact_card_info">
								<div className="d-flex justify-content-between align-items-end">
									<div>
										<div className="pod_contact_card_info_each">
											<div className="pod_contact_card_info--label">
												Account Name
											</div>
											<div className="pod_contact_card_info--title">
												{objectData.Name}
											</div>
										</div>
										<div className="pod_contact_card_info_each mb-0">
											<div className="pod_contact_card_info--label">
												Industry
											</div>
											<div className="pod_contact_card_info--subtitle">
												{objectData.Industry || "n/a"}
											</div>
										</div>
									</div>
									<div>
										<div className="pod_contact_card_info_each mb-0 text-end">
											<div className="pod_contact_card_info--user">
												<img src={UserIcon} alt="" />
											</div>
											<div className="pod_contact_card_info--label">
												Account Owner
											</div>
											<div className="pod_contact_card_info--subtitle">
												{objectData.Owner?.Name || "n/a"}
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="d-flex align-items-center justify-content-between">
								<div className="pod_contact_card_info--email">
									{" "}
									<img src={GlobeIcon} className="img-fluid" alt="" />{" "}
									{objectData.Website || "n/a"}
								</div>
								<div className="pod_contact_card_info--phn">
									<img src={CallIcon} className="img-fluid" alt="" />{" "}
									{objectData.Phone || "n/a"}
								</div>
							</div>
						</div>
					</div>

					<div className="pod_account_content">
						<Tabs defaultActiveKey="6">
							<TabPane tab="Details" key="6">
								<PipelineDrawerFormComponent
									initData={{
										objectId: objectData.Id,
										fieldInfo,
									}}
									formData={objectData}
									objectType="Account"
									onUpdate={() => retrieveObjectBaseData(initData)}
									onSave={(newValues) => setObjectData(newValues)}
									loading={loading}
									fieldsToDisplay={fieldsToDisplay}
									onUpdateSideDrawerConfig={updateSideDrawerConfig}
								/>
							</TabPane>
							<TabPane tab="Opportunities" key="7">
								<div className="pod_assign_contact">
									<Input
										className="custom_input_1"
										onChange={(e: any) => setRelatedSearchWord(e.target.value)}
										size="large"
										prefix={<AiOutlineSearch size={20} color="#999" />}
										placeholder="Search by Name"
									/>
									<Button
										className="pod_btn_secondary"
										onClick={() =>
											togglePipelineCreateModal({
												modalVisible: true,
												selectedModal: {
													fieldText: "Opportunity",
													name: "Opportunity",
													iconUrl:
														"/images/icons/SFDC-objects/opportunities.svg",
												},
											})
										}
									>
										+ &nbsp;Opportunity
									</Button>
								</div>
								{formHelpers ? (
									<PipelineBulkStageRenderComponent
										SearchInRelatedOpp={relatedSearchWord || ""}
										stages={formHelpers.StageName}
										initData={{
											objectType: "account",
											objectId: objectData.Id,
										}}
										UpdateTextOrNumberType={UpdateTextOrNumberType}
									/>
								) : (
									<Skeleton active paragraph={{ rows: 5 }} />
								)}
							</TabPane>
							<TabPane tab="Contacts" key="8">
								<div className="pod_assign_contact">
									<AutoComplete className="w-100">
										<Input
											className="custom_input_1"
											size="large"
											prefix={<AiOutlineSearch size={20} color="#999" />}
											placeholder="Search by Name"
										/>
									</AutoComplete>
									<Button
										className="pod_btn_secondary"
										onClick={() =>
											togglePipelineCreateModal({
												modalVisible: true,
												selectedModal: {
													fieldText: "Contact",
													name: "Contact",
													iconUrl: "/images/icons/SFDC-objects/contacts.svg",
												},
											})
										}
									>
										+ &nbsp;Contact
									</Button>
								</div>

								<PipelineContactListsComponent
									objectId={objectData.Id}
									objectType="account"
								/>
							</TabPane>
							<TabPane tab="Notes" key="9">
								<div className="position-relative pod_assign_contact_wrap">
									<NoteList
										notes={notes}
										searchInNotes={searchInNotes}
										onClose={closeDrawer}
									/>
								</div>
							</TabPane>
						</Tabs>
					</div>
				</div>
			) : (
				<div className="my-5 p-2">
					<Skeleton active paragraph={{ rows: 10 }}></Skeleton>
				</div>
			)}
		</>
	);
};
