import { firebaseApp } from "./Config";
import { doc, setDoc, getFirestore } from "firebase/firestore";
import moment from "moment";

export const CreateDefaultTasks = async (data: any) => {
	const dateVal = moment();

	const defatultTaskData = [
		{
			task_id: Math.random().toString(36).slice(2),
			assignee: "Head of Legal",
			due_date: dateVal.add(15, "days").format().split("T")[0],
			content: "Review contract with internal legal team",
			task_status: "Not Completed",
		},
		{
			task_id: Math.random().toString(36).slice(2),
			assignee: "Sales Director",
			due_date: dateVal.add(7, "days").format().split("T")[0],
			content: "Prepare presentation with manage",
			task_status: "Completed",
		},
	];

	const db = getFirestore(firebaseApp);

	defatultTaskData.forEach((obj) => {
		const refObj = doc(db, "tasks", obj.task_id);
		setDoc(refObj, {
			assignee: obj.assignee,
			content: obj.content,
			created_at: dateVal.format().split("T")[0],
			creator: data.email,
			due_date: obj.due_date,
			last_modified_at: dateVal.format().split("T")[0],
			opportunity_id: "",
			opportunity_name: "",
			pod_user_id: data.pod_user_id,
			task_status: obj.task_status,
		});
	});
};
