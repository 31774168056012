import { Button, Col, Form, Row, Select, Switch, DatePicker } from "antd";
import { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import "./style.scss";
import { DropdownSearch } from "../../../Components/DropdownSearchV2";
import moment from "moment";
import { API_FETCH } from "../../../Utils/Index";
import clsx from "clsx";

const { Item, useForm } = Form;
const { Option } = Select;

const opportunityTypeOptions = [
	{ label: "Meeting Notes", value: "Meeting Notes" },
	{ label: "Account Plan", value: "Account Plan" },
	{ label: "Personal Notes", value: "Personal Notes" },
	{ label: "Others", value: "Others" },
];

const iconUrls: { [key: string]: string } = {
	Opportunity: "/images/icons/SFDC-objects/opportunities.svg",
	Account: "/images/icons/SFDC-objects/accounts.svg",
	Contact: "/images/icons/SFDC-objects/contacts.svg",
	Lead: "/images/icons/SFDC-objects/leads.svg",
	Custom: "/images/icons/Shared.svg",
};

export interface Props {
	fetchData: () => void;
}

const DocumentHubFiltersComponent = ({ fetchData }: Props) => {
	const [filterCreationForm] = useForm();
	const [contactOptions, setContactOptions] = useState<any>(null);
	const [opportunity, setOpportunity] = useState<Record<string, string>>({});
	const [account, setAccount] = useState<Record<string, string>>({});
	const [stakeholders, setStakeholders] = useState<string[]>([]);
	const [privateDocs, setPrivateDocs] = useState(false);
	const [myDocs, setMyDocs] = useState(false);
	const [createdOn, setCreatedOn] = useState<any>();
	const [documentType, setDocumentType] = useState<string>();

	useEffect(() => {
		const filters = JSON.parse(
			localStorage.getItem("documentHubFilters") || "{}",
		);
		setOpportunity(filters.opportunity || {});
		setAccount(filters.account || {});
		setStakeholders(filters.stakeholders || []);
		setPrivateDocs(filters.privateDocs || false);
		setMyDocs(filters.myDocs || false);
		setCreatedOn(filters.createdOn ? moment(filters.createdOn) : undefined);
		setDocumentType(filters.documentType || undefined);
	}, []);

	useEffect(() => {
		let requestOptions: any = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				pod_user_id: Number(localStorage.getItem("podUserId")),
			}),
		};

		fetch(API_FETCH("/contacts_for_gcal_event"), requestOptions)
			.then((res: any) => res.json())
			.then((res: any) => {
				setContactOptions(res?.data || []);
			});
	}, []);

	const applyFilters = () => {
		localStorage.setItem(
			"documentHubFilters",
			JSON.stringify({
				opportunity,
				account,
				stakeholders,
				privateDocs,
				myDocs,
				createdOn: createdOn ? moment(createdOn).format("YYYY-MM-DD") : "",
				documentType: documentType || "",
			}),
		);
		fetchData();
	};

	const resetFilters = () => {
		localStorage.setItem(
			"documentHubFilters",
			JSON.stringify({
				opportunity: {},
				privateDocs: false,
				myDocs: false,
				createdOn: "",
				documentType: "",
			}),
		);
		setOpportunity({});
		setAccount({});
		setStakeholders([]);
		setPrivateDocs(false);
		setMyDocs(false);
		setCreatedOn(undefined);
		setDocumentType(undefined);
		fetchData();
	};

	return (
		<div className="pip_filter">
			<div className="pip_filter_creator">
				<Form
					form={filterCreationForm}
					layout="vertical"
					onFinish={applyFilters}
				>
					<Row gutter={10}>
						<Col span={12} style={{ paddingRight: 10 }}>
							<Item label="Opportunity" name="opportunity_id">
								<div className="direction-row">
									<div className="icon">
										<ReactSVG src="/images/icons/SFDC-objects/opportunities.svg" />
									</div>
									<DropdownSearch
										currentName={opportunity.name}
										currentId={opportunity.id}
										objectType="Opportunity"
										updateData={null}
										UpdateTextOrNumberType={(
											id: string,
											_: unknown,
											name: string,
										) => setOpportunity({ id, name })}
										size="middle"
										allowClear
									/>
								</div>
							</Item>
						</Col>
						<Col span={12} style={{ paddingLeft: 10 }}>
							<div className="pip_filter_box">
								<div className="pip_filter_box_item">
									<Item name="private">
										<Switch
											checked={privateDocs}
											onChange={(checked: boolean) => setPrivateDocs(checked)}
											size="small"
										/>
									</Item>
									<div className="pip_filter_box_label">Private Documents</div>
								</div>
								<div className="pip_filter_box_item">
									<Item name="myDocs">
										<Switch
											checked={myDocs}
											onChange={(checked: boolean) => setMyDocs(checked)}
											size="small"
										/>
									</Item>
									<div className="pip_filter_box_label">My Documents</div>
								</div>
							</div>
						</Col>
					</Row>
					<Row>
						<Col span={12} style={{ paddingRight: 10 }}>
							<Item label="Account" name="account_id">
								<div className="direction-row">
									<div className="icon">
										<ReactSVG src="/images/icons/SFDC-objects/accounts.svg" />
									</div>
									<DropdownSearch
										currentName={account.name}
										currentId={account.id}
										objectType="Account"
										updateData={null}
										UpdateTextOrNumberType={(
											id: string,
											_: unknown,
											name: string,
										) => setAccount({ id, name })}
										size="middle"
										allowClear
									/>
								</div>
							</Item>
						</Col>
						<Col span={12} style={{ paddingLeft: 10 }}>
							<Item label="Document Type">
								<Select
									size="middle"
									placeholder="Select"
									showSearch
									value={documentType}
									onChange={(value: string) => setDocumentType(value)}
									allowClear
									notFoundContent={
										!opportunityTypeOptions.length
											? "Search for a record ..."
											: "No results found"
									}
								>
									{opportunityTypeOptions.map(({ label, value }) => (
										<Select.Option key={value} value={value}>
											{label}
										</Select.Option>
									))}
								</Select>
							</Item>
						</Col>
					</Row>
					<Row>
						<Col span={12} style={{ paddingRight: 10 }}>
							<Item label="Stakeholders" name="stakeholders">
								<div className="direction-row">
									<div className="icon">
										<ReactSVG src="/images/icons/Shared.svg" />
									</div>
									<Select
										mode="tags"
										className={clsx(
											"calendar-view_add_participant_box",
											"doc_wrapper_meta_select",
										)}
										optionLabelProp="label"
										value={stakeholders || []}
										filterOption={(input, option: any) =>
											(option.dataName || option.value)
												.toLowerCase()
												.indexOf(input.toLowerCase()) >= 0
										}
										onChange={(value) => setStakeholders(value)}
										tokenSeparators={[","]}
									>
										{contactOptions?.map((contact: any) => (
											<Option
												value={contact.Id}
												key={contact.Id}
												label={
													<>
														<ReactSVG src={iconUrls[contact.attributes.type]} />
														<div>{contact.Name}</div>
													</>
												}
												dataName={contact.Name}
											>
												<div className="calendar-view_add_participant_item">
													<ReactSVG src={iconUrls[contact.attributes.type]} />
													{contact.Name}
												</div>
											</Option>
										))}
										{stakeholders
											?.filter(
												(item: string) =>
													!contactOptions?.find(
														(contact: any) => contact.Id === item,
													),
											)
											?.map((contact: any) => (
												<Option
													value={contact}
													key={contact}
													label={
														<>
															<ReactSVG src={iconUrls.Custom} />
															<div>{contact}</div>
														</>
													}
													dataName={contact}
												>
													<div className="calendar-view_add_participant_item">
														<ReactSVG src={iconUrls.Custom} />
														{contact}
													</div>
												</Option>
											))}
									</Select>
								</div>
							</Item>
						</Col>
						<Col span={12} style={{ paddingLeft: 10 }}>
							<Item label="Meeting Date">
								<DatePicker
									value={createdOn}
									onChange={(date) => setCreatedOn(date)}
									style={{ width: "100%" }}
								/>
							</Item>
						</Col>
					</Row>
					<Row style={{ height: 36 }}>
						<Col span={24} className="text-end">
							<Item className="d-inline-block">
								<Button onClick={resetFilters}>Reset</Button>
							</Item>
							<Item className="d-inline-block ms-2">
								<Button className="custom_btn" type="primary" htmlType="submit">
									Apply Filter
								</Button>
							</Item>
						</Col>
					</Row>
				</Form>
			</div>
		</div>
	);
};

export default DocumentHubFiltersComponent;
