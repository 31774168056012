import moment from "moment";
import { Config } from "../Config";
import lodash from "lodash";

export type Transformer<T> = (chunk: BufferSource | undefined) => T;

export const textTransformer: Transformer<string> = (
	chunk: BufferSource | undefined,
) => new TextDecoder().decode(chunk);

export const jsonTransformer: Transformer<any> = <T>(
	// TODO fix this generic typing
	chunk: BufferSource | undefined,
): T | undefined => {
	const jsonObjects = textTransformer(chunk)
		.trimEnd() // remove trailing newline, guaranteeing newlines only between objects
		.split("\n")
		.map((jsonObject) => Buffer.from(jsonObject, "base64").toString());

	// join chunks with commas and wrap in square brackets to ensure valid json and a consistent return type of T
	const wrappedJsonText = `[${jsonObjects.join(",")}]`;

	try {
		return JSON.parse(wrappedJsonText) as T;
	} catch (e) {
		console.log(e, wrappedJsonText);
		throw e; // to be handled at iteration level of the generator
	}
};

export const END_OF_STREAM_TOKEN = Symbol("EndOfStream");

// TODO chained transformers
export const fetchStream = async function* <
	T = string,
	IncludeEndToken extends boolean = false,
>(
	uri: string,
	params: { [key: string]: unknown },
	transformer: Transformer<T> = textTransformer as Transformer<T>,
	yieldEndOfStream: IncludeEndToken extends true
		? boolean
		: false = false as IncludeEndToken extends true ? boolean : false,
): AsyncGenerator<
	IncludeEndToken extends true ? T | typeof END_OF_STREAM_TOKEN : T
> {
	const response = await fetch(`${Config.API_BASE_URL()}${uri}`, {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify(params),
	});

	const reader =
		response.body === null
			? await getCanceledReader() // TODO: improved error handling, by throwing an error here
			: transformStream(response.body, transformer);

	while (true) {
		const { value, done } = await reader.read();
		if (done) {
			reader.releaseLock();
			if (yieldEndOfStream) {
				yield END_OF_STREAM_TOKEN as T; // TODO maybe we don't need an end of stream token, since we just `for await`
			}
			break;
		}

		yield value;
	}
};

const getCanceledReader = async (): Promise<ReadableStreamDefaultReader> => {
	const controller = new ReadableStream().getReader();
	await controller.cancel();
	return controller;
};

// https://developer.mozilla.org/en-US/docs/Web/API/TransformStream
const transformStream = <T = string>(
	stream: ReadableStream,
	transformer: Transformer<T> = textTransformer as Transformer<T>,
): ReadableStreamDefaultReader<T> => {
	const transformStream = new TransformStream({
		transform(chunk, controller) {
			controller.enqueue(transformer(chunk));
		},
	});
	return stream.pipeThrough(transformStream).getReader();
};

// +/- ~10ms
export const sleep = (ms: number): void => {
	const start = Date.now();
	while (Date.now() - start < ms) {}
};

// min and max are inclusive
export const getRandomInt = (min: number, max: number): number => {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const iterateIterables = function* (...iterables: any[]): Iterable<any> {
	for (const iterable of iterables) {
		yield* iterable;
	}
};

export const formatMoney = (n: number): string =>
	Intl.NumberFormat("en-US", {
		notation: "compact",
		maximumFractionDigits: 2,
	}).format(n);

export const ensure = <T>(
	value: T | undefined | null,
	message: string = "This value should be of type Exclude<T, null | undefined>",
): T => {
	if (value === undefined || value === null) {
		throw new TypeError(message);
	}

	return value;
};

/**
 * Use instead of Boolean with .filter so that typescript knows there are no longer falsy values
 * @param x
 */
export const truthyGuard = <T>(
	x: T | false | undefined | 0 | "" | null | 0n,
): x is T => Boolean(x); // TODO handle NaN

/**
 * Validate a string is a valid sfdc id, whether the 15 or 18 digit format.
 * https://stackoverflow.com/a/29299786/1333724
 * @param id
 */
export const isValidSfdcId = (id: string): boolean => {
	if (id.length === 15) {
		return /[a-zA-Z0-9]{15}/.test(id);
	}
	if (id.length !== 18 || !/[a-zA-Z0-9]{18}/.test(id)) {
		return false;
	}

	const upperCaseToBit = (char: string) => (char.match(/[A-Z]/) ? "1" : "0");
	const binaryToSymbol = (digit: number) =>
		digit <= 25
			? String.fromCharCode(digit + 65)
			: String.fromCharCode(digit - 26 + 48);

	const parts = [
		id.slice(0, 5).split("").reverse().map(upperCaseToBit).join(""),
		id.slice(5, 10).split("").reverse().map(upperCaseToBit).join(""),
		id.slice(10, 15).split("").reverse().map(upperCaseToBit).join(""),
	];

	return (
		id.slice(-3) ===
		parts.map((str) => binaryToSymbol(parseInt(str, 2))).join("")
	);
};

export const API_FETCH = (
	endpoint: any,
	local?: string | undefined | number,
) => {
	let baseURL =
		local === "local" ? "http://localhost:3000" : Config.API_BASE_URL();
	return baseURL + endpoint;
};

export const isValidEmailFormat = (email: string): boolean =>
	/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
		email,
	);

export const ArrayObjectStringFilter = (arr: any[], searchValue: any) =>
	searchValue.length > 0
		? arr.filter((obj) =>
				JSON.stringify(obj).toLowerCase().includes(searchValue.toLowerCase()),
		  )
		: arr;

export const ReplaceNullValuesWithString = (arr: any) =>
	JSON.stringify(arr, (key: any, value: any) => (value === null ? "-" : value));

export const NameFinderUsingId = (
	fieldArr: any[],
	fieldKey: string,
	idValue: string,
) => {
	try {
		let _check: any = fieldArr
			.find((item: any) => item[0] === fieldKey)[2]
			.find((pick: any) => pick.id === idValue);
		return { name: _check.name, id: _check.id };
	} catch (err) {
		return { id: idValue, name: idValue };
	}
};

export const FormTitleSearch = (v: string, data: any) => {
	let val: any;
	if (v !== "") {
		val = data.filter((item: any) => {
			if (item.label.toLowerCase().includes(v.toLowerCase())) {
				return { ...item };
			}
		});
	} else {
		val = data;
	}
	return val || [];
};

export function measureText(pText: any, pFontSize: any, pStyle?: any) {
	let lDiv: any = document.createElement("div");
	document.body.appendChild(lDiv);

	if (pStyle != null) {
		lDiv.style = pStyle;
	}
	lDiv.style.fontSize = "" + pFontSize + "px";
	lDiv.style.position = "absolute";
	lDiv.style.left = -1000;
	lDiv.style.top = -1000;

	lDiv.innerHTML = pText;

	const lResult = {
		width: lDiv.clientWidth * 1.1,
		height: lDiv.clientHeight,
	};

	document.body.removeChild(lDiv);
	lDiv = null;

	return lResult;
}
export const capitalize = (s: string) => s[0].toUpperCase() + s.slice(1);

export const shortTxt = (text: String) =>
	text.length > 30 ? text.substring(0, 30) + "..." : text;

/**
 * Trims " ID" from reference field labels
 */
export const effectiveLabelValue = (
	fieldInfoItemLabel: string,
	fieldInfoItemType: string,
): string =>
	fieldInfoItemType === "reference"
		? fieldInfoItemLabel.replace(" ID", "")
		: fieldInfoItemLabel;

export const needsFollowUp = (
	emails: Array<Record<string, string>>,
	meetings: Array<Record<string, string>>,
	data: any,
): boolean => {
	const sortedMeetings = lodash.sortBy(
		meetings.filter((i) => i.category === "customer_meeting"),
		"date",
	);
	const meeting = sortedMeetings[0];
	const from = localStorage.getItem("sfdcEmail");
	const email = emails
		.filter((i) => from && "from" in i && i.from.includes(from))
		.find((i) => i.date >= meeting?.date);
	const date = moment().add(-2, "days").format();
	const meetingFollowUp = !Boolean(email);
	const needsUpdate = data?.LastModifiedDate < date;
	return meeting && (meetingFollowUp || needsUpdate);
};
