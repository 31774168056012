import { Input, Menu, Spin } from "antd";
import { useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import {
	searchSObjectsByName,
	SObjectSearchResult,
} from "../../Apis/multiSObject";
import "../Pipeline/PipelineColAutoCompleteComponent.styles.scss";

const { Item } = Menu;

const DropdownSearchAutoComplete = ({
	objectType,
	onMenuClick,
}: {
	objectType: string;
	onMenuClick: any;
}) => {
	const [fil, setFil] = useState<SObjectSearchResult[]>([]);
	const [searchText, setSearchText] = useState("");
	const [loading, setLoading] = useState<boolean>(false);

	const clickAction = (key: any) => {
		const value = fil.find((item) => item.id === key.key)?.name;
		onMenuClick(key, value);
	};

	const searchOption = (e: any) => {
		setSearchText(e.target.value);
		setLoading(true);
		searchSObjectsByName([objectType], e.target.value, 5).then((r) => {
			setFil(r);
			setLoading(false);
		});
	};

	return (
		<div className={`pip_col_editor selectable`}>
			<div className="selectable_search">
				<Input
					onChange={searchOption}
					value={searchText}
					prefix={<AiOutlineSearch size={18} color="#c9c9c9" />}
					size="large"
					placeholder="Select"
				/>
			</div>
			{loading ? (
				<Spin
					className="spin"
					style={{ marginBottom: "0px", marginTop: "14px" }}
				/>
			) : (
				<Menu className="selectable_options" onClick={clickAction}>
					{fil.length && searchText.length >= 2 ? (
						fil.map(({ id, name }) => (
							<Item className="d-block" key={id}>
								{name}
							</Item>
						))
					) : (
						<Item className="d-block" key={"no-option"}>
							{searchText.length < 2
								? "Search for a record ..."
								: "No results found"}
						</Item>
					)}
				</Menu>
			)}
		</div>
	);
};

export default DropdownSearchAutoComplete;
