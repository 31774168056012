import { Button, Skeleton } from "antd";
import { ReactSVG } from "react-svg";
import { FiCopy } from "react-icons/fi";
import { IoMdArrowBack } from "react-icons/io";
import { useEffect, useState } from "react";
import { EmailTypes, generateEmail } from "../../../Apis/EmailGen/emailGen";

export const EmailPreview = ({
	emailType,
	context,
	onBack,
}: {
	emailType: EmailTypes;
	context: { [key: string]: unknown };
	onBack: VoidFunction;
}) => {
	const [subject, setSubject] = useState<string | undefined>(undefined);
	const [body, setBody] = useState<string | undefined>(undefined);

	useEffect(() => {
		(async () => {
			for await (const [stream, isBody] of generateEmail(emailType, context)) {
				if (isBody) {
					setBody((prevState) => (prevState ?? "") + stream);
				} else {
					setSubject((prevState) => (prevState ?? "") + stream);
				}
			}
		})();
	}, []);

	const copyEmailToClipboard = () => {
		if (body === undefined) {
			return;
		}
		void navigator.clipboard.writeText(body);
	};

	return subject !== undefined ? (
		<div className="email_preview">
			<div className="email_preview_header">
				<div className="back" onClick={onBack}>
					<IoMdArrowBack size={18} />
				</div>
				{"recipient" in context && (
					<div>Email to : {(context as { recipient: string }).recipient}</div>
				)}
			</div>
			<div className="email_preview_body">
				<ReactSVG src="/images/icons/NewCampaign.svg" />
				<div className="email_preview_content">
					<div className="email_preview_content--title">{subject}</div>
					<div
						className="email_preview_content--data"
						style={{ whiteSpace: "pre-line" }}
					>
						{body}
					</div>
				</div>
			</div>
			<div className="email_preview_actions">
				<Button
					type="primary"
					icon={<FiCopy size={18} />}
					onClick={copyEmailToClipboard}
				>
					Copy Email
				</Button>
			</div>
		</div>
	) : (
		<Skeleton active paragraph={{ rows: 10 }}></Skeleton>
	);
};
