import { Input, Menu } from "antd";
import { useState, useEffect } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import "./PipelineColAutoCompleteComponent.styles.scss";

interface Props {
	currentId?: string;
	onMenuClick?: any;
	selectOptions?: any;
	multiple?: boolean;
}
const { Item } = Menu;
const PipelineColAutoCompleteComponent = ({
	onMenuClick,
	selectOptions,
}: Props) => {
	const [fil, setFil] = useState<any>(selectOptions || []);
	const [search, setSearch] = useState("");
	const clickAction = (key: any) => {
		onMenuClick(key);
	};
	const searchOption = (text: string) => {
		setSearch(text);
		if (text !== "") {
			const filter = selectOptions.filter((item: any) =>
				item.value.toLowerCase().includes(text.toLowerCase()),
			);
			setFil(filter);
		} else {
			setFil(selectOptions);
		}
	};

	useEffect(() => {
		searchOption(search);
	}, [selectOptions]);

	return (
		<div className={`pip_col_editor selectable`}>
			<div className="selectable_search">
				<Input
					onChange={(e) => searchOption(e.target.value)}
					prefix={<AiOutlineSearch size={18} color="#c9c9c9" />}
					size="large"
					placeholder="Search Here"
				/>
			</div>
			<Menu className="selectable_options" onClick={clickAction}>
				<Menu className="selectable_options" onClick={clickAction}>
					{fil.length ? (
						fil.map(({ key, value }: any) => (
							<Item className="d-block" key={key}>
								{value}
							</Item>
						))
					) : (
						<Item className="d-block" key={"no-option"}>
							No options to select
						</Item>
					)}
				</Menu>
			</Menu>
		</div>
	);
};

export default PipelineColAutoCompleteComponent;
