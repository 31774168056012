import { API_FETCH } from "../../Utils/Index";

export const PipelineObjectHelpers = () => {
	let myHeaders = new Headers();
	myHeaders.append("Content-Type", "application/json");

	const requestOptions: any = {
		method: "POST",
		headers: myHeaders,
		body: JSON.stringify({
			pod_user_id: Number(localStorage.getItem("podUserId")),
		}),
		redirect: "follow",
	};
	return new Promise((resolve, reject) => {
		fetch(API_FETCH(`/create_sfdc_object_helper`), requestOptions)
			.then((response) => response.json())
			.then((result: PromiseLike<Object[]>) => resolve(result))
			.catch((error) => {
				console.warn("error while getting Opportunities =>", error);
				reject(error);
			});
	});
};
