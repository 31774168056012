import { END_OF_STREAM_TOKEN, fetchStream } from "../../Utils/Index";

export const knowledgeCenterEmailTypes = ["Outreach", "FollowUp", "General"];
export type KnowledgeCenterEmailType = typeof knowledgeCenterEmailTypes[number];
export const notesEmailTypes = ["Internal", "External"];
export type NotesEmailType = typeof notesEmailTypes[number];
export type EmailTypes = KnowledgeCenterEmailType | NotesEmailType;
export const emailTypeLabels: { [key in EmailTypes]: string } = {
	Outreach: "Outreach",
	FollowUp: "Follow-up",
	General: "General",
	Internal: "Internal",
	External: "External",
};
const emailTypeToRoute: { [key in EmailTypes]: string } = {
	Outreach: "/email_gen_outreach",
	FollowUp: "/email_gen_followup",
	General: "/email_gen_general",
	Internal: "/email_gen_internal",
	External: "/email_gen_external",
};

export const generateEmail = async function* (
	emailType: EmailTypes,
	context: { [key: string]: unknown },
): AsyncGenerator<[string, boolean]> {
	const stream = fetchStream(emailTypeToRoute[emailType], {
		pod_user_id: Number(localStorage.getItem("podUserId")),
		sfdcUserDisplayName: localStorage.getItem("sfdcUserDisplayName"), // TODO fetch on BE instead?
		context,
	});

	let isBody = false;
	for await (const chunk of stream) {
		if (isBody) {
			yield [chunk, true];
		} else {
			const [before, after] = chunk.split("\n", 2);
			yield [before, false];
			if (after !== undefined) {
				isBody = true;
				yield [after, true];
			}
		}
	}
};
