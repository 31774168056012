import { DefaultRootState } from "react-redux";
import { AnyAction } from "redux";
import { makeAction } from "./Action";

const defaultState: DefaultRootState = {
	sfdcEmail: "",
	instanceURL: "",
	podUserId: "",
	sfdcListViewData: "",
};

export const logoutUser = makeAction("LOGOUT_USER");
export const updateSFDCData = makeAction<any>("UPDATE_SFDC_TOKENS");

export const userReducer = (state: any = defaultState, action: AnyAction) => {
	if (logoutUser.guard(action)) {
		return defaultState;
	} else if (updateSFDCData.guard(action)) {
		return {
			...state,
			sfdcEmail: action.payload.sfdc_email || "",
			instanceURL: action.payload.instance_url || "",
			podUserId: action.payload.pod_user_id || "",
			sfdcListViewData: action.payload.sfdc_list_view_data || "",
		};
	}

	return state;
};
