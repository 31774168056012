import { useState } from "react";
import { Dropdown } from "antd";
import { CgFileDocument } from "react-icons/cg";
import "./style.scss";

interface Props {
	onClick: () => void;
	children: any;
	trigger?: any;
}

const menu = (onClick: () => void) => {
	return (
		<div onClick={onClick} className="dropdown-icon-container">
			<CgFileDocument color="#FFF" />
		</div>
	);
};

const IconDropdown = (props: Props) => {
	const { onClick, children, trigger } = props;
	const [visible, setVisible] = useState(false);

	const handleClick = () => {
		setVisible(false);
		onClick();
	};

	return (
		<Dropdown
			overlay={menu(handleClick)}
			trigger={!trigger ? ["click"] : trigger}
			visible={visible}
			onVisibleChange={setVisible}
		>
			{children}
		</Dropdown>
	);
};

export default IconDropdown;
