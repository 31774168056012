import { Tabs, Skeleton, Tooltip } from "antd";
import { useEffect, useState } from "react";
import LeadIcon from "../../../Images/SFDC-objects/leads.svg";
import ProfileIcon from "../../../Images/Profile.svg";
import EmailIcon from "../../../Images/Email.svg";
import CallIcon from "../../../Images/Call.svg";
import BackIcon from "../../../Images/Arrow.svg";
import "./PipelineDrawerComponent.styles.scss";
import { UpdateSFDCSideDrawerConfig } from "../../../Apis/UpdateSFDCSideDrawerConfig";
import { GetSFDCObjectDetailsV2 } from "../../../Apis/GetSFDCObjectDetailsV2";
import PipelineDrawerFormComponent from "../../Forms/Pipeline/Drawer/PipelineDrawerFormComponent";
import { useAppDispatch, useAppSelector } from "../../../hooks";

const { TabPane } = Tabs;

export const PipelineLeadDrawerComponent = ({
	initData,
}: {
	initData: { objectType: string; objectId: string };
}) => {
	const dispatch = useAppDispatch();
	const [objectData, setObjectData] = useState<any>(null);
	const [fieldInfo, setFieldInfo] = useState<any>(null);
	const [fieldsToDisplay, setFieldsToDisplay] = useState<Array<string>>([]);
	const [loading, setLoading] = useState(false);
	const drawers = useAppSelector((state: any) => state.drawer.drawers);

	const closeDrawer = () => {
		dispatch({
			type: "CLOSE_DRAWER",
		});
	};

	const retrieveObjectBaseData = (data: any) => {
		setLoading(true);
		GetSFDCObjectDetailsV2(data.objectType, data.objectId).then((res: any) => {
			setObjectData({ ...res.data[0] });
			setFieldInfo([...res.field_info]);
			setFieldsToDisplay(res.sfdc_drawer_fields_for_display);
			setLoading(false);
		});
	};
	useEffect(() => {
		retrieveObjectBaseData(initData);
		return () => {
			setObjectData(null);
		};
	}, [initData]);

	const updateSideDrawerConfig = (data: Record<string, unknown>) => {
		setLoading(true);
		UpdateSFDCSideDrawerConfig(
			data.field_name as string,
			data.field_type as string,
			"Lead",
			data.toggle_type as string,
			data.reference_to as Array<string>,
		).then(() => {
			retrieveObjectBaseData(initData);
		});
	};

	const openInSalesforce = () => {
		const instanceUrl = localStorage.getItem("sfdcInstanceUrl") || "";
		window.open(`${instanceUrl}/${objectData.Id}`, "_blank");
	};

	return (
		<>
			{objectData && fieldInfo ? (
				<div className="pod_drawer pod_pipeline_drawer">
					<div className="pod_drawer_head">
						{drawers.length > 1 && (
							<div className="close-icon">
								<img src={BackIcon} alt="" onClick={closeDrawer} />
							</div>
						)}
						<div className="pod_drawer_head--title">
							<span>Lead</span>
							<img src={LeadIcon} alt="" />
						</div>
						<Tooltip
							placement="topLeft"
							title="Open in Salesforce"
							style={{ background: "none" }}
						>
							<span className="external-icon-wrapper">
								<img
									src="/images/icons/cloud.png"
									alt="external-icon"
									className="external-icon"
									onClick={openInSalesforce}
								/>
							</span>
						</Tooltip>
					</div>
					<div className="pod_contact_card">
						<div className="pod_contact_card_info_wrap">
							<div className="pod_contact_card_info">
								<div className="pod_contact_card_info--profile">
									<img src={ProfileIcon} className="img-fluid" alt="" />
								</div>
								<div className="pod_contact_card_info--name">
									{objectData.Name || "n/a"}
								</div>
								<div className="pod_contact_card_info--desn">
									{objectData.Company || "n/a"}
								</div>
							</div>
							<div className="d-flex align-items-center justify-content-between">
								<div className="pod_contact_card_info--email">
									{" "}
									<img src={EmailIcon} className="img-fluid" alt="" />{" "}
									{objectData.Email || "n/a"}
								</div>
								<div className="pod_contact_card_info--phn">
									<img src={CallIcon} className="img-fluid" alt="" />{" "}
									{objectData.Phone || "n/a"}
								</div>
							</div>
						</div>
					</div>
					<div className="pod_lead_content">
						<Tabs defaultActiveKey="4">
							<TabPane tab="Details" key="4">
								<PipelineDrawerFormComponent
									initData={{
										objectId: objectData.Id,
										fieldInfo,
									}}
									formData={objectData}
									objectType="Lead"
									onUpdate={() => retrieveObjectBaseData(initData)}
									onSave={(newValues) => setObjectData(newValues)}
									loading={loading}
									fieldsToDisplay={fieldsToDisplay}
									onUpdateSideDrawerConfig={updateSideDrawerConfig}
								/>
							</TabPane>
						</Tabs>
					</div>
				</div>
			) : (
				<div className="my-5 p-2">
					<Skeleton active paragraph={{ rows: 10 }}></Skeleton>
				</div>
			)}
		</>
	);
};
