import api from "./api";

const getFieldDetailsByObjectType = async (
	objectType: string,
): Promise<any[]> => {
	return api
		.post("/get_field_details_for_sfdc_object_type", {
			object_type: objectType,
			pod_user_id: Number(localStorage.getItem("podUserId")),
		})
		.then((res) => res.data as any[]);
};

export const getOptionalAndRequiredFieldDetailsByObjectType = async (
	objectType: string,
): Promise<any[][]> => {
	const fieldDetails = await getFieldDetailsByObjectType(objectType);
	let _optional = [];
	let _required = [];
	for (let field of fieldDetails) {
		if (!field.createable) {
			continue;
		}
		if (!field.nillable && !field.defaultedOnCreate) {
			_required.push(field);
		} else {
			_optional.push(field);
		}
	}
	const optional = _optional.map(
		({ label, type, name, referenceTo, picklistValues }: any) => ({
			value: name,
			type,
			label,
			objectType:
				referenceTo.includes("Group") || referenceTo.includes("User")
					? "User"
					: referenceTo.length
					? referenceTo[0]
					: undefined,
			picklistValues,
		}),
	);
	const required = _required.map((item: any) => {
		const referenceTo =
			item.referenceTo.includes("Group") || item.referenceTo.includes("User")
				? "User"
				: item.referenceTo.length
				? item.referenceTo[0]
				: undefined;
		return {
			key: item.name,
			type: item.type,
			title: item.label,
			objectType: referenceTo,
			picklistValues: item.picklistValues,
		};
	});

	return [optional, required];
};
