import { firebaseApp } from "./Config";
import {
	collection,
	query,
	where,
	getDocs,
	getFirestore,
	doc,
	setDoc,
	documentId,
} from "firebase/firestore";

export const CreateSharedTemplate = async (
	templateId: string,
	isShared = true,
) => {
	const db = getFirestore(firebaseApp);
	const organization_id: string = localStorage.getItem("organizationId") || "";

	const q = query(
		collection(db, "shared_document_templates"),
		where(documentId(), "==", organization_id),
	);
	const sharedDoc = (await getDocs(q)).docs[0];
	let sharedIds: string[] = sharedDoc?.data()["document_template_ids"];

	if (sharedIds?.includes(templateId)) {
		const index = sharedIds.indexOf(templateId);
		sharedIds.splice(index, 1);
	}
	if (isShared) {
		sharedIds = [...(sharedIds || []), templateId];
	}

	const refObj = doc(db, "shared_document_templates", organization_id);
	await setDoc(refObj, {
		document_template_ids: sharedIds || [],
		organization_id,
	});
};
