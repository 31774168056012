import { DatePicker } from "antd";
import moment from "moment";
import { ReactSVG } from "react-svg";
import { UpdateTask } from "../../../Apis/Firebase/UpdateTask";
import "./style.scss";

export interface Props {
	task: Record<string, string>;
	taskId: string;
	reload: () => void;
}

const TaskCard = ({ task, taskId, reload }: Props) => {
	const updateExistingTask = (
		taskId: string,
		updateData: Record<string, string>,
	) => {
		UpdateTask(taskId, {
			...task,
			...updateData,
		}).then(() => {
			reload();
		});
	};

	const updateTaskStatus = () => {
		const task_status =
			task.task_status === "Not Completed" ? "Completed" : "Not Completed";

		window.analytics.track("TaskManager_Update_Status", {
			podUserId: localStorage.getItem("podUserId"),
		});

		updateExistingTask(taskId, { task_status });
	};

	const renderTaskListCheckMark = () => {
		if (task.task_status === "Not Completed") {
			return (
				<span
					onClick={(e) => {
						updateTaskStatus();
						e.stopPropagation();
					}}
					style={{ position: "absolute", left: 24, top: 24 }}
					className="checkmark"
				>
					<ReactSVG src="/images/icons/UncheckedTask.svg" />
				</span>
			);
		} else {
			return (
				<span
					onClick={(e) => {
						updateTaskStatus();
						e.stopPropagation();
					}}
					style={{ position: "absolute", left: 24, top: 24 }}
					className="checkmark"
				>
					<ReactSVG src="/images/icons/CheckedTask.svg" />
				</span>
			);
		}
	};

	return (
		<div className="task-card-container">
			{renderTaskListCheckMark()}
			<div className="row" style={{ paddingRight: 0, paddingLeft: 40 }}>
				<p
					className="description"
					style={
						task.task_status == "Completed"
							? { textDecoration: "line-through", color: "#7D828C" }
							: {}
					}
				>
					{task.content}
				</p>
				<div
					className="right"
					style={{ position: "absolute", right: 24, width: "70%", top: 16 }}
				>
					<div className="item">
						<DatePicker
							className="item-datepicker"
							bordered={false}
							placeholder={
								task.due_date
									? moment(task.due_date).format("YYYY-MM-DD")
									: "Not selected"
							}
							disabled
						/>
					</div>
					<div
						className="item"
						style={{
							flexDirection: "row",
							display: "flex",
							justifyContent: "right",
						}}
					>
						<p className="item-value">{task.assignee}</p>
						<ReactSVG
							src="/images/icons/UserLogo.svg"
							className="user-icon"
							color="#d9d9d9"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TaskCard;
