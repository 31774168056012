import React, { useState } from "react";
import { Button, Col, DatePicker, Row } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import moment from "moment";

const Datepicker = ({ change }) => {
	const [monthName, setMonth] = useState(moment());

	const handleChange = (arg, direction) => {
		if (direction == undefined) {
			let month = moment(arg, "YYYY-MM");
			setMonth(month);
			change(month);
		} else {
			if (direction === "left") {
				let month = moment(monthName).subtract(1, "month");
				setMonth(month);
				change(month);
			} else if (direction === "right") {
				let month = moment(monthName).add(1, "month");
				setMonth(month);
				change(month);
			}
		}
	};

	return (
		<Row
			justify="space-between"
			style={{ marginTop: "8px", marginBottom: "8px" }}
		>
			<Col
				style={{
					display: "flex",
					justifyContent: "space-between",
				}}
			>
				<div className="btn-container">
					<div style={{ display: "flex", padding: "0 10px" }}>
						<Button
							className="datepicker-btn-cstm"
							style={{ marginBottom: "4px", border: "none" }}
							onClick={() => handleChange(1, "left")}
						>
							<LeftOutlined />
						</Button>
						<div style={{ alignSelf: "center" }}>
							<DatePicker
								picker="month"
								size="small"
								bordered={false}
								onChange={(date) => handleChange(date)}
								value={monthName}
								allowClear={false}
								format={"MMM, YYYY"}
							/>
						</div>
						<Button
							className="datepicker-btn-cstm"
							style={{ marginBottom: "4px", border: "none" }}
							onClick={() => handleChange(1, "right")}
						>
							<RightOutlined />
						</Button>
					</div>
				</div>
			</Col>
		</Row>
	);
};

export default Datepicker;
