import {
	Drawer,
	Button,
	Row,
	Col,
	Tabs,
	Spin,
	Tooltip,
	Skeleton,
	notification,
} from "antd";
import { useState, useEffect, useMemo } from "react";
import { IoMdOpen } from "react-icons/io";
import { AiOutlineMail } from "react-icons/ai";
import { ReactSVG } from "react-svg";
import CloudIcon from "../../../Images/Cloud.svg";
import { FiUsers, FiCalendar } from "react-icons/fi";
import moment, { Moment } from "moment";
import { shortTxt } from "../../../Utils/Index";
import { GetActivityDrawerDetails } from "../../../Apis/GetActivityDrawerDetails";
import { DropdownSearch } from "../../../Components/DropdownSearchV2";
import { SyncEmailFromGmailToSfdc } from "../../../Apis/SyncEmailFromGmailToSfdc";
import { DrawerProps } from "../DrawerList";

export const EmailDrawer = ({ visible, onClose, initData }: DrawerProps) => {
	const { data, sfdcOpportunityName, sfdcOpportunityId } = initData;
	const [opportunityName, setOpportunityName] = useState<string>("");
	const [opportunityId, setOpportunityId] = useState<string>("");
	const [details, setDetails] = useState<any>();
	const [loader, setLoader] = useState(false);

	useEffect(() => {
		setDetails(null);
		setOpportunityName("");
		setOpportunityId("");
		if (data && data.source && data.category && data.reference_id) {
			GetActivityDrawerDetails(
				data.reference_id,
				data.source,
				data.category,
			).then((res: any) => {
				setDetails(res);
				setOpportunityId(sfdcOpportunityId);
				setOpportunityName(sfdcOpportunityName);
			});
		}
	}, [data]);

	const getEmailDate = (date: Moment | string) => {
		return moment(date).format("dddd, DD MMMM YYYY");
	};

	const from = useMemo(() => {
		const value = details?.payload?.headers?.find(
			(item: any) => item.name === "From",
		)?.value;
		return value && value !== "Not stored in SFDC" ? value : "---";
	}, [details]);

	const to = useMemo(() => {
		const value =
			details?.payload?.headers
				?.find((item: any) => item.name === "To")
				?.value?.split(",") || [];
		const filteredEmails = value.filter(
			(item: string) => item && item !== "Not stored in SFDC",
		);
		return filteredEmails.length ? filteredEmails : ["---"];
	}, [details]);

	const cc = useMemo(() => {
		const value =
			details?.payload?.headers
				?.find((item: any) => item.name === "Cc")
				?.value?.split(",") || [];
		const filteredEmails = value.filter(
			(item: string) => item && item !== "Not stored in SFDC",
		);
		return filteredEmails.length ? filteredEmails : ["---"];
	}, [details]);

	const emailBody = useMemo(() => {
		return details?.snippet && details.snippet !== "Not stored in SFDC"
			? details.snippet
			: "No email body stored in Salesforce";
	}, [details]);

	const handleSubmit = () => {
		if (data?.source !== "gmail") {
			notification.warn({
				message:
					"This data is already from SFDC. Therefore, it is already synced",
				placement: "bottomRight",
				duration: 1,
			});
			return;
		}
		setLoader(true);
		SyncEmailFromGmailToSfdc(
			data.subject,
			details?.snippet || "",
			[from, ...to, ...cc].filter((i) => i !== "---"),
			moment(data.date).format(),
			from,
			to[0],
			sfdcOpportunityId,
			"Email",
			data.reference_id,
		).then(() => {
			window.analytics.track("ActivityView_Sync_Activity", {
				podUserId: localStorage.getItem("podUserId"),
			});

			setLoader(false);
		});
	};

	const { TabPane } = Tabs;

	return (
		<Drawer
			title=""
			placement="right"
			width={400}
			onClose={() => onClose()}
			visible={visible}
			style={{
				marginTop: "64px",
			}}
			className="customer_drawer_wrapper"
		>
			{!details ? (
				<div className="my-5 px-3">
					<Skeleton active paragraph={{ rows: 10 }}></Skeleton>
				</div>
			) : (
				<div className="pod_drawer customer_drawer">
					{/*<span className="pod_drawer_meeting_title">
              This email is from {data?.source === "gmail" ? "Gmail" : "Salesforce"}
            </span>*/}
					<div className="pod_drawer_head">
						<div className="d-flex justify-content-between">
							<div className="pod_drawer_head--title">
								<span className="email-icon">
									{" "}
									<AiOutlineMail color="#fff" />
								</span>{" "}
								<span
									className="pod_drawer_meeting_title"
									title={data && shortTxt(data.subject)}
								>
									{data && shortTxt(data.subject)}
								</span>{" "}
							</div>
						</div>

						<div
							className="pod_drawer_head--subtitle"
							style={{ paddingLeft: 0 }}
						>
							<Spin spinning={loader}>
								<Row style={{ alignItems: "center" }}>
									<Col span={2}>
										<ReactSVG
											className="crown-icon"
											src="/images/icons/SFDC-objects/opportunities.svg"
										/>
									</Col>
									<Col span={16} style={{ paddingLeft: 10 }}>
										<DropdownSearch
											currentName={opportunityName}
											currentId={opportunityId}
											objectType="Opportunity"
											UpdateTextOrNumberType={(
												value: string,
												_: unknown,
												name: string,
											) => {
												setOpportunityId(value);
												setOpportunityName(name);
											}}
											size="middle"
											disabled
										/>
									</Col>
									<Col span={6}>
										<div className="pod_stage_syn--btn">
											{data?.source !== "gmail" ? (
												<Tooltip
													placement="topLeft"
													title="This data is already from SFDC. Therefore, it is already synced"
													style={{ background: "none" }}
												>
													<Button
														type="primary"
														onClick={handleSubmit}
														style={{
															height: "32px",
															marginLeft: "10px",
															borderRadius: 4,
															backgroundColor: "#d9d9d9",
														}}
														icon={
															<img
																src={CloudIcon}
																className="img-fluid"
																style={{ marginRight: 4 }}
															></img>
														}
														disabled={data?.source !== "gcal"}
													>
														{" "}
														Sync
													</Button>
												</Tooltip>
											) : (
												<Button
													type="primary"
													onClick={handleSubmit}
													className="syn_btn"
													style={{ height: "32px", marginLeft: "10px" }}
													icon={
														<img src={CloudIcon} className="img-fluid"></img>
													}
												>
													Sync
												</Button>
											)}
										</div>
									</Col>
								</Row>
							</Spin>
						</div>
					</div>

					<div
						className="pod_drawer_meeting_details_area"
						style={{ height: "calc(100vh - 244px)" }}
					>
						<div className="row meeting_date_time_container">
							<div className="col-md-1">
								<FiCalendar
									style={{
										alignSelf: "center",
										width: "23px",
										height: "23px",
										marginLeft: "-5px",
									}}
								/>
							</div>
							<div className="col-md-11">
								{data && getEmailDate(data.date?.replaceAll("/", "-"))}
							</div>
						</div>

						<div>
							<Tabs defaultActiveKey="1" style={{ position: "relative" }}>
								<TabPane tab="Recipients" key="1">
									<div>
										<div
											className="recipient-list"
											style={{ flex: 1, overflowY: "scroll", width: "auto" }}
										>
											<div>
												<div className="recipient-list-title no-top-border">
													<FiUsers className="participants-icon" /> From
												</div>
												<ul className="recipient-list-ul">
													<li>{from}</li>
												</ul>
											</div>
											{to?.length ? (
												<div>
													<div className="recipient-list-title">
														<FiUsers className="participants-icon" /> To
													</div>
													<ul className="recipient-list-ul">
														{to.map((i: string) => (
															<li>{i}</li>
														))}
													</ul>
												</div>
											) : null}
											{cc?.length ? (
												<div>
													<div className="recipient-list-title">
														<FiUsers className="participants-icon" /> CC
													</div>
													<ul className="recipient-list-ul">
														{cc.map((i: string) => (
															<li>{i}</li>
														))}
													</ul>
												</div>
											) : null}
										</div>
									</div>

									<Row>
										<div
											className="open-with-calendar-box"
											style={{
												position: "fixed",
												bottom: 0,
												right: 0,
												padding: "20px 0",
												borderTop: "1px solid #ddd",
												width: "400px",
												backgroundColor: "white",
												paddingLeft: 20,
											}}
										>
											<Button
												className="calendar-open-btn"
												style={{
													border: "1px solid #5000A2",
													borderRadius: "4px",
													color: "#5000A2",
													fontSize: "15px",
													fontWeight: "400",
													height: "40px",
												}}
												icon={
													<IoMdOpen
														fontSize="20"
														style={{ marginRight: "10px" }}
													/>
												}
												onClick={() =>
													window.open("https://mail.google.com/", "_blank")
												}
											>
												{" "}
												Open in Gmail
											</Button>
										</div>
									</Row>
								</TabPane>
								<TabPane
									tab="Email Body"
									key="2"
									style={{ margin: "0 0 0 15px" }}
								>
									<div style={{ width: "90%" }}>
										<Row
											gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
											style={{
												fontSize: "16px",
											}}
										>
											<p
												className="email-body-text"
												style={{
													whiteSpace: "pre-line",
												}}
											>
												{emailBody}
											</p>
										</Row>
									</div>
									<Row>
										<div
											className="open-with-calendar-box"
											style={{
												position: "fixed",
												bottom: 0,
												right: 0,
												padding: "20px 0",
												borderTop: "1px solid #ddd",
												width: "400px",
												backgroundColor: "white",
												paddingLeft: 20,
											}}
										>
											<Button
												className="calendar-open-btn"
												style={{
													border: "1px solid #5000A2",
													borderRadius: "4px",
													color: "#5000A2",
													fontSize: "15px",
													fontWeight: "400",
													height: "40px",
												}}
												icon={
													<IoMdOpen
														fontSize="20"
														style={{ marginRight: "10px" }}
													/>
												}
												onClick={() =>
													window.open("https://mail.google.com/", "_blank")
												}
											>
												{" "}
												Open in Gmail
											</Button>
										</div>
									</Row>
								</TabPane>
								<div
									style={{
										position: "absolute",
										right: "20px",
										bottom: "56px",
										top: 14,
									}}
								>
									{data?.source === "gmail" ? (
										<img
											style={{ width: 25 }}
											src="/images/icons/google-login-icon.png"
											alt="google-icon"
											className="google-icon"
										/>
									) : (
										<img
											style={{ width: 27 }}
											src="/images/icons/cloud.png"
											alt="sfdc-icon"
										/>
									)}
								</div>
							</Tabs>
						</div>
					</div>
				</div>
			)}
		</Drawer>
	);
};
