import { Input, Switch, DatePicker, Button } from "antd";
import { DropdownSearch } from "../DropdownSearchV2";
import { PicklistWidget } from "../Forms/Widgets/FormWidgets";
import moment, { Moment } from "moment";
import { ReactElement } from "react";

export const FieldSelector = (props: {
	type: string;
	objectType: string;
	refObjectName: string;
	currentValue: unknown;
	updateValue: (value: unknown, name?: string, isSave?: boolean) => void;
	saveValue?: () => Promise<void>;
	picklist: Record<string, unknown>[];
	size?: "small" | "middle" | "large";
	booleanAsDropdown?: boolean;
	updateable?: boolean;
	recordTypeId?: any;
	fieldInfo?: any;
	placeholder?: string;
}): ReactElement => {
	const {
		refObjectName,
		currentValue,
		objectType,
		updateValue,
		saveValue = () => {},
		type,
		picklist,
		size,
		booleanAsDropdown,
		updateable,
		recordTypeId,
		fieldInfo,
		placeholder,
	} = props;

	if (updateable === false) {
		const value = type === "reference" ? refObjectName : currentValue;
		return (
			<Input value={String(value || "")} disabled size={size || "large"} />
		);
	}

	if (type.includes("reference")) {
		return (
			<DropdownSearch
				currentName={refObjectName as string}
				currentId={currentValue as string}
				objectType={objectType}
				UpdateTextOrNumberType={(value: string, _: unknown, name: string) => {
					updateValue(value, name, true);
				}}
				size={size || "large"}
			/>
		);
	} else if (type === "picklist") {
		let picklist2;

		if (fieldInfo?.name == "StageName") {
			if (recordTypeId != null) {
				const stagesForRecordTypes = JSON.parse(
					localStorage.getItem("stagesForRecordTypes") || "[]",
				);
				const dataForRecordType = stagesForRecordTypes.filter((obj: any) =>
					Object.keys(obj).includes(recordTypeId),
				);
				const data =
					dataForRecordType.length > 0
						? dataForRecordType[0][recordTypeId]
						: [];
				const labels = data.map((obj: any) => obj.label);
				picklist2 = picklist.filter((obj: any) => labels.includes(obj.label));
			} else {
				picklist2 = picklist;
			}
		}

		return PicklistWidget(
			{
				value: currentValue,
				onChange: (value: unknown) => {
					updateValue(value, value as string, true);
				},
			},
			typeof picklist2 !== "undefined" ? picklist2 : picklist,
			size || "middle",
		);
	} else if (type === "textarea" || type === "address") {
		return (
			<Input.TextArea
				value={String(currentValue || "")}
				onChange={(e) => updateValue(e.target.value)}
				onBlur={saveValue}
				size={size || "large"}
				placeholder={placeholder}
			/>
		);
	} else if (type === "boolean") {
		return booleanAsDropdown ? (
			PicklistWidget(
				{
					value: currentValue,
					onChange: (value: unknown) => {
						updateValue(value, value as string, true);
					},
				},
				[
					{ value: "TRUE", label: "Yes" },
					{ value: "FALSE", label: "No" },
				],
				"middle",
			)
		) : (
			<Switch
				checked={Boolean(currentValue)}
				onChange={(checked: boolean) => {
					updateValue(checked, undefined, true);
				}}
			/>
		);
	} else if (type === "datetime" || type === "date") {
		return (
			<DatePicker
				className="w-100"
				value={
					currentValue && currentValue !== "-"
						? moment(currentValue as Moment)
						: undefined
				}
				onChange={(value: Moment | null) => {
					updateValue(value, undefined, true);
				}}
				size={size || "large"}
			/>
		);
	} else if (type === "button-select") {
		return (
			<div
				style={{
					display: "flex",
					alignItems: "center",
					flexWrap: "wrap",
					gap: 11,
				}}
			>
				{picklist.map((item) => (
					<Button
						type={currentValue === item.value ? "primary" : "default"}
						onClick={() => updateValue(item.value, undefined, true)}
					>
						{item.label as string}
					</Button>
				))}
			</div>
		);
	} else {
		return (
			<Input
				value={String(currentValue || "")}
				onChange={(e) => updateValue(e.target.value)}
				onBlur={saveValue}
				onKeyDown={(e) => {
					if (e.key === "Enter") {
						saveValue();
					}
				}}
				size={size || "large"}
			/>
		);
	}
};
