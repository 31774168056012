import api from "./api";
import moment from "moment";

export const GetInactiveOpportunities = async (opportunity_id: string) => {
	const start_date = moment()
		.add(-1, "month")
		.format()
		.replace("-", "/")
		.replace("-", "/");
	const end_date = moment()
		.add(1, "month")
		.format()
		.replace("-", "/")
		.replace("-", "/");

	return api
		.get("/is_opportunity_inactive", {
			params: {
				opportunity_id,
				start_date,
				end_date,
				pod_user_id: Number(localStorage.getItem("podUserId")),
			},
		})
		.then((res) => res.data);
};
