import React, { useState, useEffect } from "react";
import { Card } from "antd";
import { startOfWeek, endOfWeek } from "date-fns";
import Calendar from "./CalendarBody";
import { CalendarHeader } from "./CalendarHeader";
import { GenericEvent, CalendarContainerProps } from "./types";
import { daysToWeekObject } from "./utils";
import OutlinedLoader from "../../Components/OutlinedLoader";

export function WeeklyCalendar<T extends GenericEvent>({
	events,
	onEventClick,
	onSelectDate,
	weekends = false,
	value,
	currentWeekData,
	loading,
}: CalendarContainerProps<T>) {
	const [startWeek, setStartWeek] = useState(startOfWeek(value || new Date()));
	const weekPeriod = {
		startDate: startWeek,
		endDate: endOfWeek(startWeek),
	};

	useEffect(() => {
		if (value && startOfWeek(value).getTime() !== startWeek.getTime()) {
			setStartWeek(value);
		}
	}, [value]);

	useEffect(() => {
		onSelectDate && onSelectDate(startWeek);
		currentWeekData(weekPeriod);
	}, [startWeek]);

	const weekObject = daysToWeekObject(events, startWeek);
	return (
		<Card>
			{loading && (
				<>
					<OutlinedLoader
						style={{
							position: "absolute",
							zIndex: 1001,
							marginTop: 320,
							fontSize: 120,
							marginLeft: (window.innerWidth - 280) / 2,
						}}
					/>
				</>
			)}
			<CalendarHeader startWeek={startWeek} setStartWeek={setStartWeek} />
			<Calendar
				weekDates={weekPeriod}
				getDayEvents={weekObject}
				onEventClick={onEventClick}
				weekends={weekends}
			/>
		</Card>
	);
}
