import api from "./api";

export type SimilarOpportunity = {
	accountName: string | null;
	oppName: string;
	ownerName: string | null;
};

export const GetSimilarOpportunities = async (
	opportunity_id: string,
	type: string,
): Promise<SimilarOpportunity[]> => {
	return api
		.post("/get_similar_opportunities", {
			opportunity_id,
			type,
			pod_user_id: Number(localStorage.getItem("podUserId")),
		})
		.then((res) => res.data);
};
