import {
	Col,
	Input,
	notification,
	Popover,
	Row,
	Select,
	Table,
	Tooltip,
} from "antd";
import {
	useEffect,
	useRef,
	useState,
	useMemo,
	useCallback,
	HTMLAttributes,
	SyntheticEvent,
} from "react";
import { BaseLayoutComponent } from "../Components/Shared/BaseLayoutComponent";
import {
	AiOutlineSearch,
	AiOutlineRight,
	AiOutlineDoubleRight,
	AiOutlineLeft,
	AiOutlineDoubleLeft,
} from "react-icons/ai";
import { MdDragIndicator } from "react-icons/md";
import ReactDragListView from "react-drag-listview";
import { ReactSVG } from "react-svg";
import PipelineColsSelectorComponent from "../Components/Pipeline/PipelineColsSelectorComponent";
import {
	effectiveLabelValue,
	isValidSfdcId,
	ReplaceNullValuesWithString,
} from "../Utils/Index";
import MasterFilter, {
	defaultFilter,
	doFiltersHaveSameActiveState,
	hasNonEmptyFilter,
	numActiveFilters,
} from "../Components/Pipeline/MasterFilter";
import { GetSFDCObjectV2 } from "../Apis/GetSFDCObjectV2";
import { UpdateSFDCObject } from "../Apis/UpdateSFDCObject";
import { CreateDocument } from "../Apis/Firebase/CreateDocument";
import { UpdatePipelineViewTableConfig } from "../Apis/UpdatePipelineViewTableConfig";
import "./PipelineViewPage.styles.scss";
import useWindowDimensions from "../Utils/WinodwSizeFinder";
import { Link, useSearchParams } from "react-router-dom";
import PipelineFieldSelector from "../Components/PipelineFieldSelector";
import type { ResizeCallbackData } from "react-resizable";
import { Resizable } from "react-resizable";
import type { ColumnsType, ColumnType } from "antd/lib/table";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { debounce } from "../Utils/debounce";
import {
	SyncMasterFilters,
	UpdateMasterFilters,
} from "../Apis/Firebase/SyncMasterFilters";
import api from "../Apis/api";
import { setUpdateRecordErrorModal } from "../Store/ActionReducerPairs/Modals";
import { useAppSelector } from "../hooks";
import {
	changeActiveCategory,
	refreshPipelineTable,
	togglePipelineCreateModal,
	updatePipelineFilter,
} from "../Store/ActionReducerPairs/Pipeline";
import {
	defaultField,
	getRecordTypesAndStagesBySObject,
	getSfdcOpportunitiesByRecordTypeAndStage,
	getStagesByRecordType,
} from "../Apis/RecordTypesAndStages";
import Pagination from "react-js-pagination";
import { KanbanBoardList } from "../Components/Pipeline/Kanban/List";
import {
	RowInsightAction,
	RowMoreActions,
} from "../Components/Pipeline/Components/PipelineActions";
import { ViewDropdown } from "../Components/Pipeline/ViewDropdown";
import clsx from "clsx";
import { BsLightning } from "react-icons/bs";

const ResizableTitle = (
	props: HTMLAttributes<any> & {
		onResize: (e: SyntheticEvent, data: ResizeCallbackData) => void;
		width: number;
	},
) => {
	const { onResize, width, ...restProps } = props;

	if (!width) {
		return <th {...restProps} />;
	}

	return (
		<Resizable
			width={width}
			height={0}
			handle={
				<span
					className="react-resizable-handle"
					onClick={(e) => {
						e.stopPropagation();
					}}
				/>
			}
			onResize={onResize}
			draggableOpts={{ enableUserSelectHack: false }}
		>
			<th {...restProps} />
		</Resizable>
	);
};

const typeByCategory: any = {
	opportunities: "Opportunity",
	accounts: "Account",
	contacts: "Contact",
	leads: "Lead",
};

enum ViewMode {
	table,
	kanban,
}

const { Option } = Select;
const PipelineViewPage = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const pipelineStore = useAppSelector(({ pipeline }) => pipeline);
	const masterFilterList = useAppSelector(({ user }) => user.sfdcListViewData);
	const [syncedMasterFilter, setSyncedMasterFilter] = useState<any>([]);
	const tableRef = useRef<any>();
	const windowSize = useWindowDimensions();
	const [tableData, setTableData] = useState<any>([]);
	const [tableSize, setTableSize] = useState<any>({
		x: "max-content",
		y: 400,
	});
	const [loading, setLoading] = useState<boolean>(true);
	const [objectList, setObjectList] = useState<any>([
		{
			name: "opportunities",
			iconUrl: "/images/icons/SFDC-objects/opportunities.svg",
			active: true,
		},
		{
			name: "accounts",
			iconUrl: "/images/icons/SFDC-objects/accounts.svg",
			active: false,
		},
		{
			name: "contacts",
			iconUrl: "/images/icons/SFDC-objects/contacts.svg",
			active: false,
		},
		{
			name: "leads",
			iconUrl: "/images/icons/SFDC-objects/leads.svg",
			active: false,
		},
	]);
	const [searchTerm, setSearchTerm] = useState<any>("");
	const [cols, setCols] = useState<any>([{}]);
	const [fieldInfo, setFieldInfo] = useState<any>([]);
	const [masterFilterText, setMasterFilterText] = useState<string>("");
	const [viewMode, setViewMode] = useState<ViewMode>(
		(localStorage.getItem("viewMode") &&
			ViewMode[localStorage.getItem("viewMode") as keyof typeof ViewMode]) ||
			ViewMode.table,
	);
	const [currFieldInfo, setCurrFieldInfo] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [sorter, setSorter] = useState<any>({});
	const [objectCount, setObjectCount] = useState<number>(1);
	const [pageSize, setPageSize] = useState<number>(25);

	// Kanban board states
	const [recordTypes, setRecordTypes] = useState<any[]>([]);
	const [currentRecordType, setCurrentRecordType] = useState("");
	const [kanbanStages, setKanbanStages] = useState<any[]>([]);

	useEffect(() => {
		if (searchParams) {
			const qs = new URLSearchParams(location.search);
			const isShowTask = qs.get("task_manager") === "visible";
			const isShowNewDoc = qs.get("new_doc") === "visible";
			const isShowLog = qs.get("log_activity") === "visible";

			if (isShowTask) {
				dispatch({
					type: "OPEN_TASK_MANAGER",
				});
			} else if (isShowNewDoc) {
				actionDotsHandler("Create Notes", "", "", "");
			} else if (isShowLog) {
				actionDotsHandler("Log New Activity", "", "", "");
			}

			setSearchParams("");
		}
	}, [searchParams]);

	useEffect(() => {
		window.analytics.page("PipelineView", {
			podUserId: localStorage.getItem("podUserId"),
		});
		getStagesByRecordType().then((res: any) => {
			localStorage.setItem("stagesForRecordTypes", JSON.stringify(res.data));
		});
		getRecordTypesAndStagesBySObject("Opportunity").then((res: any) => {
			setRecordTypes(res);
			setCurrentRecordType(res[0].Id);
		});
	}, []);

	useEffect(() => {
		fetchCategoryData(pipelineStore.category);
	}, [currentPage]);

	useEffect(() => {
		if (currentPage === 1) {
			fetchCategoryData(pipelineStore.category);
		} else {
			setCurrentPage(1);
		}
	}, [viewMode, pageSize]);

	useEffect(() => {
		fetchCategoryData(pipelineStore.category, true);
	}, [pipelineStore.stamp]);

	useEffect(() => {
		if (currentRecordType) {
			fetchCategoryData(pipelineStore.category);
		}
	}, [currentRecordType]);

	const masterFilters = useMemo(() => {
		return syncedMasterFilter.filter(
			(obj: any) => obj.SobjectType === typeByCategory[pipelineStore.category],
		);
	}, [pipelineStore.category, syncedMasterFilter]);

	const onDragHandler = (fromIndex: number, toIndex: number) => {
		if (
			toIndex !== cols.length - 1 &&
			toIndex !== 0 &&
			fromIndex !== cols.length - 1 &&
			fromIndex !== 0
		) {
			void UpdatePipelineViewTableConfig({
				view_id: JSON.parse(
					localStorage.getItem("pipelineViewMasterFilter") || "{}",
				)[pipelineStore.category],
				origin_column_idx: fromIndex,
				destination_column_idx: toIndex,
				update_type: "column_reorder",
				object_type: typeByCategory[pipelineStore.category],
			});

			const _cols: any = cols;
			const item = _cols[fromIndex];
			_cols.splice(fromIndex, 1);
			_cols.splice(toIndex, 0, item);
			setCols([..._cols]);
		}
	};

	useEffect(() => {
		const currentSearch = localStorage.getItem("searchText");
		localStorage.setItem("searchText", searchTerm);
		if (currentSearch && !searchTerm) {
			triggerSFDCStringSearch();
		}
	}, [searchTerm]);

	const updateMasterFilterList = (filterList: any[]) => {
		SyncMasterFilters(filterList).then((syncedListData) => {
			setSyncedMasterFilter(syncedListData);
		});
	};

	useEffect(() => {
		const doRequest = async () => {
			const res = await api.post("/get_list_view_data", {
				pod_user_id: Number(localStorage.getItem("podUserId")),
			});
			const listData = res.data.sfdc_list_view_data;
			updateMasterFilterList(listData);
		};
		void doRequest();
	}, []);

	useEffect(() => {
		if (masterFilterList && masterFilterList.length) {
			updateMasterFilterList(masterFilterList);
		}
	}, [masterFilterList]);

	useEffect(() => {
		const newObjectList: any = objectList.map((item: any) => {
			return { ...item, active: item.name === pipelineStore.category };
		});

		setObjectList([...newObjectList]);

		setSearchTerm("");

		fetchCategoryData(pipelineStore.category);
	}, [pipelineStore.category]);

	useEffect(() => {
		const pipelineViewMasterFilter = JSON.parse(
			localStorage.getItem("pipelineViewMasterFilter") || "{}",
		);
		setMasterFilterText(pipelineViewMasterFilter[pipelineStore.category]);
	}, [pipelineStore.category]);

	useEffect(() => {
		const pipelineViewMasterFilter = JSON.parse(
			localStorage.getItem("pipelineViewMasterFilter") || "{}",
		);

		if (
			masterFilterText &&
			masterFilterText !== pipelineViewMasterFilter[pipelineStore.category]
		) {
			localStorage.setItem(
				"pipelineViewMasterFilter",
				JSON.stringify({
					...pipelineViewMasterFilter,
					[pipelineStore.category]: masterFilterText,
				}),
			);
		}

		if (currentPage === 1) {
			fetchCategoryData(pipelineStore.category);
		} else {
			setCurrentPage(1);
		}
	}, [masterFilterText]);

	const updateObjectData = async (
		value: any,
		objectInfo: any,
		fieldType: string,
	) => {
		try {
			const res: any = await UpdateSFDCObject(
				objectInfo.id,
				typeByCategory[pipelineStore.category],
				{
					[objectInfo.objectDataKey]: ["datetime", "date"].includes(fieldType)
						? value.format("YYYY-MM-DD")
						: value,
				},
			);
			window.analytics.track("PipelineView_Update_Field", {
				podUserId: localStorage.getItem("podUserId"),
			});
			res?.data.is_success
				? notification.success({
						message: "Field updated successfully",
						placement: "bottomRight",
						duration: 1,
				  })
				: setUpdateRecordErrorModal({
						visible: true,
						errorMessage: res.data.message.message,
				  });
		} catch (e) {
			console.log(e);
		}
		fetchCategoryData(pipelineStore.category, true);
	};

	const fetchKanbanStagesByRecordType = async (
		stages: any[],
		reload = false,
	) => {
		return Promise.all(
			stages.map((stage: any) =>
				stage.is_last_page && !reload
					? {
							data: [],
							is_last_page: stage.is_last_page,
							object_count: stage.object_count,
					  }
					: getSfdcOpportunitiesByRecordTypeAndStage({
							recordTypeId: currentRecordType,
							stage: stage.value,
							pageSize: reload ? Math.max(stage.cards?.length || 0, 25) : 25,
							pageNum: currentPage,
							customFields: [
								{
									...defaultField,
									label: "Name",
									query_string: "Name",
								},
								{
									...defaultField,
									label: "Id",
									query_string: "Id",
								},
								{
									...defaultField,
									label: "StageName",
									query_string: "StageName",
								},
								{
									...defaultField,
									label: "Amount",
									query_string: "Amount",
								},
								{
									...defaultField,
									label: "CloseDate",
									query_string: "CloseDate",
								},
								{
									...defaultField,
									label: "AccountId",
									query_string: "Account.Name, AccountId",
								},
							],
					  }),
			),
		);
	};

	const fetchCategoryData = (changeURLKey: string, reload = false): void => {
		const pipelineViewMasterFilter = JSON.parse(
			localStorage.getItem("pipelineViewMasterFilter") || "{}",
		);
		if (!pipelineViewMasterFilter[changeURLKey]) return;

		if (
			viewMode === ViewMode.kanban &&
			pipelineStore.category === "opportunities"
		) {
			const recordTypeData = recordTypes.find(
				(item) => item.Id === currentRecordType,
			);
			if (recordTypeData?.stages) {
				const stages = currentPage === 1 ? recordTypeData.stages : kanbanStages;

				setLoading(true);
				fetchKanbanStagesByRecordType(stages, reload || currentPage === 1).then(
					(cards) => {
						stages.forEach((stage: any, index: number) => {
							stage.cards =
								reload || currentPage === 1
									? cards[index].data
									: [...(stage.cards || []), ...cards[index].data];
							stage.object_count = cards[index].object_count;
							stage.is_last_page = cards[index].is_last_page;
						});
						setKanbanStages(stages);
						if (
							!isValidSfdcId(pipelineViewMasterFilter[changeURLKey]) &&
							cards.length > 0 &&
							!doFiltersHaveSameActiveState(
								pipelineStore.filters[changeURLKey],
								cards[0].filters,
							)
						) {
							// TODO PERF:
							//  1) Do initial query on BE with the filters from DB
							//  2) set filters in FE without triggering re-render
							updatePipelineFilter({
								[changeURLKey]:
									cards[0].filters.length > 0
										? cards[0].filters
										: [defaultFilter],
							});
							refreshPipelineTable(new Date());
						}
						setLoading(false);
					},
				);
			}
		} else {
			setLoading(true);
			GetSFDCObjectV2({ changeURLKey, pageSize, pageNum: currentPage }).then(
				(res: any) => {
					if (!res) {
						return;
					}
					renderTableCols(res.field_info, res.data, res.columns_to_display);
					setFieldInfo(res.field_info);
					setObjectCount(res.object_count);
					if (
						!isValidSfdcId(pipelineViewMasterFilter[changeURLKey]) &&
						!doFiltersHaveSameActiveState(
							pipelineStore.filters[changeURLKey],
							res.filters,
						)
					) {
						// TODO PERF:
						//  1) Do initial query on BE with the filters from DB
						//  2) set filters in FE without triggering re-render
						updatePipelineFilter({
							[changeURLKey]:
								res.filters.length > 0 ? res.filters : [defaultFilter],
						});
						refreshPipelineTable(new Date());
					}
				},
			);
		}
	};

	const actionDotsHandler = (
		option: string,
		objectType: any,
		objectId: any,
		name: string,
	): void => {
		switch (option) {
			case "View Details":
				showDrawer(objectType, objectId);
				return;
			case "Create Notes":
				CreateDocument("", "", false, objectId, name).then((res) => {
					navigate("/document-hub/notes-page/" + res);
				});
				return;
			case "Create Task":
				dispatch({
					type: "UPDATE_TASK_MANAGER_STATE",
					payload: {
						newTaskOpportunityName: name,
						newTaskOpportunityId: objectId,
						showNewTaskArea: "block",
						visible: "visible",
					},
				});
				return;
			case "Log New Activity":
				togglePipelineCreateModal({
					modalVisible: true,
					selectedModal: {
						name: "Log New Activity",
						iconUrl: "/images/icons/SFDC-objects/Activity.svg",
						opportunityId: objectId,
						opportunityName: name,
					},
				});
				return;
			case "View Activity":
				navigate(`/dashboard/activity-hub?opportunity_id=${objectId}`);
				return;
			default:
				return;
		}
	};

	function getTextWidth(text: string, font: string) {
		const canvas = document.createElement("canvas");
		const context = canvas.getContext("2d");

		if (context) {
			context.font = font || getComputedStyle(document.body).font;

			return context?.measureText(text).width;
		}
		return 0;
	}

	const getMinimumWidth = (title: string) => {
		const splitTitle = title.split(" ");
		const midIndex = Math.floor(splitTitle.length / 2);
		const endBoundary = splitTitle.length % 2 === 0 ? midIndex : midIndex + 1;
		const leftWords = splitTitle.slice(0, endBoundary).join(" ");
		const rightWords = splitTitle
			.slice(endBoundary, splitTitle.length)
			.join(" ");
		const longerLine =
			leftWords.length > rightWords.length ? leftWords : rightWords;
		return getTextWidth(longerLine, "14px") + 100;
	};

	// TODO rename this, it says it renders something but in fact it seems to set state
	// that state includes jsx elements but we probably should store data and
	// render like normal humans in an actual render function.
	const renderTableCols = (
		fieldInfo: any,
		records: any,
		columnsToDisplay: any,
	) => {
		setCurrFieldInfo(fieldInfo);
		const tableCols: any = columnsToDisplay.map(
			(columnToDisplay: any, index: number) => {
				// Data coming from sfdc listview vs custom view differ slightly.
				// They don't yet have nicely consolidated definitions on the BE.
				if ("name" in columnToDisplay) {
					columnToDisplay.label = columnToDisplay.name;
					delete columnToDisplay.name;
				}
				columnToDisplay.width = columnToDisplay.width || 240;

				const fieldInfoItem: any = fieldInfo.find(
					(field: any) => field.name === columnToDisplay.label,
				);
				return {
					columnObj: columnToDisplay,
					key: fieldInfoItem.name,
					dataIndex: fieldInfoItem.name,
					width: columnToDisplay.width,
					minWidth: getMinimumWidth(
						effectiveLabelValue(fieldInfoItem.label, fieldInfoItem.type),
					),
					fixed: index === 0,
					title: () => {
						return (
							<div className="pip_col d-flex align-items-center">
								<i className="pip_col--drag">
									<MdDragIndicator />
								</i>
								<span className="pt-1">
									{effectiveLabelValue(fieldInfoItem.label, fieldInfoItem.type)}
								</span>
							</div>
						);
					},
					sorter: fieldInfoItem.sortable
						? (_: unknown, __: unknown, direction: string) =>
								setSorter({
									...sorter,
									[pipelineStore.category]: {
										field_name: fieldInfoItem.name,
										order: direction === "ascend" ? "ASC" : "DESC",
									},
								})
						: undefined,

					// TODO: change from objectDataKey to fieldToUpdate
					render: (value: any, all: any) => {
						return (
							<PipelineFieldSelector
								type={fieldInfoItem.type}
								currentValue={value}
								valueForRefObjWithName={
									all[fieldInfoItem.name.replace("Id", "")]?.Name
								}
								updateData={{
									objectDataKey: fieldInfoItem.name,
									objectType: typeByCategory[pipelineStore.category],
									id: all.Id,
								}}
								updateValue={(value, objectInfo) =>
									updateObjectData(value, objectInfo, fieldInfoItem.type)
								} // TODO: check update method
								name={fieldInfoItem.name}
								referencedObjectType={fieldInfoItem.referenceTo[0]} // TODO: handle edge case of Group and User
								updateable={fieldInfoItem.updateable}
								picklist={fieldInfoItem.picklistValues}
								inputLength={fieldInfoItem.length}
								nullable={fieldInfoItem.nillable}
								showDrawerCb={showDrawer}
								recordTypeId={
									["-", null].includes(all.RecordTypeId)
										? null
										: all.RecordTypeId
								}
							/>
						);
					},
				};
			},
		);

		tableCols.push({
			key: "actions",
			dataIndex: "actions",
			fixed: "right",
			align: "center",
			width: 130,
			colName: "Actions",
			display: false,
			title: () => {
				return <div className="pip_col">Actions</div>;
			},
			render: (_: any, all: any) => {
				return (
					<div className="pip_col_actions">
						<RowInsightAction category={pipelineStore.category} record={all} />
						{pipelineStore.category == "opportunities" ? (
							<span className="mx-1">
								<Link to={`/dashboard/activity-hub?opportunity_id=${all.Id}`}>
									<ReactSVG src="/images/icons/DealTimeline.svg" />
								</Link>
							</span>
						) : (
							<Tooltip title="Coming soon...">
								<span className="mx-2">
									<ReactSVG src="/images/icons/DealTimeline.svg" />
								</span>
							</Tooltip>
						)}
						<span>
							<RowMoreActions
								category={pipelineStore.category}
								record={all}
								onAction={actionDotsHandler}
							/>
						</span>
					</div>
				);
			},
		});

		setLoading(false);

		if (tableCols.length > 0) {
			setTableData([]);

			let clearData = JSON.parse(ReplaceNullValuesWithString(records));

			setCols([...tableCols]);
			setTableData([...clearData]);
			setTableSize({
				...tableSize,
				y: windowSize.height - 280 || 400,
			});
		}
	};

	useEffect(() => {
		setTableSize({
			...tableSize,
			y: windowSize.height - 280 || 400,
		});
	}, [windowSize.height]);

	const showDrawer = (objectType: any, objectId: any) => {
		dispatch({
			type: "OPEN_DRAWER",
			payload: { drawerType: "pipeline", objectType, objectId },
		});
	};

	const updateFavoriteFilter = (favorite: boolean, id: string) => {
		const filters = [...syncedMasterFilter];
		const index = syncedMasterFilter.findIndex(({ Id }: any) => Id === id);
		filters[index].Favorite = favorite;
		setSyncedMasterFilter(filters);
		void UpdateMasterFilters(filters);
	};

	const triggerSFDCStringSearch = () => {
		if (currentPage === 1) {
			fetchCategoryData(pipelineStore.category);
		} else {
			setCurrentPage(1);
		}
	};

	useEffect(() => {
		localStorage.setItem("sortingOrder", JSON.stringify(sorter));
		fetchCategoryData(pipelineStore.category);
	}, [
		sorter[pipelineStore.category]?.field_name,
		sorter[pipelineStore.category]?.order,
	]);

	const handleResize =
		(index: number) => (_: SyntheticEvent, { size }: ResizeCallbackData) => {
			const newColumns = [...cols];
			newColumns[index] = {
				...newColumns[index],
				width:
					size.width < newColumns[index].minWidth
						? newColumns[index].minWidth
						: size.width,
			};
			newColumns[index].columnObj.width = newColumns[index].width;
			verify(index, size.width);
			setCols(newColumns);
		};

	const mergeColumns: ColumnsType<any> = cols.map(
		(col: any, index: number) => ({
			...col,
			onHeaderCell: (column: any) => ({
				width: (column as ColumnType<any>).width,
				onResize: handleResize(index),
			}),
		}),
	);

	const verify = useCallback(
		debounce((idx: number, width: number) => {
			void UpdatePipelineViewTableConfig({
				view_id: JSON.parse(
					localStorage.getItem("pipelineViewMasterFilter") || "{}",
				)[pipelineStore.category],
				update_type: "change_column_width",
				column_idx: idx,
				width,
				object_type: typeByCategory[pipelineStore.category],
			});
		}, 275),
		[pipelineStore.category],
	);

	return (
		<BaseLayoutComponent pageTitle={<h3>Pipeline</h3>}>
			<div>
				<Row
					gutter={8}
					justify="space-between"
					align="middle"
					className="pip_table_extras"
				>
					<Col span={24} className="d-flex align-items-center">
						<div className="d-flex align-items-center py-2">
							<div className="pip_table_extras_actions">
								{objectList.map(
									({ name, iconUrl, active }: any, index: number) => {
										return (
											<span key={name} className={active ? "selected" : ""}>
												<Tooltip
													title={name.charAt(0).toUpperCase() + name.slice(1)}
													placement="bottom"
												>
													<ReactSVG
														src={iconUrl}
														onClick={() => {
															console.log({ name });
															changeActiveCategory(name);
														}}
													/>
												</Tooltip>
											</span>
										);
									},
								)}
							</div>
							<div className="pip_table_extras_search">
								<Input
									onChange={(e) => setSearchTerm(e.target.value)}
									value={searchTerm}
									className="custom_input_1"
									size="large"
									prefix={<AiOutlineSearch size={20} color="#999" />}
									placeholder={`Search ${pipelineStore.category} by name`}
									onKeyPress={(e) => {
										if (e.key === "Enter") {
											triggerSFDCStringSearch();
										}
									}}
								/>
							</div>
						</div>
						<div
							className="pip_table_extras_addon text-end"
							style={{ marginLeft: "auto" }}
						>
							<div className="ms-3">
								<ViewDropdown
									objectType={pipelineStore.category}
									sfdcViews={masterFilters || []}
									onUpdateMasterFilter={(value: string) =>
										setMasterFilterText(value)
									}
									onUpdateSfdcFavorite={updateFavoriteFilter}
									fields={cols.map((col: any) => col.columnObj)}
								/>
							</div>
							{pipelineStore.category === "opportunities" && (
								<div className="ms-3">
									<Select
										size="large"
										value={viewMode}
										className="custom_select"
										onChange={(value) => {
											setViewMode(value);
											localStorage.setItem("viewMode", ViewMode[value]);
										}}
										style={{ width: 130 }}
									>
										<Option value={ViewMode.kanban}>
											<div className="d-flex align-items-center">
												<ReactSVG
													src="/images/icons/Kanban.svg"
													className="me-2"
												/>
												Kanban
											</div>
										</Option>
										<Option value={ViewMode.table}>
											<div className="d-flex align-items-center">
												<ReactSVG
													src="/images/icons/Table.svg"
													className="me-2"
												/>
												Table
											</div>
										</Option>
									</Select>
								</div>
							)}
							<Popover
								placement="bottomRight"
								content={
									<PipelineColsSelectorComponent
										cols={cols}
										setTableLoading={setLoading}
										currFieldInfo={currFieldInfo}
										fetchCategoryDataMethod={fetchCategoryData}
									/>
								}
								trigger={"click"}
								destroyTooltipOnHide
							>
								<span
									className={clsx(
										"pip_table_extras_addon--btn",
										viewMode === ViewMode.kanban &&
											pipelineStore.category === "opportunities" &&
											"disabled",
									)}
								>
									<ReactSVG src="/images/icons/Add.svg" />
								</span>
							</Popover>
							<Popover
								placement="bottomRight"
								trigger={"click"}
								content={
									<MasterFilter
										columns={fieldInfo}
										category={pipelineStore.category}
									/>
								}
							>
								<span
									className={`pip_table_extras_addon--btn ${
										hasNonEmptyFilter(
											pipelineStore.filters[pipelineStore.category],
										)
											? "filters"
											: ""
									}`}
								>
									{hasNonEmptyFilter(
										pipelineStore.filters[pipelineStore.category],
									) && (
										<span className="fil_count">
											{numActiveFilters(
												pipelineStore.filters[pipelineStore.category],
											)}
										</span>
									)}
									<ReactSVG src="/images/icons/Filter.svg" />
								</span>
							</Popover>
						</div>
					</Col>
					<Col span={24}>
						{pipelineStore.category === "opportunities" &&
						viewMode === ViewMode.kanban ? (
							<div className="w-100 pt-2 pip_table_wrapper">
								<KanbanBoardList
									loading={loading}
									recordTypes={recordTypes}
									currentRecordType={currentRecordType}
									stages={kanbanStages}
									onAction={actionDotsHandler}
									onSetRecordType={(recordType) => {
										setCurrentPage(1);
										setCurrentRecordType(recordType);
									}}
									onLoadMore={() => setCurrentPage((page) => page + 1)}
									onUpdate={(value: any, objectInfo: any) =>
										updateObjectData(value, objectInfo, "")
									}
								/>
							</div>
						) : (
							<div className="w-100 pt-2 pip_table_wrapper">
								{cols.length > 0 ? (
									<ReactDragListView.DragColumn
										nodeSelector="th"
										handleSelector="i"
										onDragEnd={onDragHandler}
									>
										<Table
											ref={tableRef}
											loading={loading}
											rowKey="Id"
											scroll={tableSize}
											components={{
												header: {
													cell: ResizableTitle,
												},
											}}
											columns={mergeColumns || []}
											dataSource={tableData}
											pagination={false}
										/>
										<div className="page-navigator">
											<Pagination
												activePage={currentPage}
												itemsCountPerPage={pageSize}
												totalItemsCount={objectCount}
												pageRangeDisplayed={5}
												onChange={(page: any) => setCurrentPage(page)}
												itemClass="page-navigator-item"
												itemClassFirst="page-navigator-link"
												itemClassLast="page-navigator-link"
												itemClassPrev="page-navigator-link"
												itemClassNext="page-navigator-link"
												disabledClass="page-navigator-link-disabled"
												activeClass="page-navigator-active"
												innerClass="page-navigator-list"
												nextPageText={<AiOutlineRight />}
												prevPageText={<AiOutlineLeft />}
												firstPageText={<AiOutlineDoubleLeft />}
												lastPageText={<AiOutlineDoubleRight />}
											/>
											<div className="page-navigator-actions">
												<Select
													className="page-navigator-size"
													value={pageSize}
													onChange={(e) => setPageSize(e)}
													options={[25, 30, 50, 100].map((size) => ({
														value: size,
														label: `${size} / Page`,
													}))}
												/>
												<div className="page-navigator-page">
													Go to page{" "}
													<Input
														type="number"
														min={1}
														max={Math.ceil(objectCount / pageSize) || 1}
														value={currentPage}
														onChange={(e) => {
															let newPage = Number(e.target.value);
															if (newPage < 1) {
																newPage = 1;
															} else if (
																newPage >
																(Math.ceil(objectCount / pageSize) || 1)
															) {
																newPage =
																	Math.ceil(objectCount / pageSize) || 1;
															}
															setCurrentPage(newPage);
														}}
													/>
												</div>
											</div>
										</div>
									</ReactDragListView.DragColumn>
								) : null}
							</div>
						)}
					</Col>
				</Row>
			</div>
		</BaseLayoutComponent>
	);
};

export default PipelineViewPage;
