import axios, { AxiosInstance } from "axios";
import { Config } from "../Config";

const API_URL = Config.API_BASE_URL();

const api: AxiosInstance = axios.create({
	baseURL: API_URL,
	headers: {
		"Content-type": "application/json; charset=UTF-8",
		Accept: "application/json",
	},
});

export default api;
