import { BsLightningFill } from "react-icons/bs";
import "./TopInsightsRenderComponent.styles.scss";
import AccountIcon from "../../Images/SFDC-objects/accounts.svg";
import UserIcon from "../../Images/User.svg";
import { useEffect, useState } from "react";
import { Spin } from "antd";
import {
	Deal,
	GetPipelineViewInsights,
	TopAndBottomDeals,
} from "../../Apis/GetPipelineViewInsights";

const renderDeal = (deal: Deal) => (
	<li className="pip_actions_insights_cards_each">
		<div className="row justify-content-between align-items-end">
			<div className="col-8">
				<div className="pip_actions_insights_cards_each--details">
					<h6>{deal.oppName}</h6>
					<p>
						<img src={AccountIcon} alt="Account Icon" />
						{deal.accountName}
					</p>
				</div>
			</div>
			<div className="col-4">
				<div className="pip_actions_insights_cards_each--user">
					<span>
						{deal.ownerName} <img src={UserIcon} alt="User" />
					</span>
				</div>
			</div>
		</div>
	</li>
);

export const TopInsightsPipelineDataRenderComponent = () => {
	const [topAndBottomDeals, setTopAndBottomDeals] = useState<
		TopAndBottomDeals | undefined
	>(undefined);

	useEffect(() => {
		window.analytics.track("PipelineView_Open_TopDeals", {
			podUserId: localStorage.getItem("podUserId"),
		});
		GetPipelineViewInsights().then((r) => setTopAndBottomDeals(r));
	}, []);

	return (
		<div className="pip_actions_insights pipInsightsRender">
			<div className="pip_actions_insights--title">
				<h4>
					<span>
						<BsLightningFill size={18} />
					</span>
					Pipeline Insights
				</h4>
			</div>
			<div className="pipInsightsRender_childtitle">
				<span>Top Deals</span>
			</div>
			<ul className="pip_actions_insights_cards">
				{topAndBottomDeals !== undefined ? (
					topAndBottomDeals.topDeals.map((deal) => renderDeal(deal))
				) : (
					<Spin></Spin>
				)}
			</ul>
			<div className="pipInsightsRender_childtitle">
				<span>Bottom Deals</span>
			</div>
			<ul className="pip_actions_insights_cards">
				{topAndBottomDeals !== undefined ? (
					topAndBottomDeals.bottomDeals.map((deal) => renderDeal(deal))
				) : (
					<Spin></Spin>
				)}
			</ul>
		</div>
	);
};
