import { Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GetContactForAccount } from "../../../Apis/Pipeline/GetContactForAccount";

type Props = {
	objectType?: string;
	objectId: string;
};

const PipelineContactListsComponent = ({ objectType, objectId }: Props) => {
	const dispatch = useDispatch();
	const pipelineStore = useSelector<any>((state) => state.pipeline);
	const [loading, setLoading] = useState(true);
	const [contactList, setContactList] = useState<Object[]>([]);
	useEffect(() => {
		GetContactForAccount(objectId).then((res: any) => {
			setLoading(false);
			if (res?.length) {
				setContactList([...res]);
			}
		});
	}, [pipelineStore]);

	const openContact = (objectId: string) => {
		dispatch({
			type: "OPEN_DRAWER",
			payload: {
				drawerType: "pipeline",
				objectType: "Contact",
				objectId,
			},
		});
	};

	return !loading ? (
		<div className="pod_assign_contact_list p-0">
			{contactList.length ? (
				contactList.map(({ Id, Name, Title }: any) => (
					<div className="pod_assign_contact_list_each_v2" key={Id}>
						<div
							style={{ cursor: "pointer" }}
							className="pod_assign_contact_list_each_v2--title"
							onClick={() => openContact(Id)}
						>
							{Name}
						</div>
						<div className="pod_assign_contact_list_each_v2--email">
							{Title || "N/A"}
						</div>
					</div>
				))
			) : (
				<div className="text-center">No contacts available</div>
			)}
		</div>
	) : (
		<div>
			<Skeleton paragraph={{ rows: 3 }} active />
		</div>
	);
};

export default PipelineContactListsComponent;
