import React from "react";
import { Button, Row, Col } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { addWeeks, endOfWeek, format, startOfWeek } from "date-fns";
import { DatePicker } from "./DatePicker";
import { CalendarHeaderProps } from "./types";

const getMonthDayYear = (startWeek: any) => {
	const endOfWeekDate = endOfWeek(startWeek, { weekStartsOn: 0 });
	const startOfWeekDate = startOfWeek(startWeek, { weekStartsOn: 0 });
	return (
		format(startOfWeekDate, "MMM dd, yyyy") +
		" - " +
		format(endOfWeekDate, "MMM dd, yyyy")
	);
};

export const CalendarHeader: React.FunctionComponent<CalendarHeaderProps> = ({
	startWeek,
	setStartWeek,
}) => {
	const onDateChange = (date: any) => {
		setStartWeek(startOfWeek(date));
	};
	const customFormat = (value: any): string => {
		return getMonthDayYear(value);
	};
	return (
		<>
			<Row justify="space-between" style={{ marginBottom: "20px" }}>
				<Col span={6} style={{ maxWidth: "300px" }}>
					<div>
						<div
							style={{
								display: "flex",
								padding: "4px 4px",
								border: "1px #d5d7db solid",
								backgroundColor: "#F5F5F5",
								borderRadius: "4px",
							}}
						>
							<Button
								style={{
									marginBottom: "4px",
									border: "none",
									background: "none",
								}}
								onClick={() => setStartWeek(addWeeks(startWeek, -1))}
							>
								<LeftOutlined />
							</Button>
							<div style={{ alignSelf: "center" }}>
								<DatePicker
									picker="week"
									size="small"
									onChange={onDateChange}
									format={customFormat}
									bordered={false}
									value={startWeek}
									className="weekly-picker"
								/>
							</div>
							<Button
								style={{
									marginBottom: "4px",
									border: "none",
									background: "none",
								}}
								onClick={() => setStartWeek(addWeeks(startWeek, 1))}
							>
								<RightOutlined />
							</Button>
						</div>
					</div>
				</Col>
			</Row>
		</>
	);
};
