import { Popover, Tooltip } from "antd";
import { BsLightning } from "react-icons/bs";
import { ReactSVG } from "react-svg";
import OptionsDropdown from "../../OptionsDropdown";
import { PipelineActionInsights } from "../PipelineActionInsights";

const typeByCategory: any = {
	opportunities: "Opportunity",
	accounts: "Account",
	contacts: "Contact",
	leads: "Lead",
};

export const RowInsightAction = ({
	category,
	record,
}: { category: string; record: any }) => {
	return (
		<>
			{category === "opportunities" ? (
				<Popover
					overlayClassName="pip_actions_insights_wrapper"
					placement="bottomRight"
					content={<PipelineActionInsights oppId={record.Id} />}
					trigger={"click"}
					destroyTooltipOnHide
				>
					<span>
						<BsLightning color="#666" />
					</span>
				</Popover>
			) : (
				<Tooltip title="Coming soon...">
					<span className="mx-3">
						<BsLightning color="#666" />
					</span>
				</Tooltip>
			)}
		</>
	);
};

export const RowMoreActions = ({
	category,
	record,
	onAction,
	showSwap,
}: {
	category: string;
	record: any;
	onAction: any;
	showSwap?: boolean;
}) => {
	const type = typeByCategory[category];

	let options: string[] = [];
	if (category == "opportunities") {
		if (showSwap) {
			options.push("View Activity");
		}
		options = options.concat([
			"Create Notes",
			"Create Task",
			"Log New Activity",
		]);
	}
	options.push("View Details");

	return (
		<div className="pip_col_actions">
			<OptionsDropdown
				options={options}
				onClick={(item) => onAction(item, type, record.Id, record.Name)}
			>
				<ReactSVG src="/images/icons/more-vertical.svg" />
			</OptionsDropdown>
		</div>
	);
};
