import api from "../api";

export const UpdateSyncedOpportunity = async (
	googleId: String,
	sfdcId: String,
	googleSource: String,
	sfdcSource: String,
	related_sfdc_object_id?: string,
) => {
	const pod_user_id = Number(localStorage.getItem("podUserId"));

	return api
		.post("/sync_with_opportunity", {
			google_id: googleId,
			sfdc_id: sfdcId,
			google_source: googleSource,
			sfdc_source: sfdcSource,
			related_sfdc_object_id,
			pod_user_id,
		})
		.then((res) => res.data);
};
