import { Button } from "antd";
import "./InviteComplete.styles.scss";

const InviteComplete = ({
	invitedName,
	onClose,
}: { invitedName: string; onClose: () => void }) => {
	return (
		<div className="invite-complete-modal-column">
			<div className="title">Invite Sent!</div>
			<div className="description">
				"{invitedName}" will receive an invitation to join your Pod workspace -
				congratulations!
			</div>
			<Button type="primary" onClick={onClose}>
				Great!
			</Button>
		</div>
	);
};

export default InviteComplete;
