import { BsLightningFill } from "react-icons/bs";
import "./PipelineActionInsights.styles.scss";
import UserIcon from "../../Images/User.svg";
import AccountIcon from "../../Images/SFDC-objects/accounts.svg";
import {
	GetSimilarOpportunities,
	SimilarOpportunity,
} from "../../Apis/GetSimilarOpportunities";
import { useEffect, useState } from "react";
import { Select, Skeleton } from "antd";

const similarFilters = ["Closed Won", "Closed Lost", "In Progress"];
type similarFiltersType = typeof similarFilters[number];

export const PipelineActionInsights = ({
	oppId,
}: {
	oppId: any;
}) => {
	const [similarOpportunities, setSimilarOpportunities] = useState<
		SimilarOpportunity[]
	>([]);
	const [loading, setLoading] = useState(false);
	const [similarFilter, setSimilarFilter] =
		useState<similarFiltersType>("Closed Won");

	useEffect(() => {
		setLoading(true);
		GetSimilarOpportunities(oppId, similarFilter).then((r) => {
			window.analytics.track("PipelineView_Click_Similar_Deals", {
				podUserId: localStorage.getItem("podUserId"),
			});
			setLoading(false);
			setSimilarOpportunities(
				r.map((obj) => {
					return {
						accountName: obj.accountName
							? obj.accountName
							: "No related Account",
						oppName: obj.oppName,
						ownerName: obj.ownerName ? obj.ownerName : "No Owner",
					};
				}),
			);
		});
	}, [similarFilter]);

	return (
		<div className="pip_actions_insights">
			<div className="pip_actions_insights--title" style={{ minWidth: 408 }}>
				<h4>
					<span>
						<BsLightningFill size={18} />
					</span>
					Similar Deals
				</h4>
				<div
					className="pip_table_extras_select me-3"
					style={{
						padding: "12px 20px 12px 56%",
						width: "100%",
						marginLeft: 0,
						position: "absolute",
						top: 14,
						right: -18,
					}}
				>
					<Select
						size="middle"
						value={similarFilter}
						className="custom_select"
						onChange={(value: string) => setSimilarFilter(value)}
						notFoundContent={
							!similarFilters.length
								? "Search for a record ..."
								: "No results found"
						}
					>
						{similarFilters.map((item) => (
							<Select.Option key={item} value={item}>
								{item}
							</Select.Option>
						))}
					</Select>
				</div>
			</div>
			<ul className="pip_actions_insights_cards">
				{!loading && similarOpportunities.length != 0 ? (
					similarOpportunities.map((obj: any) => {
						return (
							<li className="pip_actions_insights_cards_each">
								<div className="row justify-content-between align-items-end">
									<div className="col-8">
										<div className="pip_actions_insights_cards_each--details">
											<h6>{obj.oppName}</h6>
											<p>
												<img src={AccountIcon} alt="Account Icon" />
												{obj.accountName}
											</p>
										</div>
									</div>
									<div className="col-4">
										<div className="pip_actions_insights_cards_each--user">
											<span>
												{obj.ownerName}
												<img src={UserIcon} alt="User" />
											</span>
										</div>
									</div>
								</div>
							</li>
						);
					})
				) : loading ? (
					<div style={{ width: 300 }}>
						<Skeleton
							active
							paragraph={{ rows: 1 }}
							style={{ width: 600 }}
						></Skeleton>
					</div>
				) : (
					<div>No similar deals found</div>
				)}
			</ul>
		</div>
	);
};
