import "./GettingStarted.styles.scss";
import SS1 from "../../../Images/SignupCarouselSS1.svg";
import SS2 from "../../../Images/SignupCarouselSS2.svg";
import SS3 from "../../../Images/SignupCarouselSS3.svg";
import SS4 from "../../../Images/SignupCarouselSS4.svg";
import { useState } from "react";

const GettingStarted = () => {
	const [carouselIndex, setCarouselIndex] = useState<number>(0);
	const carouselItems = [
		{
			title: "Update your CRM in seconds",
			description:
				"Review and update all of your CRM records in an intuitive configurable dashboard. Apply filters, create new records, and create new views to always on top of your pipeline.",
			image: SS1,
		},
		{
			title: "Keep track of deal progress",
			description:
				"Get an overview of your pipeline's activities and execute on your success plan. Use Pod activity timeline to get more visibility into the progress of key deals and run more productive pipeline reviews with management.",
			image: SS2,
		},
		{
			title: "Manage all your notes in a single place",
			description:
				"Take rich notes directly in Pod and sync them to Salesforce. Use shortcuts and shared templates to take smarter notes on your deals.",
			image: SS3,
		},
		{
			title: "Get real-time insights to guide you",
			description:
				"Know what to do at all times with Pod's real-time recommendations, nudges, and enablement. We will help you to prioritize your deals, know the best next step, and prepare better for customer meetings.",
			image: SS4,
		},
	];

	return (
		<div
			className="welcome-column"
			style={{
				height: "100%",
				alignItems: "center",
				paddingTop: "15px",
				justifyContent: "space-between",
			}}
		>
			<div
				className="welcome-column"
				style={{ alignItems: "center", marginTop: "2vh" }}
			>
				<img
					src={carouselItems[carouselIndex].image}
					alt=""
					style={{ height: "50vh" }}
				/>
				<p
					style={{
						fontWeight: "600",
						fontSize: "24px",
						margin: "0",
					}}
				>
					{carouselItems[carouselIndex].title}
				</p>
				<p
					style={{
						width: "650px",
						textAlign: "center",
						margin: "16px 0 0",
					}}
				>
					{carouselItems[carouselIndex].description}
				</p>
			</div>
			<span className="welcome-row">
				<div
					className="arrow-img-left"
					onClick={() => {
						if (carouselIndex === 0) {
							setCarouselIndex(carouselItems.length - 1);
						} else {
							setCarouselIndex(carouselIndex - 1);
						}
					}}
				/>
				{Array.from(Array(carouselItems.length), (_, i) => (
					<div
						key={i}
						className="dot"
						style={i === carouselIndex ? { backgroundColor: "#5000A2" } : {}}
					/>
				))}
				<div
					className="arrow-img-right"
					onClick={() => {
						if (carouselIndex === carouselItems.length - 1) {
							setCarouselIndex(0);
						} else {
							setCarouselIndex(carouselIndex + 1);
						}
					}}
				/>
			</span>
		</div>
	);
};

export default GettingStarted;
