import { Input, Checkbox } from "antd";
import { useEffect, useState, useMemo } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { UpdatePipelineViewTableConfig } from "../../Apis/UpdatePipelineViewTableConfig";
import "./PipelineColsSelectorComponent.styles.scss";
import { useSelector } from "react-redux";
import { effectiveLabelValue } from "../../Utils/Index";
//TODO: use the below API to get data for the new column as API response
// import { GetDataForSFDCObjectField } from "../../Apis/GetDataForSFDCObjectField";

interface Props {
	cols: any;
	setTableLoading: any;
	currFieldInfo: Array<Record<string, any>>;
	fetchCategoryDataMethod: any;
	onChange?: (updateInfo: Record<string, unknown>) => void;
	forcedFields?: Array<string>;
}

const translation: Record<string, string> = {
	opportunities: "Opportunity",
	accounts: "Account",
	leads: "Lead",
	contacts: "Contact",
};

export default ({
	cols,
	setTableLoading,
	currFieldInfo,
	fetchCategoryDataMethod,
	onChange,
	forcedFields,
}: Props) => {
	const pipelineStore = useSelector(({ pipeline }: any) => pipeline);
	const [visibleCols, setVisibleCols] = useState(
		cols.map((obj: any) => obj.key || obj),
	);
	const [init, setInit] = useState(false);
	const [toggledColumn, setToggledColumn] = useState("");
	const [toggleType, setToggleType] = useState("");
	const [searchText, setSearchText] = useState("");

	useEffect(() => {
		if (init) {
			const fieldInfoObjForToggledColumn = currFieldInfo.find(
				(obj) => obj.name == toggledColumn,
			)!;
			if (onChange) {
				onChange({
					field_name: toggledColumn,
					field_type: fieldInfoObjForToggledColumn.type,
					reference_to: fieldInfoObjForToggledColumn.referenceTo,
					toggle_type: toggleType,
				});
				return;
			}
			UpdatePipelineViewTableConfig({
				view_id: JSON.parse(
					localStorage.getItem("pipelineViewMasterFilter") || "{}",
				)[pipelineStore.category],
				field_name: toggledColumn,
				field_type: fieldInfoObjForToggledColumn.type,
				reference_to: fieldInfoObjForToggledColumn.referenceTo,
				object_type: translation[pipelineStore.category],
				toggle_type: toggleType,
				update_type: "toggle_column",
			}).then((res) => {
				fetchCategoryDataMethod(pipelineStore.category);
			});
		} else {
			setInit(true);
		}
	}, [visibleCols]);

	const toggleHandler = (e: any, fieldName: string) => {
		setTableLoading(true);

		if (visibleCols.includes(fieldName)) {
			setToggledColumn(fieldName);
			setToggleType("remove");
			setVisibleCols(visibleCols.filter((item: string) => item != fieldName));
		} else {
			setToggledColumn(fieldName);
			setToggleType("add");
			setVisibleCols([...visibleCols, fieldName]);
		}

		setTableLoading(false);
	};

	const filteredFieldInfo = useMemo(() => {
		if (!searchText)
			return currFieldInfo.filter(
				(item) =>
					item.name !== "Name" &&
					(!forcedFields?.length || !forcedFields.includes(item.name)),
			);
		return currFieldInfo.filter((item) => {
			const labelValue = effectiveLabelValue(item.label, item.type);
			return (
				labelValue.toLowerCase().includes(searchText.toLowerCase()) &&
				item.name !== "Name" &&
				(!forcedFields?.length || !forcedFields.includes(item.name))
			);
		});
	}, [searchText]);

	return (
		<div className="pip_col_customiser">
			<div className="pip_col_customiser--search pb-2">
				<Input
					onChange={({ target }) => setSearchText(target.value)}
					size="large"
					placeholder="Search here"
					prefix={<AiOutlineSearch color="#c9c9c9" size={16} />}
				/>
			</div>
			<div className="pip_col_customiser_list">
				{filteredFieldInfo.length ? (
					filteredFieldInfo.map((fieldInfoObj) => {
						return (
							<div key={fieldInfoObj.name} className="py-2">
								<Checkbox
									name={fieldInfoObj.name}
									checked={visibleCols.includes(fieldInfoObj.name)}
									onChange={(e) => toggleHandler(e, fieldInfoObj.name)}
									disabled={fieldInfoObj.isFixed}
								>
									{effectiveLabelValue(fieldInfoObj.label, fieldInfoObj.type)}
								</Checkbox>
							</div>
						);
					})
				) : (
					<div>No columns to toggle</div>
				)}
				{/*{fil.length ? (
          fil.map((col: any) => {
            if (col[7] === "display" && col[0] !== "Name") {
              return (
                <div key={col[0]} className="py-2">
                  <Checkbox checked={col[8]} onChange={(e) => onToggleCheck(e, col[0])}>
                    {col[4]}
                  </Checkbox>
                </div>
              );
            }
          })
        ) : (
          <div>No columns to toggle</div>
        )}*/}
			</div>
		</div>
	);
};
