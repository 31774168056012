import api from "./api";

export type Deal = {
	oppName: string;
	accountName: string;
	ownerName: number;
};

export type TopAndBottomDeals = {
	topDeals: Deal[];
	bottomDeals: Deal[];
};

export const GetPipelineViewInsights = async (): Promise<TopAndBottomDeals> => {
	return api
		.post("/get_pipeline_view_insights", {
			pod_user_id: Number(localStorage.getItem("podUserId")),
		})
		.then((res) => res.data);
};
