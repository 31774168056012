import moment from "moment";
import api from "./api";

export const GetUpcomingMeetings = async (opportunity_id: string) => {
	const start_date = moment().format().replace("-", "/").replace("-", "/");
	const end_date = moment()
		.add(14, "day")
		.format()
		.replace("-", "/")
		.replace("-", "/");

	return api
		.get("/has_upcoming_meeting", {
			params: {
				opportunity_id,
				start_date,
				end_date,
				pod_user_id: Number(localStorage.getItem("podUserId")),
			},
		})
		.then((res) => res.data);
};
