import { Button, Tooltip, notification } from "antd";
import { useEffect, useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { BsLightningFill } from "react-icons/bs";
import { FiUsers } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { CreateDocument } from "../../Apis/Firebase/CreateDocument";
import { GetInactiveOpportunities } from "../../Apis/GetInactiveOpportunities";
import { GetInternalMeetingsForActivityHub } from "../../Apis/GetInternalMeetingsForActivityHub";
import { GetUpcomingMeetings } from "../../Apis/GetUpcomingMeetings";
import OutlinedLoader from "../OutlinedLoader";
import { useNavigate } from "react-router-dom";
import "./TopInsightsRenderComponent.styles.scss";
import { GetSFDCObjectDetailsV2 } from "../../Apis/GetSFDCObjectDetailsV2";
import { GetEmailsForActivityHub } from "../../Apis/GetEmailsForActivityHub";
import moment from "moment";
import { isGoogleIntegrationEnabled } from "../../Apis/Integration/google";
import { togglePipelineCreateModal } from "../../Store/ActionReducerPairs/Pipeline";

export const TopInsightsActivityDataRenderComponent = ({
	close,
	openNewEventModal,
}: {
	close: () => void;
	openNewEventModal: (data: any) => void;
}) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [inactive, setInactive] = useState<boolean>(false);
	const [hasUpcomingMeeting, setHasUpcomingMeeting] = useState<boolean>(false);
	const [meetingFollowUp, setMeetingFollowUp] = useState<boolean>(true);
	const [needsUpdate, setNeedsUpdate] = useState<boolean>(false);
	const [oppName, setOppName] = useState("");
	const [loading, setLoading] = useState(false);
	const opportunity =
		new URLSearchParams(location.search).get("opportunity_id") || "";
	const [isGoogleAuthed, setIsGoogleAuthed] = useState<boolean>(false);

	const needsFollowUp = (
		emails: Array<Record<string, string>>,
		meetings: Array<Record<string, string>>,
		data: any,
	) => {
		const from = localStorage.getItem("sfdcEmail");
		const sortedMeetings = meetings
			.filter((i: any) => i.category === "customer_meeting")
			.sort((a: any, b: any) => {
				if (a.date > b.date) return -1;
				else if (a.date < b.date) return 1;
				return 0;
			});
		const meeting = sortedMeetings[0];
		const email = emails
			.filter((i) => from && i.from.includes(from))
			.find((item) => item.date >= meeting?.date);
		setMeetingFollowUp(meeting ? Boolean(email) : true);
		const date = moment().add(-2, "days").format();
		setNeedsUpdate(meeting ? data.LastModifiedDate < date : false);
	};

	useEffect(() => {
		setLoading(true);
		isGoogleIntegrationEnabled().then((res: boolean) => setIsGoogleAuthed(res));
		const startDate = moment().startOf("month").format().split("T");
		const endDate = moment().format().split("T");
		const formattedEndDate = `${endDate[0].replaceAll("-", "/")}T${endDate[1]}`;
		const formattedStartDate = `${startDate[0].replaceAll("-", "/")}T${
			startDate[1]
		}`;
		Promise.all([
			GetUpcomingMeetings(opportunity),
			GetInactiveOpportunities(opportunity),
			GetEmailsForActivityHub(
				opportunity,
				formattedStartDate,
				formattedEndDate,
			),
			GetInternalMeetingsForActivityHub(
				opportunity,
				formattedStartDate,
				formattedEndDate,
			),
			GetSFDCObjectDetailsV2("Opportunity", opportunity || "", ""),
		])
			.then((res: any) => {
				setInactive(res[1].is_inactive);
				setHasUpcomingMeeting(res[0].has_upcoming_meeting);
				needsFollowUp(res[2], res[3], res[4].data[0]);
				console.log(res[4].data);
				setOppName(res[4].data[0]?.Name);
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	const getOpportunityName = async () => {
		return GetSFDCObjectDetailsV2("Opportunity", opportunity || "", "").then(
			(res) => {
				return res?.data[0]?.Name;
			},
		);
	};

	const newMeeting = (opportunityId?: string, opportunityName?: string) => {
		if (opportunityId && opportunityName) {
			openNewEventModal({
				opportunityId,
				opportunityName,
			});
			close();
		} else {
			getOpportunityName()
				.then((res) => {
					openNewEventModal({
						opportunityId: opportunity || "",
						opportunityName: res,
					});
					close();
				})
				.catch(() => {
					notification.error({
						message: "Error fetching opportunity",
						placement: "bottomRight",
						duration: 1,
					});
				});
		}
	};

	const newDocument = () => {
		getOpportunityName()
			.then((opportunityName) => {
				CreateDocument("", "", false, opportunity || "", opportunityName).then(
					(res) => {
						close();
						navigate("/document-hub/notes-page/" + res);
					},
				);
			})
			.catch(() => {
				notification.error({
					message: "Error fetching opportunity",
					placement: "bottomRight",
					duration: 1,
				});
			});
	};

	const updateSalesforce = () => {
		close();
		dispatch({
			type: "OPEN_DRAWER",
			payload: {
				drawerType: "pipeline",
				objectType: "Opportunity",
				objectId: opportunity,
			},
		});
	};

	const sendMail = () => {
		window.open("https://mail.google.com/", "_blank");
	};

	return (
		<div className="pip_actions_insights activityRender">
			<div className="pip_actions_insights--title">
				<h4>
					<span>
						<BsLightningFill size={18} />
					</span>
					Activity Insights
				</h4>
			</div>
			<div className="activityRender_items">
				{loading && (
					<>
						<OutlinedLoader
							style={{
								marginTop: 20,
								marginBottom: 20,
								fontSize: 24,
								width: "100%",
							}}
						/>
					</>
				)}
				{inactive && !loading && (
					<div className="activityRender_items_each type_meeting item_hover">
						<div className="activityRender_items_each_top d-flex justify-content-between">
							<h5 className="activityRender_items_each_top--title col-auto">
								<AiOutlineInfoCircle className="text-success" /> Inactive
								opportunity
							</h5>
						</div>
						<div className="activityRender_items_each_content">
							<p>
								There has been no activity on this opportunity in the past
								month. Time to re-engage?
							</p>
							<div className="activityRender_items_each_content_links">
								<Button
									type="primary"
									className="custom_btn"
									onClick={() => {
										togglePipelineCreateModal({
											modalVisible: true,
											selectedModal: {
												name: "Log New Activity",
												iconUrl: "/images/icons/activity.svg",
												oppName,
												opportunity,
											},
										});
										close();
									}}
								>
									Log meeting to SFDC
								</Button>
								{isGoogleAuthed ? (
									<Button
										type="primary"
										className="custom_btn outlined"
										style={{ textShadow: "none" }}
										onClick={() => newMeeting(opportunity, oppName)}
									>
										Schedule a meeting
									</Button>
								) : (
									<Tooltip
										placement="topLeft"
										title="Connect your G-Suite account to enable this button"
										style={{ background: "none" }}
									>
										<Button type="primary" className="custom_btn" disabled>
											Schedule a meeting
										</Button>
									</Tooltip>
								)}
							</div>
						</div>
					</div>
				)}
				{hasUpcomingMeeting && !loading && (
					<div className="activityRender_items_each type_meeting item_hover">
						<div className="activityRender_items_each_top d-flex justify-content-between">
							<h5 className="activityRender_items_each_top--title col-auto">
								<FiUsers /> Upcoming meeting
							</h5>
						</div>
						<div className="activityRender_items_each_content">
							<p>
								You have a customer meeting coming up - make sure to be
								adequately prepared
							</p>
							<div className="activityRender_items_each_content_links">
								<Button
									type="primary"
									className="custom_btn"
									onClick={() => newDocument()}
								>
									Create a document
								</Button>
								{isGoogleAuthed ? (
									<Button
										type="primary"
										className="custom_btn outlined"
										style={{ textShadow: "none" }}
										onClick={() => newMeeting()}
									>
										Schedule internal meeting
									</Button>
								) : (
									<Tooltip
										placement="topLeft"
										title="Connect your G-Suite account to enable this button"
										style={{ background: "none" }}
									>
										<Button type="primary" className="custom_btn" disabled>
											Schedule internal meeting
										</Button>
									</Tooltip>
								)}
							</div>
						</div>
					</div>
				)}
				{(!meetingFollowUp || needsUpdate) && !loading && (
					<div className="activityRender_items_each type_meeting item_hover">
						<div className="activityRender_items_each_top d-flex justify-content-between">
							<h5 className="activityRender_items_each_top--title col-auto">
								<FiUsers /> Meeting follow-up
							</h5>
						</div>
						<div className="activityRender_items_each_content">
							<p>
								You've had a meeting recently, don't forget to follow-up and
								update Salesforce next steps
							</p>
							<div className="activityRender_items_each_content_links">
								<Button
									type="primary"
									className="custom_btn"
									onClick={() => updateSalesforce()}
								>
									Update salesforce
								</Button>
								<Button
									type="primary"
									className="custom_btn outlined"
									style={{ textShadow: "none" }}
									onClick={sendMail}
								>
									Send an email
								</Button>
							</div>
						</div>
					</div>
				)}
				{!hasUpcomingMeeting &&
					!inactive &&
					!(!meetingFollowUp || needsUpdate) &&
					!loading && (
						<div className="activityRender_items_each type_meeting">
							<div className="activityRender_items_each_top d-flex justify-content-between">
								<h5
									className="activityRender_items_each_top--title col-auto"
									style={{ color: "#c0c0c0" }}
								>
									No alerts at this time
								</h5>
							</div>
						</div>
					)}
			</div>
		</div>
	);
};
