import {
	add,
	isSameHour,
	isSameDay,
	addHours,
	eachDayOfInterval,
	startOfDay,
	getDay,
	isSameWeek,
	startOfWeek,
	format,
	differenceInMinutes,
} from "date-fns";

import {
	WeekObject,
	EventsObject,
	GetWeekDates,
	GenericEvent,
	DayName,
} from "./types";

export const daysToWeekObject = <T extends GenericEvent>(
	events: T[],
	startWeek: Date,
) => {
	const dayNames: DayName[] = [
		"sunday",
		"monday",
		"tuesday",
		"wednesday",
		"thursday",
		"friday",
		"saturday",
	];

	const weekObject: WeekObject<T> = {
		sunday: [],
		monday: [],
		tuesday: [],
		wednesday: [],
		thursday: [],
		friday: [],
		saturday: [],
	};

	if (events == null) {
		return weekObject;
	}

	for (const googleEventIndex in events) {
		const eventStartTimeDay = events[googleEventIndex].startTime;
		const eventEndTimeDay = events[googleEventIndex].endTime;
		// TODO test
		if (!isSameDay(eventStartTimeDay, eventEndTimeDay)) {
			const result = eachDayOfInterval({
				start: eventStartTimeDay,
				end: eventEndTimeDay,
			});
			for (const dayInterval in result) {
				const splitedEvent = { ...events[googleEventIndex] };
				splitedEvent.startTime = result[dayInterval];
				splitedEvent.endTime = result[dayInterval];
				const weekObjectKey: DayName =
					dayNames[getDay(new Date(result[dayInterval]))];
				isSameWeek(startWeek, splitedEvent.startTime) &&
					weekObject[weekObjectKey].push(splitedEvent);
			}
		} else {
			const weekObjectKey: DayName = dayNames[getDay(eventStartTimeDay)];
			weekObject[weekObjectKey].push(events[googleEventIndex]);
		}
	}

	return weekObject;
};

export const getDayHoursEvents = <T extends GenericEvent>(
	value: GetWeekDates,
	weekObject: WeekObject<T> | undefined,
) => {
	const ALL_DAY_EVENT = 0;
	const events: EventsObject<T>[] = [];

	for (let j = 0; j < 24; j++) {
		let i = j % 24;
		const startDate = add(startOfDay(startOfWeek(value.startDate)), {
			days: 0,
		});
		const hour = addHours(startDate, i);
		events.push({
			id: i,
			hourObject: hour,
			hour: format(hour, "hh a"),
			Sunday:
				weekObject?.sunday &&
				weekObject?.sunday.filter((e) => {
					return e.allDay
						? i === ALL_DAY_EVENT
						: isSameHour(e.startTime, add(hour, { days: 0 }));
				}),
			Monday:
				weekObject?.monday &&
				weekObject?.monday.filter((e) => {
					return e.allDay
						? i === ALL_DAY_EVENT
						: isSameHour(e.startTime, add(hour, { days: 1 }));
				}),
			Tuesday:
				weekObject?.tuesday &&
				weekObject?.tuesday.filter((e) => {
					return e.allDay
						? i === ALL_DAY_EVENT
						: isSameHour(e.startTime, add(hour, { days: 2 }));
				}),
			Wednesday:
				weekObject?.wednesday &&
				weekObject?.wednesday.filter((e) => {
					return e.allDay
						? i === ALL_DAY_EVENT
						: isSameHour(e.startTime, add(hour, { days: 3 }));
				}),
			Thursday:
				weekObject?.thursday &&
				weekObject?.thursday.filter((e) => {
					return e.allDay
						? i === ALL_DAY_EVENT
						: isSameHour(e.startTime, add(hour, { days: 4 }));
				}),
			Friday:
				weekObject?.friday &&
				weekObject?.friday.filter((e) => {
					return e.allDay
						? i === ALL_DAY_EVENT
						: isSameHour(e.startTime, add(hour, { days: 5 }));
				}),
			Saturday:
				weekObject?.saturday &&
				weekObject?.saturday.filter((e) => {
					return e.allDay
						? i === ALL_DAY_EVENT
						: isSameHour(e.startTime, add(hour, { days: 6 }));
				}),
		});
	}

	return events;
};

export const HOUR_TO_DECIMAL = 1.666666667;
export const MIN_BOX_SIZE = 61;

export const sizeEventBox = <T extends GenericEvent>(event: T, hour: Date) => {
	const eventStartTime = new Date(event.startTime);
	const eventEndTime = new Date(event.endTime);
	const boxSize = Math.floor(
		differenceInMinutes(eventEndTime, eventStartTime) * HOUR_TO_DECIMAL,
	);
	const boxPosition =
		differenceInMinutes(hour, eventStartTime) * HOUR_TO_DECIMAL > 100
			? 0
			: differenceInMinutes(eventStartTime, hour) * HOUR_TO_DECIMAL;

	return { boxPosition: boxPosition, boxSize: boxSize };
};

export function getSubTitle(startTime: Date, endTime: Date): string {
	return format(startTime, "hh:mm a") + " - " + format(endTime, "hh:mm a");
}

export function matchDay(d1: Date, d2: Date): boolean {
	return (
		d1.getFullYear() === d2.getFullYear() &&
		d1.getMonth() === d2.getMonth() &&
		d1.getDate() === d2.getDate()
	);
}
