import api from "./api";

export const LogNewEvent = async (opportunity_id: String, event: any) => {
	return api
		.post("/log_new_event", {
			pod_user_id: Number(localStorage.getItem("podUserId")),
			opportunity_id,
			attendee_emails: event.attendees
				? event.attendees.map((attendee: any) => attendee.email)
				: [],
			subject: event.title,
			start_time: event.startTime,
			end_time: event.endTime,
			timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
			g_cal_meeting_id: event.eventId,
			html_link: event.description,
		})
		.then((res) => res.data);
};
