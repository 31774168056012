import moment from "moment";
import Store from "../Store/Store";
import { API_FETCH } from "../Utils/Index";

export const GetSFDCObject = (
	changeURLKey: any,
	ignoreFilters?: boolean,
): Promise<any> => {
	const pipelineViewSpecificFilter = !ignoreFilters
		? Store.getState().pipeline.filters.filter((filter: any) =>
				Object.values(filter).every((el: any) => el !== null),
		  )
		: {};
	const searchText = !ignoreFilters
		? localStorage.getItem("searchText") || ""
		: "";

	const currMonth =
		moment().format("M").length == 1
			? "0" + moment().format("M")
			: moment().format("M");
	const currYear = moment().format("Y");

	let myHeaders = new Headers();
	myHeaders.append("Content-Type", "application/json");

	const requestOptions: any = {
		method: "POST",
		headers: myHeaders,
		body: JSON.stringify({
			pod_user_id: Number(localStorage.getItem("podUserId")),
			filter_data: {
				master_filter: {
					name: `all_${changeURLKey}`,
					data: { closeDateData: `${currYear}-${currMonth}` },
				},
				field_specific_filter: pipelineViewSpecificFilter,
				search_text: searchText,
			},
		}),
		redirect: "follow",
	};
	return new Promise((resolve, reject) => {
		fetch(API_FETCH(`/get_sfdc_${changeURLKey}`), requestOptions)
			.then((response: any) => response.json())
			.then((result: PromiseLike<Object[]>) => resolve(result))
			.catch((error: any) => {
				console.warn("error while getting Opportunites =>", error);
				reject(error);
			});
	});
};
