import { useEffect, useState } from "react";
import { Skeleton } from "antd";
import { getNotesSummary } from "../../../../../Apis/NotesAssistant/summary";
import { Note } from "../NotesAssistantDrawer";

export const SummaryTab = ({ note }: { note: Note }) => {
	const [summary, setSummary] = useState<string | undefined>(undefined);

	useEffect(() => {
		(async () => {
			for await (const value of getNotesSummary(note.type, note.content)) {
				setSummary((prevState) => (prevState ?? "") + value);
			}
		})();
	}, []);

	return summary !== undefined ? (
		<div className="notes_assistant_tab">
			{/*TODO uncomment these blocks once we stream gpt output on the BE, so we can have styled headers and sections*/}
			<div className="summary_title">
				<div>Notes Summary</div>
			</div>
			<div className="summary_block">
				{/*<div className="summary_block--title">Summary of the content</div>*/}
				<div
					className="summary_block--content"
					style={{ whiteSpace: "pre-line" }}
				>
					{summary}
				</div>
			</div>
			{/*<div className="summary_block">*/}
			{/*  <div className="summary_block--title">Next steps</div>*/}
			{/*  <ul className="summary_block--content summary_block_steps">*/}
			{/*    {data.nextSteps.map((item) => (*/}
			{/*      <li className="summary_block_steps--item" key={item}>*/}
			{/*        {item}*/}
			{/*      </li>*/}
			{/*    ))}*/}
			{/*  </ul>*/}
			{/*</div>*/}
		</div>
	) : (
		<Skeleton active paragraph={{ rows: 10 }}></Skeleton>
	);
};
