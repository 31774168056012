import { enableGoogleIntegration } from "../../Apis/Integration/google";
import { useGoogleLogin } from "@react-oauth/google";

// TODO we'll need to update this string whenever we change scopes.
// TODO one better solution is to migrate to @react-oauth/google's TokenResponse and use hasGrantedAllScopesGoogle()
// TODO (which probably just makes a call to some endpoint like googleapis.com/oauth2/v1/tokeninfo?access_token=)
const hasGrantedAllScopes = (scopes: string): boolean =>
	scopes ===
	"email profile openid https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/calendar.events";

export default ({
	render,
	onSuccess,
}: {
	render: (props: { onClick: () => void; disabled?: boolean }) => JSX.Element;
	onSuccess?: () => void;
}) => {
	const login = useGoogleLogin({
		onSuccess: (codeResponse) =>
			hasGrantedAllScopes(codeResponse.scope) &&
			enableGoogleIntegration(codeResponse.code).then(
				() => onSuccess && onSuccess(),
			),
		scope: process.env.REACT_APP_GOOGLE_SCOPES,
		onError: (errorResponse) => console.log({ errorResponse }),
		onNonOAuthError: (nonOAuthError) => console.log({ nonOAuthError }),
		flow: "auth-code",
	});

	return render({
		onClick: () => login(),
	});
};
