import api from "../api";
import { Md5 } from "ts-md5";

export const createInvite = async (
	invited_email: string,
	invited_name: string = "",
): Promise<void> => {
	// This track is purely for logging purposes
	window.analytics.track(
		"Invite_Sent",
		{
			inviterName: localStorage.getItem("sfdcUserDisplayName"),
			inviterEmail: localStorage.getItem("sfdcEmail"),
			invitedName: invited_name,
			invitedEmail: invited_email,
		},
		() => {
			const id = Md5.hashStr(
				"" +
					localStorage.getItem("sfdcUserDisplayName") +
					localStorage.getItem("sfdcEmail") +
					invited_name +
					invited_email,
			);
			// The invited needs their own intercom user to send to their email
			window.analytics.identify(
				id,
				{
					user_id: id,
					name: invited_name,
					email: invited_email,
				},
				() => {
					// Triggers emails
					window.analytics.track(
						"Invite_Received",
						{
							inviterName: localStorage.getItem("sfdcUserDisplayName"),
							inviterEmail: localStorage.getItem("sfdcEmail"),
							invitedName: invited_name,
							invitedEmail: invited_email,
						},
						() => {
							// Re-identify as the inviter/original user
							window.analytics.identify(
								localStorage.getItem("podUserId") as string,
								{
									email: localStorage.getItem("sfdcEmail") as string,
								},
							);
						},
					);
				},
			);
		},
	);

	await api.post("invite", {
		invited_email,
		invited_name,
		inviter_id: Number(localStorage.getItem("podUserId")),
	});
};
