import { API_FETCH } from "../../Utils/Index";

export const AddContactToOpportunity = (
	role: string,
	opportunityId: any,
	contactId: string,
) => {
	const myHeaders = new Headers();
	myHeaders.append("Content-Type", "application/json");

	const requestOptions: any = {
		method: "POST",
		headers: myHeaders,
		body: JSON.stringify({
			opportunityId,
			contactId,
			role,
			pod_user_id: Number(localStorage.getItem("podUserId")),
		}),
		redirect: "follow",
	};
	return new Promise((resolve, reject) => {
		fetch(API_FETCH(`/add_contact_to_opportunity`), requestOptions)
			.then((response: any) => response.json())
			.then((result: PromiseLike<Object[]>) => resolve(result))
			.catch((error: any) => {
				console.warn("error while getting Opportunites =>", error);
				reject(error);
			});
	});
};
