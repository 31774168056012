import { Dropdown, Input, Menu, Switch, Tooltip } from "antd";
import AddIcon from "../../../Images/Plus.svg";
import { AiOutlineSearch } from "react-icons/ai";
import { ReactSVG } from "react-svg";
import { useEffect, useState } from "react";
import { ConditionalTooltip } from "../../../Components/Common/ConditionalTooltip";

interface Props {
	data: any;
	showAction?: boolean;
	onCreate: () => void;
	onSelect?: (item: any) => void;
	onShare?: (id: string, shared?: boolean) => void;
	onEdit?: (item: any) => void;
	onDelete?: (id: string) => void;
}

const TemplateList = ({
	data,
	showAction = true,
	onCreate,
	onSelect,
	onShare,
	onEdit,
	onDelete,
}: Props) => {
	const [templates, setTemplates] = useState(data);

	useEffect(() => {
		setTemplates(data);
	}, [data]);

	const handleSearch = (query: string) => {
		setTemplates(
			data.filter(
				(item: any) =>
					!query || item.title.toLowerCase().includes(query.toLowerCase()),
			),
		);
	};

	return (
		<div className="pod-template-list">
			<div className="flex-grow-1 pod-template-list-search">
				<Input
					className="custom_input_1"
					onChange={(e) => handleSearch(e.target.value)}
					size="large"
					prefix={<AiOutlineSearch size={20} color="#999" />}
					placeholder="Type here..."
				/>
			</div>
			<div className="pod-template-list-content">
				{templates?.map((item: any) => {
					const title = item.title;
					const displayTitle =
						title.length > 50 ? `${title.slice(0, 47)}...` : title;
					return (
						<div className="actions-dropdown-item" key={item.id}>
							<ConditionalTooltip title={title} limit={50}>
								<div
									className="actions-dropdown-item-title"
									onClick={() => onSelect?.(item)}
								>
									{displayTitle}
								</div>
							</ConditionalTooltip>
							{showAction && item.shared && (
								<div className="ms-2">
									<Tooltip title="Shared Doc">
										<ReactSVG src="/images/icons/Shared.svg" />
									</Tooltip>
								</div>
							)}
							{showAction && (
								<Dropdown
									overlay={
										<Menu className="actions-dropdown-item-menu">
											<Menu.Item
												onClick={(info) => {
													info.domEvent.stopPropagation();
													onShare?.(item.id, !item.shared);
												}}
											>
												<div className="d-flex align-items-center justify-content-between">
													<div className="">Share</div>
													<Switch checked={item.shared} />
												</div>
											</Menu.Item>
											<Menu.Item onClick={() => onEdit?.(item)}>Edit</Menu.Item>
											<Menu.Item onClick={() => onDelete?.(item.id)}>
												Delete
											</Menu.Item>
										</Menu>
									}
									placement="bottomRight"
									trigger={["click"]}
								>
									<div className="actions-dropdown-icon-container ms-2">
										<ReactSVG src="/images/icons/more-horizontal.svg" />
									</div>
								</Dropdown>
							)}
						</div>
					);
				})}
			</div>
			<div className="pod-template-list-actions" onClick={onCreate}>
				<div className="create-template-icon me-1">
					<ReactSVG wrapper="span" src={AddIcon} />
				</div>
				<div className="options-item-text">Create Template</div>
			</div>
		</div>
	);
};

export default TemplateList;
