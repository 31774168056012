import { firebaseApp } from "./Config";
import {
	collection,
	query,
	where,
	getDocs,
	getFirestore,
	getDoc,
	doc,
	documentId,
} from "firebase/firestore";

export const GetSharedTemplates = async () => {
	const db = getFirestore(firebaseApp);
	const organization_id: string = localStorage.getItem("organizationId") || "";

	const refObj = doc(db, "shared_document_templates", organization_id);
	const sharedDoc = await getDoc(refObj);
	const sharedIds = sharedDoc.data()?.["document_template_ids"];

	if (sharedIds?.length) {
		const templateQuery = query(
			collection(db, "document_templates"),
			where(documentId(), "in", sharedIds || []),
		);
		const querySnapshot = await getDocs(templateQuery);

		return querySnapshot.docs;
	}

	return [];
};
