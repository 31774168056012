import api from "./api";

export const UpdateSFDCObject = async (
	objectId: string,
	objectType: string,
	updateData: any,
) => {
	return api.post("update_sfdc_object", {
		pod_user_id: Number(localStorage.getItem("podUserId")),
		object_id: objectId,
		object_type: objectType,
		update_data: updateData,
	});
};
