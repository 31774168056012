import api from "./api";
import { GetSFDCObjectV2 } from "./GetSFDCObjectV2";

export const getRecordTypesAndStagesBySObject = async (
	sobject: string,
	isActive: boolean = true,
) => {
	const res = await api.get("/get_record_types_and_stages_by_sobject", {
		params: {
			pod_user_id: Number(localStorage.getItem("podUserId")),
			sobject,
			is_active: isActive,
		},
	});

	return res.data;
};

export const getStagesByRecordType = async (record_type_ids?: string[]) => {
	const res = await api.post("/get_stages_by_record_type", {
		pod_user_id: Number(localStorage.getItem("podUserId")),
		record_type_ids,
	});

	return res.data;
};

export type Field = {
	label: string;
	query_string: string;
	width: number;
};

export const defaultField = {
	width: 240,
};

export const getSfdcOpportunitiesByRecordTypeAndStage = async ({
	recordTypeId,
	stage,
	pageSize = 0,
	pageNum,
	customFields,
}: {
	recordTypeId: string;
	stage: string;
	pageSize?: number;
	pageNum?: number;
	customFields?: Field[];
}) => {
	return await GetSFDCObjectV2({
		changeURLKey: "opportunities",
		pageSize,
		pageNum,
		customFilters: [
			{
				field: "RecordTypeId",
				operator: "equals",
				values: [`'${recordTypeId}'`],
				type: "picklist",
				interFilterOperator: "And",
				refObjectName: null,
			},
			{
				field: "StageName",
				operator: "equals",
				values: [`'${stage}'`],
				type: "picklist",
				interFilterOperator: "And",
				refObjectName: null,
			},
		],
		customFields: customFields,
	});
};
