import { Drawer, Button, Row, Col, Spin, notification } from "antd";
import { useState, useEffect, useMemo } from "react";
import { ReactSVG } from "react-svg";
import CloudIcon from "../../../Images/Cloud.svg";

import { AiOutlineEnvironment } from "react-icons/ai";
import { BsClock } from "react-icons/bs";
import moment from "moment";
import "react-initials-avatar/lib/ReactInitialsAvatar.css";
import { API_FETCH, capitalize, shortTxt } from "../../../Utils/Index";
import { IoMdOpen } from "react-icons/io";
import { UpdateSyncedOpportunity } from "../../../Apis/ActivityHub/UpdateSyncedOpportunity";
import { LogNewEvent } from "../../../Apis/LogNewEvent";
import { DropdownSearch } from "../../DropdownSearchV2";
import { DrawerProps } from "../DrawerList";
import "./EventDrawer.styles.scss";

const handleEventLink = (link: any) => {
	window.open(link, "_blank");
};

export const EditEventDrawer = ({
	visible,
	onClose,
	initData,
}: DrawerProps) => {
	const { event } = initData;
	const [selectedOppId, setSelectedOppId] = useState("");
	const [selectedOppName, setSelectedOppName] = useState("");

	const myHeaders = new Headers();
	myHeaders.append("Content-Type", "application/json");

	const [loader, setLoader] = useState(false);

	const phoneNumber = useMemo(() => {
		const eventPhone = event?.conferenceData?.entryPoints?.find(
			(i: any) => i.entryPointType === "phone",
		);
		const descPhone = event?.description?.split(
			"Phone number to be used for this meeting: ",
		)[1];
		return eventPhone?.label || descPhone || "";
	}, [event]);

	const description = useMemo(() => {
		return (
			event?.description?.split(
				"Phone number to be used for this meeting: ",
			)[0] || ""
		);
	}, [event]);

	const handleSync = () => {
		setLoader(true);
		LogNewEvent(selectedOppId, event).then((res) => {
			UpdateSyncedOpportunity(
				event && event.eventId,
				selectedOppId,
				"gcal",
				"Event",
				res?.event_data?.id,
			).then(() => {
				notification.success({
					message: "Event successfully logged",
					placement: "bottomRight",
					duration: 1,
				});
				setLoader(false);
			});
		});
	};

	useEffect(() => {
		let body = {
			pod_user_id: Number(localStorage.getItem("podUserId")),
			google_id: event.eventId,
			google_source: "gcal",
		};

		let requestOptions: any = {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify(body),
		};

		setLoader(true);

		fetch(API_FETCH("/get_sync_with_opportunity_data"), requestOptions)
			.then((res: any) => res.json())
			.then((res: any) => {
				let relevantObject = res.data.find((obj: any) => {
					return obj.isActive;
				});
				setSelectedOppId((relevantObject && relevantObject.sfdcOppId) || "");
				setSelectedOppName(
					(relevantObject && relevantObject.sfdcOppName) || "",
				);
				setLoader(false);
			});
	}, [event]);

	return (
		<Drawer
			title=""
			placement="right"
			width={400}
			onClose={onClose}
			visible={visible}
			style={{
				marginTop: "64px",
			}}
			className="calendar_drawer_wrapper"
		>
			<div className="pod_drawer calendar_drawer">
				<div className="pod_drawer_head ">
					<div className="d-flex justify-content-between">
						<div className="pod_drawer_head--title">
							<div>
								<span
									className="pod_drawer_meeting_title"
									title={event && event.title}
								>
									{event && event.title && shortTxt(event.title)}
								</span>{" "}
							</div>
						</div>
					</div>

					<div className="pod_drawer_head--subtitle" style={{ paddingLeft: 8 }}>
						<Spin spinning={loader}>
							<Row style={{ alignItems: "center" }}>
								<Col span={2}>
									<ReactSVG
										className="crown-icon"
										src="/images/icons/SFDC-objects/opportunities.svg"
									/>
								</Col>
								<Col span={15} style={{ marginLeft: 10 }}>
									<DropdownSearch
										currentId={selectedOppId}
										currentName={selectedOppName}
										objectType="Opportunity"
										UpdateTextOrNumberType={(
											id: string,
											_: unknown,
											name: string,
										) => {
											setSelectedOppId(id);
											setSelectedOppName(name);
										}}
										size="middle"
									/>
								</Col>
								<Col span={6}>
									<div className="pod_stage_syn--btn">
										<Button
											type="primary"
											className="syn_btn"
											onClick={() => handleSync()}
											style={{ height: "32px", marginLeft: "10px" }}
											icon={<img src={CloudIcon} className="img-fluid" />}
										>
											Sync
										</Button>
									</div>
								</Col>
							</Row>
						</Spin>
					</div>
				</div>
				<div className="pod_drawer_meeting_details_area fixed_area_drawer_details">
					<div className="row meeting_date_time_container">
						<div className="col-md-2">
							<BsClock
								style={{
									alignSelf: "center",
									width: "18px",
									height: "18px",
								}}
							/>
						</div>
						<div className="col-md-4">
							{event && moment(event.startTime).format("ddd, MMM DD")}
						</div>
						<div className="col-md-6 p-0 d-flex justify-content-end">
							<p className="mb-0">
								<span>
									{event && moment(event.startTime).format("hh:mm a")}
								</span>
								{"  -  "}
								<span>{event && moment(event.endTime).format("hh:mm a")} </span>
							</p>
						</div>
					</div>

					<div>
						<div className="row row-items meeting_date_time_container">
							<div className="col-md-2">
								<ReactSVG
									className="google-meet-icon"
									src="/images/icons/google-meet.svg"
									style={{
										alignSelf: "center",
									}}
								/>
							</div>

							<div className="col-md-8">
								<div
									className="google-meet-link-title"
									style={{
										...(!event?.hangoutLink ? { color: "#808080" } : {}),
									}}
								>
									{event?.hangoutLink
										? "Join with Google Meet"
										: "No Google Meet link available"}
								</div>
								<div
									className="google-meet-link"
									style={{ cursor: "pointer" }}
									onClick={() =>
										event && window.open(event.hangoutLink, "_blank")
									}
								>
									{" "}
									{event && event.hangoutLink}{" "}
								</div>
							</div>
						</div>
						<div className="row pt-3 row-items meeting_date_time_container">
							<div className="col-md-2">
								<ReactSVG
									className="phone-svg-icon"
									src="/images/icons/phone.svg"
									style={{
										alignSelf: "center",
									}}
								/>
							</div>
							<div className="col-md-8">
								<div
									className="google-phone-title"
									style={{
										...(!phoneNumber ? { color: "#808080" } : {}),
									}}
								>
									{phoneNumber ? "Join by Phone" : "No Phone Number Available"}
								</div>
								<div className="google-phone">{phoneNumber}</div>
							</div>
						</div>

						<div className="row meeting_date_time_container">
							<div className="col-md-2">
								<AiOutlineEnvironment
									style={{
										alignSelf: "center",
										width: "20px",
										height: "20px",
									}}
								/>
							</div>
							{event.location ? (
								<div className="col-md-10 ">{event.location}</div>
							) : (
								<div className="col-md-10" style={{ color: "#808080" }}>
									No Location Available
								</div>
							)}
						</div>

						<div className="description2-area">
							<div className="pt-3">
								<span style={{ fontWeight: "600", fontSize: "15px" }}>
									Description
								</span>
							</div>
							<div
								style={{
									fontSize: "14px",
									marginTop: "10px",
								}}
							>
								<div className="pb-3">
									<div dangerouslySetInnerHTML={{ __html: description }} />
								</div>
							</div>
						</div>

						<div>
							<div className=" px-4 py-3 border-top border-bottom">
								Total {event.attendees ? event.attendees.length : 1}{" "}
								Participant(s)
							</div>
							<div className="participant-list2">
								<ul>
									{event.attendees
										? event.attendees.map((attendee: any) => {
												return (
													<li>
														{capitalize(attendee.email || "")}{" "}
														<span>
															{capitalize(
																attendee.organizer
																	? "Organizer"
																	: attendee.responseStatus === "needsAction"
																	? "pending"
																	: attendee.responseStatus || "",
															)}
														</span>{" "}
													</li>
												);
										  })
										: event.organizer && event.organizer.email}
								</ul>
							</div>
						</div>

						<div style={{ height: "125px" }}></div>

						<div className="row pt-3 row-items meeting_date_time_container">
							<div
								className="open-with-calendar-box2"
								style={{
									position: "fixed",
									bottom: 0,
									padding: "20px 0",
									borderTop: "1px solid #ddd",
									width: "400px",
									background: "#fff",
								}}
							>
								<Button
									style={{
										border: "1px solid #5000A2",
										borderRadius: "4px",
										color: "#5000A2",
										fontSize: "15px",
										fontWeight: "400",
										height: "40px",
									}}
									icon={
										<IoMdOpen fontSize="20" style={{ marginRight: "10px" }} />
									}
									onClick={() => handleEventLink(event && event.htmlLink)}
								>
									{" "}
									Open in Google Calendar
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Drawer>
	);
};
