import { useCallback, useEffect, useRef } from "react";
import { ReactSVG } from "react-svg";
import { useSelector } from "react-redux";
import "./MasterFilter.styles.scss";
import { capitalize, effectiveLabelValue } from "../../Utils/Index";
import { Select } from "antd";
import { FieldSelector } from "../FieldSelector";
import { produce } from "immer";
import { debounce, isEqual } from "lodash";
import {
	refreshPipelineTable,
	updatePipelineFilter,
} from "../../Store/ActionReducerPairs/Pipeline";
import { updateCustomViewFilters } from "../../Apis/CustomViews";
import Store from "../../Store/Store";

interface LogicType {
	label: string;
	value: string;
}

const dateLogic: LogicType[] = [
	{ label: "Before", value: "lessThan" },
	{ label: "After", value: "greaterThan" },
	{ label: "On", value: "equals" },
];

const textLogic: LogicType[] = [
	{ label: "Contains", value: "like" },
	{ label: "Does Not Contain", value: "notLike" },
	{ label: "Includes", value: "includes" },
	{ label: "Does Not Include", value: "notInclude" },
	{ label: "Starts With", value: "startsWith" },
];

const picklistLogic: LogicType[] = [
	{ label: "Equals", value: "like" },
	{ label: "Not Equals", value: "notLike" },
];

const booleanLogic: LogicType[] = [{ label: "Equals", value: "equals" }];

// const booleanLogic: LogicType[] = [
//   { label: "Yes", value: "TRUE" },
//   { label: "No", value: "FALSE" }
// ]

const numberLogic: LogicType[] = [
	{ label: "Equals", value: "equals" },
	{ label: "Greater Than", value: "greaterThan" },
	{ label: "Less Than", value: "lessThan" },
	{ label: "Greater Or Equal", value: "greaterThanOrEqualTo" },
	{ label: "Lesser Or Equal", value: "lessThanOrEqualTo" },
];

const referenceLogic: LogicType[] = [
	{ label: "Equals", value: "equals" },
	{ label: "Not Equals", value: "notEquals" },
	{ label: "Includes", value: "referenceLogicIncludes" },
	{ label: "Does Not Include", value: "referenceLogicIncludesNotInclude" },
	{ label: "Starts With", value: "referenceLogicIncludesStartsWith" },
];

const logicByType = {
	date: dateLogic,
	datetime: dateLogic,
	reference: referenceLogic,
	string: textLogic,
	text: textLogic,
	textarea: textLogic,
	boolean: booleanLogic,
	number: numberLogic,
	double: numberLogic,
	currency: numberLogic,
	money: numberLogic,
	percent: numberLogic,
	int: numberLogic,
	url: textLogic,
	email: textLogic,
	id: referenceLogic,
	picklist: picklistLogic,
	address: textLogic,
	phone: textLogic,
};

// Active filters are being applied
export const isFilterActive = (filter: Filter) =>
	Object.values(filter).every((value: any) => value !== null);

// Empty filters are inactive filters without any fields filled in
export const isFilterEmpty = (filter: Filter) =>
	Object.entries(filter).every(
		(property: any) =>
			property[0] === "interFilterOperator" || property[1] === null,
	);

export const hasNonEmptyFilter = (filters: any) =>
	filters.some((filter: Filter) => !isFilterEmpty(filter));

export const numActiveFilters = (filters: any): number =>
	filters.reduce(
		(count: number, filter: Filter) =>
			isFilterActive(filter) ? count + 1 : count,
		0,
	);

const getActiveFilters = (filters: Filter[]): Filter[] =>
	filters.filter((f) => isFilterActive(f));

export const doFiltersHaveSameState = (a: Filter[], b: Filter[]): boolean =>
	isEqual(a, b);

export const doFiltersHaveSameActiveState = (
	a: Filter[],
	b: Filter[],
): boolean => doFiltersHaveSameState(getActiveFilters(a), getActiveFilters(b));

type InterFilterOperator = "And" | "Or";
const interFilterOperators: InterFilterOperator[] = ["And", "Or"];

export type Filter = {
	field: string | null;
	operator: string | null;
	refObjectName: string | null;
	type: string | null;
	values: string[] | null;
	interFilterOperator: InterFilterOperator;
};

export const defaultFilter: Filter = {
	field: null,
	operator: null,
	values: null,
	type: null,
	refObjectName: null,
	interFilterOperator: "And",
};

export default ({
	category,
	columns,
}: {
	category: string;
	columns: any;
}) => {
	const filters = useSelector(({ pipeline }: any) => pipeline.filters);
	const preventNextFilter = useRef<boolean>(true);
	const debouncedPersistAndRefresh = useCallback(
		debounce(async () => {
			await updateCustomViewFilters(
				JSON.parse(localStorage.getItem("pipelineViewMasterFilter") || "{}")[
					Store.getState().pipeline.category
				],
				getActiveFilters(
					Store.getState().pipeline.filters[Store.getState().pipeline.category],
				),
			);
			refreshPipelineTable(new Date());
		}, 1000),
		[],
	);

	useEffect(() => {
		// optimization to minimize pv reloads and persisting
		if (preventNextFilter.current) {
			preventNextFilter.current = false;
			return;
		}

		debouncedPersistAndRefresh();
	}, [filters]);

	const getFieldInfo = (field: string | null) =>
		columns.find(({ name }: any) => name === field);

	const dispatchFilterUpdate = async (newFilters: Filter[]) => {
		updatePipelineFilter({
			[category]: newFilters,
		});
	};

	const removeFilter = (filterIndex: number) => {
		if (1 === filters[category].length) {
			reset();
			return;
		}
		if (!isFilterActive(filters[category][filterIndex])) {
			preventNextFilter.current = true;
		}

		void dispatchFilterUpdate(
			produce<Filter[]>(filters[category], (draft) => {
				draft.splice(filterIndex, 1);
			}),
		);
	};

	const reset = () => {
		preventNextFilter.current = 0 === numActiveFilters(filters[category]);

		void dispatchFilterUpdate([defaultFilter]);
	};

	const addFilter = () => {
		preventNextFilter.current = true;

		void dispatchFilterUpdate([
			...filters[category],
			{
				...defaultFilter,
				// Set new filter's interFilterOperator to what other filters are set to, if they exist
				interFilterOperator:
					filters[category].length !== 0
						? filters[category][0].interFilterOperator
						: "And",
			},
		]);

		window.analytics.track("PipelineView_Add_Filter", {
			podUserId: localStorage.getItem("podUserId"),
		});
	};

	const updateInterFilterOperator = (
		interFilterOperator: InterFilterOperator,
	) => {
		preventNextFilter.current = numActiveFilters(filters[category]) <= 1;

		void dispatchFilterUpdate(
			produce<Filter[]>(filters[category], (draft) => {
				draft.map(
					(filter) => (filter.interFilterOperator = interFilterOperator),
				);
			}),
		);
	};

	// Prevent filter trigger when mutating an invalid filter to another invalid state
	const isFilterStillInvalid = (before: Filter, after: Filter) => {
		return !isFilterActive(before) && !isFilterActive(after);
	};

	const updateField = (filterIndex: number, field: string) => {
		const newFilters = produce<Filter[]>(filters[category], (draft) => {
			draft[filterIndex].field = field;
			draft[filterIndex].values = null;
			draft[filterIndex].refObjectName = null;
			draft[filterIndex].operator = null;
		});

		preventNextFilter.current = isFilterStillInvalid(
			filters[category][filterIndex],
			newFilters[filterIndex],
		);

		void dispatchFilterUpdate(newFilters);
	};

	const updateOperator = (filterIndex: number, operator: string) => {
		const newFilters = produce<Filter[]>(filters[category], (draft) => {
			draft[filterIndex].operator = operator;
		});

		preventNextFilter.current = isFilterStillInvalid(
			filters[category][filterIndex],
			newFilters[filterIndex],
		);

		void dispatchFilterUpdate(newFilters);
	};

	const updateQueryValue = (
		filterIndex: number,
		queryValue: string,
		type: string,
		name: string,
	) => {
		const isQueryValueEmpty = ["", null].includes(queryValue);

		const newFilters = produce<Filter[]>(filters[category], (draft) => {
			draft[filterIndex].values = isQueryValueEmpty
				? null
				: ["date", "datetime"].includes(type)
				? [(queryValue as any).format("YYYY-MM-DD")]
				: [queryValue];
			draft[filterIndex].refObjectName = isQueryValueEmpty ? null : name || "";
			draft[filterIndex].type = isQueryValueEmpty ? null : type;
		});

		preventNextFilter.current = isFilterStillInvalid(
			filters[category][filterIndex],
			newFilters[filterIndex],
		);

		void dispatchFilterUpdate(newFilters);
	};

	const renderConjunction = (filterIndex: Number, filter: Filter) => {
		switch (filterIndex) {
			case 0:
				return (
					<p
						style={{
							fontWeight: "600",
							fontSize: "14px",
							letterSpacing: "0.0015em",
							color: "#1B1B1C",
							marginBottom: "4px",
							paddingLeft: "4px",
							paddingRight: "18px",
						}}
					>
						Where
					</p>
				);
			case 1:
				return (
					<Select
						value={filter.interFilterOperator ?? "And"}
						onChange={(value) => {
							updateInterFilterOperator(interFilterOperators[Number(value)]);
						}}
						style={{
							width: "67px",
							color: "black",
							fontSize: "13px",
							fontWeight: "500",
							letterSpacing: "0.004em",
						}}
						dropdownMatchSelectWidth={145}
						notFoundContent={
							!interFilterOperators.length
								? "Search for a record ..."
								: "No results found"
						}
					>
						{interFilterOperators.map((operator, i) => (
							<Select key={i}>
								<div className="pv-filter-column">
									{operator}
									<p
										style={{
											color: "black",
											fontSize: "10px",
										}}
									>
										{operator === "And"
											? "All Filters must match"
											: "At least one match"}
									</p>
								</div>
							</Select>
						))}
					</Select>
				);
			default:
				return (
					<p
						style={{
							fontWeight: "600",
							fontSize: "14px",
							letterSpacing: "0.0015em",
							color: "#1B1B1C",
							marginBottom: "4px",
							paddingLeft:
								filter.interFilterOperator === "And" ? "14px" : "14px",
							paddingRight:
								filter.interFilterOperator === "And" ? "26px" : "35px",
						}}
					>
						{filter.interFilterOperator}
					</p>
				);
		}
	};

	return (
		<div className="pv-filter">
			<div
				className="pv-filter-row"
				style={{ alignItems: "center", marginBottom: "12px" }}
			>
				<p
					style={{
						display: "inline-block",
						fontSize: "13px",
						letterSpacing: "0.004em",
						marginBottom: "0",
						marginRight: "12px",
					}}
				>
					Filter for:
				</p>
				<ReactSVG
					beforeInjection={(svg) => {
						svg.setAttribute("style", "width: 23px");
					}}
					src={`/images/icons/SFDC-objects/${category}.svg`}
				/>
				<p
					style={{
						fontWeight: "600",
						letterSpacing: "0.0015em",
						color: "#1B1B1C",
						marginBottom: "0",
						marginLeft: "12px",
					}}
				>
					{capitalize(category)}
				</p>
			</div>
			{filters[category].map((filter: Filter, filterIndex: number) => (
				<div
					key={filterIndex}
					className="pv-filter-row"
					style={{
						alignItems: "center",
						justifyContent: "space-around",
						paddingBottom: "4px",
					}}
				>
					<div style={{ paddingTop: "20px" }}>
						{renderConjunction(filterIndex, filter)}
					</div>
					<div className="pv-filter-column">
						<p className="pv-filter-field-descriptor">Field/Column Name</p>
						<Select
							placeholder="Select"
							showSearch
							filterOption={(searchValue, option) =>
								String(option?.children)
									.toLowerCase()
									.includes(searchValue.toLowerCase())
							}
							onChange={(value) => updateField(filterIndex, value)}
							value={filter.field}
							dropdownMatchSelectWidth={375}
							notFoundContent={
								!columns.length ? "Search for a record ..." : "No results found"
							}
						>
							{columns.map(({ label, name, type }: any) => (
								<Select key={name} value={name}>
									{effectiveLabelValue(label, type)}
								</Select>
							))}
						</Select>
					</div>
					<div className="pv-filter-column">
						<p className="pv-filter-field-descriptor">Apply Logic</p>
						<Select
							placeholder="Select"
							onChange={(value) => updateOperator(filterIndex, value)}
							value={filter.operator}
							dropdownMatchSelectWidth={190}
							notFoundContent={
								!(
									logicByType[
										getFieldInfo(filter.field)?.type as keyof typeof logicByType
									] ?? []
								).length
									? "Search for a record ..."
									: "No results found"
							}
						>
							{(
								logicByType[
									getFieldInfo(filter.field)?.type as keyof typeof logicByType
								] ?? []
							).map(({ label, value }) => (
								<Select key={value} value={value}>
									{label}
								</Select>
							))}
						</Select>
					</div>
					<div
						className="pv-filter-column pv-filter-value"
						id="pv-filter-value"
					>
						<p className="pv-filter-field-descriptor">Value</p>
						<FieldSelector
							type={getFieldInfo(filter.field)?.type ?? ""}
							picklist={getFieldInfo(filter.field)?.picklistValues ?? []}
							refObjectName={filter.refObjectName ?? ""}
							currentValue={filter.values ? filter.values[0] : ""}
							updateValue={(value: unknown, name?: string) =>
								updateQueryValue(
									filterIndex,
									value as string,
									getFieldInfo(filter.field)?.type ?? "",
									name ?? "",
								)
							}
							objectType={getFieldInfo(filter.field)?.referenceTo[0]}
							size="middle"
							booleanAsDropdown
						/>
					</div>
					<button
						className="pv-filter-btn pv-filter-trash"
						onClick={() => removeFilter(filterIndex)}
					>
						<ReactSVG src={`/images/icons/Trash.svg`} />
					</button>
				</div>
			))}
			<div
				className="pv-filter-row"
				style={{
					justifyContent: "end",
					marginTop: "16px",
					marginRight: "4px",
					alignItems: "center",
				}}
			>
				<div className="pv-filter-reset" onClick={reset}>
					Reset
				</div>
				<button
					className="pv-filter-btn pv-filter-add-filter"
					onClick={addFilter}
				>
					+
				</button>
			</div>
		</div>
	);
};
