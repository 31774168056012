import { Select, Input } from "antd";

const { TextArea } = Input;
export const PicklistWidget = (
	{ value, onChange }: any,
	pickList: any,
	size: any = "large",
) => (
	<Select
		size={size}
		placeholder="Select"
		showSearch
		value={value}
		onChange={(v) => onChange(v)}
		filterOption={(input, option: any) =>
			option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
		}
		notFoundContent={
			!pickList.length ? "Search for a record ..." : "No results found"
		}
	>
		{pickList.map(({ id, name, label, value }: any) => (
			<Select.Option key={id || value} value={id || value}>
				{name || label}
			</Select.Option>
		))}
	</Select>
);
export const TextareaWidget = ({ value, onChange }: any) => (
	<TextArea size="large" value={value} onChange={(v) => onChange(v)} />
);
