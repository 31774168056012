import { useEffect, useState, useMemo } from "react";
import {
	AiOutlineUser,
	AiOutlineMail,
	AiOutlineUsergroupAdd,
} from "react-icons/ai";
import { BsTelephone } from "react-icons/bs";
import { Menu, Dropdown, Row } from "antd";
import moment, { Moment } from "moment";
import DashedBlue from "../../../Images/DashedBlue.svg";
import DashedPurple from "../../../Images/DashedPurple.svg";
import { shortTxt } from "../../../Utils/Index";
import { GetEmailsForActivityHub } from "../../../Apis/GetEmailsForActivityHub";
import { GetInternalMeetingsForActivityHub } from "../../../Apis/GetInternalMeetingsForActivityHub";
import { GetPhoneCallsForActivityHub } from "../../../Apis/GetPhoneCallsForActivityHub";
import { GetOpportunityAndAccountName } from "../../../Apis/GetOpportunityAndAccountName";
import { GetDealStagesForAcivityHub } from "../../../Apis/GetDealStagesForActivityHub";
import { GetSFDCObjectDetailsV2 } from "../../../Apis/GetSFDCObjectDetailsV2";
import { GetEngagementScore } from "../../../Apis/GetEngagementScore";
import OutlinedLoader from "../../../Components/OutlinedLoader";
import { InsightBox } from "./InsightBox";
import { useAppDispatch } from "../../../hooks";

export interface Drawer {
	visible: boolean;
	data: any;
}

export interface Event {
	date: string;
	source: string;
	reference_id: string;
	category: string;
}

export interface DataProps {
	emails: Event[];
	customerMeetings: Event[];
	internalMeetings: Event[];
	phoneCalls: Event[];
	dealStages: Event[];
}

const days = ["01", "08", "15", "22", "29"];

const iconByType: Record<string, any> = {
	emails: <AiOutlineMail color="#fff" />,
	phoneCalls: <BsTelephone color="#fff" />,
	internalMeetings: <AiOutlineUsergroupAdd color="#fff" />,
	customerMeetings: <AiOutlineUser color="#fff" />,
	dealStages: <AiOutlineUser color="#fff" />,
};

const classByType: Record<string, string> = {
	emails: "email-icon",
	phoneCalls: "telephone-icon",
	internalMeetings: "internal-meetings-icon",
	customerMeetings: "customer-icon",
	dealStages: "customer-icon",
};

export const ActivityAreaV2 = (props: {
	currentMonth: string;
	reload: number;
}) => {
	const dispatch = useAppDispatch();
	const { currentMonth, reload } = props;
	const [loading, setLoading] = useState(false);
	const [allData, setAllData] = useState<Record<string, DataProps>>({});
	const opportunityId = new URLSearchParams(location.search).get(
		"opportunity_id",
	);
	const [dealStageVisible, setDealStageVisible] = useState<
		Record<string, boolean>
	>({});
	const [opportunityInsights, setOpportunityInsights] = useState<any>({});
	const [opportunityName, setOpportunityName] = useState<string | undefined>(
		undefined,
	);

	const data: DataProps = useMemo(() => {
		const monthKey = moment(currentMonth).format("YYYY-MM");
		return (
			allData[monthKey] || {
				emails: [],
				customerMeetings: [],
				internalMeetings: [],
				phoneCalls: [],
				dealStages: [],
			}
		);
	}, [allData, currentMonth]);

	const handleOpenDrawer = (drawerName: string, data: any) => {
		dispatch({
			type: "OPEN_DRAWER",
			payload: {
				drawerType: drawerName,
				data,
				sfdcOpportunityName: opportunityName,
				sfdcOpportunityId: opportunityId,
			},
		});
	};

	const fetchData = (month: Moment) => {
		setLoading(true);
		// TODO: use moment formatting instead of replacing - "YYYY/MM/DDTHH:mm:ss"
		const monthKey = month.format("YYYY-MM");
		const startDate = month.startOf("month").format().split("T");
		const endDate = month.endOf("month").format().split("T");
		const formattedEndDate = `${endDate[0].replaceAll("-", "/")}T${endDate[1]}`;
		const formattedStartDate = `${startDate[0].replaceAll("-", "/")}T${
			startDate[1]
		}`;

		Promise.all([
			GetEmailsForActivityHub(
				opportunityId!,
				formattedStartDate,
				formattedEndDate,
			),
			GetInternalMeetingsForActivityHub(
				opportunityId!,
				formattedStartDate,
				formattedEndDate,
			),
			GetPhoneCallsForActivityHub(
				opportunityId!,
				formattedStartDate,
				formattedEndDate,
			),
			GetOpportunityAndAccountName(opportunityId!),
			GetDealStagesForAcivityHub(
				opportunityId!,
				formattedStartDate,
				formattedEndDate,
			),
		]).then(
			([
				emails,
				meetings,
				phoneCalls,
				opportunityAndAccountName,
				dealStages,
			]: any) => {
				setOpportunityName(opportunityAndAccountName["opportunity_name"]);
				setAllData({
					...allData,
					[monthKey]: {
						emails: emails as Event[],
						customerMeetings: (meetings as Event[]).filter(
							(item: any) => item.category === "customer_meeting",
						),
						internalMeetings: (meetings as Event[]).filter(
							(item: any) => item.category === "internal_meeting",
						),
						phoneCalls: phoneCalls as Event[],
						dealStages: dealStages as Event[],
					},
				});
				setLoading(false);
			},
		);
	};

	useEffect(() => {
		const queryParams = new URLSearchParams(window.location.search);
		const opportunityId = queryParams.get("opportunity_id") || "";
		setOpportunityInsights({});
		Promise.all([
			GetSFDCObjectDetailsV2("Opportunity", opportunityId, "activity_area"),
			GetEngagementScore([opportunityId]),
		]).then(([opportunityInfo, engagementScore]) => {
			const date = moment(opportunityInfo.data[0].CreatedDate);
			const duration = moment.duration(moment().diff(date));
			const days = Math.ceil(duration.asDays());
			setOpportunityInsights({
				date: date.format("DD MMMM, YYYY"),
				dateDifference: days,
				engagementScore: engagementScore[0].engagement_score,
			});
		});
	}, []);

	useEffect(() => {
		fetchData(currentMonth ? moment(currentMonth) : moment());
	}, [currentMonth, reload]);

	const showEvent = (
		date: Moment,
		type: "emails" | "customerMeetings" | "internalMeetings" | "phoneCalls",
	): Boolean =>
		data[type].some((item) => {
			const formattedEventDate = moment(item.date).format("YYYY-MM-DD");
			return formattedEventDate == moment(date).format("YYYY-MM-DD");
		});

	const areMultipleEvents = (
		date: Moment,
		type: "emails" | "customerMeetings" | "internalMeetings" | "phoneCalls",
	) => {
		const dropdownData = data[type].filter(
			(item: any) =>
				moment(item.date).format("YYYY-MM-DD") ==
				moment(date).format("YYYY-MM-DD"),
		);
		return dropdownData?.length > 1;
	};

	const eventsMenuData = (
		date: Moment,
		type:
			| "emails"
			| "customerMeetings"
			| "internalMeetings"
			| "phoneCalls"
			| "dealStages",
	) => {
		let dropdownData = data[type].filter(
			(event: Event) =>
				moment(event.date).format("YYYY-MM-DD") ==
				moment(date).format("YYYY-MM-DD"),
		);

		return (
			<Menu style={{ maxHeight: 191, overflowY: "scroll" }}>
				{(dropdownData || [])?.map((item: any) => {
					return (
						<Menu.Item onClick={() => handleOpenDrawer(type, item)}>
							<span className="dropdown-list-item">
								{shortTxt(item.name || item.subject)}
							</span>
							<div>{moment(item.date).format("hh:mm a")}</div>
						</Menu.Item>
					);
				})}
			</Menu>
		);
	};

	const monthName = useMemo(() => {
		const date = currentMonth ? moment(currentMonth) : moment();
		return date.format("MMMM");
	}, [currentMonth]);

	const monthKey = useMemo(() => {
		const date = currentMonth ? moment(currentMonth) : moment();
		return date.format("YYYY-MM");
	}, [currentMonth]);

	const touchpoints = useMemo(() => {
		return allData[monthKey]
			? allData[monthKey].customerMeetings.length +
					allData[monthKey].phoneCalls.length +
					allData[monthKey].emails.length +
					allData[monthKey].internalMeetings.length
			: 0;
	}, [currentMonth, allData]);

	return (
		<div className="grid-container">
			<Row>
				{/*<InsightBox
          loading={!opportunityInsights.date}
          title="Engagement Rate"
          description="Since the creation of Opportunity"
          value={`${opportunityInsights.engagementScore || 0}%`}
          engagement
        />*/}
				<InsightBox
					loading={!opportunityInsights.date}
					title="No. of days since creation"
					description={`Created on ${opportunityInsights.date}`}
					value={`${opportunityInsights.dateDifference} days`}
				/>
				<InsightBox
					loading={!opportunityInsights.date}
					title="No. of touchpoints this month"
					description={`Total no. of touchpoints for ${monthName}`}
					value={String(touchpoints)}
				/>
			</Row>
			<div
				className="grid"
				style={{
					overflow: "auto",
					position: "relative",
					height: "calc(100vh - 307px)",
				}}
			>
				{loading && (
					<>
						<OutlinedLoader
							style={{
								position: "absolute",
								zIndex: 1001,
								marginTop: 160,
								fontSize: 32,
								marginLeft: (window.innerWidth - 280) / 2,
							}}
						/>
					</>
				)}
				<div className="grid-col grid-col--fixed-left">
					<div className="grid-item grid-item--header" style={{ zIndex: 9999 }}>
						<p>Activities</p>
					</div>
					<div className="grid-item">
						<p>
							Customer Meetings{" "}
							<span className="activity-data-length">
								{allData[monthKey]
									? allData[monthKey].customerMeetings.length
									: 0}
							</span>
						</p>
					</div>
					<div className="grid-item">
						<p>
							Emails{" "}
							<span className="activity-data-length">
								{allData[monthKey] ? allData[monthKey].emails.length : 0}
							</span>
						</p>
					</div>
					<div className="grid-item">
						<p>
							Internal Meetings{" "}
							<span className="activity-data-length">
								{allData[monthKey]
									? allData[monthKey].internalMeetings.length
									: 0}
							</span>
						</p>
					</div>
					<div className="grid-item">
						<p>
							Phone Calls{" "}
							<span className="activity-data-length">
								{allData[monthKey] ? allData[monthKey].phoneCalls.length : 0}
							</span>
						</p>
					</div>
					<div className="grid-item">
						<p>Deal Stages</p>
					</div>
				</div>
				{days.map((day) => (
					<div className="grid-col">
						<div
							className="grid-item grid-item--header"
							style={{ zIndex: 998 }}
						>
							<div className="d-flex">
								<div className="grid-date-single">
									<span style={{ width: 26 }}>{day}</span>
									<span style={{ position: "absolute" }}>
										{moment(currentMonth).format("MMM")}
									</span>
								</div>
								<div className="grid-date-multiple d-flex">
									<span>|</span>
									<span>|</span>
									<span>|</span>
									<span>|</span>
									<span>|</span>
									<span>|</span>
								</div>
							</div>
						</div>
						{[
							"customerMeetings",
							"emails",
							"internalMeetings",
							"phoneCalls",
						].map((type: any) => (
							<div
								className="grid-item"
								key={type}
								style={{ position: "relative" }}
							>
								<div className="d-flex grid-content">
									{Array.apply(null, { length: 7 } as any).map((_, index) => {
										const numDay = Number(day) - 1;
										const date = moment(currentMonth)
											.startOf("month")
											.add(numDay + index, "days");
										const doShowEvent = showEvent(date, type);
										const pos =
											index === 0
												? 10
												: index === 1
												? 47
												: 47 + 34 * (index - 1);

										return (
											<div
												style={{
													position: "absolute",
													height: 80,
													marginRight: 0,
													width: 30,
													left: pos,
												}}
											>
												{doShowEvent ? (
													<div
														className="single-item-container"
														key={`${numDay + index}`}
													>
														{areMultipleEvents(date, type) && (
															<span className="pointer-more-items"></span>
														)}
														<Dropdown
															overlay={eventsMenuData(date, type)}
															placement="bottomLeft"
															arrow
															trigger={["click"]}
														>
															<span className={classByType[type]}>
																{iconByType[type]}
															</span>
														</Dropdown>
													</div>
												) : (
													<div
														key={`${numDay + index}`}
														style={{ marginRight: 0 }}
													>
														<span
															className={`${classByType[type]} empty`}
															style={{ width: 30 }}
														>
															{" "}
															{iconByType[type]}
														</span>
													</div>
												)}
											</div>
										);
									})}
								</div>
							</div>
						))}
						<div className="grid-item" style={{ position: "relative" }}>
							<div className="d-flex grid-content">
								{Array.apply(null, { length: 7 } as any).map((_, index) => {
									const numDay = Number(day) - 1;
									const date = moment(currentMonth)
										.startOf("month")
										.add(numDay + index, "days");

									const stage: any = data.dealStages.find(
										(item: Event) =>
											moment(item.date).format("YYYY-MM-DD") ===
											date.format("YYYY-MM-DD"),
									);

									return (
										<div key={`${numDay + index}`}>
											<div
												style={{
													position: "absolute",
													height: 80,
													marginRight: 0,
													width: 30,
													left:
														index === 0
															? 8
															: index === 1
															? 45
															: 45 + 34 * (index - 1),
												}}
											>
												{date.format("YYYY-MM-DD") ===
													moment().format("YYYY-MM-DD") && (
													<>
														<img
															src={DashedPurple}
															className="dashed-line"
															style={{ height: 404 }}
														/>
														<div
															className="stage-box box-today"
															style={{
																display: "flex",
																justifyContent: "center",
															}}
														>
															<p>Today</p>
														</div>
													</>
												)}
												{Boolean(stage) && (
													<>
														<img
															src={DashedBlue}
															className="dashed-line"
															style={{
																...(index % 2 === 0
																	? { height: 338 }
																	: { height: 362 }),
															}}
														/>
														<Dropdown
															overlay={eventsMenuData(date, "dealStages")}
															placement="bottomLeft"
															arrow
														>
															<div
																className="stage-box box-stage"
																style={{
																	...(index % 2 === 0
																		? { top: 18 }
																		: { top: 42 }),
																}}
																onClick={() =>
																	setDealStageVisible({
																		...dealStageVisible,
																		[date.format("YYYY-MM-DD")]: true,
																	})
																}
															>
																<p>
																	{stage.name?.length > 8
																		? `${stage.name.slice(0, 6)}...`
																		: stage.name}
																</p>
															</div>
														</Dropdown>
													</>
												)}
											</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};
