import { useState } from "react";
import { Menu, Dropdown } from "antd";
import CalendarIcon from "../../Images/Calendar.svg";
import DocumentIcon from "../../Images/NewDocument.svg";
import TaskIcon from "../../Images/Task.svg";
import DetailsIcon from "../../Images/Details.svg";
import ExpandIcon from "../../Images/Expand.svg";
import EditIcon from "../../Images/Edit.svg";
import DeleteIcon from "../../Images/Delete.svg";
import { ReactSVG } from "react-svg";
import "./style.scss";

interface Props {
	options: Array<string>;
	onClick: (item: string) => void;
	children: any;
	hideIcon?: boolean;
	trigger?: any;
	iconClassName?: string;
	className?: string;
}

const icons: any = {
	"Log New Activity": CalendarIcon,
	"Create Notes": DocumentIcon,
	"Create Task": TaskIcon,
	"View Details": DetailsIcon,
	"View Activity": CalendarIcon,
	Open: ExpandIcon,
	"Share Link": EditIcon,
	Delete: DeleteIcon,
};

const menu = (
	options: Array<string>,
	onClick: (item: string) => void,
	hideIcon?: boolean,
	className?: string,
	iconClassName?: string,
) => {
	return (
		<Menu className="actions-dropdown-container">
			{Boolean(options.length > 0) ? (
				options.map((item) => (
					<div
						className={`actions-dropdown-item ${className}`}
						key={item}
						onClick={(event) => {
							event.stopPropagation();
							onClick(item);
						}}
					>
						{!hideIcon ? (
							<div className="actions-dropdown-icon-container">
								<ReactSVG
									className={`actions-dropdown-icon ${iconClassName}`}
									wrapper="span"
									src={icons[item]}
								/>
							</div>
						) : null}
						<p
							className="options-item-text"
							style={hideIcon ? { marginLeft: 6, marginRight: 6 } : {}}
						>
							{item}
						</p>
					</div>
				))
			) : (
				<p className="no-result">No item to select</p>
			)}
		</Menu>
	);
};

const OptionsDropdown = (props: Props) => {
	const {
		options,
		onClick,
		children,
		hideIcon,
		trigger,
		iconClassName,
		className,
	} = props;
	const [visible, setVisible] = useState(false);

	const handleClick = (item: string) => {
		setVisible(false);
		onClick(item);
	};

	return (
		<Dropdown
			overlay={menu(options, handleClick, hideIcon, className, iconClassName)}
			trigger={!trigger ? ["click"] : trigger}
			visible={visible}
			onVisibleChange={setVisible}
		>
			{children}
		</Dropdown>
	);
};

export default OptionsDropdown;
