import { useEffect, useState, useMemo } from "react";
import { DatePicker, Input, Button, Drawer, Select, notification } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { ReactSVG } from "react-svg";
import "./TaskManager.styles.scss";
import moment from "moment";
import { DropdownSearch } from "./Components/DropdownSearchV2";
import { CreateTask } from "./Apis/Firebase/CreateTask";
import { GetUserTasks } from "./Apis/Firebase/GetUserTasks";
import { UpdateTask } from "./Apis/Firebase/UpdateTask";
import { DeleteTask } from "./Apis/Firebase/DeleteTask";

const taskFilters = [
	"All tasks",
	"Due today",
	"Due this week",
	"Due next week",
];

export const TaskManager = () => {
	const dispatch = useDispatch();
	const taskManagerVisibility = useSelector(
		(state: any) => state.taskManager.visible,
	);
	const [showNewTaskArea, setShowNewTaskArea] = useState<any>("none");
	const [taskList, setTaskList] = useState<Array<Record<string, string>>>([]);
	const [filteredTaskList, setFilteredTaskList] = useState<
		Array<Record<string, string>>
	>([]);
	const [taskOpportunity, setTaskOpportunity] = useState<any>({});
	const [expandedTasks, setExpandedTasks] = useState<any>([]);
	const [newTaskContent, setNewTaskContent] = useState("");
	const [newTaskDate, setNewTaskDate] = useState("");
	const [newTaskAssignee, setNewTaskAssignee] = useState("");
	const [newTaskOppId, setNewTaskOppId] = useState("");
	const [newTaskOppName, setNewTaskOppName] = useState("");
	const [taskFilter, setTaskFilter] = useState("All tasks");
	const [opportunityNameSearch, setOpportunityNameSearch] = useState("");
	const [opportunityIdSearch, setOpportunityIdSearch] = useState("");
	const [loader, setLoader] = useState(false);

	const reduxNewTaskContent = useSelector(
		(state: any) => state.taskManager.newTaskContent,
	);
	const reduxNewTaskOppId = useSelector(
		(state: any) => state.taskManager.newTaskOpportunityId,
	);
	const reduxNewTaskOppName = useSelector(
		(state: any) => state.taskManager.newTaskOpportunityName,
	);
	const reduxShowNewArea = useSelector(
		(state: any) => state.taskManager.showNewTaskArea,
	);

	useEffect(() => {
		if (reduxNewTaskContent) {
			setNewTaskContent(reduxNewTaskContent);
		}
	}, [reduxNewTaskContent]);

	useEffect(() => {
		setNewTaskOppId(reduxNewTaskOppId);
	}, [reduxNewTaskOppId]);

	useEffect(() => {
		setNewTaskOppName(reduxNewTaskOppName);
	}, [reduxNewTaskOppName]);

	useEffect(() => {
		setShowNewTaskArea(reduxShowNewArea);
	}, [reduxShowNewArea]);

	useEffect(() => {
		fetchTasks();
	}, [taskManagerVisibility]);

	useEffect(() => {
		if (taskManagerVisibility) {
			setTaskFilter("All tasks");
			setOpportunityIdSearch("");
			setOpportunityNameSearch("");
		}
	}, [taskManagerVisibility]);

	useEffect(() => {
		const today = moment().format("YYYY-MM-DD");
		const thisWeekStart = moment().startOf("week").format("YYYY-MM-DD");
		const thisWeekEnd = moment().endOf("week").format("YYYY-MM-DD");
		const nextWeekStart = moment(thisWeekEnd)
			.add(1, "day")
			.format("YYYY-MM-DD");
		const nextWeekFinish = moment(nextWeekStart)
			.endOf("week")
			.format("YYYY-MM-DD");
		const list = taskList.filter((item) => {
			const isOnSearch = opportunityIdSearch
				? item.opportunity_id === opportunityIdSearch
				: true;
			if (taskFilter === "Due today") {
				return isOnSearch && today === item.due_date;
			} else if (taskFilter === "Due this week") {
				return (
					isOnSearch &&
					item.due_date >= thisWeekStart &&
					item.due_date <= thisWeekEnd
				);
			} else if (taskFilter === "Due next week") {
				return (
					isOnSearch &&
					item.due_date >= nextWeekStart &&
					item.due_date <= nextWeekFinish
				);
			}

			return isOnSearch;
		});

		setFilteredTaskList(list);
	}, [taskFilter, opportunityIdSearch, taskList]);

	const pendingTasks = useMemo(() => {
		return filteredTaskList.filter(
			(item) => item.task_status === "Not Completed",
		).length;
	}, [filteredTaskList]);

	const fetchTasks = () => {
		GetUserTasks().then((res) => {
			setTaskList(
				res.map((item: any) => ({
					...item.data(),
					id: item.id,
				})),
			);
		});
	};

	const updateTaskStatus = (taskId: string, currentStatus: string) => {
		const task_status =
			currentStatus === "Not Completed" ? "Completed" : "Not Completed";

		window.analytics.track("TaskManager_Update_Status", {
			podUserId: localStorage.getItem("podUserId"),
		});

		updateExistingTask(taskId, { task_status });
	};

	const updateTaskOpportunity = (
		taskId: string,
		opportunity_id: string,
		opportunity_name: string,
	) => {
		window.analytics.track("TaskManager_Assign_Opportunity", {
			podUserId: localStorage.getItem("podUserId"),
		});

		updateExistingTask(taskId, { opportunity_id, opportunity_name });
	};

	const addNewTask = (
		newTaskContent: string,
		newTaskDate: string,
		newTaskAssignee: string,
		newTaskOppId: string,
	) => {
		let sfdcCreds = JSON.parse(localStorage.getItem("BaseApp1") || "{}").user;
		setLoader(true);

		window.analytics.track("TaskManger_Create_Task", {
			podUserId: localStorage.getItem("podUserId"),
		});

		CreateTask(
			"Not Completed",
			newTaskOppId,
			newTaskOppName,
			newTaskContent,
			newTaskAssignee,
			newTaskDate,
			sfdcCreds.sfdcEmail,
		).then(() => {
			setLoader(false);
			setShowNewTaskArea("none");
			notification.success({
				message: "New task added",
				placement: "bottomRight",
				duration: 1,
			});
			setNewTaskContent("");
			setNewTaskDate("");
			setNewTaskAssignee("");
			setNewTaskOppId("");
			fetchTasks();
		});
	};

	const updateExistingTask = (taskId: string, updateData: any) => {
		const { id, ...task } = taskList.find(
			(item: Record<string, string>) => item.id === taskId,
		) as any;
		UpdateTask(taskId, {
			...task,
			...updateData,
		}).then(() => {
			fetchTasks();
		});
	};

	const toggleTaskCard = (taskId: string) => {
		if (expandedTasks.includes(taskId)) {
			const array = [...expandedTasks];
			const index = array.indexOf(taskId);
			array.splice(index, 1);
			setExpandedTasks(array);
		} else {
			setExpandedTasks(expandedTasks.concat(taskId));
			const task = taskList.find(
				(item: Record<string, string>) => item.id === taskId,
			);
			if (task?.opportunity_id) {
				setTaskOpportunity({
					...taskOpportunity,
					[taskId]: {
						Name: task.opportunity_name,
						Id: task.opportunity_id,
					},
				});
			}
		}
	};

	const updateTaskListObject = (obj: any, key: string, newText: string) => {
		const array = [...taskList];
		const index = array.indexOf(obj);
		array.splice(index, 1, { ...obj, ...{ [key]: newText } });
		setTaskList(array);
	};

	const deleteTask = (taskId: string) => {
		window.analytics.track("TaskManager_Delete_Task", {
			podUserId: localStorage.getItem("podUserId"),
		});

		DeleteTask(taskId).then(() => {
			fetchTasks();
			notification.success({
				message: "Task successfully updated",
				placement: "bottomRight",
				duration: 1,
			});
		});
	};

	const renderExistingTasksList = () => {
		const today = moment().format("YYYY-MM-DD");
		return filteredTaskList.map((obj: Record<string, string>) => {
			return (
				<>
					<div
						className={
							expandedTasks.includes(obj.id) ? "expandedCard" : "collapsedCard"
						}
						onClick={() => {
							toggleTaskCard(obj.id);
						}}
					>
						<>
							{renderTaskListCheckMark(obj.id, obj.task_status)}
							<input
								className="taskMgrTaskDscr"
								style={
									obj.task_status == "Completed"
										? {
												textDecoration: "line-through",
												top: "26%",
												color: "#7D828C",
										  }
										: { top: "26%" }
								}
								type="text"
								value={obj.content}
								onBlur={(e) => {
									updateExistingTask(obj.id, { content: e.target.value });
								}}
								onChange={(e) => {
									updateTaskListObject(obj, "content", e.target.value);
								}}
								onClick={(e) => e.stopPropagation()}
							/>
							<div onClick={(e) => e.stopPropagation()}>
								<DatePicker
									style={{
										position: "absolute",
										right: "0.6%",
										top: "10%",
									}}
									className={
										obj.due_date < today && obj.task_status === "Not Completed"
											? "overdue-datepicker"
											: ""
									}
									size="small"
									bordered={false}
									placeholder="       Due date"
									value={obj.due_date ? moment(obj.due_date) : null}
									onChange={(_, dateString) => {
										updateExistingTask(obj.id, { due_date: dateString });
									}}
								/>
							</div>
							<span
								style={{ position: "absolute", right: "1%", bottom: "10%" }}
							>
								<ReactSVG src="/images/icons/UserLogo.svg" />
								<Input
									id="existingTaskAssignee"
									size="small"
									placeholder={obj.assignee}
									style={{
										width: "120px",
										zIndex: "2",
										right: "30px",
										bottom: "1px",
										position: "absolute",
										border: "none",
										textAlign: "right",
									}}
									onBlur={(e) => {
										updateExistingTask(obj.id, { assignee: e.target.value });
									}}
									onChange={(e) => {
										updateTaskListObject(obj, "assignee", e.target.value);
									}}
								/>
							</span>
						</>
					</div>
					<div
						style={{
							left: "2.5%",
							display: expandedTasks.includes(obj.id) ? "block" : "none",
							position: "relative",
							width: "95%",
							backgroundColor: "white",
							height: "46px",
							border: "1px solid #F4F6F8",
							borderTop: "none",
						}}
					>
						<span
							style={{
								position: "absolute",
								left: "95.7%",
								top: "6px",
							}}
							onClick={() => deleteTask(obj.id)}
						>
							<ReactSVG src="/images/icons/DeleteButton.svg" />
						</span>
						<span
							style={{
								position: "absolute",
								left: "2%",
								backgroundColor: "#F4F6F8",
								borderRadius: "2px",
							}}
						>
							<ReactSVG src="/images/icons/SFDC-objects/opportunities.svg" />
						</span>
						<div
							style={{
								width: "50%",
								left: "7.5%",
								top: "0",
								position: "absolute",
							}}
						>
							<DropdownSearch
								size="medium"
								currentName={taskOpportunity[obj.id]?.Name}
								currentId={taskOpportunity[obj.id]?.Id}
								objectType={"Opportunity"}
								UpdateTextOrNumberType={(
									Id: string,
									_: unknown,
									Name: string,
								) => {
									updateTaskOpportunity(obj.id, Id, Name);
									setTaskOpportunity({
										...taskOpportunity,
										[obj.id]: {
											Id,
											Name,
										},
									});
								}}
							/>
						</div>
					</div>
					<div style={{ height: "5px" }}></div>
				</>
			);
		});
	};

	const renderNewTaskArea = () => {
		if (showNewTaskArea) {
			return (
				<div
					style={{
						border: "0px solid #E0E0E0",
						backgroundColor: "white",
						width: "100%",
						// left: "2.5%",
						height: "140px",
						display: showNewTaskArea,
					}}
				>
					<div
						style={{
							backgroundColor: "#EFEFFD",
							position: "relative",
							width: "95%",
							left: "2.5%",
							top: "10px",
							height: "120px",
						}}
					>
						<textarea
							style={{
								position: "relative",
								left: "2%",
								top: "10px",
								width: "75%",
							}}
							className="newTaskTextArea"
							placeholder="Type your task here..."
							value={newTaskContent}
							onChange={(event) => {
								setNewTaskContent(event.target.value);
							}}
						></textarea>
						<DatePicker
							style={{ position: "absolute", top: "5%", right: "0.6%" }}
							bordered={false}
							placeholder="    Due date"
							value={newTaskDate ? moment(newTaskDate) : null}
							onChange={(_, dateString) => {
								setNewTaskDate(dateString);
							}}
						/>

						<span
							style={{
								position: "absolute",
								left: "2%",
								// bottom: '8px',
								top: "63%",
								backgroundColor: "#F4F6F8",
								borderRadius: "2px",
							}}
						>
							<ReactSVG src="/images/icons/SFDC-objects/opportunities.svg" />
						</span>
						<input
							className="newTaskAssignee"
							placeholder="Assignee"
							style={{
								textAlign: "right",
								fontSize: "14px",
							}}
							value={newTaskAssignee}
							onChange={(e) => {
								setNewTaskAssignee(e.target.value);
							}}
						/>
						<span
							style={{
								position: "absolute",
								right: "1.5%",
								top: "32.5%",
							}}
						>
							<ReactSVG src="/images/icons/UserLogo.svg" />
						</span>
						<div
							style={{
								width: "50%",
								left: "7.5%",
								top: "63%",
								position: "absolute",
							}}
						>
							<DropdownSearch
								size="medium"
								currentName={newTaskOppName}
								currentId={newTaskOppId}
								objectType={"Opportunity"}
								UpdateTextOrNumberType={(item: any, _: any, name: string) => {
									setNewTaskOppId(item);
									setNewTaskOppName(name);
								}}
							/>
						</div>
						<button
							style={{
								backgroundColor: "transparent",
								border: "1px solid #00AA66",
								width: "11.5%",
								height: "32px",
								top: "63%",
								position: "absolute",
								left: "73.5%",
								borderRadius: "3px",
								borderColor: "#5000A2",
								overflow: "hidden",
							}}
							onClick={() => {
								setShowNewTaskArea("none");
							}}
						>
							Cancel
						</button>
						<Button
							loading={loader}
							style={{
								backgroundColor: "#5000A2",
								color: "white",
								border: "1px solid #00AA66",
								width: "11.5%",
								height: "32px",
								top: "63%",
								position: "absolute",
								right: "2.3%",
								borderRadius: "3px",
								borderColor: "#5000A2",
								overflow: "hidden",
							}}
							onClick={() =>
								addNewTask(
									newTaskContent,
									newTaskDate,
									newTaskAssignee,
									newTaskOppId,
								)
							}
						>
							Save
						</Button>
					</div>
				</div>
			);
		}
	};

	const renderTaskListCheckMark = (key: any, taskStatus: any) => {
		if (taskStatus == "Not Completed") {
			return (
				<span
					onClick={(e) => {
						e.stopPropagation();
						updateTaskStatus(key, taskStatus);
					}}
					style={{ position: "absolute", left: "2%", top: "20%" }}
				>
					<ReactSVG src="/images/icons/UncheckedTask.svg" />
				</span>
			);
		} else {
			return (
				<span
					onClick={(e) => {
						e.stopPropagation();
						updateTaskStatus(key, taskStatus);
					}}
					style={{ position: "absolute", left: "1.5%", top: "20%" }}
				>
					<ReactSVG src="/images/icons/CheckedTask.svg" />
				</span>
			);
		}
	};

	return (
		<Drawer
			contentWrapperStyle={{
				marginLeft: 12.5,
				width: "60vw",
				height: "65vh",
				minWidth: "750px",
				borderRadius: "4px 4px 0px 0px",
				backgroundColor: "white",
			}}
			closable={false}
			mask={false}
			placement="bottom"
			destroyOnClose={true}
			visible={taskManagerVisibility === "visible"}
			onClose={() => {
				dispatch({
					type: "CLOSE_TASK_MANAGER",
				});
			}}
		>
			<div
				style={{
					width: "100%",
					height: "100%",
				}}
			>
				<div
					style={{
						paddingTop: "7px",
						paddingLeft: "15px",
						backgroundColor: "#EFEFFD",
						height: "40px",
						width: "100%",
						zIndex: "5",
						position: "sticky",
						top: "0",
						borderBottom: "2px solid #5000A2",
					}}
					onClick={() => {
						dispatch({
							type: "CLOSE_TASK_MANAGER",
						});
					}}
				>
					<b>Task Manager</b>
					<span
						style={{
							position: "absolute",
							right: "2%",
							// backgroundColor: "#F4F6F8",
							borderRadius: "2px",
						}}
					>
						<ReactSVG src="/images/icons/collapseTM.svg" />
					</span>
					{pendingTasks ? (
						<div
							style={{
								position: "absolute",
								width: 80,
								height: 14,
								backgroundColor: "#FFEC86",
								borderRadius: 100,
								fontSize: "10px",
								lineHeight: "14px",
								top: 12,
								left: 120,
								textAlign: "center",
							}}
						>
							{pendingTasks} Pending
						</div>
					) : null}
				</div>
				<div style={{ height: "65px" }}>
					<div
						className="pip_table_extras_select me-3 ms-1"
						style={{
							top: "60px",
							position: "absolute",
							left: "calc(2.5% - 4px)",
						}}
					>
						<Select
							size="middle"
							value={taskFilter}
							className="custom_select"
							onChange={(value: string) => setTaskFilter(value)}
						>
							{taskFilters.map((item) => (
								<Select.Option key={item} value={item}>
									{item}
								</Select.Option>
							))}
						</Select>
					</div>

					<span
						style={{
							position: "absolute",
							right: "calc(15% + 214px)",
							backgroundColor: "#F4F6F8",
							borderRadius: "2px",
							top: 60,
						}}
					>
						<ReactSVG src="/images/icons/SFDC-objects/opportunities.svg" />
					</span>

					<div
						className="pip_table_extras_search"
						style={{
							top: "60px",
							position: "absolute",
							right: "15%",
							width: 204,
						}}
					>
						<DropdownSearch
							size="medium"
							currentName={opportunityNameSearch}
							currentId={opportunityIdSearch}
							objectType={"Opportunity"}
							UpdateTextOrNumberType={(
								Id: string,
								_: unknown,
								Name: string,
							) => {
								setOpportunityIdSearch(Id);
								setOpportunityNameSearch(Name);
							}}
							allowClear
						/>
					</div>
					<button
						style={{
							backgroundColor: "transparent",
							border: "1px solid #00AA66",
							width: "11.5%",
							height: "32px",
							top: "60px",
							position: "absolute",
							right: "2.5%",
							borderRadius: "3px",
							borderColor: "#5000A2",
							overflow: "hidden",
						}}
						onClick={() => {
							setShowNewTaskArea(showNewTaskArea == "block" ? "none" : "block");
						}}
					>
						+ Task
					</button>
				</div>
				<div
					style={{
						height: "calc(100% - 105px)",
						overflowY: "auto",
						paddingBottom: 20,
					}}
				>
					{renderNewTaskArea()}
					{renderExistingTasksList()}
				</div>
			</div>
		</Drawer>
	);
};
