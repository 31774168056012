import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GetRelatedOpportunities } from "../../../Apis/Pipeline/GetRelatedOpportunities";
import { ArrayObjectStringFilter } from "../../../Utils/Index";
import { PipelineStageRenderComponent } from "../../Pipeline/PipelineStageRenderComponent";

export const PipelineBulkStageRenderComponent = ({
	initData,
	stages,
	UpdateTextOrNumberType,
	SearchInRelatedOpp,
}: {
	UpdateTextOrNumberType?: any;
	stages: any;
	initData: any;
	SearchInRelatedOpp?: any;
}) => {
	const dispatch = useDispatch();
	const { stamp } = useSelector((state: any) => state.pipeline);
	const [oppStagesReadOnly, setOppStagesReadOnly] = useState<any>([]);
	const [oppStages, setOppStages] = useState<any>([]);

	const relatedOpportunity = () => {
		GetRelatedOpportunities(initData.objectType, initData.objectId).then(
			(res: any) => {
				setOppStagesReadOnly([...res.opportunities]);
				setOppStages([...res.opportunities]);
			},
		);
	};

	useEffect(() => {
		relatedOpportunity();
	}, [stamp]);

	useEffect(() => {
		setOppStages([
			...ArrayObjectStringFilter(oppStagesReadOnly, SearchInRelatedOpp),
		]);
	}, [SearchInRelatedOpp]);

	const changeStep = (value: any, o: any) => {
		UpdateTextOrNumberType(value, {
			objectDataKey: "StageName",
			objectType: "opportunity",
			id: o.objectId,
		});
	};

	const openOpportunity = (objectId: string) => {
		// TODO swap out for an action creator like everything else...
		dispatch({
			type: "OPEN_DRAWER",
			payload: {
				drawerType: "pipeline",
				objectType: "Opportunity",
				objectId,
			},
		});
	};

	return oppStages.length ? (
		<div className="pod_opp_list">
			{oppStages.map((opp: any, index: number) => (
				<div key={index} className="pod_opp_list_each">
					<div
						style={{ cursor: "pointer" }}
						className="pod_opp_list_each--title"
						onClick={() => openOpportunity(opp.Id)}
					>
						{opp.Name}
					</div>
					<div className="pod_opp_list_each--stage">
						<div>Stage</div>
						<span className="d-inline-block pt-2 pb-1">
							<PipelineStageRenderComponent
								value={opp.StageName}
								stages={stages}
								changeInStage={changeStep}
								updateData={{ objectType: "opportunity", objectId: opp.Id }}
								recordTypeId={opp?.RecordTypeId}
							/>
						</span>
						<div>{opp.StageName}</div>
					</div>
				</div>
			))}
		</div>
	) : (
		<div className="p-3 text-center">No related opportunities</div>
	);
};
