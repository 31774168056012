import EmailIcon from "../../Images/Email.svg";
import "./FooterComponent.styles.scss";

const FooterComponent = () => (
	<div
		className="welcome-row"
		style={{
			flexShrink: "0",
			width: "92%",
			justifyContent: "space-between",
			margin: "0 0 1.3% 4%",
		}}
	>
		<span style={{ width: "max-content", height: "max-content" }}>
			<a
				id="help-email"
				href="mailto: help@workwithpod.com"
				style={{
					margin: "0 5px 0 5px",
					fontSize: "12px",
					height: "12px",
					letterSpacing: "0.004em",
					color: "#1B1B1C",
				}}
			>
				<img
					src={EmailIcon}
					style={{ width: "20px", marginRight: "5px" }}
					alt=""
				/>
				help@workwithpod.com
			</a>
		</span>
		<p
			style={{
				width: "max-content",
				fontSize: "12px",
				marginTop: "4px",
				letterSpacing: "0.004em",
				color: "#1B1B1C",
			}}
		>
			Pod Technologies, Inc. © 2022
		</p>
	</div>
);

export default FooterComponent;
