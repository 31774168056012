import "./Styles/custom-antd.css";
import "./Styles/Index.scss";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import {
	PrivateRoute,
	PrivateComponent,
	ValidatedRoute,
} from "./Components/Routes/ProtectedRoute";
import PageNotFound from "./Pages/PageNotFound";
import PipelineViewPage from "./Pages/PipelineViewPage";
import { SFDCCallbackHandler } from "./Utils/SFDCCallbackHandler";
import CalendarViewPage from "./Pages/CalendarViewPage";
import { ActivityHub } from "./Pages/ActivityHub";
import { DocumentHubNotesPage } from "./DocumentHub/DocumentHubNotesPage";
import DocumentHubSummaryPage from "./DocumentHub/DocumentHubSummaryPage";
import { TaskManager } from "./TaskManager";
import { init } from "commandbar";
import WelcomePage from "./Pages/WelcomeFlow/WelcomePage";
import { OnboardPage } from "./Pages/WelcomeFlow/OnboardPage";
import { DrawerList } from "./Components/Drawer/DrawerList";
init("de66c7e9");

declare global {
	interface Window {
		analytics: SegmentAnalytics.AnalyticsJS;
	}
}

export default () => {
	return (
		<div className="App" style={{ overflow: "auto", height: "100vh" }}>
			<BrowserRouter>
				<DrawerList />
				<PrivateComponent>
					<TaskManager />
				</PrivateComponent>
				<Routes>
					<Route path="*" element={<PageNotFound />} />
					<Route path="/" element={<Navigate to="/signup" replace />} />
					<Route path="/signup" element={<WelcomePage isSignup={true} />} />
					<Route path="/signin" element={<WelcomePage />} />
					<Route
						path="/impersonate"
						element={<WelcomePage isImpersonate={true} />}
					/>
					<Route
						path="/onboard/:step"
						element={
							<ValidatedRoute regExps={{ step: /^[1-3]$/ }}>
								<OnboardPage />
							</ValidatedRoute>
						}
					/>
					<Route path="/sfdc-callback-url" element={<SFDCCallbackHandler />} />
					<Route
						path="/dashboard/calendar-view"
						element={
							<PrivateRoute>
								<CalendarViewPage />
							</PrivateRoute>
						}
					/>
					<Route
						path="/document-hub/notes-page/:docId"
						element={
							<PrivateRoute>
								<DocumentHubNotesPage />
							</PrivateRoute>
						}
					/>
					<Route
						path="/document-hub/summary-page"
						element={
							<PrivateRoute>
								<DocumentHubSummaryPage />
							</PrivateRoute>
						}
					/>
					<Route
						path="/dashboard/pipeline-view"
						element={
							<PrivateRoute>
								<PipelineViewPage />
							</PrivateRoute>
						}
					/>
					<Route
						path="/dashboard/activity-hub"
						element={
							<PrivateRoute>
								<ActivityHub />
							</PrivateRoute>
						}
					/>
				</Routes>
			</BrowserRouter>
		</div>
	);
};
