import api from "./api";
import Store from "../Store/Store";
import { Filter, isFilterActive } from "../Components/Pipeline/MasterFilter";
import { Field } from "./RecordTypesAndStages";

const parseValue = (value: string, type: string, operator: string) => {
	value = value.replaceAll(/'/g, "\\'");
	if (["like", "notLike", "includes", "notInclude"].includes(operator))
		return [`\'%${value}%\'`];
	else if (["startsWith"].includes(operator)) return [`\'${value}%\'`];
	else if (["reference", "id"].includes(type)) return [`\'${value}\'`];
	return [value];
};

export const GetSFDCObjectV2 = async ({
	changeURLKey,
	searchText,
	pageSize = 0,
	pageNum,
	customFilters,
	customFields,
	ignoreFilters,
}: {
	changeURLKey: string;
	searchText?: string;
	pageSize?: number;
	pageNum?: number;
	customFilters?: Filter[];
	customFields?: Field[];
	ignoreFilters?: boolean;
}) => {
	const filters = Store.getState().pipeline.filters[changeURLKey];
	const field_specific_filters = filters
		.filter((filter: any) => isFilterActive(filter))
		.map(({ refObjectName, ...item }: any) => {
			return {
				...item,
				values: parseValue(item.values[0], item.type, item.operator),
			};
		});
	// TODO have a fn to easily manipulate filters in LS, to be used at call site.
	if (customFilters) {
		for (let customFilter of customFilters) {
			const index: number = field_specific_filters.findIndex(
				(element: { field: string | null }) =>
					element.field == customFilter.field,
			);
			if (index != -1) {
				field_specific_filters[index] = customFilter;
			} else {
				field_specific_filters.push(customFilter);
			}
		}
	}

	const res = await api.post(`/get_sfdc_${changeURLKey}_v2`, {
		list_view_id: JSON.parse(
			localStorage.getItem("pipelineViewMasterFilter") || "{}",
		)[changeURLKey],
		field_specific_filters: ignoreFilters ? [] : field_specific_filters,
		sort_order: JSON.parse(localStorage.getItem("sortingOrder") || "{}")[
			changeURLKey
		],
		page_num: pageNum || Number(localStorage.getItem("pageNum")),
		search_text: searchText ?? localStorage.getItem("searchText"),
		pod_user_id: Number(localStorage.getItem("podUserId")),
		page_size: pageSize || 25,
		custom_fields: customFields,
	});
	return res.data;
};
