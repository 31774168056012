import { Rate } from "antd";
import React from "react";
import { BsFillCircleFill } from "react-icons/bs";

export const PipelineStageRenderComponent = ({
	value,
	stages,
	updateData,
	changeInStage,
	recordTypeId,
}: {
	value: string;
	stages: any;
	updateData: any;
	changeInStage: any;
	recordTypeId?: any;
}) => {
	let stagesData: any;
	if (recordTypeId != null) {
		const stagesForRecordTypes: any = JSON.parse(
			localStorage.getItem("stagesForRecordTypes") || "[]",
		);
		const dataForRecordType: any = stagesForRecordTypes.filter((obj: any) =>
			Object.keys(obj).includes(recordTypeId),
		);
		let data: any = [];
		if (dataForRecordType.length > 0) {
			data = dataForRecordType[0][recordTypeId];
		}
		const labels: any = data.map((obj: any) => obj.label);
		stagesData = stages.filter(
			(obj: any) => labels.includes(obj.label) || labels.includes(obj.name),
		);
	} else {
		stagesData = stages;
	}

	return (
		<div className="pip_col_stage">
			<Rate
				onChange={(value: any) => {
					changeInStage(stagesData[value - 1].value, updateData);
				}}
				character={<BsFillCircleFill size={14} />}
				value={
					stagesData?.findIndex(
						(x: any) => x.value === value || x.name === value,
					) + 1
				}
				count={stagesData.length || 0}
				tooltips={stagesData.map(({ value, name }: any) => value || name)}
			/>
		</div>
	);
};
