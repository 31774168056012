import { useState } from "react";
import { Menu, Dropdown, notification, Tabs } from "antd";
import { DeleteTemplate } from "../../../Apis/Firebase/DeleteTemplate";
import { useNavigate } from "react-router-dom";
import { CreateDocument } from "../../../Apis/Firebase/CreateDocument";
import TemplateList from "./List";

import "./style.scss";

const { TabPane } = Tabs;

interface Props {
	templates: any[];
	sharedTemplates: any[];
	onClick: (item: any) => void;
	onShare: (id: string, shared?: boolean) => void;
	children: any;
	refresh: () => void;
}

const menu = (
	templates: any[],
	defaultSharedTemplates: any[],
	onClick: (item: any) => void,
	handleShare: (id: string, shared?: boolean) => void,
	handleEdit: (item: any) => void,
	handleDelete: (id: string) => void,
) => {
	const sharedTemplates = defaultSharedTemplates.filter(
		(item) => !templates.find((template) => template.id === item.id),
	);
	return (
		<Menu className="actions-dropdown-container templates-container">
			<Tabs defaultActiveKey="my-templates">
				<TabPane tab="My Templates" key="my-templates">
					<TemplateList
						data={templates}
						onSelect={onClick}
						onCreate={() => onClick("Create")}
						onShare={handleShare}
						onEdit={handleEdit}
						onDelete={handleDelete}
					/>
				</TabPane>
				<TabPane tab="Shared Templates" key="shared-templates">
					<TemplateList
						data={sharedTemplates}
						onSelect={onClick}
						showAction={false}
						onCreate={() => onClick("Create")}
					/>
				</TabPane>
			</Tabs>
		</Menu>
	);
};

const TemplatesDropdown = ({
	templates,
	sharedTemplates,
	onClick,
	onShare,
	children,
	refresh,
}: Props) => {
	const navigate = useNavigate();
	const [visible, setVisible] = useState(false);

	const handleClick = (item: any) => {
		setVisible(false);
		if (item === "Create") {
			onClick({
				id: "",
				data: {},
			});
		} else {
			CreateDocument("", item.body).then((res) => {
				window.analytics.track("DocumentHub_Create_TemplatedDocument", {
					podUserId: localStorage.getItem("podUserId"),
				});

				navigate(`/document-hub/notes-page/${res}`);
			});
		}
	};

	const handleEdit = (item: any) => {
		setVisible(false);
		onClick({
			id: item.id,
			data: { ...item },
		});
	};

	const handleDelete = (id: string) => {
		DeleteTemplate(id).then(() => {
			refresh();
			notification.success({
				message: "Template deleted successfully",
				placement: "bottomRight",
				duration: 1,
			});
		});
	};

	return (
		<Dropdown
			overlay={menu(
				templates,
				sharedTemplates,
				handleClick,
				onShare,
				handleEdit,
				handleDelete,
			)}
			trigger={["click"]}
			visible={visible}
			onVisibleChange={setVisible}
		>
			{children}
		</Dropdown>
	);
};

export default TemplatesDropdown;
