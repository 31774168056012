import { useState } from "react";
import { Button, Form } from "antd";
import { ReactSVG } from "react-svg";
import { EmailTypes } from "../../../Apis/EmailGen/emailGen";
import { effectiveLabelValue } from "../../../Utils/Index";
import { FieldSelector } from "../../FieldSelector";

const { Item, useForm } = Form;

type Field = {
	key: string;
	title: string;
	type: string;
	picklistValues?: Record<string, unknown>[];
	required: boolean;
	objectType?: string;
	subItem?: boolean;
	placeholder?: string;
};

const fieldsByEmailType: { [key in EmailTypes]: Field[] } = {
	Internal: [
		{
			key: "tone",
			title: "Tone",
			type: "button-select",
			picklistValues: [
				{ value: "friendly", label: "Friendly" },
				{ value: "formal", label: "Formal" },
				{ value: "informal", label: "Casual" },
				{ value: "assertive", label: "Assertive" },
				{ value: "inquisitive", label: "Inquisitive" },
			],
			required: true,
		},
		{
			key: "comments",
			title: "Comments",
			type: "textarea",
			placeholder: "Enter text here",
			required: false,
		},
		{
			key: "callToAction",
			title: "What is your call-to-action?",
			type: "textarea",
			placeholder: "Enter text here",
			required: false,
		},
	],
	External: [
		{
			key: "tone",
			title: "Tone",
			type: "button-select",
			picklistValues: [
				{ value: "friendly", label: "Friendly" },
				{ value: "formal", label: "Formal" },
				{ value: "informal", label: "Casual" },
				{ value: "assertive", label: "Assertive" },
				{ value: "inquisitive", label: "Inquisitive" },
			],
			required: true,
		},
		{
			key: "recipient",
			title: "Recipient",
			type: "reference",
			objectType: "Contact",
			required: true,
		},
		{
			key: "callToAction",
			title: "What is your call-to-action?",
			type: "textarea",
			placeholder: "Enter text here",
			required: false,
		},
	],
	Outreach: [
		{
			key: "tone",
			title: "Tone",
			type: "button-select",
			picklistValues: [
				{ value: "friendly", label: "Friendly" },
				{ value: "formal", label: "Formal" },
				{ value: "informal", label: "Casual" },
				{ value: "assertive", label: "Assertive" },
				{ value: "inquisitive", label: "Inquisitive" },
			],
			required: true,
		},
		{
			key: "recipient",
			title: "Recipient",
			type: "reference",
			objectType: "Contact",
			required: true,
		},
		{
			key: "context",
			title: "Context for outreach",
			type: "textarea",
			required: true,
			placeholder: "Enter text here",
		},
		{
			key: "painPoint",
			title: "What is the outlined pain point?",
			type: "textarea",
			required: true,
			subItem: true,
			placeholder: "Enter text here",
		},
		{
			key: "productName",
			title: "What is the name of your product?",
			type: "textarea",
			required: true,
			placeholder: "Enter text here",
		},
		{
			key: "productDescription",
			title: "Enter a one-line description of the product",
			type: "textarea",
			required: true,
			subItem: true,
			placeholder: "Enter text here",
		},
		{
			key: "callToAction",
			title: "What is your call-to-action?",
			type: "textarea",
			placeholder: "Enter text here",
			required: false,
		},
	],
	FollowUp: [
		{
			key: "tone",
			title: "Tone",
			type: "button-select",
			picklistValues: [
				{ value: "friendly", label: "Friendly" },
				{ value: "formal", label: "Formal" },
				{ value: "informal", label: "Casual" },
				{ value: "assertive", label: "Assertive" },
				{ value: "inquisitive", label: "Inquisitive" },
			],
			required: true,
		},
		{
			key: "recipient",
			title: "Recipient",
			type: "reference",
			objectType: "Contact",
			required: true,
		},
		{
			key: "introOrRecap",
			title: "Introduction / Recap from last meeting",
			type: "textarea",
			required: true,
			placeholder: "Enter text here",
		},
		{
			key: "nextSteps",
			title: "Next steps",
			type: "textarea",
			required: true,
			placeholder: "Enter text here",
		},
		{
			key: "callToAction",
			title: "What is your call-to-action?",
			type: "textarea",
			placeholder: "Enter text here",
			required: false,
		},
	],
	General: [
		{
			key: "tone",
			title: "Tone",
			type: "button-select",
			picklistValues: [
				{ value: "friendly", label: "Friendly" },
				{ value: "formal", label: "Formal" },
				{ value: "informal", label: "Casual" },
				{ value: "assertive", label: "Assertive" },
				{ value: "inquisitive", label: "Inquisitive" },
			],
			required: true,
		},
		{
			key: "recipient",
			title: "Recipient",
			type: "reference",
			objectType: "Contact",
			required: true,
		},
		{
			key: "notes",
			title: "Bullet point notes/commands",
			type: "textarea",
			required: true,
			placeholder: "Enter text here",
		},
	],
};

export const GenerateEmailForm = ({
	emailType,
	onSubmit,
}: {
	emailType: EmailTypes;
	onSubmit: (values: { [key: string]: unknown }) => void;
}) => {
	const [form] = useForm();
	const [values, setValues] = useState<any>({});

	return (
		<Form layout="vertical" onFinish={onSubmit} form={form} scrollToFirstError>
			{fieldsByEmailType[emailType].flatMap((field) => {
				return (
					<Item
						key={field.key}
						name={field.key}
						label={effectiveLabelValue(field.title, field.type)}
						rules={[
							{
								required: field.required,
								message: `Please enter ${field.title}`,
							},
						]}
						className={field?.subItem ? "sub-item" : undefined}
					>
						<FieldSelector
							type={field.type}
							refObjectName=""
							currentValue={values[field.key]}
							objectType={field?.objectType || ""}
							updateValue={(value: unknown, name?: string) => {
								setValues((prev: any) => ({ ...prev, [field.key]: value }));
								form.setFieldsValue({
									[field.key]: field.type === "reference" ? name : value,
								});
							}}
							picklist={field?.picklistValues || []}
							size="large"
							placeholder={field?.placeholder}
						/>
					</Item>
				);
			})}

			<Button
				htmlType="submit"
				type="primary"
				icon={<ReactSVG src="/images/icons/FileSummary.svg" />}
			>
				Generate Email
			</Button>
		</Form>
	);
};
