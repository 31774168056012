import api from "./api";

export const SyncEmailFromGmailToSfdc = async (
	subject: string,
	description: string,
	participants: Array<string>,
	date: string,
	from_address: string,
	to_address: string,
	opportunity_id: string,
	task_subtype: string,
	reference_id: string,
) => {
	return api
		.post("/sync_email_from_gmail_to_sfdc", {
			pod_user_id: Number(localStorage.getItem("podUserId")),
			subject,
			description,
			participants,
			date,
			from_address,
			to_address,
			opportunity_id,
			task_subtype,
			reference_id,
		})
		.then((res) => res.data);
};
