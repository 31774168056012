import { initStore } from "../Store/Store";
import { clearPipeline } from "../Store/ActionReducerPairs/Pipeline";
import { logoutUser } from "../Store/ActionReducerPairs/User";

initStore(); // TODO removing this gives white screen of death due to an import graph issue.

export const clearAllLocalState = () => {
	localStorage.clear();
	logoutUser({});
	clearPipeline({});
};

export const UserLogout = () => {
	clearAllLocalState();
	setTimeout(() => {
		window.location.pathname = "/signin";
	}, 100);
};
