import {
	Drawer,
	Select,
	Button,
	Input,
	DatePicker,
	Row,
	Col,
	TimePicker,
	notification,
} from "antd";
import { useState, useEffect } from "react";
import { ReactSVG } from "react-svg";
import { AiOutlineEnvironment } from "react-icons/ai";
import { BsClock } from "react-icons/bs";
import moment from "moment";
import "react-initials-avatar/lib/ReactInitialsAvatar.css";
import "./EventDrawer.styles.scss";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { API_FETCH } from "../../../Utils/Index";
import { DropdownSearch } from "../../DropdownSearchV2";
import { CreateGcalEvent } from "../../../Apis/CreateGcalEvent";
import { refreshCalendar } from "../../../Store/ActionReducerPairs/ActivityHub";
import { DrawerProps } from "../DrawerList";

export const NewEventDrawer = ({ visible, onClose, initData }: DrawerProps) => {
	const { opportunityId, opportunityName } = initData as {
		opportunityId: string;
		opportunityName: string;
	};
	const myHeaders = new Headers();
	myHeaders.append("Content-Type", "application/json");

	const { Option } = Select;
	const [contactOptions, setContactOptions] = useState<any>([]);
	const [, setAttendeeList] = useState<any>([]);
	const [selectedOppId, setSelectedOppId] = useState("");
	const [selectedOppName, setSelectedOppName] = useState("");

	useEffect(() => {
		if (opportunityId && opportunityName) {
			setSelectedOppId(opportunityId);
			setSelectedOppName(opportunityName);
		}
	}, [opportunityId, opportunityName]);

	const [state, setState] = useState<any>({
		eventId: null,
		summary: "",
		location: "",
		eventDate: moment(),
		phoneNumber: "",
		allDay: false,
		description: "",
		startTime: moment(),
		endTime: moment().add("15", "minutes"),
		addGoogleMeetLink: false,
		attendees: "",
	});

	const handleInputChange: any = (e: any) => {
		let { name, value } = e.target;

		setState({
			...state,
			[name]: value,
		});
	};

	const handleDateChange: any = (date: any, dateString: any) => {
		setState({
			...state,
			eventDate: moment(dateString),
		});
	};

	const addGoogleLink = (googleLink: string) => {
		if (googleLink) {
			return (
				<div>
					<p
						style={{
							color: "#3674E6",
						}}
					>
						Connect with google meet
					</p>
					<p
						style={{
							color: "#3674E6",
						}}
					>
						{googleLink}
					</p>
				</div>
			);
		}

		return (
			<div>
				{!state.addGoogleMeetLink ? (
					<Button
						className="ant-btn-primary add_meet_link_btn w-100 w-full"
						onClick={() => setState({ ...state, addGoogleMeetLink: true })}
					>
						{" "}
						Add Google meet video conference link
					</Button>
				) : (
					<Button className="ant-btn-primary add_meet_link_btn w-100 w-full">
						{" "}
						Added
					</Button>
				)}
			</div>
		);
	};

	const googleLink: string = "";
	const handlePopUpMessage = (event: any) => {
		console.log(event);
	};

	const onChangeTime: any = (time: any) => {
		setState({
			...state,
			startTime: time[0],
			endTime: time[1],
		});
	};

	const onChangeAllDay: any = (event: any) => {
		console.log(event.target.value);
		setState({
			...state,
			allDay: !state.allDay,
		});
	};

	const handleSubmit = () => {
		const state2: any = {
			...state,
			startTime: moment
				.utc(state.startTime)
				.zone(new Date().getTimezoneOffset())
				.format(),
			endTime: moment
				.utc(state.endTime)
				.zone(new Date().getTimezoneOffset())
				.format(),
		};

		CreateGcalEvent(state2, selectedOppId)
			.then(() => {
				notification.success({
					message: "New meeting scheduled",
					placement: "bottomRight",
					duration: 1,
				});
			})
			.finally(() => {
				setTimeout(() => {
					refreshCalendar({});
					onClose();
				}, 500);
			});

		window.analytics.track("CalendarView_Create_Meeting", {
			podUserId: localStorage.getItem("podUserId"),
		});
	};

	function handleChangeParticipants(value: any) {
		setState({
			...state,
			attendees: value,
		});
	}

	useEffect(() => {
		if (state.eventId !== null) {
			console.log("newAttendees", state.attendees);
			let arr: any = [];
			state &&
				state.attendees &&
				state.attendees.map((attendee: any) => {
					arr.push(attendee.email);
				});
			setAttendeeList(arr);
		}
	}, [state.eventId]);

	useEffect(() => {
		const body = {
			pod_user_id: Number(localStorage.getItem("podUserId")),
		};

		const requestOptions: any = {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify(body),
		};

		state.eventId == null &&
			fetch(API_FETCH("/contacts_for_gcal_event"), requestOptions)
				.then((res: any) => res.json())
				.then((res: any) => {
					if (res && res.data && res.data.length) {
						let children: any = [];
						res.data.map((contact: any) => {
							return children.push(
								<Option value={contact.Email} key={contact.Id}>
									{contact.Name}
								</Option>,
							);
						});
						setContactOptions(children);
					}
				});
	}, []);

	useEffect(() => {
		window.addEventListener("message", handlePopUpMessage);
		return () => {
			window.removeEventListener("message", handlePopUpMessage);
		};
	}, []);

	return (
		<Drawer
			title=""
			placement="right"
			width={400}
			onClose={onClose}
			visible={visible}
			style={{
				marginTop: "64px",
			}}
			className="calendar_drawer_wrapper"
		>
			<div className="pod_drawer calendar_drawer">
				<div className="pod_drawer_head edit_drawer_head">
					<div className="pod_drawer_head--title d-flex justify-content-space-between">
						<div
							style={{
								width: "100%",
								height: "auto",
							}}
						>
							<Input
								className="placeholder_event_title"
								placeholder="Enter Name here...."
								size="large"
								bordered={false}
								value={state.summary}
								name="summary"
								style={{
									borderBottom: "1px solid #C4C4C4",
									padding: "8px",
								}}
								onChange={(e) => handleInputChange(e)}
							/>
						</div>
					</div>
					<div
						className="pod_drawer_head--subtitle pt-1"
						style={{ paddingLeft: "0" }}
					>
						<Row style={{ alignItems: "center" }}>
							<Col span={2}>
								<ReactSVG
									className="crown-icon"
									src="/images/icons/SFDC-objects/opportunities.svg"
								/>
							</Col>
							<Col span={15} style={{ marginLeft: 10 }}>
								<DropdownSearch
									currentId={selectedOppId}
									currentName={selectedOppName}
									objectType="Opportunity"
									UpdateTextOrNumberType={(
										id: string,
										_: unknown,
										name: string,
									) => {
										setSelectedOppId(id);
										setSelectedOppName(name);
									}}
									size="middle"
								/>
							</Col>
						</Row>
					</div>
				</div>
				<div className="pod_drawer_meeting_details_area fixed_area_drawer_details">
					<div className="row meeting_date_time_container">
						<div className="col-md-1">
							<BsClock
								style={{
									alignSelf: "center",
									width: "18px",
									height: "18px",
								}}
							/>
						</div>
						<div className="col-md-5">
							<DatePicker
								bordered={false}
								value={state.eventDate}
								name="eventDate"
								format={"YYYY-MM-DD"}
								className="datepicker"
								onChange={(date, dateString) =>
									handleDateChange(date, dateString)
								}
							/>{" "}
						</div>
						<div className="col-md-6 p-0 d-flex justify-content-end">
							<TimePicker.RangePicker
								bordered={true}
								format={"HH:mm"}
								value={[state.startTime, state.endTime]}
								onChange={onChangeTime}
							/>
						</div>
						<div className="col-md-12 pt-3 pb-1">
							<Checkbox
								checked={state.allDay}
								onChange={(e) => onChangeAllDay(e)}
							>
								All Day
							</Checkbox>
						</div>
					</div>
					<div className="row meeting_date_time_container">
						<div className="col-md-2">
							<ReactSVG
								src="/images/icons/google-meet.svg"
								style={{
									alignSelf: "center",
									width: "20px",
									height: "20px",
								}}
							/>
						</div>
						<div className="col-md-10 p-0 ">
							<div>{addGoogleLink(googleLink)}</div>
						</div>
					</div>
					<div className="row meeting_date_time_container">
						<div className="col-md-2">
							<ReactSVG
								src="/images/icons/phone.svg"
								style={{
									alignSelf: "center",
									width: "20px",
									height: "20px",
								}}
							/>
						</div>
						<div className="col-md-10 p-0 d-flex justify-content-end">
							<Input
								placeholder="Add Phone Number"
								value={state.phoneNumber}
								size="large"
								name="phoneNumber"
								style={{
									backgroundColor: "#F5F5F5",
									fontSize: "14px",
								}}
								onChange={(e) => handleInputChange(e)}
							/>
						</div>
					</div>
					<div className="row meeting_date_time_container">
						<div className="col-md-2">
							<AiOutlineEnvironment
								style={{
									alignSelf: "center",
									width: "20px",
									height: "20px",
								}}
							/>
						</div>
						<div className="col-md-10 p-0 d-flex justify-content-end p-0 d-flex justify-content-end">
							<Input
								placeholder="Add Location"
								size="large"
								value={state.location}
								style={{
									backgroundColor: "#F5F5F5",
									fontSize: "14px",
									color: "333333",
								}}
								name="location"
								onChange={(e) => handleInputChange(e)}
							/>
						</div>
					</div>
					<div className="row meeting_date_time_container">
						<div className="col-md-12 p-0 d-flex justify-content-end">
							<Input.TextArea
								rows={2}
								name="description"
								onChange={(e) => handleInputChange(e)}
								value={state.description}
								placeholder={"Description..."}
							/>
						</div>
					</div>
					<div className="row px-0 m-0 pt-4">
						<div className="col-md-12 p-0 d-flex justify-content-end">
							<Select
								mode="tags"
								size="large"
								className="calendar-view_add_participant_box"
								optionFilterProp="contactOptions"
								placeholder={"Add Participants"}
								filterOption={(input, option: any) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >=
									0
								}
								style={{ width: "100%", background: "#F4F6F8" }}
								onChange={handleChangeParticipants}
								tokenSeparators={[","]}
								notFoundContent={
									!contactOptions.length
										? "Search for a record ..."
										: "No results found"
								}
							>
								{contactOptions}
							</Select>
						</div>
					</div>
					<div style={{ height: "150px" }}></div>
				</div>
				<div className="row pt-3 row-items meeting_date_time_container">
					<div
						className="open-with-calendar-box2"
						style={{
							position: "fixed",
							bottom: 0,
							padding: "20px 0",
							borderTop: "1px solid #ddd",
							width: "400px",
							background: "#fff",
						}}
					>
						<Button
							onClick={handleSubmit}
							type="primary"
							className="custom_btn"
							size="large"
						>
							Save Invite
						</Button>
					</div>
				</div>
			</div>
		</Drawer>
	);
};
