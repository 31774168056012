import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
	apiKey: "AIzaSyBDM1aTQF6rOyJQB8lExsF_MxYvLNEFHT4",
	authDomain: "pod-1-5e511.firebaseapp.com",
	projectId: "pod-1-5e511",
	storageBucket: "pod-1-5e511.appspot.com",
	messagingSenderId: "475243255754",
	appId: "1:475243255754:web:83bf00f5bed92a2ecc2133",
	measurementId: "G-6D977CBKXE",
};

export const firebaseApp = initializeApp(firebaseConfig);
export const analytics = getAnalytics(firebaseApp);
