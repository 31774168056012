import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import classNames from "classnames";
import InfiniteScroll from "react-infinite-scroll-component";
import { KanbanBoardItem } from "./Card";

import "./style.scss";
import { Select, Spin } from "antd";
import { ConditionalTooltip } from "../../Common/ConditionalTooltip";

export const KanbanBoardList = ({
	loading,
	recordTypes,
	currentRecordType = "",
	stages,
	onAction,
	onSetRecordType,
	onLoadMore,
	onUpdate,
}: {
	loading: boolean;
	recordTypes: any[];
	currentRecordType?: string;
	stages: any[];
	onAction: any;
	onSetRecordType: (recordType: string) => void;
	onLoadMore: VoidFunction;
	onUpdate: any;
}) => {
	const maxItemsCount = Math.max(
		...(stages || []).map((item) => item.cards?.length || 0),
	);
	const isLastPage = !(stages || []).find((item) => item.is_last_page);

	const handleDragEnd = (result: any) => {
		const { source, destination } = result;

		// dropped outside the list
		if (destination) {
			const [removedObject] = stages[source.droppableId].cards.splice(
				source.index,
				1,
			);
			stages[destination.droppableId].cards.splice(
				destination.index,
				0,
				removedObject,
			);

			onUpdate(stages[destination.droppableId].value, {
				id: removedObject.Id,
				objectDataKey: "StageName",
				objectType: "Opportunity",
			});
		}
	};

	return (
		<>
			<Select
				size="large"
				value={currentRecordType}
				className="custom_select"
				onChange={(value) => onSetRecordType(value)}
				style={{ width: 250 }}
			>
				{recordTypes.map((type) => (
					<Select.Option value={type.Id} key={type.Id}>
						{type.Name}
					</Select.Option>
				))}
			</Select>
			<div className="kanban_board_wrapper">
				<div className="kanban_board">
					{stages.map((stage: any) => (
						<div className="kanban_board_column">
							<div className="kanban_board_column_header">
								<div className="name">
									<ConditionalTooltip title={stage.label} />
								</div>
								<div className="count">{stage.cards?.length || 0}</div>
							</div>
						</div>
					))}
				</div>
				<div className="kanban_board" id="kanban-board">
					<InfiniteScroll
						dataLength={maxItemsCount || 0}
						next={onLoadMore}
						hasMore={!isLastPage}
						loader={null}
						scrollableTarget="kanban-board"
					>
						<DragDropContext onDragEnd={handleDragEnd}>
							{stages.map((stage: any, stageIndex: number) => (
								<div className="kanban_board_column">
									<Droppable key={stage.value} droppableId={`${stageIndex}`}>
										{(provided, snapshot) => (
											<div
												ref={provided.innerRef}
												className={classNames(
													"kanban_board_column_droppable",
													snapshot.isDraggingOver && "dragging",
												)}
												{...provided.droppableProps}
											>
												{stage.cards?.map((card: any, cardIndex: number) => (
													<Draggable
														key={card.Id}
														draggableId={`${stageIndex}-${cardIndex}`}
														index={cardIndex}
													>
														{(provided, snapshot) => (
															<KanbanBoardItem
																card={card}
																provided={provided}
																snapshot={snapshot}
																onAction={onAction}
															/>
														)}
													</Draggable>
												))}

												{provided.placeholder}
											</div>
										)}
									</Droppable>
								</div>
							))}
						</DragDropContext>
					</InfiniteScroll>
				</div>
			</div>
			{loading && (
				<div className="loader kanban_board_loader">
					<Spin />
				</div>
			)}
		</>
	);
};
