import { Checkbox, Popover, Skeleton, Tabs, Tooltip } from "antd";
import clsx from "clsx";
import moment from "moment";
import { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import {
	CompellingEvents,
	CompellingEventCategories,
	CompellingEventCategoriesType,
	getNewsCompellingEvents,
	getRecentArticles,
	OpportunityNews,
} from "../../../../../Apis/KnowledgeCenter/news";
import { ENTITY_404_MESSAGE } from "../KnowledgeCenterDrawer";
const { TabPane } = Tabs;

const NewsList = ({ newsData }: { newsData: NonNullable<OpportunityNews> }) => (
	<div className="news_list">
		{newsData.articles.map((article, i) => (
			<div
				className="news_list_item"
				key={i}
				onClick={() => {
					window.open(article.url !== null ? article.url : undefined, "_blank");
				}}
			>
				<div className="news_list_item--head">
					<span style={{ marginRight: 10 }}>{article.source}</span>
					<span>|</span>
					<span style={{ marginLeft: 10 }}>
						{moment(article.date).format("MM-DD-YYYY")}
					</span>
				</div>
				<Tooltip
					title="This is an indicator of the article’s sentiment: how positive or negative an article is about the company"
					placement="bottomRight"
				>
					<div
						className="news_list_item--sentiment"
						style={{
							background:
								article.sentiment === null
									? "#f82427"
									: article.sentiment < -0.15
									? "#f82427"
									: article.sentiment < 0.15
									? "#c2c2c2"
									: "#31d0aa",
						}}
					/>
				</Tooltip>
				<div className="news_list_item--title">{article.title}</div>
				{i < newsData.articles.length - 1 && (
					<div className="news_list_item--divider" />
				)}
			</div>
		))}
	</div>
);

const NewsSummary = ({
	newsData,
	categoryCheckboxes,
}: {
	newsData: NonNullable<OpportunityNews>;
	categoryCheckboxes: { [key in CompellingEventCategoriesType]: boolean };
}) => {
	// const [expanded, setExpanded] = useState(false);
	// const [summary, setSummary] = useState<string | undefined>(undefined);
	const [compellingEvents, setCompellingEvents] = useState<
		CompellingEvents[] | undefined
	>(undefined);

	useEffect(() => {
		// (async () => {
		// 	for await (const value of getNewsSummary(newsData.articles.map((article) => article.title !== null ? article.title : "")) {
		// 		setSummary((prevState) => (prevState ?? "") + value);
		// 	}
		// })();
		(async () => {
			for await (const value of getNewsCompellingEvents(
				newsData.orgName,
				newsData.articles,
			)) {
				setCompellingEvents((prevState) => (prevState ?? []).concat(value));
			}
		})();
		// getNewsCompellingEvents(newsData.orgName, newsData.articles).then((res) =>
		// 	setArticlesAndEvents(res),
		// );
	}, []);

	return (
		<div>
			{/*<div className="news_summary">*/}
			{/*  <ReactSVG src="/images/icons/NewCampaign.svg" />*/}
			{/*  <div className="news_summary_content">*/}
			{/*    <div className="news_summary_content--title">{newsData.orgName} latest news summary</div>*/}
			{/*    {*/}
			{/*      summary !== undefined ? (*/}
			{/*        <div className="news_summary_content--data">{summary}</div>*/}
			{/*      ) : (*/}
			{/*        <Skeleton active paragraph={{ rows: 5 }}></Skeleton>*/}
			{/*      )*/}
			{/*    }*/}
			{/*    <div className="news_summary_content--subtitle">News sources</div>*/}
			{/*    <div className="news_summary_content--sources">*/}
			{/*      {newsData.articles.slice(0, expanded ? newsData.articles.length : 2).map((article, i) => (*/}
			{/*        <a href={article.url !== null ? article.url : undefined} target="_blank" className="news_summary_content--source" key={i}>*/}
			{/*          {article.source}*/}
			{/*        </a>*/}
			{/*      ))}*/}
			{/*      {newsData.articles.length > 2 && (*/}
			{/*        <div className="news_summary_content--source" onClick={() => setExpanded((x) => !x)}>*/}
			{/*          {expanded ? "Less" : `+${newsData.articles.length - 2} More`}*/}
			{/*        </div>*/}
			{/*      )}*/}
			{/*    </div>*/}
			{/*  </div>*/}
			{/*</div>*/}
			<div>
				{compellingEvents !== undefined ? (
					<>
						<div className="news_list">
							{compellingEvents.map((articleAndEvents, i) =>
								articleAndEvents.events
									.filter(
										(compellingEvent) =>
											categoryCheckboxes[compellingEvent.category],
									)
									.map((compellingEvent) => (
										<div
											className="news_list_item"
											key={i}
											onClick={() => {
												const url = articleAndEvents.url;
												window.open(url !== null ? url : undefined, "_blank");
											}}
										>
											<div className="news_list_item--head">
												<span style={{ marginRight: 10 }}>
													{compellingEvent.category}
												</span>
												<span>|</span>
												<span style={{ marginLeft: 10 }}>
													{moment(articleAndEvents.date).format("MM-DD-YYYY")}
												</span>
											</div>
											<div className="news_list_item--title">
												{compellingEvent.event}
											</div>
											{i < compellingEvents.length - 1 && (
												<div className="news_list_item--divider" />
											)}
										</div>
									)),
							)}
						</div>
					</>
				) : (
					<Skeleton active paragraph={{ rows: 5 }}></Skeleton>
				)}
			</div>
		</div>
	);
};

const CompellingEventsFilter = (
	categoryCheckboxes: any,
	setCategoryCheckboxes: (category: any) => void,
) => (
	<Popover
		placement="bottomRight"
		content={
			<div className="pip_col_customiser">
				<div className="pip_col_customiser_list">
					{CompellingEventCategories.map(
						(category: CompellingEventCategoriesType, index) => {
							return (
								<div key={index} className="py-2">
									<Checkbox
										name={category}
										checked={categoryCheckboxes[category]}
										onChange={() => setCategoryCheckboxes(category)}
									>
										{category}
									</Checkbox>
								</div>
							);
						},
					)}
				</div>
			</div>
		}
		trigger={"click"}
		destroyTooltipOnHide
	>
		<div className="news_mode--item">
			<ReactSVG src="/images/icons/Filter.svg" />
		</div>
	</Popover>
);

export const NewsTab = ({ opportunityId }: { opportunityId: string }) => {
	const [activeTabIndex, setActiveTabIndex] = useState<string>("0");
	const [newsData, setNewsData] = useState<OpportunityNews | undefined>(
		undefined,
	);
	const [categoryCheckboxes, setCategoryCheckboxes] = useState<{
		[key in CompellingEventCategoriesType]: boolean;
	}>(
		Object.fromEntries(
			CompellingEventCategories.map(
				(category: CompellingEventCategoriesType) => [category, true],
			),
		) as { [key in CompellingEventCategoriesType]: boolean },
	);

	// TODO Figure out how to fetch async data pre-render (useEffect is post-render).
	// TODO Maybe just trigger fetch into redux and have a useSelector here?
	// https://articles.wesionary.team/why-useeffect-is-a-bad-place-to-make-api-calls-98a606735c1c
	useEffect(() => {
		getRecentArticles(opportunityId).then((r) => setNewsData(r));
	}, []);

	const renderNewsData = () => {
		switch (newsData) {
			case undefined:
				return <Skeleton active paragraph={{ rows: 10 }}></Skeleton>;
			case null:
				return <p>{ENTITY_404_MESSAGE}</p>;
			default:
				return (
					<div
						className="pod_drawer_tab news"
						style={{ justifyContent: "flex-end" }}
					>
						<div className="news_mode">
							<div style={{ display: "flex", flexDirection: "row" }}>
								<Tabs
									style={{ width: "485px" }}
									defaultActiveKey={activeTabIndex}
									onChange={(activeKey) => setActiveTabIndex(activeKey)}
								>
									<TabPane
										tab={
											<ReactSVG
												className={clsx(
													"news_mode--item",
													activeTabIndex === "0" && "active",
												)}
												src="/images/icons/FileList.svg"
											/>
										}
										key="0"
									>
										<NewsList newsData={newsData} />
									</TabPane>
									{newsData.articles.length !== 0 && (
										<TabPane
											tab={
												<ReactSVG
													className={clsx(
														"news_mode--item",
														activeTabIndex === "1" && "active",
													)}
													src="/images/icons/FileSummary.svg"
													style={{ marginTop: "3px" }}
												/>
											}
											key="1"
										>
											<NewsSummary
												newsData={newsData}
												categoryCheckboxes={categoryCheckboxes}
											/>
										</TabPane>
									)}
								</Tabs>
								<div>
									{activeTabIndex === "1" &&
										CompellingEventsFilter(
											categoryCheckboxes,
											(category: CompellingEventCategoriesType) =>
												setCategoryCheckboxes(
													(
														prevState: {
															[key in CompellingEventCategoriesType]: boolean;
														},
													) => ({
														...prevState,
														[category]: !prevState[category],
													}),
												),
										)}
								</div>
							</div>
						</div>
					</div>
				);
		}
	};

	return renderNewsData();
};
