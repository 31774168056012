import { ReactSVG } from "react-svg";
import { useEffect, useState } from "react";
import {
	getOrgDataFromDiffbotId,
	getOrgDataFromOpportunityId,
	OrgData,
} from "../../../../../../Apis/KnowledgeCenter/org";
import { Skeleton } from "antd";
import moment from "moment";
import { BiMap, BiPhone } from "react-icons/bi";
import { formatMoney, isValidSfdcId } from "../../../../../../Utils/Index";
import { IoMdArrowBack } from "react-icons/io";
import { ENTITY_404_MESSAGE } from "../../KnowledgeCenterDrawer";

const moreLinks = [
	{ name: "homepage", icon: <ReactSVG src="/images/icons/Global.svg" /> },
	{ name: "linkedin", icon: <ReactSVG src="/images/icons/Linkedin.svg" /> },
	{ name: "twitter", icon: <ReactSVG src="/images/icons/Twitter.svg" /> },
	{ name: "facebook", icon: <ReactSVG src="/images/icons/Facebook.svg" /> },
	{ name: "crunchbase", icon: <ReactSVG src="/images/icons/Crunchbase.svg" /> },
	{ name: "wikipedia", icon: <ReactSVG src="/images/icons/Wikipedia.svg" /> },
];

export const SummaryTab = ({
	initialId,
}: {
	initialId: string;
}) => {
	const [id, setId] = useState<string>(initialId);
	const [historyStack, setHistoryStack] = useState<(OrgData | undefined)[]>([]);
	const [isIndustryExpanded, setIsIndustryExpanded] = useState(false);
	const [isCompetitorExpanded, setIsCompetitorExpanded] = useState(false);

	useEffect(() => {
		setHistoryStack((prev) => [...prev, undefined]);
		(isValidSfdcId(id)
			? getOrgDataFromOpportunityId(id)
			: getOrgDataFromDiffbotId(id)
		).then((r) => {
			setHistoryStack((prev) => [...prev.slice(0, -1), r]);
		});
	}, [id]);

	const orgData = historyStack.at(-1);

	const renderOrgData = () => {
		switch (orgData) {
			case undefined:
				return <Skeleton active paragraph={{ rows: 10 }}></Skeleton>;
			case null:
				return <p>{ENTITY_404_MESSAGE}</p>;
			default:
				return (
					<div className="pod_drawer_tab">
						<div className="summary_title">
							{orgData.logo ? (
								<img
									src={orgData.logo}
									alt={orgData.name !== null ? orgData.name : undefined}
								/>
							) : (
								<ReactSVG
									src="/images/icons/SFDC-objects/accounts.svg"
									size={20}
								/>
							)}
							<div>{orgData.name}</div>
						</div>
						<div className="summary_block">
							<div className="summary_block--title">Overview</div>
							<div className="summary_block--content">
								{orgData.description}
							</div>
						</div>
						<div className="summary_block">
							<div className="summary_block--title">Industries Serving</div>
							<div className="summary_block--content summary_block_serving">
								{orgData.industriesNiche
									.slice(0, isIndustryExpanded ? undefined : 5)
									.map((item) => (
										<div className="summary_block_serving--tab" key={item}>
											{item}
										</div>
									))}
								{orgData.industriesNiche.length > 5 && (
									<div
										className="summary_block_serving--tab"
										onClick={() => setIsIndustryExpanded((x) => !x)}
									>
										{isIndustryExpanded ? "-" : "+"}
									</div>
								)}
							</div>
						</div>
						<div className="summary_block">
							<div className="summary_block--title">About the company</div>
							<div className="summary_block--content summary_block_about">
								<div className="summary_block_about--tab">
									<div className="summary_block_about--icon">
										<ReactSVG src="/images/icons/Founded.svg" />
									</div>
									<div className="d-flex flex-column">
										<div className="summary_block_about--title">Founded on</div>
										<div className="summary_block_about--value">
											{moment(orgData.foundingTimestamp).format("YYYY/MM/DD")}
										</div>
									</div>
								</div>
								<div className="summary_block_about--tab">
									<div className="summary_block_about--icon">
										<ReactSVG src="/images/icons/TotalEmployees.svg" />
									</div>
									<div className="d-flex flex-column">
										<div className="summary_block_about--title">
											Total Employees
										</div>
										<div className="summary_block_about--value">
											{orgData.numEmployees}
										</div>
									</div>
								</div>
								<div className="summary_block_about--tab">
									<div className="summary_block_about--icon">
										<ReactSVG src="/images/icons/YearlyRevenue.svg" />
									</div>
									<div className="d-flex flex-column">
										<div className="summary_block_about--title">
											Annual Revenue
										</div>
										<div className="summary_block_about--value">
											{!orgData.annualRevenue
												? "-"
												: `$${
														orgData.annualRevenue.revenue !== null
															? formatMoney(orgData.annualRevenue.revenue)
															: null
												  } ${orgData.annualRevenue.currency} (
                    ${orgData.annualRevenue.year})`}
										</div>
									</div>
								</div>
								<div className="summary_block_about--tab">
									<div className="summary_block_about--icon">
										<BiPhone size={15} />
									</div>
									<div className="d-flex flex-column">
										<div className="summary_block_about--title">
											Phone Number
										</div>
										<div className="summary_block_about--value">
											+{orgData.phone}
										</div>
									</div>
								</div>
								<div className="summary_block_about--tab">
									<div className="summary_block_about--icon">
										<BiMap size={15} />
									</div>
									<div className="d-flex flex-column">
										<div className="summary_block_about--title">Address</div>
										<div className="summary_block_about--value">
											{orgData.address}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="summary_block">
							<div className="summary_block--title">Fundraising history</div>
							<div className="summary_block--content summary_block_fundraising">
								{orgData.investments.map((item) => (
									<div
										className="summary_block_fundraising--item"
										key={item.series}
									>
										<div className="summary_block_fundraising--series">
											{item.series}
										</div>
										<div className="summary_block_fundraising--amount">
											{item.amount !== null
												? `$${formatMoney(item.amount)} ${item.currency}`
												: "N/A"}
										</div>
										<div className="summary_block_fundraising--timestamp">
											{moment(item.timestamp).format("YYYY/MM/DD")}
										</div>
									</div>
								))}
							</div>
						</div>
						<div className="summary_block">
							<div className="summary_block--title">Competitors</div>
							<div className="summary_block--content summary_block_competitors">
								{orgData.competitors
									.slice(0, isCompetitorExpanded ? undefined : 5)
									.map((competitor) => (
										<div
											key={competitor.targetDiffbotId}
											className="summary_block_competitors--item"
											onClick={() => {
												setId(competitor.targetDiffbotId);
											}}
										>
											<div className="summary_block_competitors--title">
												{competitor.name}
											</div>
											<div className="summary_block_competitors--summary">
												{competitor.summary}
											</div>
											<div className="summary_block_competitors--homepage">
												{competitor.image ? (
													<img
														src={competitor.image}
														alt={
															competitor.name !== null
																? competitor.name
																: undefined
														}
														style={{ maxWidth: "50px", maxHeight: "50px" }}
													/>
												) : (
													<ReactSVG src="/images/icons/Global.svg" />
												)}
											</div>
										</div>
									))}
								{orgData.competitors.length > 5 && (
									<div
										className="summary_block_serving--plus"
										onClick={() => setIsCompetitorExpanded((x) => !x)}
									>
										{isCompetitorExpanded ? "-" : "+"}
									</div>
								)}
							</div>
						</div>
						<div className="summary_block">
							<div className="summary_block--title">More links</div>
							<div className="summary_block--content summary_block_links">
								{moreLinks
									.filter(({ name }) => !!(orgData.relatedUrls as any)[name])
									.map(({ name, icon }) => {
										const url = (orgData.relatedUrls as any)[name];
										return (
											<a
												className="summary_block_links--item"
												href={url.startsWith("https") ? url : `https://${url}`}
												target="_blank"
												key={name}
											>
												{icon}
											</a>
										);
									})}
							</div>
						</div>
					</div>
				);
		}
	};

	return (
		<>
			{historyStack.filter((x) => x !== null).length > 1 && (
				<div
					style={{
						display: "flex",
						alignItems: "center",
						gap: "26px",
						fontWeight: "400",
						fontSize: "10px",
						lineHeight: "14px",
						color: "#7d828c",
						marginBottom: "16px",
					}}
				>
					<div
						style={{
							cursor: "pointer",
							background: "#f4f6f8",
							borderRadius: "2px",
							width: "24px",
							height: "24px",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
						onClick={() => setHistoryStack(historyStack.slice(0, -1))}
					>
						<IoMdArrowBack size={18} />
					</div>
				</div>
			)}
			{renderOrgData()}
		</>
	);
};
