import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import Store from "./Store/Store";
import { Provider } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";

ReactDOM.render(
	<React.StrictMode>
		<Provider store={Store}>
			<GoogleOAuthProvider
				clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID ?? ""}
			>
				<App />
			</GoogleOAuthProvider>
		</Provider>
	</React.StrictMode>,
	document.getElementById("root"),
);
