import { Form, Input, Popover, Skeleton } from "antd";
import lodash from "lodash";
import { useEffect, useRef, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { FormTitleSearch } from "../../../../Utils/Index";
import FormBuilder from "antd-form-builder";
import { UpdateSFDCObject } from "../../../../Apis/UpdateSFDCObject";
import PipelineColsSelectorComponent from "../../../Pipeline/PipelineColsSelectorComponent";
import PipelineDrawerFormItemComponent from "./PipelineDrawerFormItemComponent";
import { setUpdateRecordErrorModal } from "../../../../Store/ActionReducerPairs/Modals";
import { refreshPipelineTable } from "../../../../Store/ActionReducerPairs/Pipeline";

interface Props {
	initData: any;
	formData: any;
	objectType: string;
	onUpdate: () => void;
	onSave: (savedValues: any) => void;
	loading?: boolean;
	fieldsToDisplay: Array<string>;
	onUpdateSideDrawerConfig: any;
}

const PipelineDrawerFormComponent = ({
	initData,
	formData,
	objectType,
	onUpdate,
	onSave,
	loading,
	fieldsToDisplay,
	onUpdateSideDrawerConfig,
}: Props) => {
	const forceUpdate = FormBuilder.useForceUpdate();
	const [previewFormMeta, setPreviewFormMeta] = useState<any>([]);
	const [formMeta, setFormMeta] = useState<(Object | null)[]>([]);
	const [formValues, setFormValues] = useState<Record<string, unknown>>({});
	const [referenceFieldNameValues, setReferenceFieldNameValues] = useState<
		Record<string, string>
	>({});
	const searchInDetailForm = (e: any) => {
		setPreviewFormMeta([...FormTitleSearch(e.target.value || "", formMeta)]);
	};

	const setInitData = (init: any) => {
		const _formMeta = fieldsToDisplay
			.map((key: string) => {
				const fieldInfoItem = init.fieldInfo.find((i: any) => i.name === key);
				if (!fieldInfoItem) return null;
				return {
					key,
					label: fieldInfoItem.label,
					fieldInfo: fieldInfoItem,
				};
			})
			.filter((i) => i !== null);
		setPreviewFormMeta([..._formMeta]);
		setFormMeta([..._formMeta]);
	};

	// This effect seems to intend to say "the form was reset" which isn't actually happening
	// instead you're just rerendering and this effect is running when you don't want it.
	const prevFormData = useRef<typeof formData>();
	const prevInitData = useRef<typeof initData>();
	useEffect(() => {
		// honestly this should probably be in a different hook but wtv
		setInitData(initData);

		// We don't use normal hook equality since they're objects and that doesn't work for us.
		// We want deep equality check for whether we need to reset the form, so let's check with
		// lodash and early exit if we're fine. This could be a minor perf issue if rendering is wildly
		// often but should be mostly fine.
		if (
			lodash.isEqual(prevInitData.current, initData) &&
			lodash.isEqual(prevFormData.current, formData)
		) {
			return;
		}

		prevFormData.current = formData;
		prevInitData.current = initData;
		// why does this exist? I bet removing it would be fine but for now let's leave it in-case.
		// We really should rework this component to not have like 50 sources of truth for what are the form
		// values, but alas for another time.
		return () => {
			setFormValues({});
			setReferenceFieldNameValues({});
		};
		// only run the hook when these changed, but we also need deep equality check above since
		// these objects get recreated when no values have changed.
	}, [formData, initData]);

	return (
		<Form requiredMark={false} layout="vertical" onValuesChange={forceUpdate}>
			<div className="my-2">
				<div className="d-flex justify-content-between align-items-center">
					<div className="flex-grow-1">
						<Input
							className="custom_input_1"
							onChange={searchInDetailForm}
							size="large"
							prefix={<AiOutlineSearch size={20} color="#999" />}
							placeholder="Search by Name"
						/>
					</div>
					<Popover
						placement="bottomRight"
						overlayClassName="popover-replace"
						overlayStyle={{
							top: 351,
							position: "absolute",
							right: 10,
							width: 250,
						}}
						content={
							<PipelineColsSelectorComponent
								cols={fieldsToDisplay}
								setTableLoading={() => null}
								currFieldInfo={initData.fieldInfo}
								fetchCategoryDataMethod={onUpdate}
								onChange={onUpdateSideDrawerConfig}
							/>
						}
						trigger={"click"}
						destroyTooltipOnHide
					>
						<span className="pip_table_extras_addon--btn">
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path d="M7.75 4V11.5" stroke="#666666" strokeLinecap="round" />
								<path
									d="M11.5 7.75L4 7.75"
									stroke="#666666"
									strokeLinecap="round"
								/>
								<path
									d="M19.5 17.75L12 17.75"
									stroke="#666666"
									strokeLinecap="round"
								/>
								<path d="M20 4L4 20" stroke="#666666" strokeLinecap="round" />
							</svg>
						</span>
					</Popover>
				</div>
			</div>
			<div className="pod_details_form pe-3">
				{loading ? (
					<Skeleton active paragraph={{ rows: 5 }} />
				) : (
					(previewFormMeta || formMeta).map(({ key, fieldInfo }: any) => (
						<PipelineDrawerFormItemComponent
							fieldInfo={fieldInfo}
							refObjectName={
								referenceFieldNameValues[key] ||
								formData[key.replace("Id", "")]?.Name
							}
							currentValue={formValues[key] ?? formData[key]}
							onUpdate={(value: unknown, name?: string) => {
								setReferenceFieldNameValues({
									...referenceFieldNameValues,
									[key]: name,
								});

								const newFormValues = {
									...formValues,
									[key]: value,
								};
								setFormValues(newFormValues);
								return newFormValues;
							}}
							onSave={async (newValues?: any) => {
								await UpdateSFDCObject(formData.Id, objectType, {
									...formValues,
									...(newValues || {}),
								}).then((res: any) => {
									onSave?.({
										...formData,
										...formValues,
										...(newValues || {}),
									});
									refreshPipelineTable(new Date());
									!res?.data.is_success &&
										setUpdateRecordErrorModal({
											visible: true,
											errorMessage: res.data.message.message,
										});
								});
							}}
							recordTypeId={formData.RecordTypeId}
						/>
					))
				)}
			</div>
		</Form>
	);
};

export default PipelineDrawerFormComponent;
