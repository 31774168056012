import { useState, useEffect } from "react";
import { BaseLayoutComponent } from "../../Components/Shared/BaseLayoutComponent";
import { ActivityAreaV2 } from "./Components/ActivityAreaV2";
import Datepicker from "./Components/Datepicker";
import { Subheader } from "./Components/Subheader";
import "./Sass/Activityhub.styles.scss";
import { Spin, Button } from "antd";
import GoogleIcon from "../../Images/GoogleIcon.png";
import { isGoogleIntegrationEnabled } from "../../Apis/Integration/google";
import PodGoogleLogin from "../../Components/GoogleLogin";
import { KnowledgeCenterDrawer } from "../../Components/Drawer/ActivityHub/KnowledgeCenter/KnowledgeCenterDrawer";
import { AutoGeneratedEmailDrawer } from "../../Components/Drawer/ActivityHub/AutoGeneratedEmail/AutoGeneratedEmailDrawer";

export const ActivityHub = () => {
	const [month, setMonth] = useState<any>();
	const [reload, setReload] = useState(0);
	const [helper, setHelper] = useState("");
	const [hideGoogleLogin, setHideGoogleLogin] = useState<boolean>(true);
	const handleCurrentMonth = (data: any) => setMonth(data);

	useEffect(() => {
		isGoogleIntegrationEnabled().then((res: boolean) =>
			setHideGoogleLogin(res),
		);
		window.analytics.page("ActivityView", {
			podUserId: localStorage.getItem("podUserId"),
		});
	}, []);

	return (
		<BaseLayoutComponent pageTitle={<h3>Activity View</h3>}>
			<Spin spinning={false} tip="Loading...">
				<div className="activity_page">
					<Subheader openHelper={setHelper} />
					<div className="activity_page_content">
						<div
							style={{
								flexDirection: "row",
								display: "flex",
								justifyContent: "space-between",
							}}
						>
							<Datepicker change={handleCurrentMonth} />
							{hideGoogleLogin ? null : (
								<div>
									<span
										style={{
											textShadow: "none",
											fontWeight: "bold",
											color: "#0f0f0f",
											marginRight: 10,
										}}
									>
										Sign in with:
									</span>
									<PodGoogleLogin
										render={({ onClick }) => (
											<Button
												onClick={onClick}
												type="primary"
												className="custom_btn"
												style={{
													marginTop: 8,
													backgroundColor: "#fff",
													color: "#000",
													borderColor: "#000",
													border: "none",
													padding: 6,
													boxShadow: "1px 1px 6px -1px rgba(0,0,0,0.27)",
													height: "36px",
												}}
											>
												<img
													src={GoogleIcon}
													alt="google-icon"
													style={{ height: 20, marginBottom: 2 }}
												/>
												<span
													style={{
														textShadow: "none",
														fontWeight: "bold",
														marginLeft: 20,
														color: "#8f8f8f",
													}}
												>
													Google
												</span>
											</Button>
										)}
										onSuccess={() => setReload(Date.now())}
									/>
								</div>
							)}
						</div>
						<ActivityAreaV2 currentMonth={month} reload={reload} />

						{/*TODO this should be handled in redux as a popup, not rendered in this component at all*/}
						{helper === "knowledge-center" && (
							<KnowledgeCenterDrawer
								onClose={() => setHelper("")}
								opportunityId={
									new URLSearchParams(location.search).get(
										"opportunity_id",
									) as string
								}
							/>
						)}
						{helper === "generate-email" && (
							<AutoGeneratedEmailDrawer onClose={() => setHelper("")} />
						)}
					</div>
				</div>
			</Spin>
		</BaseLayoutComponent>
	);
};
