import { useState } from "react";
import { Select } from "antd";
import {
	emailTypeLabels,
	NotesEmailType,
	notesEmailTypes,
} from "../../../../../Apis/EmailGen/emailGen";
import { EmailPreview } from "../../../../Shared/EmailGen/EmailPreview";
import { GenerateEmailForm } from "../../../../Shared/EmailGen/GenerateEmailForm";
import { Note } from "../NotesAssistantDrawer";

export const FollowupTab = ({ note }: { note: Note }) => {
	const [values, setValues] = useState<{ [key: string]: unknown }>({});
	const [isFormSubmitted, showIsFormSubmitted] = useState<boolean>(false);
	const [emailType, setEmailType] = useState<NotesEmailType>("Internal");

	const handleSubmitForm = (values: { [key: string]: unknown }) => {
		window.analytics.track("Notes_EmailGeneration_Submit", {
			podUserId: localStorage.getItem("podUserId"),
		});
		setValues(values);
		showIsFormSubmitted(true);
	};

	return (
		<div className="notes_assistant_tab followup">
			{!isFormSubmitted ? (
				<>
					<p
						style={{
							marginBottom: "0",
							fontSize: "12px",
							fontWeight: "400",
							lineHeight: "16px",
							paddingBottom: "5px",
							color: "#000000D9",
						}}
					>
						Type
						<span
							style={{
								display: "inline-block",
								marginLeft: "4px",
								color: "#ff4d4f",
								fontSize: "14px",
								fontFamily: "SimSun, sans-serif",
								lineHeight: "1",
								content: "*",
							}}
						>
							*
						</span>
					</p>
					<Select
						style={{
							border: "1px solid #e0e0e0",
							borderRadius: "4px",
							marginBottom: "15px",
						}}
						value={emailType}
						onChange={(v) => setEmailType(v)}
					>
						{notesEmailTypes.map((emailType: NotesEmailType) => (
							<Select.Option key={emailType} value={emailType}>
								{emailTypeLabels[emailType]}
							</Select.Option>
						))}
					</Select>
					<GenerateEmailForm
						emailType={emailType}
						onSubmit={handleSubmitForm}
					/>
				</>
			) : (
				<EmailPreview
					emailType={emailType}
					context={{
						...values,
						noteType: note.type,
						noteContent: note.content,
					}}
					onBack={() => showIsFormSubmitted(false)}
				/>
			)}
		</div>
	);
};
