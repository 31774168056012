import Store from "../Store";
import { AnyAction } from "redux";

type OurAction<P> = { type: string; payload: P };

export const makeAction = <P = {}>(type: string) => {
	const dispatcher = (payload: P) =>
		Store.dispatch({
			type,
			payload,
		});

	dispatcher.guard = (action: AnyAction): action is OurAction<P> =>
		action.type === type;

	return dispatcher;
};
