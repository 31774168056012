import AccountIcon from "../../../Images/SFDC-objects/accounts.svg";
import classNames from "classnames";
import {
	RowInsightAction,
	RowMoreActions,
} from "../Components/PipelineActions";
import moment from "moment";

export const KanbanBoardItem = ({
	card,
	category = "opportunities",
	onAction,
	provided,
	snapshot,
}: {
	card: any;
	category?: string;
	provided: any;
	snapshot: any;
	onAction: any;
}) => {
	return (
		<div
			ref={provided.innerRef}
			{...provided.draggableProps}
			{...provided.dragHandleProps}
			className={classNames(
				"kanban_board_card",
				snapshot.isDragging && "dragging",
			)}
			style={provided.draggableProps.style}
		>
			<div className="kanban_board_card_info">
				<div className="header">
					<div
						className="title"
						onClick={() => onAction("View Details", "Opportunity", card.Id)}
					>
						{card.Name}
					</div>
					<div className="action">
						<RowMoreActions
							showSwap
							category={category}
							record={card}
							onAction={onAction}
						/>
					</div>
				</div>
				<div className="account_info">
					<img src={AccountIcon} alt="" />
					<div className="name">{card.Account?.Name || "Not available"}</div>
				</div>
				<div className="meta">
					<div className="block">
						<div className="label">Close date</div>
						<div className="value">
							{moment(card.CloseDate).format("MM/DD/YYYY")}
						</div>
					</div>
					<div className="block">
						<div className="label">Amount</div>
						<div className="value">${card.Amount || 0}</div>
					</div>
					<div className="actions">
						<div className="action">
							<RowInsightAction category={category} record={card} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
