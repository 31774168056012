import api from "../api";

export const updateObjectives = async (objectivesArr: number[]) => {
	const user_id = Number(localStorage.getItem("podUserId"));
	let objectives = null;
	if (objectivesArr) {
		objectives = objectivesArr.join(",");
	}

	return api.post("objectives", {
		user_id,
		objectives,
	});
};
