import { Navigate, useLocation, useParams } from "react-router-dom";
import PageNotFound from "../../Pages/PageNotFound";
import { useAppSelector } from "../../hooks";
import { FunctionComponent } from "react";

interface ValidationProps {
	regExps: { [key: string]: RegExp };
}

export const ValidatedRoute: FunctionComponent<ValidationProps> = ({
	regExps, // TODO arbitrary validators, pref defined by child
	children,
}) => {
	for (const [param, value] of Object.entries(useParams())) {
		if (!regExps[param]?.test(value ?? "")) {
			return <PageNotFound />;
		}
	}
	return <>{children}</>;
};

export const PrivateRoute: FunctionComponent = ({ children }) => {
	const location = useLocation();
	const searchParams = location.search;
	const user = useAppSelector(({ user }) => user);
	return user.podUserId ? (
		<>{children}</>
	) : (
		<Navigate to={`/${searchParams || ""}`} />
	);
};

export const PrivateComponent: FunctionComponent = ({ children }) => {
	const user = useAppSelector(({ user }) => user);
	return user.podUserId ? <>{children}</> : null;
};
