import { addTrailingSlash } from "./Utils/helpers";

export const Config = <ConfigObject>{
	MODE: process.env.REACT_APP_MODE,
	sdfcAuth: {
		redirectUri: `${addTrailingSlash(window.location.origin)}sfdc-callback-url`,
		responseType: "code",
		clientId:
			"3MVG9p1Q1BCe9GmBdPUgwpkNGjr5RnbRBF8Xs.F2.4Sl0YoAQHNLzPSQXaqAlNIPLVNKjnOsek3Nq9Z30TemQ",
	},
	COMMIT_SHA: function () {
		return process.env.REACT_APP_COMMIT_SHA;
	},
	API_BASE_URL: function () {
		return process.env.REACT_APP_API_BASE_URL;
	},
	API_STATIC_URL: function () {
		return process.env.REACT_APP_API_STATIC_URL;
	},
};

interface ConfigObject {
	MODE: string | undefined | number;
	processIds: Object;
	API_BASE_URL: () => string;
	API_STATIC_URL: () => string;
	COMMIT_SHA: () => string;
	sdfcAuth: {
		redirectUri: string;
		responseType: string;
		clientId: string;
	};
}
