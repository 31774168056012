import { configureStore } from "@reduxjs/toolkit";
import { userReducer } from "./ActionReducerPairs/User";
import { pipelineReducer } from "./ActionReducerPairs/Pipeline";
import { activityHubReducer } from "./ActionReducerPairs/ActivityHub";
import { modalReducer } from "./ActionReducerPairs/Modals";
import { drawerReducer } from "./ActionReducerPairs/Drawer";
import { taskManagerReducer } from "./ActionReducerPairs/TaskManager";

function saveToLocalStorage(state: any) {
	try {
		const { drawer, taskManager, ...stateToSave } = state;
		if (state.user.podUserId) {
			const serialisedState = JSON.stringify(stateToSave);
			localStorage.setItem("BaseApp1", serialisedState);
		}
	} catch (e) {
		console.warn(e);
	}
}

function loadFromLocalStorage() {
	const serialisedState = localStorage.getItem("BaseApp1");
	if (serialisedState === null) return undefined;
	try {
		return JSON.parse(serialisedState);
	} catch (e) {
		console.warn(e);
		return undefined;
	}
}

const Store = configureStore({
	reducer: {
		user: userReducer,
		pipeline: pipelineReducer,
		activityHub: activityHubReducer,
		modals: modalReducer,
		drawer: drawerReducer,
		taskManager: taskManagerReducer,
	},
	preloadedState: loadFromLocalStorage(),
});

// The store is initialized just by importing this file, but we'd
// like for callers to have an obvious thing to invoke so that webpack
// doesn't do any funny business linting away or tree shaking out
// unused expressions or imports.
export const initStore = () => {};

export type RootState = ReturnType<typeof Store.getState>;
export type AppDispatch = typeof Store.dispatch;

Store.subscribe(() => saveToLocalStorage(Store.getState()));

export default Store;
