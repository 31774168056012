import { Button, Input } from "antd";
import moment from "moment";
import { AiOutlineSearch } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { CreateDocument } from "../../../Apis/Firebase/CreateDocument";

import "./style.scss";

export interface Props {
	notes: any[];
	searchInNotes: (e: any) => void;
	onClose: VoidFunction;
}

const NoteList = ({ notes, searchInNotes, onClose }: Props) => {
	const navigate = useNavigate();

	const createNote = () => {
		CreateDocument().then((res) => {
			navigate("/document-hub/notes-page/" + res);
			onClose();
		});
	};

	const selectItem = (id: string) => {
		navigate("/document-hub/notes-page/" + id);
		onClose();
	};

	return (
		<>
			<div className="pod_assign_contact">
				<Input
					className="custom_input_1"
					size="large"
					onChange={searchInNotes}
					prefix={<AiOutlineSearch size={20} color="#999" />}
					placeholder="Search by Name"
				/>
				<Button className="pod_btn_secondary" onClick={createNote}>
					New Note
				</Button>
			</div>
			<div className="pod_note_list">
				{notes.length ? (
					notes.map(({ id, title, last_modified_at }: any) => (
						<div
							key={id}
							className="pod_note_list_each"
							onClick={() => selectItem(id)}
						>
							<div>
								<div className="pod_note_list_each--title">
									{title || "No name available"}
								</div>
								<div className="pod_note_list_each--modified">
									Last modified on{" "}
									{moment(last_modified_at).format("MMM DD, YYYY")}
								</div>
							</div>
						</div>
					))
				) : (
					<>No notes</>
				)}
			</div>
		</>
	);
};

export default NoteList;
