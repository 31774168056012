import { API_FETCH } from "../Utils/Index";

export const CreateLogEvent = (objectType: any, data: any) => {
	const myHeaders = new Headers();
	myHeaders.append("Content-Type", "application/json");
	const body = {
		objectType,
		pod_user_id: Number(localStorage.getItem("podUserId")),
		use_case: "quick_create_new_activity",
	};
	const output = Object.assign(data, body);
	const requestOptions: any = {
		method: "POST",
		headers: myHeaders,
		body: JSON.stringify(output),
		redirect: "follow",
	};
	return new Promise((resolve, reject) => {
		fetch(API_FETCH(`/log_new_event`), requestOptions)
			.then((response: any) => response.json())
			.then((result: PromiseLike<Object[]>) => resolve(result))
			.catch((error: any) => {
				console.warn("error while creating log event =>", error);
				reject(error);
			});
		resolve("heelow");
	});
};
