import { DatePicker, Statistic } from "antd";
import { DropdownSearch } from "../DropdownSearch";
import moment from "moment";
import { Gauge } from "@ant-design/plots";
import PipelineRenderCustomColumnComponent from "../Pipeline/PipelineRenderCustomColumnComponent";
import { PipelineStageRenderComponent } from "../Pipeline/PipelineStageRenderComponent";
import PipelineEditorDropdown from "../Pipeline/PipelineEditorDropdown";
import { ReactSVG } from "react-svg";
import { ReactElement } from "react";

const config: any = {
	range: {
		color: "#31D0AA",
	},
	innerRadius: 0.8,
	width: 40,
	height: 40,
	startAngle: Math.PI,
	endAngle: 2 * Math.PI,
	indicator: null,
	autoFit: false,
};

export interface Props {
	type: string;
	currentValue: string;
	valueForRefObjWithName: string;
	updateData: any;
	updateValue: (value: unknown, data?: any, name?: string) => void;
	name: string;
	referencedObjectType: string;
	updateable: boolean;
	picklist: Array<Record<string, string>>;
	textLength?: number;
	displayShield?: boolean;
	nullable?: boolean;
	inputLength?: number;
	showDrawerCb?: (objectType: any, objectId: any) => void;
	onClick?: VoidFunction;
	recordTypeId?: any;
}

const PipelineFieldSelector = (props: Props): ReactElement => {
	const {
		valueForRefObjWithName,
		currentValue,
		referencedObjectType,
		updateValue,
		type,
		picklist,
		updateData,
		updateable,
		name,
		textLength,
		displayShield,
		inputLength,
		nullable,
		showDrawerCb,
		onClick,
		recordTypeId,
	} = props;

	const displayValue =
		(valueForRefObjWithName || currentValue)?.length > (textLength || 200)
			? `${(valueForRefObjWithName || currentValue).slice(
					0,
					(textLength || 200) - 3,
			  )}...`
			: valueForRefObjWithName || currentValue;

	const buildPrimaryColDiv = () => {
		return (
			<div
				className="pip_col_editor pip_col--item"
				style={{ fontWeight: "bold", cursor: "pointer" }}
				onClick={
					showDrawerCb
						? () => showDrawerCb(updateData.objectType, updateData.id)
						: onClick || (() => {})
				}
			>
				{displayShield && (
					<ReactSVG
						src="/images/icons/PrivateLock.svg"
						style={{ marginRight: 10, marginTop: -2, display: "inline-block" }}
					/>
				)}
				{textLength ? displayValue : (currentValue as string)}
			</div>
		);
	};

	// TODO: handle multipicklist

	if (type === "reference") {
		if (updateable) {
			return (
				<DropdownSearch
					currentName={valueForRefObjWithName || currentValue}
					currentId={currentValue}
					objectType={referencedObjectType}
					// TODO: check for correctness of UpdateTextorNumberType
					UpdateTextorNumberType={(value: string, _: any, name: string) =>
						updateValue(value, updateData, name)
					}
					borderless
					textLength={textLength}
				/>
			);
		} else {
			// TODO: test for this case
			return <div className="pip_col_editor pip_col--item">{displayValue}</div>;
		}
	} else if (type === "picklist") {
		if (updateable) {
			if (name.includes("StageName")) {
				return (
					<PipelineStageRenderComponent
						stages={picklist}
						value={currentValue}
						changeInStage={updateValue} // TODO: test update
						updateData={updateData} // TODO: test
						recordTypeId={recordTypeId}
					/>
				);
			} else {
				return (
					<div className="pip_col--item">
						<PipelineRenderCustomColumnComponent
							currentId={currentValue}
							list={picklist}
							onClick={({ value }) => {
								updateValue(value, updateData, type);
							}}
						/>
					</div>
				);
			}
		} else {
			return <div className="pip_col_editor pip_col--item">{currentValue}</div>;
		}
	} else if (
		[
			"int",
			"percent",
			"double",
			"float",
			"decimal",
			"currency",
			"money",
		].includes(type)
	) {
		if (updateable && name === "EngagementScore") {
			return (
				<PipelineEditorDropdown
					currentValue={currentValue}
					updateValue={updateValue}
					name={name}
					numberEditorProps={{ type: type, isEngagementScore: true }}
				/>
			);
		} else if (updateable) {
			return (
				<PipelineEditorDropdown
					currentValue={currentValue}
					name={name}
					updateValue={updateValue}
					inputLength={inputLength}
					numberEditorProps={{ type: type }}
					updateData={updateData}
				/>
			);
		} else if (name === "EngagementScore") {
			return (
				<div className="pip_col_egscore">
					<span className="pip_col_egscore--chart">
						<Gauge
							percent={Number(currentValue || 0) / 100}
							{...config}
							statistic={{}}
						/>
					</span>
					<span className="pip_col_egscore--value">{currentValue}%</span>
				</div>
			);
		} else {
			return (
				<div className="ps-1">
					<Statistic
						className="custom_statistic"
						prefix={["currency", "money"].includes(type) ? "$" : ""}
						suffix={["percent"].includes(type) ? "%" : ""}
						value={currentValue}
					/>
				</div>
			);
		}
	} else if (type === "boolean") {
		if (updateable) {
			return (
				<div className="pip_col--item">
					<PipelineRenderCustomColumnComponent
						currentId={currentValue}
						list={[
							{ value: "true", label: "Yes" },
							{ value: "false", label: "No" },
						]}
						onClick={({ value }) => {
							updateValue(value === "true", updateData);
						}}
					/>
				</div>
			);
		} else {
			return (
				<div className="pip_col_editor pip_col--item">
					{currentValue ? "Yes" : "No"}
				</div>
			);
		}
	} else if (["date", "datetime"].includes(type)) {
		if (updateable) {
			return (
				<div className="pip_col_editor pip_col--item date_picker">
					<div>
						<DatePicker
							defaultValue={
								currentValue && currentValue !== "-"
									? moment(currentValue)
									: undefined
							}
							format={"Do MMM YYYY"}
							onChange={(e: any) => updateValue(e, updateData)}
						/>
					</div>
				</div>
			);
		} else {
			return (
				<div className="pip_col_editor pip_col--item date_picker">
					<div>
						{!["-", null, "", undefined].includes(currentValue)
							? moment(currentValue).format("Do MMM YYYY")
							: "NA"}
					</div>
				</div>
			);
		}
	} else if (updateable) {
		if (name === "Name" || name === "title") {
			return buildPrimaryColDiv();
		}
		// TODO: create textarea and string and ignore non-treated cases at PV level
		return (
			<PipelineEditorDropdown
				currentValue={currentValue}
				updateValue={updateValue}
				updateData={updateData}
				inputLength={inputLength}
				name={name}
				nullable={nullable}
			/>
		);
	} else {
		if (name === "Name" || name === "title") {
			return buildPrimaryColDiv();
		}
		return (
			<div
				className="pip_col_editor pip_col--item"
				style={
					name === "Name" || name === "title" ? { fontWeight: "bold" } : {}
				}
			>
				{displayShield && (
					<ReactSVG
						src="/images/icons/PrivateLock.svg"
						style={{ marginRight: 10, marginTop: -2, display: "inline-block" }}
					/>
				)}
				{textLength ? displayValue : currentValue}
			</div>
		);
	}
};

export default PipelineFieldSelector;
