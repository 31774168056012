import { fetchStream } from "../../Utils/Index";

export const getNotesSummary = async function* (
	noteType: string,
	noteContent: string,
): AsyncGenerator<string> {
	yield* fetchStream("/notes_summary", {
		type: noteType,
		content: noteContent,
	});
};
