import { DefaultRootState } from "react-redux";
import { AnyAction } from "redux";
import { makeAction } from "./Action";

export const receiveActivityHubData = makeAction<any>(
	"RECEIVE_ACTIVITY_HUB_DATA",
);
export const refreshCalendar = makeAction("REFRESH_CALENDAR");

export const activityHubReducer = (
	state: DefaultRootState = {},
	action: AnyAction,
) => {
	if (receiveActivityHubData.guard(action)) {
		return {
			...state,
			...action.payload,
		};
	} else if (refreshCalendar.guard(action)) {
		return {
			...state,
			calendarStamp: Date.now(),
		};
	}

	return state;
};
