import { firebaseApp } from "./Config";
import { doc, setDoc, getFirestore } from "firebase/firestore";
import moment from "moment";

export const CreateDocument = async (
	title: string = "",
	content: string = "",
	is_private: boolean = false,
	opportunity_id: string = "",
	opportunity_name: string = "",
	account_id: string = "",
	account_name: string = "",
	document_type: string = "",
	event_date: string = "",
	event_id: string = "",
	event_name: string = "",
	stakeholders: Array<string> = [],
) => {
	const pod_user_id = localStorage.getItem("podUserId");
	const created_by = localStorage.getItem("sfdcUserDisplayName");
	const sfdc_instance_url = localStorage.getItem("sfdcInstanceUrl");

	const db = getFirestore(firebaseApp);
	const reference = `${pod_user_id}${Date.now()}`;
	const document = doc(db, "documents", reference);
	await setDoc(document, {
		pod_user_id,
		sfdc_instance_url,
		content,
		title,
		is_private,
		opportunity_id,
		opportunity_name,
		account_id,
		account_name,
		document_type,
		event_date,
		event_id,
		event_name,
		stakeholders,
		created_by,
		created_at: moment().format().split("T")[0],
		last_modified_at: moment().format().split("T")[0],
	});

	return reference;
};
