import { useState, useEffect } from "react";
import { Button, Form, notification } from "antd";
import moment from "moment";
import { CreateNewSFDCObject } from "../../../Apis/Pipeline/CreateNewSFDCObject";
import { getOptionalAndRequiredFieldDetailsByObjectType } from "../../../Apis/FieldDetails";
import CloudIcon from "../../../Images/Cloud.svg";
import { FieldSelector } from "../../FieldSelector";
import { iterateIterables, effectiveLabelValue } from "../../../Utils/Index";
import PipelineRenderCustomColumnComponent from "../../Pipeline/PipelineRenderCustomColumnComponent";
import {
	refreshPipelineTable,
	togglePipelineCreateModal,
} from "../../../Store/ActionReducerPairs/Pipeline";

const { Item, useForm } = Form;
export const CreateFormComponent = ({
	objectType,
	initialState,
}: {
	objectType: string;
	initialState: Record<string, any>;
}) => {
	const [form] = useForm();
	const [referenceNames, setReferenceNames] = useState<any>({});
	const [referenceIds, setReferenceIds] = useState<any>({});
	const [newFields, setNewFields] = useState<any[]>([]);
	const [possibleFields, setPossibleFields] = useState<any[]>([]);
	const [fields, setFields] = useState<any>([]);
	const [loading, setLoading] = useState(false);

	const convertPossibleFieldToField = (item: any) => {
		return {
			title: item.label,
			key: item.value,
			type: item.type,
			objectType: item.objectType,
			picklistValues: item.picklistValues,
		};
	};

	const onUpdateReference = (
		key: string,
		id: string,
		name: string | undefined,
	) => {
		setReferenceIds((prev: any) => ({
			...prev,
			[key]: id,
		}));
		setReferenceNames((prev: any) => ({
			...prev,
			[key]: name,
		}));
		form.setFieldsValue({
			[key]: id,
		});
	};

	useEffect(() => {
		setLoading(true);
		getOptionalAndRequiredFieldDetailsByObjectType(objectType).then((res) => {
			const [optional, required] = res;
			// TODO consolidate optional and required interfaces

			const newFields: any[] = [];
			const possibleFields = optional.filter((x: any) => {
				if (x.value in initialState) {
					newFields.push(convertPossibleFieldToField(x));
					return false;
				}
				return true;
			});

			for (const field of iterateIterables(newFields, required)) {
				if (field.key in initialState) {
					// TODO initialState won't work for setting reference fields because we don't have the referenced sfdc ID
					onUpdateReference(field.key, initialState[field.key], undefined);
				}
			}

			setFields(required);
			setPossibleFields(possibleFields);
			setNewFields(newFields);
			setLoading(false);
		});
	}, [objectType]);

	const submitForm: any = (values: any) => {
		let _preparedData: any = {
			...values,
			...(values.closeDate
				? { CloseDate: moment(values.closeDate).format("YYYY-MM-DD") }
				: {}),
		};

		CreateNewSFDCObject(objectType.toLocaleLowerCase(), _preparedData).then(
			(res: any) => {
				form.resetFields();
				notification.success({
					message: `Successfully Created New ${objectType}`,
					placement: "bottomRight",
					duration: 1,
				});
				togglePipelineCreateModal({
					modalVisible: false,
					selectedModal: {},
				});
				refreshPipelineTable(new Date());
				window.analytics.track("PipelineView_Create_SFDC_Object", {
					podUserId: localStorage.getItem("podUserId"),
				});
			},
		);
	};

	return !loading ? (
		<Form
			layout="vertical"
			onFinish={submitForm}
			form={form}
			scrollToFirstError
		>
			<div style={{ height: 430, overflowY: "scroll" }}>
				{fields.map((item: any) => (
					<Item
						key={item.key}
						name={item.key}
						label={effectiveLabelValue(item.title, item.type)}
						rules={[{ required: true, message: `Please enter ${item.title}` }]}
					>
						<FieldSelector
							type={item.type}
							refObjectName={referenceNames[item.key]}
							currentValue={referenceIds[item.key]}
							objectType={item.objectType}
							updateValue={(value: any, name?: string) =>
								onUpdateReference(item.key, value, name!)
							}
							picklist={item.picklistValues as any}
							size="large"
						/>
					</Item>
				))}
				{newFields.map((item) => (
					<Item
						key={item.key}
						name={item.key}
						label={effectiveLabelValue(item.title, item.type)}
					>
						<FieldSelector
							type={item.type}
							refObjectName={referenceNames[item.key]}
							currentValue={referenceIds[item.key]}
							objectType={item.objectType}
							updateValue={(value: any, name?: string) =>
								onUpdateReference(item.key, value, name!)
							}
							picklist={item.picklistValues as any}
							size="large"
						/>
					</Item>
				))}
			</div>
			<Item className="my-2 pt-2">
				<div className="d-flex justify-content-between align-items-center">
					<PipelineRenderCustomColumnComponent
						list={possibleFields}
						onClick={(item) => {
							setNewFields([...newFields, convertPossibleFieldToField(item)]);
							setPossibleFields(
								possibleFields.filter((field) => field.value !== item.value),
							);
						}}
						keepOpenOnClick
					>
						<Button className="syn_btn">Add field</Button>
					</PipelineRenderCustomColumnComponent>
				</div>
			</Item>
			<Item className="my-2 pt-2">
				<div className="d-flex justify-content-between align-items-center">
					<span className="text-danger">Fill in required Fields</span>
					<Button
						htmlType="submit"
						type="primary"
						className="syn_btn"
						icon={<img src={CloudIcon} className="img-fluid" />}
					>
						Sync
					</Button>
				</div>
			</Item>
		</Form>
	) : (
		<>Loading...</>
	);
};
