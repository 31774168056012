import {
	Drawer,
	Menu,
	Select,
	Tabs,
	Button,
	AutoComplete,
	Input,
	Dropdown,
	Skeleton,
	Tooltip,
	notification,
} from "antd";
import { useEffect, useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import { ReactSVG } from "react-svg";
import MoreIcon from "../../../Images/More.svg";
import BackIcon from "../../../Images/Arrow.svg";
import { AiOutlineSearch } from "react-icons/ai";
import "./PipelineDrawerComponent.styles.scss";
import { GetSFDCObjectDetailsV2 } from "../../../Apis/GetSFDCObjectDetailsV2";
import { UpdateSFDCSideDrawerConfig } from "../../../Apis/UpdateSFDCSideDrawerConfig";
import { ArrayObjectStringFilter } from "../../../Utils/Index";
import { PipelineObjectHelpers } from "../../../Apis/Pipeline/PipelineObjectHelpers";
import { UpdateSFDCObject } from "../../../Apis/UpdateSFDCObject";
import TaskList from "../../Pipeline/Tasks/List";
import { GetContactForOpportunites } from "../../../Apis/Pipeline/GetContactForOpportunity";
import { GetSFDCObject } from "../../../Apis/GetSFDCObject";
import { AddContactToOpportunity } from "../../../Apis/Pipeline/AddContactToOpportunity";
import PipelineDrawerFormComponent from "../../Forms/Pipeline/Drawer/PipelineDrawerFormComponent";
import { RemoveSFDCObject } from "../../../Apis/Pipeline/RemoveSFDCObject";
import { GetContactForAccount } from "../../../Apis/Pipeline/GetContactForAccount";
import { setUpdateRecordErrorModal } from "../../../Store/ActionReducerPairs/Modals";
import { refreshPipelineTable } from "../../../Store/ActionReducerPairs/Pipeline";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import NoteList from "../../Pipeline/Notes/List";
import { GetUserDocuments } from "../../../Apis/Firebase/GetUserDocuments";

const { TabPane } = Tabs;
const { Option } = Select;

export const PipelineOpportunityDrawerComponent = ({
	initData,
}: {
	initData: { objectType: string; objectId: string };
}) => {
	const dispatch = useAppDispatch();
	const [childVisible, setChildVisible] = useState(false);
	const [loading, setLoading] = useState(false);
	const [objectData, setObjectData] = useState<any>(null);
	const [formHelpers, setFormHelpers] = useState<any>(null);
	const [fieldInfo, setFieldInfo] = useState<any[]>([]);
	const [fieldsToDisplay, setFieldsToDisplay] = useState<string[]>([]);
	const drawers = useAppSelector((state: any) => state.drawer.drawers);

	const closeDrawer = () => {
		dispatch({
			type: "CLOSE_DRAWER",
		});
	};

	const retrieveObjectBaseData = (data: any) => {
		setLoading(true);
		GetSFDCObjectDetailsV2(data.objectType, data.objectId).then((res: any) => {
			setFieldInfo([...res.field_info]);
			setObjectData({ ...res.data[0] });
			setFieldsToDisplay(res.sfdc_drawer_fields_for_display);
			setLoading(false);
		});
	};

	useEffect(() => {
		PipelineObjectHelpers().then((res: any) => {
			setFormHelpers(res);
		});
		retrieveObjectBaseData(initData);
		return () => {
			setObjectData(null);
		};
	}, [initData]);

	const showChildrenDrawer = () => setChildVisible(true);
	const onChildrenDrawerClose = () => setChildVisible(false);

	const UpdateTextOrNumberType = (value: any, o: any, multipleUpdate: any) => {
		UpdateSFDCObject(
			o.id,
			o.objectType,
			multipleUpdate ? value : { [o.objectDataKey]: value },
		).then((res: any) => {
			refreshPipelineTable(new Date());
			retrieveObjectBaseData(initData); //reload base data details
			if (o.objectType === "contact") {
				retrieveObjectBaseData({ objectType: "contact", objectId: o.id });
			}
			if (o.objectType === "account") {
				retrieveObjectBaseData({ objectType: "account", objectId: o.id });
			}
			getContactUsers(initData.objectId); //reload contact users
			res?.data.is_success
				? notification.success({
						message: "Opportunity updated successfully",
						placement: "bottomRight",
						duration: 1,
				  })
				: setUpdateRecordErrorModal({
						visible: true,
						errorMessage: res.data.message.message,
				  });
		});
	};

	const [contactUsersReadOnly, setContactUsersReadOnly] = useState<any>([]);
	const [contactUsers, setContactUsers] = useState<any>([]);
	const [notes, setNotes] = useState<any>([]);
	const [allNotes, setAllNotes] = useState<any>([]);

	const getContactUsers = (id: any) => {
		GetContactForOpportunites(id).then((res: any) => {
			setContactUsersReadOnly([...res.data]);
			setContactUsers([...res.data]);
		});
	};

	const getNotes = async () => {
		try {
			const res = await GetUserDocuments(initData.objectId);
			const notes = res
				.map((item: any) => ({
					id: item.id,
					...item.data(),
				}))
				.sort(
					(a, b) =>
						new Date(b.last_modified_at).getTime() -
						new Date(a.last_modified_at).getTime(),
				);
			setAllNotes(notes);
			setNotes(notes);
		} catch (e) {
			console.log(e);
		}
	};

	const handleContactRoleChange: any = (
		value: string,
		objectType: string,
		id: string | number,
	) => {
		UpdateTextOrNumberType({ Role: value }, { objectType, id }, true);
	};

	const clickContactMoreMenu: any = (
		menu: any,
		objectType: string,
		id: string,
	) => {
		if (menu.key === "primary") {
			UpdateTextOrNumberType({ isPrimary: true }, { objectType, id }, true);
		}
		if (menu.key === "remove") {
			RemoveSFDCObject(objectType, id).then((res: any) => {
				if (!res.isError) {
					refreshPipelineTable(new Date());
					getContactUsers(initData.objectId); //reload contact users
					notification.success({
						message: "Contact removed successfully",
						placement: "bottomRight",
						duration: 1,
					});
				} else {
					notification.error({
						message: "Facing some issue while contact remove",
						placement: "bottomRight",
						duration: 1,
					});
				}
			});
		}
	};

	const contactMore: any = (objectType: any, id: any) => {
		return (
			<Menu onClick={(value) => clickContactMoreMenu(value, objectType, id)}>
				<Menu.Item key="primary">Mark as Primary</Menu.Item>
				<Menu.Item key="remove">Delete</Menu.Item>
			</Menu>
		);
	};
	const searchInContact = (e: any) => {
		let value = ArrayObjectStringFilter(contactUsersReadOnly, e.target.value);
		setContactUsers([...value]);
	};

	const searchInNotes = (e: any) => {
		let value = ArrayObjectStringFilter(allNotes, e.target.value);
		setNotes([...value]);
	};

	type ContactType = "account" | "contact";

	interface ContactData {
		Name: string;
		Title: string;
		Email: string;
		Id: string;
		Type?: ContactType;
	}

	/* Account:: Assign contacts */
	const [allAvlContactsReadOnly, setAllAvlContactsReadOnly] = useState<
		ContactData[]
	>([]);
	const [allAvlContacts, setAllAvlContacts] = useState<ContactData[]>([]);

	const getAllContacts = async () => {
		interface SFDCResponseType {
			result: {
				records: ContactData[];
			};
		}

		const contactsResponse: SFDCResponseType = await GetSFDCObject(
			"contacts",
			true,
		);
		const accountsResponse: SFDCResponseType = await GetSFDCObject(
			"accounts",
			true,
		);

		const updatedContactsType = contactsResponse.result.records.map(
			(recordData) => ({
				...recordData,
				Type: "contact" as ContactType,
			}),
		);

		const updatedAccountsType = accountsResponse.result.records.map(
			(accountData) => ({
				...accountData,
				Type: "account" as ContactType,
			}),
		);

		const organizedContactData = [
			...updatedContactsType,
			...updatedAccountsType,
		];

		setAllAvlContactsReadOnly(organizedContactData);
		setAllAvlContacts(organizedContactData);
	};

	const getContactsFromAccount = async (accountId: string) => {
		const contactFromAccount: any[] = await GetContactForAccount(accountId);
		const typedContactsFromAccount = contactFromAccount.map((account) => ({
			...account,
			Type: "contact",
		}));
		setAllAvlContacts(typedContactsFromAccount);
	};

	const searchInAssignContact = (e: any) => {
		setAllAvlContacts([
			...ArrayObjectStringFilter(allAvlContactsReadOnly, e.target.value),
		]);
	};

	const assignContactToObject: any = (role: any, contactId: any) => {
		AddContactToOpportunity(role, initData.objectId, contactId).then(
			({ isError }: any) => {
				if (!isError) {
					onChildrenDrawerClose();
					notification.success({
						message: "Contact added successfully",
						placement: "bottomRight",
						duration: 1,
					});
					getContactUsers(initData.objectId);
				}
			},
		);
	};

	const updateSideDrawerConfig = (data: Record<string, unknown>) => {
		setLoading(true);
		UpdateSFDCSideDrawerConfig(
			data.field_name as string,
			data.field_type as string,
			"Opportunity",
			data.toggle_type as string,
			data.reference_to as Array<string>,
		).then(() => {
			retrieveObjectBaseData(initData);
		});
	};

	useEffect(() => {
		getContactUsers(initData.objectId);
		void getAllContacts();
		void getNotes();
	}, [initData]);

	const contactDrawer = (contactId: string) => {
		dispatch({
			type: "OPEN_DRAWER",
			payload: {
				drawerType: "pipeline",
				objectType: "Contact",
				objectId: contactId,
			},
		});
	};

	const assignContactTypeSelector: any = (id: string) => {
		return (
			<Menu onClick={(menu) => assignContactToObject(menu.key, id)}>
				{formHelpers.contactRoleList.map((role: any) => (
					<Menu.Item key={role}>{role}</Menu.Item>
				))}
			</Menu>
		);
	};

	const openInSalesforce = () => {
		const instanceUrl = localStorage.getItem("sfdcInstanceUrl") || "";
		window.open(`${instanceUrl}/${objectData.Id}`, "_blank");
	};

	const renderContactOption = (
		{ Name, Id, Title, Email, Type }: ContactData,
		index: number,
	) => {
		const drawContactInformation = () => (
			<div key={Name + index} className="pod_assign_contact_list_each">
				<div>
					<Dropdown
						trigger={["click"]}
						overlay={() => assignContactTypeSelector(Id)}
					>
						<div className="pod_assign_contact_list_each--title cur-pointer">
							{Name}
						</div>
					</Dropdown>
					<div className="pod_assign_contact_list_each--info">
						{Title || "No Title"}
					</div>
				</div>
				<div className="pod_assign_contact_list_each--email">
					{Email || "No Email"}
				</div>
			</div>
		);

		const drawAccountInformation = () => (
			<div key={Name + index} className="pod_assign_contact_list_each">
				<div onClick={() => getContactsFromAccount(Id)}>
					<div className="pod_assign_contact_list_each--title cur-pointer">
						{Name}
					</div>
					<div className="pod_assign_contact_list_each--info">{"Account"}</div>
				</div>
			</div>
		);

		return Type === "contact"
			? drawContactInformation()
			: drawAccountInformation();
	};

	return (
		<>
			{objectData && fieldInfo ? (
				<div className="pod_drawer pod_pipeline_drawer">
					<div className="pod_drawer_head">
						{drawers.length > 1 && (
							<div className="close-icon">
								<img src={BackIcon} alt="" onClick={closeDrawer} />
							</div>
						)}
						<div>
							<div className="pod_drawer_head--title">
								<span>{objectData.Name || "N/A"}</span>
								<ReactSVG src="/images/icons/SFDC-objects/opportunities.svg" />
							</div>
							<div className="pod_drawer_head--subtitle">
								{objectData.Account?.Name}
							</div>
						</div>
						<Tooltip
							placement="topLeft"
							title="Open in Salesforce"
							style={{ background: "none" }}
						>
							<span className="external-icon-wrapper">
								<img
									src="/images/icons/cloud.png"
									alt="external-icon"
									className="external-icon"
									onClick={openInSalesforce}
								/>
							</span>
						</Tooltip>
					</div>

					<div className="pod_opportunity_content">
						<Tabs defaultActiveKey="1">
							<TabPane tab="Details" key="1">
								<PipelineDrawerFormComponent
									initData={{
										...initData,
										fieldInfo,
										objectData,
									}}
									fieldsToDisplay={fieldsToDisplay}
									formData={objectData}
									objectType="Opportunity"
									onUpdate={() => retrieveObjectBaseData(initData)}
									onSave={(newValues) => setObjectData(newValues)}
									onUpdateSideDrawerConfig={updateSideDrawerConfig}
									loading={loading}
								/>
							</TabPane>
							<TabPane tab="Contacts" key="2">
								<div className="position-relative pod_assign_contact_wrap">
									<div className="pod_assign_contact">
										<Input
											className="custom_input_1"
											size="large"
											onChange={searchInContact}
											prefix={<AiOutlineSearch size={20} color="#999" />}
											placeholder="Search by Name"
										/>
										<Button
											className="pod_btn_secondary"
											onClick={showChildrenDrawer}
										>
											Assign Contact
										</Button>
									</div>
									<div className="pod_contact_list">
										{contactUsers.length ? (
											contactUsers.map(
												({
													OpportunityContactRoleId,
													contactId,
													name,
													isPrimary,
													role,
												}: any) => (
													<div
														key={OpportunityContactRoleId}
														className={`pod_contact_list_each ${
															isPrimary && "pod_primary_contact"
														}`}
													>
														<div className="d-flex justify-content-between align-items-center">
															<div
																className="pod_contact_list_each--title"
																onClick={() => contactDrawer(contactId)}
															>
																{name || "No name available"}
															</div>
															<div className="pod_contact_list_each--dropdown">
																<span>
																	<Select
																		value={role}
																		loading={formHelpers === null}
																		onChange={(value) =>
																			handleContactRoleChange(
																				value,
																				"OpportunityContactRole",
																				OpportunityContactRoleId,
																			)
																		}
																		notFoundContent={
																			!Object.keys(formHelpers ?? []).length
																				? "Search for a record ..."
																				: "No results found"
																		}
																	>
																		{Object.keys(formHelpers ?? []).length &&
																			formHelpers.contactRoleList.map(
																				(role: any) => (
																					<Option key={role} value={role}>
																						{role}
																					</Option>
																				),
																			)}
																	</Select>
																</span>

																<span className="pod_contact_list_each--options text-end">
																	<Dropdown
																		overlay={() =>
																			contactMore(
																				"OpportunityContactRole",
																				OpportunityContactRoleId,
																			)
																		}
																		trigger={["click"]}
																		placement="topLeft"
																	>
																		<a
																			className="ant-dropdown-link"
																			onClick={(e) => e.preventDefault()}
																		>
																			<img
																				src={MoreIcon}
																				alt=""
																				className="img-fluid"
																			/>
																			<DownOutlined />
																		</a>
																	</Dropdown>
																</span>
															</div>
														</div>
													</div>
												),
											)
										) : (
											<>No contact assigned...</>
										)}
									</div>
								</div>
							</TabPane>
							<TabPane tab="Tasks" key="3">
								<div className="position-relative pod_assign_contact_wrap">
									<TaskList
										opportunityId={initData.objectId}
										opportunityName={objectData.Name}
									/>
								</div>
							</TabPane>
							<TabPane tab="Notes" key="4">
								<div className="position-relative pod_assign_contact_wrap">
									<NoteList
										notes={notes}
										searchInNotes={searchInNotes}
										onClose={closeDrawer}
									/>
								</div>
							</TabPane>
						</Tabs>
						<Drawer
							title="Assign Contact"
							width={320}
							closable={true}
							onClose={onChildrenDrawerClose}
							visible={childVisible}
							getContainer=".pod_assign_contact_wrap"
						>
							<AutoComplete className="w-100 mt-2 mb-3">
								<Input
									onChange={searchInAssignContact}
									className="custom_input_1"
									size="large"
									prefix={<AiOutlineSearch size={20} color="#999" />}
									placeholder="Search by Name"
								/>
							</AutoComplete>

							<div className="pod_assign_contact_list">
								{allAvlContacts.length === 0
									? "No contact found"
									: allAvlContacts.map((contact, index) =>
											renderContactOption(contact, index),
									  )}
							</div>
						</Drawer>
					</div>
				</div>
			) : (
				<div className="my-5 px-3">
					<Skeleton active paragraph={{ rows: 10 }}></Skeleton>
				</div>
			)}
		</>
	);
};
