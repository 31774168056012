import { Row, Skeleton } from "antd";
import HighTrendingIcon from "../../../../Images/HighTrending.svg";
import "./style.scss";

export const InsightBox = (props: {
	title: string;
	description: string;
	value: string;
	engagement?: boolean;
	loading?: boolean;
}) => {
	const { engagement, value, description, title, loading } = props;

	return (
		<div className="insight-container">
			{!loading ? (
				<>
					<p className="insight-title">{title}</p>
					{engagement ? (
						<Row justify="start">
							<p className="insight-value engagement-value">{value}</p>
							<img
								src={HighTrendingIcon}
								className="engagement-icon"
								style={{ marginLeft: 10 }}
							/>
						</Row>
					) : (
						<p className="insight-value">{value}</p>
					)}
					<p className="insight-description">{description}</p>
				</>
			) : (
				<Skeleton active paragraph={{ rows: 1 }}></Skeleton>
			)}
		</div>
	);
};
