import api from "./api";

export const CreateGcalEvent = async (data: any, opportunityId: string) => {
	const pod_user_id = Number(localStorage.getItem("podUserId"));

	return api
		.post("/create_gcal_event", {
			data,
			opportunityId,
			pod_user_id,
		})
		.then((res) => res.data);
};
