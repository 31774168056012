import { firebaseApp } from "./Config";
import { doc, setDoc, getFirestore } from "firebase/firestore";
import moment from "moment";

export const CreateTemplate = async (title: string, body: string) => {
	const db = getFirestore(firebaseApp);
	const pod_user_id = localStorage.getItem("podUserId");
	const reference = `${Date.now()}${pod_user_id}`;
	const document = doc(db, "document_templates", reference);

	await setDoc(document, {
		title,
		body,
		pod_user_id,
		created_at: moment().format().split("T")[0],
		last_modified_at: moment().format().split("T")[0],
	});

	return reference;
};
