import { Popover } from "antd";
import { useState } from "react";
import DropdownSearchAutoComplete from "./DropdownSearchAutoComplete";

export const DropdownSearch = (props: {
	currentId: string;
	currentName: string;
	objectType: string;
	updateData?: any;
	UpdateTextorNumberType: any;
	borderless?: boolean;
	textLength?: number;
}) => {
	const {
		currentId,
		currentName,
		updateData,
		UpdateTextorNumberType,
		objectType,
		borderless,
		textLength,
	} = props;
	const [visible, setVisible] = useState(false);

	const onMenuClick = ({ key }: Record<string, string>, value: string) => {
		UpdateTextorNumberType(key, updateData, value);
		setVisible(false);
	};

	const handleVisibleChange = (visible: boolean) => {
		setVisible(visible);
	};

	const length = textLength || 26;
	const displayValue =
		currentName?.length > length
			? `${currentName.slice(0, length - 3)}...`
			: currentName;

	return (
		<Popover
			visible={visible}
			onVisibleChange={handleVisibleChange}
			placement="bottomLeft"
			trigger="click"
			content={
				<DropdownSearchAutoComplete
					onMenuClick={onMenuClick}
					objectType={objectType}
				/>
			}
		>
			{/* <div className="display-container" style={borderless ? { border: "none" } : {}}> */}
			<div className="display-value">{displayValue || "NA"}</div>
			{/* </div> */}
		</Popover>
	);
};
