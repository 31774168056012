import React, { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import { Popover } from "antd";
import {
	GetOpportunityAndAccountName,
	OpportunityAndAccountName,
} from "../../../Apis/GetOpportunityAndAccountName";
import { BsLightning } from "react-icons/bs";
import { useAppDispatch } from "../../../hooks";
import moment from "moment";
import { GetUpcomingMeetings } from "../../../Apis/GetUpcomingMeetings";
import { GetInactiveOpportunities } from "../../../Apis/GetInactiveOpportunities";
import { GetEmailsForActivityHub } from "../../../Apis/GetEmailsForActivityHub";
import { GetInternalMeetingsForActivityHub } from "../../../Apis/GetInternalMeetingsForActivityHub";
import { GetSFDCObjectDetailsV2 } from "../../../Apis/GetSFDCObjectDetailsV2";
import { needsFollowUp } from "../../../Utils/Index";
import { isGoogleIntegrationEnabled } from "../../../Apis/Integration/google";
import { useNavigate } from "react-router-dom";
import { AiOutlineBulb } from "react-icons/ai";
import { TopInsightsActivityDataRenderComponent } from "../../../Components/Shared/TopInsightsActivityDataRenderComponent";

export const Subheader = ({
	openHelper,
}: { openHelper: (helper: string) => void }) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const queryParams = new URLSearchParams(window.location.search);
	const opportunityId = queryParams.get("opportunity_id");
	const [opportunityAndAccountNames, setOpportunityAndAccountNames] = useState<
		OpportunityAndAccountName | any
	>({});
	const [popupVisible, setPopupVisible] = useState(false);
	const [newEventData, setNewEventData] = useState<Record<string, string>>({});
	const [hasAlert, setHasAlert] = useState(false);
	const [isGoogleAuthed, setIsGoogleAuthed] = useState<boolean>(false);

	useEffect(() => {
		isGoogleIntegrationEnabled().then((res: boolean) => setIsGoogleAuthed(res));
		window.CommandBar.addCallback("create_new_meeting", () => {
			return isGoogleAuthed
				? openNewEventDrawer(newEventData)
				: navigate("/dashboard/calendar-view");
		});
	}, []);

	useEffect(() => {
		GetOpportunityAndAccountName(opportunityId!).then((r) => {
			setOpportunityAndAccountNames(r);
		});
	}, []);

	useEffect(() => {
		const opportunity =
			new URLSearchParams(location.search).get("opportunity_id") || "";
		const startDate = moment().startOf("month").format().split("T");
		const endDate = moment().format().split("T");
		const formattedEndDate = `${endDate[0].replaceAll("-", "/")}T${endDate[1]}`;
		const formattedStartDate = `${startDate[0].replaceAll("-", "/")}T${
			startDate[1]
		}`;
		Promise.all([
			GetUpcomingMeetings(opportunity),
			GetInactiveOpportunities(opportunity),
			GetEmailsForActivityHub(
				opportunity,
				formattedStartDate,
				formattedEndDate,
			),
			GetInternalMeetingsForActivityHub(
				opportunity,
				formattedStartDate,
				formattedEndDate,
			),
			GetSFDCObjectDetailsV2("Opportunity", opportunity || "", ""),
		]).then((res: any) => {
			setHasAlert(
				res[1].is_inactive ||
					res[0].has_upcoming_meeting ||
					needsFollowUp(res[2], res[3], res[4].data[0]),
			);
		});
	}, []);

	const openNewEventDrawer = (data: any) => {
		dispatch({
			type: "OPEN_DRAWER",
			payload: {
				drawerType: "new-event",
				opportunityId: data.opportunityId,
				opportunityName: data.opportunityName,
			},
		});
	};

	return (
		<div className="d-flex justify-content-between align-items-center subheader">
			<div className="row d-flex justify-content-start">
				<span
					className="d-flex activity_page_heading2 mb-0"
					style={{ width: "auto" }}
				>
					<ReactSVG
						src="/images/SFDC/Opportunity.svg"
						style={{ fontSize: "14px" }}
					/>
					<span>{opportunityAndAccountNames.opportunity_name} </span>
				</span>
				<span
					className="d-flex activity_page_heading3"
					style={{ width: "auto" }}
				>
					<ReactSVG
						src="/images/icons/SFDC-objects/accounts.svg"
						style={{ fontSize: "14px" }}
					/>
					<span>{opportunityAndAccountNames.account_name} </span>
				</span>
			</div>
			<div className="d-flex align-items-center activity_page_timeline">
				<Popover
					overlayClassName="activity_page_insights"
					content={
						<TopInsightsActivityDataRenderComponent
							close={() => setPopupVisible(false)}
							openNewEventModal={(data: any) => {
								setNewEventData(data);
								openNewEventDrawer(data);
							}}
						/>
					}
					trigger={"click"}
					destroyTooltipOnHide
					placement="bottomRight"
					visible={popupVisible}
					onVisibleChange={setPopupVisible}
				>
					<span className="activity_page_insights--icon">
						<BsLightning size={18} />
						{Boolean(hasAlert) && (
							<span
								className="pointer-more-items"
								style={{ width: 8, height: 8, top: 4, left: 18 }}
							></span>
						)}
					</span>
				</Popover>
				<div
					className="activity_page_insights--icon"
					onClick={() => {
						// TODO segment call should be handled in the reducer (once migrated to redux)
						// TODO instead of at every call site
						window.analytics.track("ActivityHub_KnowledgeCenter_Open", {
							podUserId: localStorage.getItem("podUserId"),
						});
						openHelper("knowledge-center");
					}}
				>
					<AiOutlineBulb size={18} />
				</div>
				<div
					className="activity_page_insights--icon"
					onClick={() => {
						window.analytics.track("ActivityHub_EmailGeneration_Open", {
							podUserId: localStorage.getItem("podUserId"),
						});
						openHelper("generate-email");
					}}
				>
					<ReactSVG src="/images/icons/EditLight.svg" />
				</div>
				<div
					className="activity_page_insights--icon"
					onClick={() => {
						dispatch({
							type: "OPEN_DRAWER",
							payload: {
								drawerType: "pipeline",
								objectType: "Opportunity",
								objectId: opportunityId,
							},
						});
					}}
				>
					<ReactSVG src="/images/icons/SFDC-objects/opportunities.svg" />
				</div>
			</div>
		</div>
	);
};
