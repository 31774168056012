import { AnyAction } from "redux";
import { defaultFilter } from "../../Components/Pipeline/MasterFilter";
import { makeAction } from "./Action";

const defaultState = {
	category: "opportunities",
	filters: {
		opportunities: [defaultFilter],
		accounts: [defaultFilter],
		contacts: [defaultFilter],
		leads: [defaultFilter],
	},
	fieldsInfo: {
		opportunities: [],
		accounts: [],
		contacts: [],
		leads: [],
	},
};

export const refreshPipelineTable = makeAction<Date>(
	"UPDATE_PIPELINE_DATA_STAMP",
);
export const togglePipelineCreateModal = makeAction<any>(
	"TOGGLE_PIPELINE_CREATE_MODAL",
);
export const updatePipelineFilter = makeAction<any>(
	"UPDATE_PIPELINE_FILTERS_OBJECT",
);
export const clearPipelineFilters = makeAction(
	"UPDATE_PIPELINE_FILTERS_OBJECT",
);
export const changeActiveCategory = makeAction<string>(
	"CHANGE_PIPELINE_CATEGORY",
);
export const clearPipeline = makeAction("CLEAR_PIPELINE_DATA");

export const pipelineReducer = (
	state: any = defaultState,
	action: AnyAction,
) => {
	if (refreshPipelineTable.guard(action)) {
		return {
			...state,
			stamp: action.payload,
		};
	} else if (togglePipelineCreateModal.guard(action)) {
		return {
			...state,
			modalVisible: action.payload.modalVisible || false,
			selectedModal: action.payload.selectedModal || {},
		};
	} else if (updatePipelineFilter.guard(action)) {
		return {
			...state,
			filters: {
				...state.filters,
				opportunities: action.payload.opportunities ||
					state.filters.opportunities || [defaultFilter],
				accounts: action.payload.accounts ||
					state.filters.accounts || [defaultFilter],
				contacts: action.payload.contacts ||
					state.filters.contacts || [defaultFilter],
				leads: action.payload.leads || state.filters.leads || [defaultFilter],
			},
		};
	} else if (clearPipelineFilters.guard(action)) {
		return {
			...state,
			filters: {
				...state.filters,
				opportunities: [defaultFilter],
				accounts: [defaultFilter],
				contacts: [defaultFilter],
				leads: [defaultFilter],
			},
		};
	} else if (changeActiveCategory.guard(action)) {
		return {
			...state,
			category: action.payload,
		};
	} else if (clearPipeline.guard(action)) {
		return {
			...defaultState,
		};
	}

	return state;
};
