import { useAppDispatch, useAppSelector } from "../../hooks";
import { CustomerMeetingDrawer } from "./Activity/CustomerMeetingDrawer";
import { EmailDrawer } from "./Activity/EmailDrawer";
import { PhoneCallDrawer } from "./Activity/PhoneCallDrawer";
import { EditEventDrawer } from "./Event/EditEventDrawer";
import { NewEventDrawer } from "./Event/NewEventDrawer";
import { PipelineDrawerComponent } from "./Pipeline/PipelineDrawerComponent";

export interface DrawerProps {
	visible: boolean;
	initData: any;
	onClose: VoidFunction;
}

export const DrawerList = () => {
	const dispatch = useAppDispatch();
	const drawers = useAppSelector((state: any) => state.drawer.drawers);

	const closeAll = () => {
		dispatch({
			type: "CLOSE_ALL",
		});
	};

	if (!drawers?.length) {
		return null;
	}

	switch (drawers[0].drawerType) {
		case "pipeline":
			return (
				<PipelineDrawerComponent
					visible={true}
					initData={drawers[0]}
					onClose={closeAll}
				/>
			);
		case "new-event":
			return (
				<NewEventDrawer
					visible={true}
					initData={drawers[0]}
					onClose={closeAll}
				/>
			);
		case "edit-event":
			return (
				<EditEventDrawer
					visible={true}
					initData={drawers[0]}
					onClose={closeAll}
				/>
			);
		case "customerMeetings":
		case "internalMeetings":
			return (
				<CustomerMeetingDrawer
					visible={true}
					initData={drawers[0]}
					onClose={closeAll}
				/>
			);
		case "emails":
			return (
				<EmailDrawer visible={true} initData={drawers[0]} onClose={closeAll} />
			);
		case "phoneCalls":
			return (
				<PhoneCallDrawer
					visible={true}
					initData={drawers[0]}
					onClose={closeAll}
				/>
			);
	}

	return null;
};
