import {
	Drawer,
	Button,
	Row,
	Col,
	Tabs,
	Spin,
	Skeleton,
	Tooltip,
	notification,
} from "antd";
import moment from "moment";
import { useState, useEffect } from "react";
import { BsTelephone } from "react-icons/bs";
import { ReactSVG } from "react-svg";
import CloudIcon from "../../../Images/Cloud.svg";
import { MdCallMade, MdCallReceived } from "react-icons/md";
import { FiCalendar } from "react-icons/fi";
import { DropdownSearch } from "../../../Components/DropdownSearchV2";
import { GetActivityDrawerDetails } from "../../../Apis/GetActivityDrawerDetails";
import { DrawerProps } from "../DrawerList";

export const PhoneCallDrawer = ({
	visible,
	onClose,
	initData,
}: DrawerProps) => {
	const { data, sfdcOpportunityName, sfdcOpportunityId } = initData;
	const { TabPane } = Tabs;

	const [opportunityName, setOpportunityName] = useState<string>("");
	const [opportunityId, setOpportunityId] = useState<string>("");
	const [details, setDetails] = useState<any>();
	const [loader, setLoader] = useState(false);

	useEffect(() => {
		setDetails(null);
		setOpportunityName("");
		setOpportunityId("");
		if (data && data.source && data.category && data.reference_id) {
			GetActivityDrawerDetails(
				data.reference_id,
				data.source,
				data.category,
			).then((res: any) => {
				setDetails(res[0]);
				setOpportunityId(sfdcOpportunityId);
				setOpportunityName(sfdcOpportunityName);
			});
		}
	}, [data]);

	const handleSubmit = () => {
		notification.warn({
			message:
				"This data is already from SFDC. Therefore, it is already synced",
			placement: "bottomRight",
			duration: 1,
		});
	};

	return (
		<Drawer
			title=""
			placement="right"
			width={400}
			onClose={() => onClose()}
			visible={visible}
			style={{
				marginTop: "64px",
				paddingRight: "10px",
			}}
			className="customer_drawer_wrapper"
		>
			{!details ? (
				<div className="my-5 px-3">
					<Skeleton active paragraph={{ rows: 10 }}></Skeleton>
				</div>
			) : (
				<div className="pod_drawer customer_drawer">
					{/*<span className="pod_drawer_meeting_title">This call is from Salesforce</span>*/}
					<div className="pod_drawer_head">
						<div className="d-flex justify-content-between">
							<div className="pod_drawer_head--title">
								<span className="telephone-icon">
									<BsTelephone color="#fff" />
								</span>
								<span className="pod_drawer_meeting_title">
									{" "}
									{details?.Subject || ""}
								</span>
							</div>
						</div>

						<div
							className="pod_drawer_head--subtitle"
							style={{ paddingLeft: 0 }}
						>
							<Spin spinning={loader}>
								<Row style={{ alignItems: "center" }}>
									<Col span={2}>
										<ReactSVG
											className="crown-icon"
											src="/images/icons/SFDC-objects/opportunities.svg"
										/>
									</Col>
									<Col span={16} style={{ paddingLeft: 10 }}>
										<DropdownSearch
											currentName={opportunityName}
											currentId={opportunityId}
											objectType="Opportunity"
											UpdateTextOrNumberType={(
												value: string,
												_: unknown,
												name: string,
											) => {
												setOpportunityId(value);
												setOpportunityName(name);
											}}
											size="middle"
											disabled
										/>
									</Col>
									<Col span={6}>
										<div className="pod_stage_syn--btn">
											<Tooltip
												placement="topLeft"
												title="This data is already from SFDC. Therefore, it is already synced"
												style={{ background: "none" }}
											>
												<Button
													type="primary"
													onClick={handleSubmit}
													style={{
														height: "32px",
														marginLeft: "10px",
														borderRadius: 4,
														backgroundColor: "#d9d9d9",
													}}
													icon={
														<img
															src={CloudIcon}
															className="img-fluid"
															style={{ marginRight: 4 }}
														></img>
													}
													disabled={data?.source !== "gcal"}
												>
													{" "}
													Sync
												</Button>
											</Tooltip>
										</div>
									</Col>
								</Row>
							</Spin>
						</div>
					</div>
					<div className="pod_drawer_meeting_details_area">
						<div className="row meeting_date_time_container">
							<div className="col-md-1">
								<FiCalendar
									style={{
										alignSelf: "center",
										width: "23px",
										height: "23px",
										marginLeft: "-5px",
									}}
								/>
							</div>
							<div className="col-md-11">
								{data &&
									moment(data.date?.replaceAll("/", "-")).format(
										"dddd, DD MMMM YYYY",
									)}
							</div>
						</div>
						<Tabs defaultActiveKey="1" style={{ width: "100%" }}>
							<TabPane tab="Details" key="1">
								<div
									style={{
										display: "flex",
										justifyContent: "space-between",
										width: "100%",
									}}
								>
									<div style={{ textAlign: "left" }}>
										<div className="small-text-phone">
											Caller <MdCallMade size={18} color="#0E7537" />{" "}
										</div>
										<div className="caller-name">
											{details.from?.name || "Unknown"}
										</div>
										<div className="small-text-phone">
											{details.from?.title || "No Title"}
										</div>
									</div>
									<div style={{ textAlign: "right" }}>
										<div className="small-text-phone">
											{" "}
											<MdCallReceived size={18} color="#FF0000" /> Receiver{" "}
										</div>
										<div className="receiver-name">
											{details.to?.name || "Unknown"}
										</div>
										<div className="small-text-phone">
											{details.to?.title || "No Title"}
										</div>
									</div>
								</div>

								<div
									className="d-flex justify-content-between py-2 my-2"
									style={{
										borderTop: "1px solid #E9EBF0",
										borderBottom: "1px solid #E9EBF0",
									}}
								>
									<div className="small-text-phone">
										Dialed Number:{" "}
										{details && details.phone ? details.phone : " N/A"}{" "}
									</div>
								</div>

								<Row
									gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
									style={{
										fontSize: "16px",
										marginTop: "16px",
										paddingTop: "10px",
									}}
								>
									<Col className="gutter-row" span={24}>
										<span
											style={{
												fontWeight: "600",
												color: "#1B1B1C",
												fontSize: "15px",
												borderBottom: "1px solid E9EBF0",
											}}
										>
											Description
										</span>
									</Col>
									<Col
										className="gutter-row"
										span={24}
										style={{
											fontSize: "14px",
											marginTop: "10px",
										}}
									>
										<div>{details && details.description}</div>
									</Col>
								</Row>
							</TabPane>
							<div
								style={{
									position: "relative",
									right: "25px",
									bottom: "52.5px",
								}}
							>
								<img
									style={{ width: 30 }}
									src="/images/icons/cloud.png"
									alt="sfdc-icon"
								/>
							</div>
						</Tabs>
					</div>
				</div>
			)}
		</Drawer>
	);
};
