import {
	Button,
	DatePicker,
	Input,
	Select,
	Spin,
	Switch,
	Tooltip,
	notification,
} from "antd";
import { ReactNode, useEffect, useState } from "react";
import { BaseLayoutComponent } from "../Components/Shared/BaseLayoutComponent";
import "./DocumentHubNotesPage.styles.scss";
import moment from "moment";
import { API_FETCH, isValidSfdcId } from "../Utils/Index";
import CloudIcon from "../Images/Cloud.svg";
import { ReactSVG } from "react-svg";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Link, useNavigate, useParams } from "react-router-dom";
import { HiOutlineChevronUp } from "react-icons/hi";
import Editor from "rich-markdown-editor";
import { DropdownSearch } from "../Components/DropdownSearchV2";
import { GetSingleDocument } from "../Apis/Firebase/GetSingleDocument";
import { GetUserTemplates } from "../Apis/Firebase/GetUserTemplates";
import { UpdateDocument } from "../Apis/Firebase/UpdateDocument";
import { UploadImage } from "../Apis/Firebase/UploadImage";
import { CreateOrUpdateSFDCNoteObject } from "../Apis/CreateOrUpdateSFDCNoteObject";
import GoogleIcon from "../Images/GoogleIcon.png";
import { isGoogleIntegrationEnabled } from "../Apis/Integration/google";
import PodGoogleLogin from "../Components/GoogleLogin";
import clsx from "clsx";
import { BiSearch } from "react-icons/bi";
import { BsLightning } from "react-icons/bs";
import { NotesAssistantDrawer } from "../Components/Drawer/DocumentHub/NotesAssistant/NotesAssistantDrawer";
import {
	getSObjectsByIds,
	searchSObjectsByName,
	SObjectSearchResult,
} from "../Apis/multiSObject";

const { Option } = Select;

const limitedSize = (text: string, size: number) =>
	text.length > size ? `${text.slice(0, size - 3)}...` : text;
const inlineTemplate = (template: string, id: string) =>
	`[${limitedSize(template, 55)}](https://${id})\n`;

const documentTypes = [
	"Meeting Notes",
	"Account Plan",
	"Personal Notes",
	"Others",
];
const iconUrls: { [key: string]: string } = {
	Opportunity: "/images/icons/SFDC-objects/opportunities.svg",
	Account: "/images/icons/SFDC-objects/accounts.svg",
	Contact: "/images/icons/SFDC-objects/contacts.svg",
	Lead: "/images/icons/SFDC-objects/leads.svg",
	Custom: "/images/icons/Shared.svg",
};

const FormItemWrapper = ({
	icon,
	title,
	value,
	defaultData,
	isEditable,
	content,
}: {
	icon: ReactNode;
	title: string;
	value?: ReactNode;
	defaultData?: any;
	isEditable?: boolean;
	content?: (
		onEditEnd: VoidFunction,
		loading?: boolean,
		setLoading?: any,
		value?: any,
		setValue?: any,
	) => ReactNode;
}) => {
	const [loading, setLoading] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [data, setData] = useState(defaultData);

	useEffect(() => {
		setData(defaultData);
	}, [defaultData]);

	return (
		<div className="doc_wrapper_meta_content_item">
			<div className="doc_wrapper_meta_content_title">
				<div className="title_icon">{icon}</div>
				<div className="title_label">{title}</div>
			</div>
			{isEditing && content ? (
				content(() => setIsEditing(false), loading, setLoading, data, setData)
			) : (
				<div
					className={clsx(
						"doc_wrapper_meta_content_value",
						isEditable && "editable",
					)}
					onClick={() => isEditable && setIsEditing((v) => !v)}
				>
					{value}
				</div>
			)}
		</div>
	);
};

export const DocumentHubNotesPage = () => {
	let { docId } = useParams(); // TODO proper route validation
	const navigate = useNavigate();
	const [hideMeta, setHideMeta] = useState(false);
	const [emptyTemplate, setEmptyTemplate] = useState(false);
	const [inlineTemplates, setInlineTemplates] = useState("");
	const [templates, setTemplates] = useState<any>([]);
	const [_, setRefresh] = useState(0);
	const [monthName, setMonth] = useState(moment());
	const [stakeholders, setStakeholders] = useState<SObjectSearchResult[]>([]);
	const [privateDoc, setPrivateDoc] = useState(false);
	const [showAssistant, setShowAssistant] = useState(false);
	const [meetingData, setMeetingData] = useState<any>([]);
	const [currentDocContent, setCurrentDocContent] = useState<any>({});
	const [document, setDocument] = useState<any>({});
	const [filteredDocTypes, setFilteredDocTypes] = useState(documentTypes);
	const [content, setContent] = useState<string>();
	const [isGoogleAuthed, setIsGoogleAuthed] = useState<boolean>(false);
	const [stakeholderOptions, setStakeholderOptions] = useState<
		SObjectSearchResult[]
	>([]);

	const handleChange = (arg: any, direction: any) => {
		if (direction == "left") {
			setMonth(moment(monthName).subtract(1, "month"));
		} else if (direction == "right") {
			setMonth(moment(monthName).add(1, "month"));
		} else {
			setMonth(moment(arg, "YYYY-MM"));
		}
	};

	useEffect(() => void fetchMeetingData(), [monthName]);

	useEffect(() => {
		if (!currentDocContent.stakeholders) {
			setStakeholders([]);
			return;
		}
		const stakeholderIds = currentDocContent.stakeholders.filter((x: string) =>
			isValidSfdcId(x),
		);
		if (stakeholderIds.length === 0) {
			setStakeholders([]);
			return;
		}
		getSObjectsByIds(["Contact", "Lead"], stakeholderIds).then((r) =>
			setStakeholders(r),
		);
	}, [currentDocContent]);

	useEffect(() => {
		isGoogleIntegrationEnabled().then((res: boolean) => setIsGoogleAuthed(res));
		GetUserTemplates().then((res) => {
			let displayTemplates = "";
			displayTemplates += inlineTemplate("Start without a template", "empty");
			res.forEach((item) => {
				displayTemplates += inlineTemplate(item.data().title, item.id);
			});
			setTemplates(res);
			setInlineTemplates(displayTemplates);
		});
		(async () => {
			await fetchMeetingData();
			void fetchDocumentDetails();
		})();
	}, []);

	const fetchMeetingData = async () => {
		const myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		let requestOptions: any = {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				pod_user_id: Number(localStorage.getItem("podUserId")),
				start_time: monthName.startOf("day").format(),
				end_time: monthName.endOf("day").format(),
			}),
		};
		fetch(API_FETCH("/get_meetings_for_date"), requestOptions)
			.then((resp) => resp.json())
			.then((resp_body) => {
				setMeetingData(
					resp_body.items?.map((h: any) => {
						return { id: h.id, summary: h.summary };
					}),
				);
			});
	};

	const fetchDocumentDetails = async () => {
		const res = await GetSingleDocument(docId ?? "");
		if (
			res?.is_private &&
			res?.pod_user_id !== localStorage.getItem("podUserId")
		) {
			notification.error({
				message: "You don't have access to this document",
				placement: "bottomRight",
				duration: 1,
			});
			navigate("/document-hub/summary-page");
			return;
		}
		setDocument(res);
		console.log("---doc details---", res);
		setCurrentDocContent({
			docContent: res?.content,
			docTitle: res?.title,
			gcalEventId: res?.event_id,
			docId: docId,
			sfdcAccountId: res?.account_id,
			sfdcOppId: res?.opportunity_id,
			docType: res?.document_type,
			stakeholders: res?.stakeholders,
		});
		setContent(res?.content);
		setPrivateDoc(res?.is_private);
		setMonth(moment(res?.event_date || undefined));
	};

	useEffect(() => {
		if (document?.created_at) {
			void updateDocument("is_private", privateDoc);
		}
	}, [privateDoc]);

	useEffect(() => {
		void updateDocument("content", content, "", true);
	}, [content]);

	const syncGcalEventIdWithDoc = async (gcalEventId: string) => {
		window.analytics.track("DocumentHub_Sync_Meeting", {
			podUserId: localStorage.getItem("podUserId"),
		});

		await UpdateDocument(docId ?? "", {
			...document,
			event_id: gcalEventId || "",
			event_date: gcalEventId ? moment(monthName).format().split("T")[0] : "",
		});
	};

	const updateStateDocTitle = (newVal: string) => {
		setCurrentDocContent({
			...currentDocContent,
			...{ docTitle: newVal },
		});
	};

	const updateDocument = async (
		key: string,
		value: unknown,
		name?: string,
		hideToast?: boolean,
	) => {
		await new Promise((resolve) => {
			setDocument((doc: any) => {
				const params = {
					...doc,
					[key]: value,
					...(key === "opportunity_id" ? { opportunity_name: name } : {}),
					...(key === "account_id" ? { account_name: name } : {}),
				};

				UpdateDocument(docId ?? "", params).then(() => {
					if (!hideToast) {
						notification.success({
							message: "Update successful",
							placement: "bottomRight",
							duration: 1,
						});
					}

					resolve(params);
				});
				return params;
			});
		});
	};

	const syncDocument = () => {
		CreateOrUpdateSFDCNoteObject(
			document.title,
			document.content,
			document.opportunity_id,
			document.account_id,
			document.sfdc_note_id,
		).then((res) => {
			window.analytics.track("DocumentHub_Sync_Document", {
				podUserId: localStorage.getItem("podUserId"),
			});

			void UpdateDocument(docId ?? "", {
				...document,
				...(res.sfdc_note_id ? { sfdc_note_id: res.sfdc_note_id } : {}),
				sfdc_updation_unix_timestamp: res.sfdc_updation_unix_timestamp,
			});

			setDocument({
				...document,
				...(res.sfdc_note_id ? { sfdc_note_id: res.sfdc_note_id } : {}),
				sfdc_updation_unix_timestamp: res.sfdc_updation_unix_timestamp,
			});
		});
	};

	const handleClickLink = (href: string, event: any) => {
		event.preventDefault();
		const templatedId = href.replace("https:", "").replaceAll("/", "");
		if (templatedId === "empty") {
			setEmptyTemplate(true);
			void updateDocument("content", "\\");
			setCurrentDocContent((prev: any) => ({
				...prev,
				docContent: "\\",
			}));
		} else {
			const template = templates.find((i: any) => i.id === templatedId);
			if (template) {
				void updateDocument("content", template.data().body);
				setCurrentDocContent((prev: any) => ({
					...prev,
					docContent: template.data().body,
				}));
			}
		}
	};

	const cleanedContent = currentDocContent.docContent
		? currentDocContent.docContent
				.replaceAll("\\", "")
				.replaceAll(" ", "")
				.replaceAll("\n", "")
		: "";

	const { sfdc_updation_unix_timestamp } = document;
	return (
		<BaseLayoutComponent
			pageTitle={
				<h3 className="notes_header">
					<Link to={"/document-hub/summary-page"} className="notes_header_back">
						<AiOutlineArrowLeft />
					</Link>
					Notes
				</h3>
			}
		>
			<div className="notes_wrapper">
				<div className="notes_wrapper_subheader">
					<div
						className="notes_wrapper_subheader--icon"
						onClick={() => setShowAssistant(true)}
					>
						<BsLightning size={18} />
					</div>
				</div>
				<div className="notes_wrapper_content">
					<div className={clsx("notes_wrapper_form", showAssistant && "left")}>
						{!currentDocContent.docId ? (
							<div className="loader">
								<Spin />
							</div>
						) : (
							<div className="doc_wrapper">
								<div className="doc_wrapper_title">
									<Input
										className="title_input_box"
										type="text"
										value={currentDocContent.docTitle}
										onChange={(e) => {
											updateStateDocTitle(e.target.value);
										}}
										onBlur={(e) => {
											void updateDocument("title", e.target.value);
										}}
										placeholder="Enter Document Name"
									/>

									<div className="doc_wrapper_title_sync">
										{!document.title ||
										!document.content ||
										(!document.opportunity_id && !document.account_id) ||
										document.is_private ? (
											<Tooltip
												placement="topLeft"
												title={
													document.is_private
														? "Document must not be private"
														: "Title, Body, and Opportunity/Account need to be present to sync"
												}
											>
												<Button
													type="primary"
													icon={
														<img src={CloudIcon} className="img-fluid"></img>
													}
													disabled
												>
													Sync
												</Button>
											</Tooltip>
										) : (
											<Button
												type="primary"
												onClick={syncDocument}
												icon={<img src={CloudIcon} className="img-fluid"></img>}
											>
												Sync
											</Button>
										)}
										{!hideMeta && (
											<div className="doc_wrapper_title_timestamp">
												{sfdc_updation_unix_timestamp
													? `Last synced on ${moment(
															sfdc_updation_unix_timestamp * 1000,
													  ).format("YYYY-MM-DD")} at ${moment(
															sfdc_updation_unix_timestamp * 1000,
													  ).format("HH:mm")}`
													: ""}
											</div>
										)}
									</div>
								</div>

								<div className="doc_wrapper_meta">
									<div
										className={clsx(
											"doc_wrapper_meta_content",
											hideMeta && "collapsed",
										)}
									>
										<FormItemWrapper
											icon={<ReactSVG src="/images/icons/CreatedOn.svg" />}
											title="Created On"
											value={
												moment(document.created_at).format("MMM DD, YYYY") ||
												"Empty"
											}
										/>
										<FormItemWrapper
											icon={<ReactSVG src="/images/icons/CreatedBy.svg" />}
											title="Created By"
											value={document.created_by}
										/>
										<FormItemWrapper
											icon={<ReactSVG src="/images/icons/LastModified.svg" />}
											title="Last Modified"
											value={
												moment(document.last_modified_at).format(
													"MMM DD, YYYY",
												) || "Empty"
											}
										/>
										<FormItemWrapper
											icon={<ReactSVG src="/images/icons/DocumentType.svg" />}
											title="Document Type"
											value={currentDocContent.docType || "Empty"}
											isEditable
											content={(onEditEnd, loading, setLoading) => (
												<Select
													defaultValue={currentDocContent.docType}
													onChange={async (val) => {
														setLoading(true);
														await updateDocument("document_type", val);
														await fetchDocumentDetails();
														onEditEnd();
														setLoading(false);
													}}
													autoFocus
													onBlur={onEditEnd}
													className="doc_wrapper_meta_select"
													showSearch
													onSearch={(value) =>
														setFilteredDocTypes(
															documentTypes.filter((item) =>
																item
																	.toLowerCase()
																	.includes(value.toLowerCase()),
															),
														)
													}
													loading={loading}
												>
													{filteredDocTypes.map((type) => (
														<Option
															value={type}
															key={type}
															label={
																<>
																	<BiSearch />
																	&nbsp;{type}
																</>
															}
														>
															{type}
														</Option>
													))}
												</Select>
											)}
										/>
										<FormItemWrapper
											icon={<ReactSVG src="/images/icons/PrivateLock.svg" />}
											title="Private"
											value={
												<Switch
													checked={privateDoc}
													autoFocus
													onChange={(checked) => setPrivateDoc(checked)}
													disabled={
														document.pod_user_id !==
														localStorage.getItem("podUserId")
													}
												/>
											}
										/>

										<div className="doc_wrapper_meta_divider" />
										<div className="doc_wrapper_meta_content_section">
											Meeting Details
										</div>

										<FormItemWrapper
											icon={<ReactSVG src="/images/icons/CreatedOn.svg" />}
											title="Date"
											value={monthName.format("MMM DD, YYYY") || "Empty"}
											isEditable
											content={(onEditEnd) => (
												<DatePicker
													style={{ width: 215, margin: "8px 0px" }}
													className="datepicker-cls"
													picker="date"
													size="small"
													bordered={false}
													onChange={(date) => {
														handleChange(date, "picker");
														onEditEnd();
													}}
													autoFocus
													onBlur={onEditEnd}
													value={monthName}
													allowClear={false}
													format={"MMM DD, YYYY"}
												/>
											)}
										/>
										<FormItemWrapper
											icon={<ReactSVG src="/images/icons/Calender.svg" />}
											title="Select Meeting"
											value={
												<div className="doc_wrapper_right_content_meeting">
													{!isGoogleAuthed ? (
														<div style={{ margin: "-8px -6px" }}>
															<PodGoogleLogin
																render={({ onClick }) => (
																	<Button
																		onClick={onClick}
																		type="primary"
																		className="custom_btn"
																		style={{
																			backgroundColor: "#fff",
																			color: "#000",
																			borderColor: "#000",
																			border: "none",
																			padding: 6,
																			boxShadow:
																				"1px 1px 6px -1px rgba(0,0,0,0.27)",
																			height: "36px",
																		}}
																	>
																		<img
																			src={GoogleIcon}
																			alt="google-icon"
																			style={{ height: 20, marginBottom: 2 }}
																		/>
																		<span
																			style={{
																				textShadow: "none",
																				fontWeight: "bold",
																				marginLeft: 20,
																				color: "#8f8f8f",
																			}}
																		>
																			Connect Google Suite
																		</span>
																	</Button>
																)}
																onSuccess={() => setRefresh(Date.now())}
															/>
														</div>
													) : meetingData?.length ? (
														meetingData?.find(
															(item: any) =>
																item.id === currentDocContent?.gcalEventId,
														)?.summary || "Select a meeting"
													) : (
														"No meeting for the chosen date"
													)}
												</div>
											}
											isEditable={isGoogleAuthed && meetingData?.length}
											content={(onEditEnd, loading, setLoading) => (
												<Select
													defaultValue={currentDocContent?.gcalEventId}
													optionLabelProp="label"
													className="doc_wrapper_meta_select"
													onChange={async (val) => {
														setLoading(true);
														await syncGcalEventIdWithDoc(val);
														await fetchDocumentDetails();
														onEditEnd();
														setLoading(false);
													}}
													autoFocus
													onBlur={onEditEnd}
													loading={loading}
												>
													{meetingData?.length &&
														meetingData.map((item: any, index: any) => (
															<Option
																key={index}
																value={item.id}
																label={item.summary}
															>
																{item.summary}
															</Option>
														))}
												</Select>
											)}
										/>

										<div className="doc_wrapper_meta_divider" />
										<div className="doc_wrapper_meta_content_section">
											Record
										</div>

										<FormItemWrapper
											icon={
												<ReactSVG
													src="/images/icons/SFDC-objects/opportunities.svg"
													className="sf"
												/>
											}
											title="Opportunity"
											value={document.opportunity_name || "Empty"}
											isEditable
											content={(onEditEnd) => (
												<DropdownSearch
													size="middle"
													currentId={document.opportunity_id}
													currentName={document.opportunity_name}
													objectType={"Opportunity"}
													allowClear
													UpdateTextOrNumberType={async (
														val: string,
														_: any,
														name: string,
													) => {
														await updateDocument("opportunity_id", val, name);
														onEditEnd();
													}}
													autoFocus
													onBlur={onEditEnd}
													className="doc_wrapper_meta_select"
												/>
											)}
										/>
										<FormItemWrapper
											icon={
												<ReactSVG
													src="/images/icons/SFDC-objects/accounts.svg"
													className="sf"
												/>
											}
											title="Account"
											value={document.account_name || "Empty"}
											isEditable
											content={(onEditEnd) => (
												<DropdownSearch
													size="middle"
													currentId={document.account_id}
													currentName={document.account_name}
													objectType={"Account"}
													allowClear
													UpdateTextOrNumberType={async (
														val: string,
														_: any,
														name: string,
													) => {
														await updateDocument("account_id", val, name);
														onEditEnd();
													}}
													autoFocus
													onBlur={onEditEnd}
													className="doc_wrapper_meta_select"
												/>
											)}
										/>
										<FormItemWrapper
											icon={
												<ReactSVG
													src="/images/icons/Shared.svg"
													className="sf"
												/>
											}
											title="Stakeholders"
											value={
												stakeholders.length ? (
													<div className="doc_wrapper_meta_select_values">
														{currentDocContent?.stakeholders
															.map(
																(item: string): SObjectSearchResult =>
																	stakeholders.find(
																		(person) => person.id === item,
																	) || {
																		id: item,
																		name: item,
																		type: "Custom",
																	},
															)
															.map((item: SObjectSearchResult) => (
																<div
																	className="doc_wrapper_meta_select_value"
																	key={item.id}
																>
																	<ReactSVG src={iconUrls[item.type]} />
																	{item.name}
																</div>
															))}
													</div>
												) : (
													"Empty"
												)
											}
											isEditable
											defaultData={currentDocContent?.stakeholders}
											content={(
												onEditEnd,
												loading,
												setLoading,
												value,
												setValue,
											) => (
												<Select
													mode="tags"
													className={clsx(
														"calendar-view_add_participant_box",
														"doc_wrapper_meta_select",
													)}
													optionFilterProp="contactOptions"
													optionLabelProp="label"
													defaultValue={currentDocContent?.stakeholders}
													filterOption={(input, option: any) =>
														(option.dataName || option.value)
															.toLowerCase()
															.indexOf(input.toLowerCase()) >= 0
													}
													onSearch={async (value) => {
														const excludeIds =
															currentDocContent?.stakeholders?.filter(
																(item: string) => isValidSfdcId(item),
															) ?? [];
														setStakeholderOptions(
															await searchSObjectsByName(
																["Contact", "Lead"],
																value,
																5,
																excludeIds,
															),
														);
													}}
													onChange={(value) => {
														setStakeholderOptions([]);
														setValue(value);
													}}
													autoFocus
													onBlur={async () => {
														setLoading(true);
														setStakeholderOptions([]);
														await updateDocument("stakeholders", value);
														await fetchDocumentDetails();
														onEditEnd();
														setLoading(false);
													}}
													tokenSeparators={[","]}
													notFoundContent={
														!stakeholderOptions
															? "Search for a record..."
															: "No results found"
													}
													loading={loading}
												>
													{stakeholderOptions.map((person) => (
														<Option
															value={person.id}
															key={person.id}
															label={
																<>
																	<ReactSVG src={iconUrls[person.type]} />
																	<div>{person.name}</div>
																</>
															}
															dataName={person.name}
														>
															<div className="calendar-view_add_participant_item">
																<ReactSVG src={iconUrls[person.type]} />
																{person.name}
															</div>
														</Option>
													))}
													{stakeholders.map((person) => (
														<Option
															value={person.id}
															key={person.id}
															label={
																<>
																	<ReactSVG src={iconUrls[person.type]} />
																	<div>{person.name}</div>
																</>
															}
															dataName={person.name}
														>
															<div className="calendar-view_add_participant_item">
																<ReactSVG src={iconUrls[person.type]} />
																{person.name}
															</div>
														</Option>
													))}
													{currentDocContent?.stakeholders
														?.filter(
															(item: string) =>
																!stakeholders.find(
																	(person) => person.id === item,
																),
														)
														?.map((stakeholder: string) => (
															<Option
																value={stakeholder}
																key={stakeholder}
																label={
																	<>
																		<ReactSVG src={iconUrls.Custom} />
																		<div>{stakeholder}</div>
																	</>
																}
																dataName={stakeholder}
															>
																<div className="calendar-view_add_participant_item">
																	<ReactSVG src={iconUrls.Custom} />
																	{stakeholder}
																</div>
															</Option>
														))}
												</Select>
											)}
										/>
									</div>

									<div
										className={clsx(
											"doc_wrapper_meta_toggle",
											hideMeta && "collapsed",
										)}
									>
										<div className="doc_wrapper_meta_divider" />
										<div
											className="doc_wrapper_meta_back"
											onClick={() => setHideMeta(!hideMeta)}
										>
											<HiOutlineChevronUp />
										</div>
									</div>
								</div>

								<div className="doc_wrapper_editor">
									<Editor
										value={
											cleanedContent || emptyTemplate
												? currentDocContent.docContent
												: inlineTemplates
										}
										onChange={(event: () => string) => {
											setContent(event());
										}}
										onSave={() => {
											if (content !== undefined) {
												void updateDocument("content", content);
											}
										}}
										uploadImage={async (file) => await UploadImage(file)}
										className={`editor-content ${
											!cleanedContent ? "template-select" : ""
										}`}
										onClickLink={handleClickLink}
										readOnly={!cleanedContent && !emptyTemplate}
									/>
								</div>
							</div>
						)}
					</div>

					{showAssistant && content !== undefined && (
						<div className="notes_wrapper_assistant">
							<NotesAssistantDrawer
								onClose={() => setShowAssistant(false)}
								note={{
									type: currentDocContent.docType,
									content: content,
									stakeholders: currentDocContent.stakeholders,
								}}
							/>
						</div>
					)}
				</div>
			</div>
		</BaseLayoutComponent>
	);
};
