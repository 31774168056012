import { Button, Input, Form } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { KeyboardEventHandler } from "react";
import "./PipelineTextEditorComponent.styles.scss";

interface Props {
	value?: string;
	big?: boolean;
	type?: any;
	UpdateTextorNumberType: (value?: string, data?: any, name?: string) => void;
	updateData?: any;
	length?: number;
	nullable?: boolean;
	textForm: FormInstance;
	setShouldRequestUpdate: (a: boolean) => void;
}
const PipelineTextEditorComponent = ({
	value,
	big,
	type,
	UpdateTextorNumberType,
	updateData,
	length,
	nullable,
	textForm,
	setShouldRequestUpdate,
}: Props) => {
	const onFinishUpdate = ({ textField }: { textField?: string }) => {
		setShouldRequestUpdate(false);
		UpdateTextorNumberType(textField, updateData);
	};

	const handleEnterKeyPress: KeyboardEventHandler = (e) => {
		if (e.ctrlKey || e.altKey || e.metaKey) {
			textForm.setFieldsValue({
				textField: `${textForm.getFieldValue("textField")}\n`,
			});
		} else if (e.shiftKey) {
		} else {
			textForm.submit();
		}
	};

	const renderTextFormSection = () => (
		<Form.Item
			name="textField"
			className="mb-3"
			rules={
				nullable
					? []
					: [{ required: true, message: "This field can't be empty" }]
			}
		>
			<Input.TextArea
				size="large"
				onClick={(e) => e.stopPropagation()}
				showCount
				onPressEnter={handleEnterKeyPress}
				rows={big ? 4 : 1}
				maxLength={length ? length : big ? 100 : 50}
				style={{ fontSize: 14 }}
			/>
		</Form.Item>
	);

	const renderFormFooter = () => (
		<div className="text_editor_bottom d-flex justify-content-between align-items-center w-100">
			<div>
				<strong>click outside</strong> to close
			</div>
			<Button
				type="text"
				className="text_editor_bottom--submit"
				htmlType="submit"
			>
				Update
			</Button>
		</div>
	);

	return (
		<Form
			className={`pip_col_editor text_editor ${big ? "big" : ""}`}
			onFinish={onFinishUpdate}
			form={textForm}
			onChange={() => setShouldRequestUpdate(true)}
			initialValues={{ numberField: value, textField: value }}
		>
			{renderTextFormSection()}
			{renderFormFooter()}
		</Form>
	);
};

export default PipelineTextEditorComponent;
