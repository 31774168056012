import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./WelcomePage.styles.scss";
import CloudIcon from "../../Images/Cloud.svg";
import PodLogo from "../../Images/podLogoWithText.svg";
import WorkingArt from "../../Images/WorkingArt.svg";
import TestimonialProfile from "../../Images/TestimonialProfile.svg";
import SSPipeline from "../../Images/SSPipeline.svg";
import { Button } from "antd";
import { Config } from "../../Config";
import { Skeleton } from "./Skeleton";
import { updateSFDCData } from "../../Store/ActionReducerPairs/User";
import { CreateDefaultTasks } from "../../Apis/Firebase/CreateDefaultTasks";
import { CreateDocument } from "../../Apis/Firebase/CreateDocument";
import { CreateTemplate } from "../../Apis/Firebase/CreateTemplate";
import moment from "moment";
import { signin } from "../../Utils/SFDCCallbackHandler";
import { Md5 } from "ts-md5";

export default ({
	isSignup = false,
	isImpersonate = false,
}: {
	isSignup?: boolean;
	isImpersonate?: boolean;
}) => {
	const navigate = useNavigate();
	const location = useLocation();

	const searchParams = location.search;

	const handleLoginEvent = (event: any) => {
		if (!event.data?.instance_url) {
			return;
		}

		window.analytics.identify(event.data.pod_user_id, {
			user_id: event.data.pod_user_id,
			name: event.data.sfdc_user_display_name,
			email: event.data.sfdc_email,
			phone: event.data.mobile_phone,
			company: event.data.company_name,
			address: {
				city: event.data.addr_city,
				country: event.data.addr_country,
				postalCode: event.data.addr_zip,
				state: event.data.addr_state,
				street: event.data.addr_street,
			},
			firstName: event.data.first_name,
			lastName: event.data.last_name,
			title: event.data.title,
			division: event.data.division,
			department: event.data.department,
			alias: event.data.nick_name,
			username: event.data.username,
		});

		updateSFDCData(event.data);

		if (event.data?.is_new_user) {
			window.analytics.track("SignupFlow_Start", {
				podUserId: localStorage.getItem("podUserId"),
			});

			void CreateDocument(
				"Your first notes",
				"Your first notes on Pod",
				false,
				"",
				"",
				"",
				"",
				"",
				moment().format().split("T")[0],
				"",
				"",
				[],
			);

			void CreateTemplate(
				"Discovery call template",
				"Your first template on Pod",
			);

			CreateDefaultTasks({
				email: event.data.sfdc_email,
				pod_user_id: localStorage.getItem("podUserId"),
			}).then((resp) => {
				navigate("/onboard/1", {
					state: {
						firstName: event.data.first_name,
						title: event.data.title,
						companyName: event.data.company_name,
					},
				});
			});
		} else {
			navigate(`/dashboard/pipeline-view${searchParams || ""}`);
		}
	};

	useEffect(() => {
		window.addEventListener("message", handleLoginEvent);

		return () => {
			window.removeEventListener("message", handleLoginEvent);
		};
	}, []);

	const SignUpLeftContent = () => {
		return (
			<div
				className="welcome-column"
				style={{
					alignItems: "center",
					justifyContent: "space-around",
					height: "100%",
				}}
			>
				<div className="welcome-column" style={{ alignItems: "center" }}>
					<p
						style={{
							fontWeight: "600",
							fontSize: "24px",
						}}
					>
						The smart workspace!
					</p>
					<p style={{ width: "400px", textAlign: "center" }}>
						Pod helps you to more easily update, organize, and prioritize your
						pipeline. Focus on selling!
					</p>
				</div>
				<img id="filler-art" src={WorkingArt} alt="" />
				<div
					style={{
						width: "480px",
						height: "218px",
						borderRadius: "8px",
						backgroundColor: "#5000A2",
						padding: "32px 32px 32px 32px",
					}}
				>
					<p
						style={{
							color: "white",
							fontWeight: "400",
							letterSpacing: "0.15px",
							width: "416px",
						}}
					>
						“Pod is a game changer. We used to be all over the place. Pod keeps
						our team on track and we’re closing more deals than ever.”
					</p>
					<span className="welcome-row" style={{ marginTop: "20px" }}>
						<img src={TestimonialProfile} alt="" style={{ width: "48px" }} />
						<span
							className="welcome-column"
							style={{
								marginLeft: "16px",
								justifyContent: "space-between",
							}}
						>
							<p
								style={{
									color: "white",
									marginBottom: "0",
								}}
							>
								Director of Sales
							</p>
							<p
								style={{
									color: "white",
									fontWeight: "400",
									fontSize: "12px",
									letterSpacing: "0.004em",
									marginBottom: "0",
								}}
							>
								Series B SaaS startup in San Francisco
							</p>
						</span>
					</span>
				</div>
			</div>
		);
	};

	const SignInLeftContent = () => {
		return (
			<div
				className="welcome-column"
				style={{
					alignItems: "center",
				}}
			>
				<p
					style={{
						width: "480px",
						fontWeight: "700",
						fontSize: "34px",
						letterSpacing: "0.0025em",
						marginTop: "6vh",
					}}
				>
					All-in-One Platform for Sales
				</p>
				<p
					style={{
						width: "480px",
						fontWeight: "400",
						fontSize: "18px",
						letterSpacing: "0.0015em",
					}}
				>
					Pod helps you to more easily update, organize, and prioritize your
					pipeline. Focus on selling!
				</p>
				<img
					id="SSPipeline"
					src={SSPipeline}
					alt=""
					style={{
						height: "56vh",
						minHeight: "450px",
						position: "absolute",
						top: "35%",
						right: "55.9%",
					}}
				/>
			</div>
		);
	};

	return (
		<Skeleton
			LeftContent={isSignup ? SignUpLeftContent() : SignInLeftContent()}
			RightContent={
				<div
					className="welcome-column"
					style={{
						height: "100%",
						justifyContent: "center",
						alignItems: "center",
						textAlign: "center",
					}}
				>
					<img src={PodLogo} alt="" style={{ width: "170px" }} />
					<p
						style={{
							marginTop: "40px",
							fontWeight: "700",
							fontSize: "34px",
							letterSpacing: "0.0025em",
							marginBottom: "8px",
						}}
					>
						{isSignup ? "Sign Up to Pod" : "Welcome back!"}
					</p>
					<p
						style={{
							fontWeight: "400",
						}}
					>
						{isSignup
							? "Create your account using your Salesforce credentials"
							: "Let’s get you to close more deals"}
					</p>
					{isImpersonate ? (
						<form
							name="impersonateForm"
							onSubmit={async (event) => {
								event.preventDefault();
								const userData = await signin(
									null,
									(document.getElementById("pod_user_id") as HTMLInputElement)
										.value,
									Md5.hashStr(
										(document.getElementById("password") as HTMLInputElement)
											.value,
									),
								);
								handleLoginEvent({ data: userData });
							}}
						>
							<label htmlFor="pod_user_id">Pod User ID</label>
							<br />
							<input type="text" id="pod_user_id" name="pod_user_id" />
							<br />
							<br />
							<label htmlFor="password">Password</label>
							<br />
							<input type="password" id="password" name="password" />
							<br />
							<br />
							<input type="submit" value="Submit" />
						</form>
					) : (
						<Button
							className="nav-btn"
							onClick={() => {
								window.open(
									`https://login.salesforce.com/services/oauth2/authorize?redirect_uri=${Config.sdfcAuth.redirectUri}&response_type=${Config.sdfcAuth.responseType}&client_id=${Config.sdfcAuth.clientId}`,
									"popUpWindow",
									"width=400,height=500,left=200,top=100",
								);
							}}
							style={{
								width: "474px",
								background: "#00A1E0",
							}}
						>
							<span style={{ width: "max-content" }}>
								<p style={{ color: "white", fontSize: "16px" }}>
									{isSignup
										? "Sign Up with Salesforce"
										: "Log In with Salesforce"}
								</p>
								<img
									src={CloudIcon}
									style={{
										marginLeft: "8px",
										marginBottom: "5px",
										width: "21px",
									}}
									alt=""
								/>
							</span>
						</Button>
					)}
					<span
						className="welcome-column"
						style={{ alignItems: "center", marginTop: "10%" }}
					>
						<p style={{ marginBottom: "8px" }}>
							By signing up, you are indicating that you have read and agree
						</p>
						<p>
							to the
							<a
								href="https://www.workwithpod.com/terms-and-conditions"
								target="_blank"
								rel="noopener noreferrer"
								style={{
									margin: "0 5px 0 5px",
									fontWeight: "600",
									color: "#5000A2",
								}}
							>
								<b>Terms of Use</b>
							</a>
							and
							<a
								href="https://www.workwithpod.com/privacy"
								target="_blank"
								rel="noopener noreferrer"
								style={{
									marginLeft: "5px",
									fontWeight: "600",
									color: "#5000A2",
								}}
							>
								<b>Privacy Policy</b>
							</a>
							.
						</p>
					</span>
				</div>
			}
		/>
	);
};
