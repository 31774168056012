import "./UpdateError.styles.scss";
import { setUpdateRecordErrorModal } from "../../../Store/ActionReducerPairs/Modals";
import SFDCError from "../../../Images/SFDCError.svg";

export default ({ errorMessage }: { errorMessage: string }) => {
	return (
		<div
			className="update-error-modal-column"
			style={{ alignItems: "center", paddingTop: "10px" }}
		>
			<img src={SFDCError} alt="" style={{ width: "125px" }} />
			<div
				style={{
					textAlign: "center",
					marginTop: "10px",
					marginBottom: "20px",
					fontWeight: "500",
					fontSize: "20px",
					letterSpacing: "0.0015em",
					color: "black",
				}}
			>
				Salesforce Update Failed!
			</div>
			<div
				style={{
					fontWeight: "500",
					letterSpacing: "0.0125em",
					color: "#B30909",
				}}
			>
				{errorMessage}
			</div>
			<button
				className="update-error-modal-btn"
				onClick={() => setUpdateRecordErrorModal(undefined)}
				style={{
					alignSelf: "end",
					marginTop: "30px",
				}}
			>
				Ok
			</button>
		</div>
	);
};
