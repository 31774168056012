import { DatePicker, Input, Button } from "antd";
import { useState } from "react";
import { ReactSVG } from "react-svg";
import { CreateTask } from "../../../Apis/Firebase/CreateTask";
import "./style.scss";

export interface Props {
	opportunityId: string;
	opportunityName: string;
	reload: () => void;
}

const TaskCard = ({ opportunityId, opportunityName, reload }: Props) => {
	const [description, setDescription] = useState("");
	const [assignee, setAssignee] = useState("");
	const [date, setDate] = useState<any>();

	const save = () => {
		const sfdcCreds = JSON.parse(localStorage.getItem("BaseApp1") || "{}").user;
		CreateTask(
			"Not Completed",
			opportunityId,
			opportunityName,
			description,
			assignee,
			date?.format("YYYY-MM-DD") || "",
			sfdcCreds.sfdcEmail,
		).then(() => {
			reload();
		});
	};

	return (
		<div className="task-card-container new-task">
			<div className="row">
				<div style={{ position: "absolute", left: 12, width: "65%" }}>
					<Input
						placeholder="Task description"
						className="description"
						style={{ background: "none", width: "100%" }}
						value={description}
						onChange={(e) => setDescription(e.target.value)}
					/>
				</div>
				<div
					className="right"
					style={{ position: "absolute", right: 17, width: "35%", top: 20 }}
				>
					<div
						className="item"
						style={{
							flexDirection: "row",
							display: "flex",
							justifyContent: "right",
							marginRight: 8,
						}}
					>
						<DatePicker
							bordered={false}
							placeholder={"Due date"}
							onChange={(value) => setDate(value)}
							className="item-datepicker"
						/>
					</div>
					<div
						className="item"
						style={{
							flexDirection: "row",
							display: "flex",
							justifyContent: "right",
						}}
					>
						<Input
							placeholder="Assignee"
							className="item-value"
							style={{
								background: "none",
								textAlign: "right",
								width: "auto",
								paddingTop: 0,
								paddingBottom: 0,
							}}
							value={assignee}
							onChange={(e) => setAssignee(e.target.value)}
						/>
						<ReactSVG src="/images/icons/UserLogo.svg" className="user-icon" />
					</div>
				</div>
			</div>
			<Button
				type="primary"
				className="custom_btn"
				style={{ position: "absolute", right: 36, bottom: 12, width: 120 }}
				onClick={save}
			>
				Save
			</Button>
		</div>
	);
};

export default TaskCard;
