import { Button, Input, Popover, Table } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineSearch } from "react-icons/ai";
import { ReactSVG } from "react-svg";
import { BaseLayoutComponent } from "../Components/Shared/BaseLayoutComponent";
import useWindowDimensions from "../Utils/WinodwSizeFinder";
import "./DocumentHubSummaryPage.styles.scss";
import { GetUserDocuments } from "../Apis/Firebase/GetUserDocuments";
import { GetUserTemplates } from "../Apis/Firebase/GetUserTemplates";
import PipelineFieldSelector from "../Components/PipelineFieldSelector";
import { UpdateDocument } from "../Apis/Firebase/UpdateDocument";
import { DeleteDocument } from "../Apis/Firebase/DeleteDocument";
import { CreateDocument } from "../Apis/Firebase/CreateDocument";
import OptionsDropdown from "../Components/OptionsDropdown";
import DocumentHubFiltersComponent from "./Components/DocumentHubFilters";
import ChevronDown from "../Images/ChevronDown.svg";
import CreateTemplate from "./Components/CreateTemplate";
import TemplatesDropdown from "./Components/TemplatesDropdown";
import { GetSharedTemplates } from "../Apis/Firebase/GetSharedTemplates";
import { CreateSharedTemplate } from "../Apis/Firebase/CreateSharedTemplate";
import PipelineColsSelectorComponent from "../Components/Pipeline/PipelineColsSelectorComponent";
import { Resizable } from "react-resizable";

const allColumns = [
	{
		label: "Title",
		name: "title",
		type: "string",
		updateable: true,
		isFixed: true,
	},
	{
		label: "Owner",
		name: "created_by",
		type: "string",
		updateable: false,
	},
	{
		label: "Document Type",
		name: "document_type",
		type: "picklist",
		updateable: true,
		picklist: [
			{ label: "Meeting Notes", value: "Meeting Notes" },
			{ label: "Account Plan", value: "Account Plan" },
			{ label: "Personal Notes", value: "Personal Notes" },
			{ label: "Others", value: "Others" },
		],
	},
	{
		label: "Opportunity",
		name: "opportunity_id",
		type: "reference",
		updateable: true,
	},
	{
		label: "Account",
		name: "account_id",
		type: "reference",
		updateable: true,
	},
	{
		label: "Assigned Meeting Date",
		name: "event_date",
		type: "string",
		updateable: false,
	},
	{
		label: "Created On",
		name: "created_at",
		type: "date",
		updateable: false,
	},
	{
		label: "Last Modified At",
		name: "last_modified_at",
		type: "date",
		updateable: false,
	},
];

const ResizeableTitle = (props: any) => {
	const { onResize, width, minWidth, ...restProps } = props;
	if (!width) {
		return <th {...restProps} />;
	}
	return (
		<Resizable
			width={width}
			height={0}
			onResize={onResize}
			minConstraints={[minWidth, 0]}
			draggableOpts={{ enableUserSelectHack: false }}
		>
			<th {...restProps} />
		</Resizable>
	);
};

const DocumentHubSummaryPage = () => {
	const navigate = useNavigate();
	const windowSize = useWindowDimensions();
	const [tableSize, setTableSize] = useState<any>({
		x: "max-content",
		y: 400,
	});
	const notesCols = localStorage.getItem("notesViewColumns");
	const [cols, setCols] = useState<string[]>(
		notesCols
			? JSON.parse(notesCols || "[]")
			: allColumns.map((item) => item.name),
	);
	const [columnWidths, setColumnWidths] = useState<any>({});
	const [data, setData] = useState([]);
	const [templates, setTemplates] = useState<any>([]);
	const [sharedTemplates, setSharedTemplates] = useState<any>([]);
	const [selected, setSelected] = useState<string>();
	const [loading, setLoading] = useState(false);
	const [searchTerm, setSearchTerm] = useState("");
	const [createTemplate, setCreateTemplate] = useState<any>({
		visible: false,
	});

	const {
		opportunity,
		account,
		stakeholders,
		createdOn,
		myDocs,
		privateDocs,
		documentType,
	} = JSON.parse(localStorage.getItem("documentHubFilters") || "{}");
	let filtersApplied = 0;
	filtersApplied += opportunity?.id ? 1 : 0;
	filtersApplied += account?.id ? 1 : 0;
	filtersApplied += stakeholders?.length ? 1 : 0;
	filtersApplied += createdOn ? 1 : 0;
	filtersApplied += myDocs ? 1 : 0;
	filtersApplied += privateDocs ? 1 : 0;
	filtersApplied += documentType ? 1 : 0;

	useEffect(() => {
		window.analytics.page("DocumentHubSummaryPage", {
			podUserId: localStorage.getItem("podUserId"),
		});
	}, []);

	const fetchSharedTemplates = () => {
		GetSharedTemplates().then((shared: any) => {
			const sharedTemplates = shared.map((item: any) => ({
				id: item.id,
				...(item.data() || {}),
				shared: true,
			}));
			setSharedTemplates(sharedTemplates);

			GetUserTemplates().then((res: any) => {
				const templates = res.map((item: any) => ({
					id: item.id,
					...(item.data() || {}),
					shared: !!sharedTemplates.find(
						(sharedItem: any) => sharedItem.id === item.id,
					),
				}));
				setTemplates(templates);
			});
		});
	};

	const fetchData = () => {
		setLoading(true);
		GetUserDocuments().then((res: any) => {
			setData(
				res.map((item: any) => ({
					id: item.id,
					...item.data(),
				})),
			);
			setTableSize({ ...tableSize, y: windowSize.height - 300 || 400 });
			setLoading(false);
		});
		fetchSharedTemplates();
	};

	const handleShare = (itemId: string, shared?: boolean) => {
		CreateSharedTemplate(itemId, shared).then(() => {
			fetchSharedTemplates();
		});
	};

	const updateValue = (
		newData: Record<string, string>,
		currentObject: Record<string, string>,
	) => {
		const { id, ...currentData } = currentObject;
		const object = {
			...currentData,
			...newData,
		};
		setLoading(true);
		UpdateDocument(id, object).then(() => {
			setData([]);
			fetchData();
		});
	};

	const actionDotsHandler = (action: string, id: string) => {
		if (action === "Delete") {
			setLoading(true);
			DeleteDocument(id).then(() => {
				window.analytics.track("DocumentHub_Delete_Document", {
					podUserId: localStorage.getItem("podUserId"),
				});

				fetchData();
			});
		} else {
			navigate(`/document-hub/notes-page/${id}`);
		}
	};

	const formatAssignedDate = (dateString: string) => {
		if (dateString == "") {
			return dateString;
		} else {
			let splitDateString: any = dateString.split("-");
			let monthNumberTonNames: any = {
				"01": "Jan",
				"02": "Feb",
				"03": "Mar",
				"04": "Apr",
				"05": "May",
				"06": "Jun",
				"07": "Jul",
				"08": "Aug",
				"09": "Sep",
				"10": "Oct",
				"11": "Nov",
				"12": "Dec",
			};
			let dayStr: string;

			if (splitDateString[2] == "01") {
				dayStr = "1st";
			} else if (splitDateString[2] == "02") {
				dayStr = "2nd";
			} else if (splitDateString[2] == "03") {
				dayStr = "3rd";
			} else {
				if (splitDateString[2][0] == "0") {
					dayStr = splitDateString[2][1] + "th";
				} else {
					dayStr = splitDateString[2] + "th";
				}
			}

			return (
				dayStr +
				" " +
				monthNumberTonNames[splitDateString[1]] +
				" " +
				splitDateString[0]
			);
		}
	};

	const handleResizeColumn = (item: any) => (e: any, { size }: any) => {
		e.preventDefault();
		setColumnWidths({ ...columnWidths, [item.name]: size.width });
	};

	const columns: any = useMemo(() => {
		return [
			...allColumns
				.filter((item) => cols.includes(item.name))
				.map((item: any, index: number) => ({
					title: item.label,
					dataIndex: item.name,
					width: columnWidths[item.name] || (index === 0 ? 525 : 150),
					minWidth: index === 0 ? 200 : 100,
					fixed: index === 0,
					key: item.name,
					onHeaderCell: (column: any) => ({
						minWidth: column.minWidth,
						width: column.width,
						onResize: handleResizeColumn(item),
					}),
					sorter: (a: Record<string, string>, b: Record<string, string>) => {
						const valA =
							item.type === "reference"
								? a[`${item.name.split("_")[0]}_name`]
								: a[item.name];
						const valB =
							item.type === "reference"
								? b[`${item.name.split("_")[0]}_name`]
								: b[item.name];
						if (valA === valB) return 0;
						else if (valA > valB) return 1;
						return -1;
					},
					render: (value: string, obj: Record<string, string>) => (
						<PipelineFieldSelector
							currentValue={
								item.name == "event_date" ? formatAssignedDate(value) : value
							}
							valueForRefObjWithName={obj[`${item.name.split("_")[0]}_name`]}
							type={item.type}
							updateData={{ id: obj.id }}
							name={item.name}
							picklist={item.picklist || []}
							referencedObjectType="Opportunity"
							updateValue={(value: any, _: unknown, name?: string) => {
								updateValue(
									{
										[item.name]: (value?.label || value) as string,
										...(item.type === "reference"
											? { [`${item.name.split("_")[0]}_name`]: name! }
											: {}),
									},
									obj,
								);
							}}
							updateable={false}
							textLength={index === 0 ? 80 : 18}
							displayShield={Boolean(item.name === "title" && obj.is_private)}
							onClick={() => {
								if (item.name === "title") {
									actionDotsHandler("Open", (obj as any).id);
								}
							}}
						/>
					),
				})),
			{
				title: "",
				dataIndex: "actions",
				align: "center",
				width: 40,
				fixed: "right",
				render: (_: unknown, record: any) => (
					<div
						onClick={(event) => event.stopPropagation()}
						className="pip_col_actions"
					>
						<span>
							<OptionsDropdown
								trigger={["click"]}
								options={["Open", "Delete"]}
								onClick={(item) => actionDotsHandler(item, record.id)}
								iconClassName="resized-icon"
								className="resized"
							>
								<ReactSVG src="/images/icons/more-vertical.svg" />
							</OptionsDropdown>
						</span>
					</div>
				),
			},
		];
	}, [cols, columnWidths]);

	const mainSearchData: any = (e: any) => {
		setSearchTerm(e.target.value);
		setTimeout(() => {
			triggerSearch();
		});
	};

	useEffect(() => {
		fetchData();
	}, []);

	useEffect(() => {
		localStorage.setItem("documentHubSearch", searchTerm);
	}, [searchTerm]);

	const triggerSearch = () => {
		fetchData();
	};

	return (
		<BaseLayoutComponent pageTitle={<h3>Notes</h3>}>
			<>
				<div
					className="doc_summ_top py-2 d-flex"
					style={{ flexDirection: "row" }}
				>
					<div className="d-flex justify-content-start">
						<div className="col-auto">
							<div className="pip_table_extras_search">
								<Input
									onChange={mainSearchData}
									value={searchTerm}
									className="custom_input_1"
									size="large"
									prefix={<AiOutlineSearch size={20} color="#999" />}
									placeholder="Search by Document name"
								/>
							</div>
						</div>
					</div>
					<div
						className="d-flex justify-content-end"
						style={{ position: "absolute", right: 20 }}
					>
						<Popover
							placement="bottomRight"
							content={
								<PipelineColsSelectorComponent
									cols={cols}
									setTableLoading={setLoading}
									currFieldInfo={allColumns}
									fetchCategoryDataMethod={() => {}}
									onChange={({ field_name, toggle_type }) => {
										const column = allColumns.find(
											(item) => item.name === field_name,
										);
										if (column) {
											if (toggle_type === "add") {
												cols.push(field_name as string);
											} else if (toggle_type === "remove") {
												cols.splice(cols.indexOf(field_name as string), 1);
											}

											setCols([...cols]);
											localStorage.setItem(
												"notesViewColumns",
												JSON.stringify(cols),
											);
										}
									}}
								/>
							}
							trigger={"click"}
							destroyTooltipOnHide
						>
							<span className="pip_table_extras_addon--btn">
								<ReactSVG src="/images/icons/Add.svg" />
							</span>
						</Popover>
						<Popover
							placement="bottomRight"
							trigger={"click"}
							content={<DocumentHubFiltersComponent fetchData={fetchData} />}
							destroyTooltipOnHide
						>
							<span
								className={`pip_table_extras_addon--btn ${
									Boolean(filtersApplied) ? "filters" : ""
								}`}
							>
								{filtersApplied ? (
									<span className="fil_count">{filtersApplied}</span>
								) : null}
								<ReactSVG src="/images/icons/Filter.svg" />
							</span>
						</Popover>
						<Button
							type="primary"
							className="custom_btn"
							style={{
								height: 40,
								marginLeft: 16,
								borderRadius: 4,
								borderTopRightRadius: 0,
								borderBottomRightRadius: 0,
							}}
							onClick={() => {
								CreateDocument().then((res) => {
									window.analytics.track("DocumentHub_Create_Document", {
										podUserId: localStorage.getItem("podUserId"),
									});

									navigate("/document-hub/notes-page/" + res);
								});
							}}
						>
							New
						</Button>
						<TemplatesDropdown
							templates={templates}
							sharedTemplates={sharedTemplates}
							refresh={fetchData}
							onClick={(item) => {
								setCreateTemplate({
									...item,
									visible: true,
								});
							}}
							onShare={handleShare}
						>
							<Button
								type="primary"
								className="custom_btn"
								style={{
									width: 40,
									height: 40,
									marginLeft: 1,
									borderRadius: 4,
									borderTopLeftRadius: 0,
									borderBottomLeftRadius: 0,
									padding: 0,
								}}
							>
								<img src={ChevronDown} />
							</Button>
						</TemplatesDropdown>
					</div>
				</div>
				<div className="doc_summ_table">
					<Table
						loading={loading}
						scroll={tableSize}
						components={{
							header: {
								cell: ResizeableTitle,
							},
						}}
						columns={columns}
						dataSource={data}
						pagination={{
							pageSize: 15,
							size: "small",
							position: ["bottomCenter"],
						}}
						className="document-table"
						onRow={(record) => {
							return {
								onDoubleClick: () => {
									actionDotsHandler("Open", (record as any).id);
								},
								onClick: () => setSelected((record as any).id),
							};
						}}
						rowClassName={(record) =>
							(record as any).id === selected ? "selected" : ""
						}
					/>
				</div>
				<CreateTemplate
					visible={createTemplate.visible}
					setVisible={() => setCreateTemplate({ visible: false })}
					data={createTemplate.data}
					id={createTemplate.id}
					reload={fetchData}
				/>
			</>
		</BaseLayoutComponent>
	);
};

export default DocumentHubSummaryPage;
