import React, {
	useEffect,
	useRef,
	useState,
	useMemo,
	useCallback,
} from "react";
import { add, format, getDay, setDay, differenceInMinutes } from "date-fns";
import { useNavigate } from "react-router-dom";
import { Table } from "antd";
import moment from "moment";

import {
	GenericEvent,
	CalendarBodyProps,
	EventsObject,
	EventBlockProps,
	ColumnNode,
} from "./types";
import { CreateDocument } from "../../Apis/Firebase/CreateDocument";
import IconDropdown from "../../Components/IconDropdown";

import {
	getDayHoursEvents,
	sizeEventBox,
	MIN_BOX_SIZE,
	getSubTitle,
	matchDay,
} from "./utils";

const BOX_POSITION_OFFSET = 26;
const SCROLL_TO_ROW = 9;

const EventBlock = <T extends GenericEvent>({
	event,
	index,
	hour,
	events,
	onEventClick,
}: EventBlockProps<T>) => {
	const navigate = useNavigate();
	const getEventDay = getDay(new Date(event.endTime));
	const fitHourToDate = setDay(hour, getEventDay);
	const boxStyle = event.allDay
		? { boxSize: MIN_BOX_SIZE, boxPosition: index * BOX_POSITION_OFFSET }
		: sizeEventBox(event, fitHourToDate);
	const boxLeftPosition = (100 / events) * index;
	const diff = differenceInMinutes(
		new Date(event.endTime),
		new Date(event.startTime),
	);

	return (
		<IconDropdown
			onClick={() => {
				const date = moment(event.startTime).format("YYYY-MM-DD");
				CreateDocument(
					"",
					"",
					false,
					"",
					"",
					"",
					"",
					"",
					date,
					event.eventId,
					event.title,
				).then((res) => {
					navigate("/document-hub/notes-page/" + res);
				});
			}}
			trigger={["hover"]}
		>
			<div
				style={{
					height: boxStyle.boxSize + "%",
					width: `${100 / events}%`,
					position: "absolute",
					top: boxStyle.boxPosition + "%",
					left: boxLeftPosition + "%",
					borderColor: "white",
					borderStyle: "solid",
					borderWidth: "0.01rem",
					borderRadius: "8px",
					backgroundColor: event.backgroundColor
						? event.backgroundColor
						: "#F5F5F5",
					zIndex: 2,
					...(diff <= 15
						? { paddingTop: "0px" }
						: diff <= 30
						? { paddingTop: "5px" }
						: { paddingTop: "8px" }),
					...(diff <= 30 ? { paddingLeft: "9px" } : { paddingLeft: "12px" }),
					display: "flex",
					textOverflow: "ellipsis",
					flexDirection: "column",
					overflow: "hidden",
					cursor: "pointer",
				}}
				className="event-block"
				onClick={onEventClick ? () => onEventClick(event) : undefined}
				key={index}
			>
				<p
					style={{
						fontSize: "12px",
						fontWeight: "normal",
						padding: "0px",
						margin: "0px",
						textOverflow: "ellipsis",
						overflow: "hidden",
					}}
					className="text-overflow"
				>
					{event.title}
				</p>
				<p
					style={{
						padding: "0",
						margin: "0",
						fontWeight: "lighter",
						fontSize: "9px",
						textOverflow: "ellipsis",
						overflow: "hidden",
						...(diff <= 30 ? { visibility: "hidden" } : {}),
					}}
					className="text-overflow"
				>
					{getSubTitle(event.startTime, event.endTime)}
				</p>
			</div>
		</IconDropdown>
	);
};

const TimeIndicator = ({ timer }: any) => {
	const current: Date = new Date(timer);
	let top = current.getMinutes();
	return (
		<div
			style={{
				position: "absolute",
				width: "100%",
				height: "2px",
				backgroundColor: "#7150B0",
				zIndex: "4",
				top: top + "px",
			}}
			className="time-indicator"
			key={-1}
		></div>
	);
};

function Calendar<T extends GenericEvent>({
	weekDates,
	getDayEvents,
	onEventClick,
	weekends,
}: CalendarBodyProps<T>) {
	const rowRef = useRef<null | HTMLDivElement>(null);
	useEffect(() => {
		if (rowRef.current) {
			rowRef.current?.scrollIntoView();
			window.scrollTo(0, 0);
		}
	}, [rowRef]);
	const dayList = weekends
		? [
				"Sunday",
				"Monday",
				"Tuesday",
				"Wednesday",
				"Thursday",
				"Friday",
				"Saturday",
		  ]
		: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];

	const render = useCallback(
		(
			events: ColumnNode<T>,
			row: EventsObject<T>,
			counter: number,
		): React.ReactNode | undefined => {
			const columnDate = add(new Date(weekDates.startDate), {
				days: counter,
			});
			const matched = matchDay(columnDate, new Date());
			if (matched) {
				const [timer, setTimer] = useState(Date.now());
				useEffect(() => {
					const timeout = setTimeout(() => setTimer(timer + 60000), 60000);
					return () => clearTimeout(timeout);
				});
				if (events && events.length > 0 && events instanceof Array) {
					const eventsBlock = events.map(function (
						event,
						index: number,
					): React.ReactNode {
						return (
							<EventBlock
								key={event.eventId}
								event={event}
								index={index}
								hour={row.hourObject}
								events={events.length}
								onEventClick={onEventClick}
							/>
						);
					});
					return {
						props: {
							style: { position: "relative", padding: "0" },
						},
						children: (
							<>
								<div
									style={{
										width: "100%",
										height: "inherit",
										display: "flex",
										flexDirection: "row",
									}}
								>
									{eventsBlock}
								</div>
								<div
									style={{
										visibility: !(
											row.hourObject.getHours() === new Date(timer).getHours()
										)
											? "hidden"
											: "visible",
									}}
								>
									<TimeIndicator timer={timer} />
								</div>
							</>
						),
					};
				} else {
					return {
						props: {
							style: { position: "relative", padding: "0" },
						},
						children: (
							<>
								<div
									style={{
										visibility: !(
											row.hourObject.getHours() === new Date(timer).getHours()
										)
											? "hidden"
											: "visible",
									}}
								>
									<TimeIndicator timer={timer} />
								</div>
							</>
						),
					};
				}
			} else {
				if (events && events.length > 0 && events instanceof Array) {
					const eventsBlock = events.map(function (
						event,
						index: number,
					): React.ReactNode {
						return (
							<EventBlock
								key={event.eventId}
								event={event}
								index={index}
								hour={row.hourObject}
								events={events.length}
								onEventClick={onEventClick}
							/>
						);
					});

					return {
						props: {
							style: { position: "relative", padding: "0" },
						},
						children: (
							<>
								<div
									style={{
										width: "100%",
										height: "inherit",
										display: "flex",
										flexDirection: "row",
									}}
								>
									{eventsBlock}
								</div>
							</>
						),
					};
				}
			}
			return {
				props: {
					style: { position: "relative", padding: "0" },
				},
				children: <></>,
			};
		},
		[],
	);

	const dayColumns = useMemo(() => {
		return dayList.map((day, counter) => {
			const columnDate = add(new Date(weekDates.startDate), {
				days: counter,
			});
			const matched = matchDay(columnDate, new Date());
			return {
				title: () => {
					return (
						<div>
							<p
								style={{
									fontWeight: "lighter",
									fontSize: "10px",
									marginBottom: "4px",
									display: "inline-block",
								}}
							>
								{format(columnDate, "iii")}
							</p>

							<div
								style={{ fontSize: "20px" }}
								className={matched ? "round-date" : ""}
							>
								{format(columnDate, "dd")}
							</div>
						</div>
					);
				},
				dataIndex: day,
				key: day,
				width: 2,
				render: (events: any, row: any) => render(events, row, counter),
			};
		});
	}, [weekDates]);

	const hourColumn = {
		title: () => {
			return (
				<div>
					GMT
					{moment()
						.format()
						.substring(moment().format().length - 6, moment().format().length)}
				</div>
			);
		},
		dataIndex: "hour",
		key: "hour",
		width: 1,
		render: (hour: ColumnNode<T>, {}, id: number) => {
			return {
				props: {
					style: {
						width: "10%",
						borderBottom: "none",
						fontWeight: "normal",
						fontStyle: "normal",
						fontSize: "10px",
						lineHeight: "14px",
						textAlign: "right",
						fontFamily: "Open Sans",
						letterSpacing: "0.004em",
						paddingBottom: "40px",
					},
				},
				children:
					SCROLL_TO_ROW === id ? (
						<div ref={rowRef} style={{ marginTop: "-16px" }}>
							{hour}
						</div>
					) : (
						<div style={{ marginTop: "-16px" }}>{hour}</div>
					),
			};
		},
	};

	const tableColumns = [hourColumn, ...dayColumns];

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
			}}
		>
			<Table
				rowKey={(record) => record.id}
				dataSource={getDayHoursEvents(weekDates, getDayEvents)}
				columns={tableColumns}
				pagination={false}
				bordered={true}
				showHeader={true}
				scroll={{
					y: 1000,
				}}
				className="weekly-table"
			/>
		</div>
	);
}

export default Calendar;
