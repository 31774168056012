import "./Skeleton.styles.scss";
import FooterComponent from "../../Components/Shared/FooterComponent";
import { ReactNode } from "react";

interface Props {
	LeftContent: ReactNode;
	RightContent: ReactNode;
}

export const Skeleton = ({ LeftContent, RightContent }: Props) => (
	<div className="welcome-row page">
		<div
			id="left-split"
			style={{
				width: "44%",
				background: "linear-gradient(90deg, #F8F0FE 37.5%, #E4EEFD 78.65%)",
				borderRadius: "0px 8px 8px 0px",
			}}
		>
			{LeftContent}
		</div>
		<div id="right-split" className="welcome-column" style={{ width: "56%" }}>
			<div style={{ flex: "1 0 auto" }}>{RightContent}</div>
			<FooterComponent />
		</div>
	</div>
);
