import { FC } from "react";
import NotFound from "../Images/404.jpg";

const PageNotFound: FC = () => {
	return (
		<div
			style={{
				backgroundImage: `url(${NotFound})`,
				backgroundSize: "cover",
				backgroundPosition: "top",
				minHeight: "100%",
				height: "98vh",
				position: "relative",
			}}
		>
			<a href="https://www.vecteezy.com/free-vector/web">Vecteezy</a>
		</div>
	);
};

export default PageNotFound;
