import { Layout, Modal } from "antd";
import { FC, ReactChildren, ReactElement, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AsideComponent from "./AsideComponent";
import { HeaderComponent } from "./HeaderComponent";
import { GetUserTasks } from "../../Apis/Firebase/GetUserTasks";
import { CommandBar } from "../CommandBar";
import CloseIcon from "../../Images/Close.svg";
import { InviteModal } from "./Modals/Invite";
import InviteComplete from "./Modals/InviteComplete";
import { setUpdateRecordErrorModal } from "../../Store/ActionReducerPairs/Modals";
import UpdateError from "./Modals/UpdateError";
import { useAppSelector } from "../../hooks";

const { Content } = Layout;
export const BaseLayoutComponent: FC<{
	children: ReactElement | ReactChildren;
	pageTitle?: string | ReactElement;
}> = ({ children, pageTitle }) => {
	const dispatch = useDispatch();
	const [collapsed, setCollapsed] = useState(
		localStorage.getItem("sidebarCollapsed") !== "false",
	);
	const [invitedName, setInvitedName] = useState("");
	const [pendingTasks, setPendingTasks] = useState(0);
	const [showInviteModal, setShowInviteModal] = useState(false);
	const modals = useAppSelector(({ modals }) => modals);
	const taskManagerVisibility = useSelector(
		(state: any) => state.taskManager.visible,
	);
	const refreshTasks = useSelector((state: any) => state.taskManager.refresh);

	const onCollapse = (collapsed: boolean) => {
		setCollapsed(!collapsed);
		localStorage.setItem("sidebarCollapsed", !collapsed ? "true" : "false");
	};

	const onInvite = () => {
		setShowInviteModal(true);
	};

	useEffect(() => {
		GetUserTasks().then((res) => {
			const pending = res.filter(
				(item) => item.data().task_status === "Not Completed",
			);
			setPendingTasks(pending.length);
		});
	}, [taskManagerVisibility, refreshTasks]);

	const openTaskManager = (visible: string) => {
		if (visible === "visible") {
			window.analytics.page("TaskManager", {
				podUserId: localStorage.getItem("podUserId"),
			});
			dispatch({
				type: "OPEN_TASK_MANAGER",
			});
		} else {
			window.analytics.track("TaskManager_Closed", {
				podUserId: localStorage.getItem("podUserId"),
			});
			dispatch({
				type: "CLOSE_TASK_MANAGER",
			});
		}
	};

	return (
		<Layout className={`min-vh-100 ${collapsed ? "collapsed" : ""}`}>
			<CommandBar />
			<AsideComponent
				collapsed={collapsed}
				onCollapse={onCollapse}
				onInvite={onInvite}
				taskManagerVisibility={taskManagerVisibility}
				setTaskManagerVisibility={openTaskManager}
				pendingTasks={pendingTasks}
			/>
			<HeaderComponent
				collapsed={collapsed}
				onCollapse={onCollapse}
				onInvite={onInvite}
				pageTitle={pageTitle}
			/>
			<Content
				className={`common_content_wrapper ${collapsed ? "closed" : ""}`}
			>
				<div className="common_content_wrapper_holder">{children}</div>
			</Content>

			{showInviteModal && (
				<Modal
					centered
					className="invite-modal"
					visible={showInviteModal}
					destroyOnClose
					onCancel={() => setShowInviteModal(false)}
					zIndex={1020}
					closeIcon={<img className="close-icon" src={CloseIcon} alt="" />}
					title={
						<div style={{ justifyContent: "center" }}>
							<p
								style={{
									marginTop: "12px",
									fontWeight: "600",
									fontSize: "20px",
									letterSpacing: "0.0015em",
									color: "black",
								}}
							>
								Who would you like to invite to POD?
							</p>
						</div>
					}
					footer={null}
				>
					<InviteModal
						inviteSentCB={(email: string) => {
							setShowInviteModal(false);
							setInvitedName(email);
						}}
					/>
				</Modal>
			)}

			{invitedName && (
				<Modal
					centered
					className="invite-complete-modal"
					visible={!!invitedName}
					destroyOnClose
					onCancel={() => setInvitedName("")}
					zIndex={1020}
					closeIcon={null}
					footer={null}
				>
					<InviteComplete
						invitedName={invitedName}
						onClose={() => setInvitedName("")}
					/>
				</Modal>
			)}

			{modals.updateRecordError?.visible && (
				<Modal
					centered
					className="update-error-modal"
					visible={modals.updateRecordError.visible}
					destroyOnClose
					onCancel={() => setUpdateRecordErrorModal(undefined)}
					zIndex={1020}
					closeIcon={<img className="close-icon" src={CloseIcon} alt="" />}
					footer={null}
				>
					<UpdateError errorMessage={modals.updateRecordError.errorMessage} />
				</Modal>
			)}
		</Layout>
	);
};
