import { firebaseApp } from "./Config";
import {
	collection,
	query,
	where,
	getDocs,
	getFirestore,
} from "firebase/firestore";

export const GetUserTemplates = async () => {
	const db = getFirestore(firebaseApp);
	const pod_user_id = localStorage.getItem("podUserId");

	const q = query(
		collection(db, "document_templates"),
		where("pod_user_id", "==", pod_user_id),
	);
	const querySnapshot = await getDocs(q);

	return querySnapshot.docs;
};
