import api from "./api";

export const GetActivityDrawerDetails = async (
	reference_id: string,
	source: string,
	category: string,
) => {
	return api
		.post("/get_activity_hub_side_drawer_data", {
			reference_id,
			source,
			category,
			pod_user_id: Number(localStorage.getItem("podUserId")),
		})
		.then((res) => res.data.side_drawer_data);
};
