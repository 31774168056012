import { useEffect, useState } from "react";
import { BaseLayoutComponent } from "../Components/Shared/BaseLayoutComponent";
import moment from "moment";
import { WeeklyCalendar } from "../Components/WeeklyCalender/WeeklyCalendar";
import { GenericEvent } from "../Components/WeeklyCalender/types";
import "./CalendarViewPage.styles.scss";
import { useSelector } from "react-redux";
import PodGoogleLogin from "../Components/GoogleLogin";
import { API_FETCH } from "../Utils/Index";
import { isGoogleIntegrationEnabled } from "../Apis/Integration/google";
import { Modal } from "antd";
import ButtonGoogle from "../Images/Google/btn_google_signin_dark_normal_web@2x.png";
import { useAppDispatch } from "../hooks";

moment.updateLocale("en", {
	weekdaysMin: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
});

const CalendarViewPage = () => {
	const dispatch = useAppDispatch();
	const [data, setData] = useState<any>([]);
	const [loading, setLoading] = useState(false);
	const refreshStamp = useSelector(
		(state: any) => state.activityHub.calendarStamp,
	);
	const [isGoogleAuthed, setIsGoogleAuthed] = useState<boolean>(true);
	const [showGoogleModal, setShowGoogleModal] = useState<boolean>(true);
	const [currentWeekData, setCurrentWeekData] = useState({
		startDate: null,
		endDate: null,
	});
	const openDrawer = (event: any) => {
		dispatch({
			type: "OPEN_DRAWER",
			payload: {
				drawerType: "edit-event",
				event,
			},
		});
	};

	useEffect(() => {
		isGoogleIntegrationEnabled().then((res: boolean) => {
			if (res !== isGoogleAuthed) {
				setLoading(false);
			}
			setIsGoogleAuthed(res);
		});
		window.analytics.page("CalendarView", {
			podUserId: localStorage.getItem("podUserId"),
		});
	}, []);

	const handleWeekChange = (weekData: any) => {
		setCurrentWeekData(weekData);
	};

	let myHeaders = new Headers();
	myHeaders.append("Content-Type", "application/json");

	useEffect(() => {
		if (
			currentWeekData.startDate === null ||
			currentWeekData.endDate === null ||
			!isGoogleAuthed
		) {
			return;
		}
		setLoading(true);
		let sDate = moment(currentWeekData.startDate).format(
			"YYYY-MM-DDT00:00:00Z",
		);
		let eDate = moment(currentWeekData.endDate).format("YYYY-MM-DDT23:59:59Z");

		const data: any = {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				startDate: sDate,
				endDate: eDate,
				pod_user_id: Number(localStorage.getItem("podUserId")),
			}),
		};

		fetch(API_FETCH("/get_calendar_view_data"), data)
			.then((res: any) => res.json())
			.then((res: any) => {
				let arr: any = [];
				for (let event in res.calendarData.items) {
					let sDate = new Date(
						moment(res.calendarData.items[event].start.dateTime).get("year"),
						moment(res.calendarData.items[event].start.dateTime).get("month"),
						moment(res.calendarData.items[event].start.dateTime).get("date"),
						moment(res.calendarData.items[event].start.dateTime).get("hours"),
						moment(res.calendarData.items[event].start.dateTime).get("minutes"),
						moment(res.calendarData.items[event].start.dateTime).get("seconds"),
					);

					arr.push({
						eventId: res.calendarData.items[event].id,
						startTime: sDate,
						endTime: new Date(
							moment(res.calendarData.items[event].end.dateTime).get("year"),
							moment(res.calendarData.items[event].end.dateTime).get("month"),
							moment(res.calendarData.items[event].end.dateTime).get("date"),
							moment(res.calendarData.items[event].end.dateTime).get("hour"),
							moment(res.calendarData.items[event].end.dateTime).get("minutes"),
							moment(res.calendarData.items[event].end.dateTime).get("seconds"),
						),
						title: res.calendarData.items[event].summary,
						attendees: res.calendarData.items[event].attendees,
						organizer: res.calendarData.items[event].organizer,
						conferenceData: res.calendarData.items[event].conferenceData,
						description: res.calendarData.items[event].description,
						hangoutLink: res.calendarData.items[event].hangoutLink,
						htmlLink: res.calendarData.items[event].htmlLink,
						backgroundColor: "#F5F5F5",
						location: res.calendarData.items[event].location,
					});
				}
				setData(arr);
				setLoading(false);
			})
			.catch((err: any) => console.log(err));
	}, [currentWeekData, refreshStamp, isGoogleAuthed]);

	return (
		<BaseLayoutComponent pageTitle={<h3>Calendar</h3>}>
			<div>
				{isGoogleAuthed ? null : (
					<Modal
						centered
						visible={showGoogleModal}
						footer={null}
						title={null}
						closable={false}
						destroyOnClose
						onCancel={() => setShowGoogleModal(false)}
						width={315}
						className="google-login-modal"
					>
						<div className="text-center py-2">
							<img
								style={{ width: 44 }}
								src="/images/icons/google-login-icon.png"
								alt="google-icon"
								className="google-icon"
							/>
						</div>
						<div className="text-center">
							<div className="pt-3">
								<p className="short-desc-for-google-login">
									Sync your Google account for access to your schedule on the
									go.
								</p>
							</div>
							<div className="pt-2">
								<PodGoogleLogin
									render={({ onClick }) => (
										<button
											style={{ border: "none", background: "none" }}
											className="text-center py-2"
											onClick={onClick}
										>
											<img
												src={ButtonGoogle}
												alt="google-icon"
												className="google-icon"
											/>
										</button>
									)}
									onSuccess={() => setIsGoogleAuthed(true)}
								/>
							</div>
						</div>
					</Modal>
				)}
				<div style={{ margin: "-8px -16px" }}>
					<WeeklyCalendar
						currentWeekData={handleWeekChange}
						events={data}
						weekends={true}
						onEventClick={(event: GenericEvent) => {
							openDrawer(event);
						}}
						onSelectDate={(date) => console.log(date)}
						loading={loading}
					/>
				</div>
			</div>
		</BaseLayoutComponent>
	);
};

export default CalendarViewPage;
