import { Spin, Select, Button } from "antd";
import { useState, useEffect, useMemo } from "react";
import moment from "moment";
import TaskCard from "./Card";
import NewTask from "./New";
import { GetOpportunityTasks } from "../../../Apis/Firebase/GetUserTasks";

import "./style.scss";
import { useDispatch } from "react-redux";

const taskFilters = [
	"All tasks",
	"Due today",
	"Due this week",
	"Due next week",
];

export interface Props {
	opportunityId: string;
	opportunityName: string;
}

const TaskList = ({ opportunityId, opportunityName }: Props) => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [tasks, setTasks] = useState<Array<any>>([]);
	const [taskFilter, setTaskFilter] = useState("All tasks");
	const [newTask, setNewTask] = useState(false);
	const loadData = () => {
		setNewTask(false);
		setLoading(true);
		GetOpportunityTasks(opportunityId).then((res) => {
			setTasks(res);
			setLoading(false);
		});
		dispatch({
			type: "UPDATE_TASK_MANAGER_STATE",
			payload: {
				refresh: Date.now(),
			},
		});
	};

	useEffect(() => {
		loadData();
	}, [opportunityId]);

	const filteredTasks = useMemo(() => {
		const today = moment().format("YYYY-MM-DD");
		const thisWeekStart = moment().startOf("week").format("YYYY-MM-DD");
		const thisWeekEnd = moment().endOf("week").format("YYYY-MM-DD");
		const nextWeekStart = moment(thisWeekEnd)
			.add(1, "day")
			.format("YYYY-MM-DD");
		const nextWeekFinish = moment(nextWeekStart)
			.endOf("week")
			.format("YYYY-MM-DD");
		return tasks.filter((item) => {
			if (taskFilter === "Due today") {
				return today === item.due_date;
			} else if (taskFilter === "Due this week") {
				return item.due_date >= thisWeekStart && item.due_date <= thisWeekEnd;
			} else if (taskFilter === "Due next week") {
				return (
					item.due_date >= nextWeekStart && item.due_date <= nextWeekFinish
				);
			}
			return true;
		});
	}, [tasks, taskFilter]);

	return (
		<div className="task-list">
			<div
				className="pip_table_extras_select me-3 ms-1"
				style={{
					marginBottom: 16,
					flexDirection: "row",
					justifyContent: "space-between",
					display: "flex",
					width: "100%",
				}}
			>
				<Select
					size="middle"
					value={taskFilter}
					className="custom_select"
					onChange={(value: string) => setTaskFilter(value)}
					style={{ width: 160 }}
					notFoundContent={
						!taskFilters.length ? "Search for a record ..." : "No results found"
					}
				>
					{taskFilters.map((item) => (
						<Select.Option key={item} value={item}>
							{item}
						</Select.Option>
					))}
				</Select>
				<Button
					className="pod_btn_secondary"
					style={{ height: 32 }}
					onClick={() => setNewTask(true)}
				>
					+ Task
				</Button>
			</div>
			{newTask && (
				<NewTask
					opportunityId={opportunityId}
					opportunityName={opportunityName}
					reload={loadData}
				/>
			)}
			{loading ? (
				<Spin style={{ width: "100%", marginTop: 20 }} />
			) : (
				filteredTasks.map((item) => (
					<TaskCard
						key={item.id}
						task={item.data()}
						taskId={item.id}
						reload={loadData}
					/>
				))
			)}
		</div>
	);
};

export default TaskList;
