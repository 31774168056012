import { Select } from "antd";
import clsx from "clsx";
import { useEffect, useState } from "react";
import {
	searchSObjectsByName,
	SObjectSearchResult,
} from "../../Apis/multiSObject";

export const DropdownSearch = ({
	currentId,
	currentName,
	updateData,
	UpdateTextOrNumberType,
	onBlur,
	objectType,
	size,
	allowClear,
	disabled,
	style,
	className,
	autoFocus,
	useIdAsValue = true,
}: {
	currentId: string;
	currentName: string;
	objectType: string;
	updateData?: any;
	UpdateTextOrNumberType: any;
	onBlur?: any;
	size?: any;
	allowClear?: boolean;
	disabled?: boolean;
	style?: any;
	className?: string;
	autoFocus?: boolean;
	useIdAsValue?: boolean;
}) => {
	const [hideOptions, setHideOptions] = useState(false);
	const [selectOptions, setSelectOption] = useState<SObjectSearchResult[]>([]);
	const [searchValue, setSearchValue] = useState("");
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (currentName) {
			setSelectOption([{ id: currentId, name: currentName, type: "" }]);
		}
	}, [currentName, currentId]);

	const onMenuClick = (value: any) => {
		const name = selectOptions.find((item) => item.id === value)?.name;
		UpdateTextOrNumberType(value || "", updateData, name || "");
	};

	const onSearchChange = (value: string) => {
		setSearchValue(value);
		setLoading(true);
		searchSObjectsByName([objectType], value, 5)
			.then((r) => setSelectOption(r))
			.finally(() => setLoading(false));
		setHideOptions(false);
	};

	return (
		<Select
			size={size || "large"}
			placeholder="Select"
			showSearch
			loading={loading}
			allowClear={allowClear}
			autoClearSearchValue={false}
			searchValue={searchValue}
			onSearch={onSearchChange}
			value={currentId ? currentId : null}
			onChange={(item) => onMenuClick(item)}
			filterOption={() => true}
			style={{ width: "100%", ...style }}
			disabled={disabled}
			notFoundContent={
				hideOptions || !searchValue
					? "Search for a record ..."
					: "No results found"
			}
			className={clsx("dropdown_search_select", className)}
			onBlur={onBlur}
			autoFocus={autoFocus}
		>
			{!hideOptions
				? selectOptions.map(({ id, name }) => (
						<Select.Option key={id} value={id}>
							{name}
						</Select.Option>
				  ))
				: null}
		</Select>
	);
};
