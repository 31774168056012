import { firebaseApp } from "./Config";
import { doc, setDoc, getFirestore } from "firebase/firestore";
import moment from "moment";

export const CreateTask = async (
	task_status: string = "",
	opportunity_id: string = "",
	opportunity_name: string = "",
	content: string = "",
	assignee: string = "",
	due_date: string = moment().format().split("T")[0],
	creator: string = "",
) => {
	const db = getFirestore(firebaseApp);
	const pod_user_id = localStorage.getItem("podUserId");
	const reference = `${pod_user_id}${Date.now()}`;
	const document = doc(db, "tasks", reference);
	await setDoc(document, {
		pod_user_id,
		content,
		opportunity_id,
		opportunity_name,
		task_status,
		assignee,
		due_date,
		creator,
		created_at: moment().format().split("T")[0],
		last_modified_at: moment().format().split("T")[0],
	});

	return reference;
};
