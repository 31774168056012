import { Drawer } from "antd";
import "./PipelineDrawerComponent.styles.scss";
import { PipelineOpportunityDrawerComponent } from "./PipelineOpportunityDrawerComponent";
import { PipelineAccountDrawerComponent } from "./PipelineAccountDrawerComponent";
import { PipelineContactDrawerComponent } from "./PipelineContactDrawerComponent";
import { PipelineLeadDrawerComponent } from "./PipelineLeadDrawerComponent";
import { DrawerProps } from "../DrawerList";

const DrawerRender = ({
	initData,
}: { initData: { objectType: string; objectId: string } }) => {
	switch (initData && initData.objectType) {
		case "Opportunity": {
			return <PipelineOpportunityDrawerComponent initData={initData} />;
		}
		case "Account": {
			return <PipelineAccountDrawerComponent initData={initData} />;
		}
		case "Contact": {
			return <PipelineContactDrawerComponent initData={initData} />;
		}
		case "Lead": {
			return <PipelineLeadDrawerComponent initData={initData} />;
		}
		default: {
			return null;
		}
	}
};

export const PipelineDrawerComponent = ({
	visible,
	initData,
	onClose,
}: DrawerProps) => {
	return (
		<Drawer
			destroyOnClose
			title=""
			width={500}
			closable={false}
			onClose={onClose}
			visible={visible}
		>
			<DrawerRender initData={initData} />
		</Drawer>
	);
};
