import { Tooltip } from "antd";
import { AiOutlineDoubleRight, AiOutlineInfoCircle } from "react-icons/ai";
import { ReactNode } from "react";

import "./ActivityHubDrawerLayout.styles.scss";

export const ActivityHubDrawerLayout = ({
	icon,
	title,
	description,
	children,
	onClose,
}: {
	icon: ReactNode;
	title: string;
	description: string;
	onClose: VoidFunction;
	children: ReactNode;
}) => {
	return (
		<div className="pod_drawer activity_hub_drawer">
			<div className="pod_drawer_head">
				<div className="d-flex justify-content-between">
					<div className="pod_drawer_head--title">
						{icon}
						<span>{title}</span>
					</div>
					<div className="pod_drawer_head--actions">
						<span className="pod_drawer_head--icon">
							<Tooltip title={description} placement="bottomRight">
								<AiOutlineInfoCircle size={18} />
							</Tooltip>
						</span>
						<span className="pod_drawer_head--icon" onClick={onClose}>
							<AiOutlineDoubleRight size={18} />
						</span>
					</div>
				</div>
			</div>

			<div className="pod_drawer_content">{children}</div>
		</div>
	);
};
