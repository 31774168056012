import { AnyAction } from "redux";

export interface DefaultDrawerState {
	drawers: Array<any>;
}

const defaultState: DefaultDrawerState = {
	drawers: [],
};

export const drawerReducer = (
	state: DefaultDrawerState = defaultState,
	action: AnyAction,
) => {
	const [_, ...rest] = state.drawers;

	switch (action.type) {
		case "OPEN_DRAWER":
			return {
				...state,
				drawers: [action.payload, ...state.drawers],
			};
		case "CLOSE_DRAWER":
			return {
				...state,
				drawers: rest,
			};
		case "CLOSE_ALL":
			return {
				...state,
				drawers: [],
			};
		default:
			return state;
	}
};
