import { useEffect, useState } from "react";
import { Checkbox, Skeleton } from "antd";
import { ReactSVG } from "react-svg";
import clsx from "clsx";
import { useAppDispatch } from "../../../../../hooks";
import { getNotesNextSteps } from "../../../../../Apis/NotesAssistant/nextSteps";
import { Note } from "../NotesAssistantDrawer";

export const NextStepsTab = ({ note }: { note: Note }) => {
	const dispatch = useAppDispatch();
	const [selectedIds, setSelectedIds] = useState<number[]>([]);
	const [nextSteps, setNextSteps] = useState<string[] | undefined>(undefined);

	useEffect(() => {
		(async () => {
			for await (const value of getNotesNextSteps(note.type, note.content)) {
				setNextSteps((prevState) => (prevState ?? []).concat(value));
			}
			setNextSteps((prevState) => prevState ?? []);
		})();
	}, []);

	const renderNextSteps = () => {
		switch (nextSteps) {
			case undefined:
				return <Skeleton active paragraph={{ rows: 10 }}></Skeleton>;
			case null:
				return <p>No next steps detected</p>;
			default:
				return (
					<div className="notes_assistant_tab next_steps">
						<div className="summary_block">
							<div className="summary_block--title">Next Steps</div>
							<div className="summary_block--content next_steps_block">
								{nextSteps.map((nextStep, i) => (
									<div className="next_steps_item" key={i}>
										<Checkbox
											className={clsx(
												"next_steps_item--check",
												selectedIds.includes(i) && "checked",
											)}
											onClick={() => {
												if (selectedIds.includes(i)) {
													selectedIds.splice(selectedIds.indexOf(i), 1);
												} else {
													selectedIds.push(i);
												}
												setSelectedIds([...selectedIds]);
											}}
										>
											{nextStep}
										</Checkbox>
										<div
											className="next_steps_item--add"
											onClick={() => {
												dispatch({
													type: "UPDATE_TASK_MANAGER_STATE",
													payload: {
														newTaskContent: nextStep,
														newTaskOpportunityName: "Opportunity Name",
														newTaskOpportunityId: "Opportunity Id",
														showNewTaskArea: "block",
														visible: "visible",
													},
												});
											}}
										>
											<ReactSVG src="/images/icons/NotesAddTask.svg" />
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				);
		}
	};

	return renderNextSteps();
};
