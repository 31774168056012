import api from "./api";

export type SObjectSearchResult = {
	id: string;
	name: string;
	type: string;
};

export const searchSObjectsByName = async (
	sobjects: string[],
	query: string,
	limit?: number,
	excludeIds: string[] = [],
): Promise<SObjectSearchResult[]> => {
	return api
		.post("/search_sobjects_by_name", {
			sobjects,
			query,
			limit,
			excludeIds,
			pod_user_id: Number(localStorage.getItem("podUserId")),
		})
		.then((res) =>
			res.data.map((item: Record<string, any>) => ({
				id: item.Id,
				name: item.Name,
				type: item.attributes.type,
			})),
		);
};

export const getSObjectsByIds = async (
	sobjects: string[],
	ids: string[],
): Promise<SObjectSearchResult[]> => {
	return api
		.post("/get_sobjects_by_id", {
			sobjects,
			ids,
			pod_user_id: Number(localStorage.getItem("podUserId")),
		})
		.then((res) =>
			res.data.map((item: Record<string, any>) => ({
				id: item.Id,
				name: item.Name,
				type: item.attributes.type,
			})),
		);
};
