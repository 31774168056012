import api from "../api";

export const persistReduxState = async (state: unknown) => {
	await api
		.post("persist_redux_state", {
			pod_user_id: Number(localStorage.getItem("podUserId")),
			state,
		})
		.then((res) => res.data);
};
