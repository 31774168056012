import { API_FETCH } from "../../Utils/Index";

export const GetRelatedOpportunities = (type: any, id: any) => {
	const myHeaders = new Headers();
	myHeaders.append("Content-Type", "application/json");

	let _id: any;
	if (type === "contact") {
		_id = {};
		_id = { contactId: id };
	} else if (type === "account") {
		_id = {};
		_id = { accountId: id };
	} else {
		_id = {};
	}
	const requestOptions: any = {
		method: "POST",
		headers: myHeaders,
		body: JSON.stringify({
			..._id,
			pod_user_id: Number(localStorage.getItem("podUserId")),
		}),
		redirect: "follow",
	};
	return new Promise((resolve, reject) => {
		fetch(API_FETCH(`/get_related_opportunities_for_${type}`), requestOptions)
			.then((response: any) => response.json())
			.then((result: PromiseLike<Object[]>) => resolve(result))
			.catch((error: any) => {
				console.warn("error while getting Opportunites =>", error);
				reject(error);
			});
	});
};
