import api from "./api";

export const UpdateSFDCSideDrawerConfig = async (
	field_name: string,
	field_type: string,
	object_type: string,
	toggle_type: string,
	reference_to: Array<string>,
) => {
	return api
		.post("update_sfdc_side_drawer_config", {
			pod_user_id: Number(localStorage.getItem("podUserId")),
			object_type,
			toggle_type,
			reference_to,
			field_name,
			field_type,
		})
		.then((res) => res.data);
};
